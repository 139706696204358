import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'

import { Loading } from 'app_search/components/Loading'
import { ENGINE_CURATIONS_NEW_PATH, getCurationRoute, getEngineRoute } from 'app_search/utils/routePaths'

import { CurationsLogic } from './CurationsLogic'

import {
  EuiButton,
  EuiSpacer
} from '@elastic/eui'

import StuiEmptyState from 'stui/EmptyState'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'
import StuiPagination from 'stui/Pagination'
import {
  StuiTable,
  StuiTableBody,
  StuiTableBodyCell,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'
import StuiTruncatedContent from 'stui/TruncatedContent'

const MAX_LENGTH = 16

import { ICuration, IMeta } from 'app_search/types'

export interface ICurationsProps {
  actions: {
    initializeCurations()
    deleteCurationSet(id: string)
    handlePageClick()
    resetCuration()
  }
  engineName: string
  curations: ICuration[]
  flashMessages: IStuiFlashMessagesProps
  meta: IMeta
  dataLoading: boolean
}

const Curations: React.SFC<ICurationsProps> = ({
  actions: {
    initializeCurations,
    deleteCurationSet,
    handlePageClick,
    resetCuration
  },
  curations,
  engineName,
  flashMessages,
  meta,
  dataLoading
}) => {
  useEffect(() => {
    initializeCurations()
    return resetCuration
  }, [])

  if (dataLoading) { return <Loading /> }

  const hasCurations = curations.length > 0
  const createPinsLink = <Link to={getEngineRoute(ENGINE_CURATIONS_NEW_PATH, engineName)}>
    <EuiButton
      color="secondary"
      fill={true}
      data-test-subj="CreateNewCurationButton"
    >
      Create new Curation
    </EuiButton>
  </Link>
  const curationsContent = !hasCurations ?
    <StuiEmptyState
      icon="result_ranking-fill"
      title="No curations yet"
      action={createPinsLink}
    /> :
    <>
      <StuiTable>
        <StuiTableHead>
          <StuiTableRow>
            <StuiTableHeadCell>Queries</StuiTableHeadCell>
            <StuiTableHeadCell>Last Updated</StuiTableHeadCell>
            <StuiTableHeadCell />
          </StuiTableRow>
        </StuiTableHead>
        <StuiTableBody>
          {curations.map((curation, index) => (
            <StuiTableRow key={index}>
              <StuiTableBodyCell wrap={true}>
                <Link to={getCurationRoute(engineName, curation.id)}>
                  {curation.queries.map((query, i) => (
                    <span key={i}>
                      {query.length < MAX_LENGTH ?
                        query :
                        <StuiTruncatedContent key={i} tooltipType="title" content={query} length={MAX_LENGTH} />}
                      {i < curation.queries.length - 1 ? ',' : ''}
                      &nbsp;
                    </span>
                  ))}
                </Link>
              </StuiTableBodyCell>
              <StuiTableBodyCell>{curation.last_updated} </StuiTableBodyCell>
              <StuiTableBodyCell>
                <Link
                  className="o-stui-table-action"
                  to={getCurationRoute(engineName, curation.id)}
                  data-test-subj="UpdateCurationsLink"
                >
                  Update
                </Link>
                <a className="o-stui-table-action" onClick={() => deleteCurationSet(curation.id)}>Remove</a>
              </StuiTableBodyCell>
            </StuiTableRow>
          ))}
        </StuiTableBody>
      </StuiTable>
      <EuiSpacer />
      {meta && meta.page.total_pages > 0 &&
        <StuiPagination
          key="pager"
          metaPage={meta.page}
          handlePageClick={handlePageClick}
        />}
    </>

  return (
    <div data-test-subj="Curations">
      <StuiHeader
        key="header"
        contained={true}
        viewHeader={true}
        isSticky={true}
        actions={hasCurations ? createPinsLink : null}
      >
        <StuiHeading type="view">Curated Results</StuiHeading>
      </StuiHeader>
      <EuiSpacer key="spacer" />
      <StuiMain key="main">
        <StuiFlashMessages {...flashMessages} />
        {curationsContent}
      </StuiMain>
    </div>
  )
}

export default CurationsLogic(Curations)
