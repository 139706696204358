import React from 'react'

import classNames from 'classnames'

import StuiIcon from 'stui/Icon'
import StuiTooltip from 'stui/Tooltip'

interface IStuiHiddenKeyProps {
  content?: string
  isVisible?: boolean
}

interface IStuiHiddenKeyState {
  visible: boolean
}

class StuiHiddenKey extends React.Component<IStuiHiddenKeyProps, IStuiHiddenKeyState> {
  readonly state: IStuiHiddenKeyState = {
    visible: this.props.isVisible || false
  }

  render() {
    const { content = '', ...rest } = this.props

    const { visible } = this.state

    const contentClassName = classNames(
      'o-stui-copy-field',
      'o-stui-hidden-text__content',
      {
        'o-stui-hidden-text__content--hidden': !visible
      }
    )

    const label = visible ? 'Hide key' : 'Show key'

    return (
      <div className="o-stui-hidden-text">
        <div className="o-stui-hidden-text__control" onClick={this.onChange}>
          <StuiTooltip placement="top" content={label}>
            <span title={label}>
              <StuiIcon name={visible ? 'hide-fill' : 'show-fill'} />
            </span>
          </StuiTooltip>
        </div>
        <input
          {...rest}
          className={contentClassName}
          readOnly={true}
          value={content}
        />
      </div>
    )
  }

  private onChange = () => {
    this.setState({ visible: !this.state.visible })
  }
}

export default StuiHiddenKey
