import React from 'react'

import {
  EuiButton,
  EuiCallOut
} from '@elastic/eui'

interface IDeleteMappingCalloutProps {
  handleDeleteMapping()
}

const DeleteMappingCallout: React.SFC<IDeleteMappingCalloutProps> = ({ handleDeleteMapping }) => (
  <EuiCallOut
    color="danger"
    iconType="alert"
    title="Remove this role mapping"
  >
    <p>Please note that deleting a mapping is permanent and cannot be undone</p>
    <EuiButton
      color="danger"
      fill={true}
      onClick={handleDeleteMapping}
    >
      Delete Mapping
    </EuiButton>
  </EuiCallOut>
)

export default DeleteMappingCallout
