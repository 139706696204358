import React, { useState } from 'react'

import classNames from 'classnames'
import { useValues } from 'kea'

import { AppLogic } from 'app_search/App'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSelect
} from '@elastic/eui'

import StuiCode from 'stui/Code'

interface ICodeExampleProps {
  engineName: string
  apiKey: string
}

const BASH = 'bash'
const RUBY = 'ruby'
const PYTHON = 'python'
const JAVASCRIPT = 'javascript'
const JAVA = 'java'

const activeCssClass = 'c-stui-code-block--visible'

const CodeExample: React.SFC<ICodeExampleProps> = ({ engineName, apiKey }) => {
  const [currentLanguage, setCurrentLanguage] = useState(BASH)
  const { account: { accountKey }, apiUrl } = useValues(AppLogic)

  const handleLanguageChange = e => setCurrentLanguage(e.target.value)

  const languages = [
    {
      value: BASH,
      text: 'cURL',
      example: `${`
              curl -X POST '${apiUrl}/api/as/v1/engines/${engineName}/documents' \\
                -H 'Content-Type: application/json' \\
                -H 'Authorization: Bearer ${apiKey}' \\
                -d '[
                      {
                        "id": "park_rocky-mountain",
                        "title": "Rocky Mountain",
                        "description": "Bisected north to south by the Continental Divide, this portion of the Rockies has ecosystems varying from over 150 riparian lakes to montane and subalpine forests to treeless alpine tundra. Wildlife including mule deer, bighorn sheep, black bears, and cougars inhabit its igneous mountains and glacial valleys. Longs Peak, a classic Colorado fourteener, and the scenic Bear Lake are popular destinations, as well as the historic Trail Ridge Road, which reaches an elevation of more than 12,000 feet (3,700 m).",
                        "nps_link": "https://www.nps.gov/romo/index.htm",
                        "states": [
                          "Colorado"
                        ],
                        "visitors": 4517585,
                        "world_heritage_site": false,
                        "location": "40.4,-105.58",
                        "acres": 265795.2,
                        "square_km": 1075.6,
                        "date_established": "1915-01-26T06:00:00Z"
                      },
                      {
                        "id": "park_saguaro",
                        "title": "Saguaro",
                        "description": "Split into the separate Rincon Mountain and Tucson Mountain districts, this park is evidence that the dry Sonoran Desert is still home to a great variety of life spanning six biotic communities. Beyond the namesake giant saguaro cacti, there are barrel cacti, chollas, and prickly pears, as well as lesser long-nosed bats, spotted owls, and javelinas.",
                        "nps_link": "https://www.nps.gov/sagu/index.htm",
                        "states": [
                          "Arizona"
                        ],
                        "visitors": 820426,
                        "world_heritage_site": false,
                        "location": "32.25,-110.5",
                        "acres": 91715.72,
                        "square_km": 371.2,
                        "date_established": "1994-10-14T05:00:00Z"
                      }
                    ]'
              # [
              #   {
              #     "id": "park_rocky-mountain",
              #     "errors": []
              #   },
              #   {
              #     "id": "park_saguaro",
              #     "errors": []
              #   }
              # ]
            `}`
    }
  ]

  if (!process.env.LOCO_TOGO) {
    languages.push(
      {
        value: RUBY,
        text: 'Ruby',
        example: `${`
                client = Elastic::AppSearch::Client.new(:host_identifier => '${accountKey}', :api_key => '${apiKey}')
                engine_name = '${engineName}'

                documents = [
                  {
                    :id => 'park_rocky-mountain',
                    :title => 'Rocky Mountain',
                    :description => 'Bisected north to south by the Continental Divide, this portion of the Rockies has ecosystems varying from over 150 riparian lakes to montane and subalpine forests to treeless alpine tundra. Wildlife including mule deer, bighorn sheep, black bears, and cougars inhabit its igneous mountains and glacial valleys. Longs Peak, a classic Colorado fourteener, and the scenic Bear Lake are popular destinations, as well as the historic Trail Ridge Road, which reaches an elevation of more than 12,000 feet (3,700 m).',
                    :nps_link => 'https://www.nps.gov/romo/index.htm',
                    :states => [
                      'Colorado'
                    ],
                    :visitors => 4517585,
                    :world_heritage_site => false,
                    :location => '40.4,-105.58',
                    :acres => 265795.2,
                    :square_km => 1075.6,
                    :date_established => '1915-01-26T06:00:00Z'
                  },
                  {
                    :id => 'park_saguaro',
                    :title => 'Saguaro',
                    :description => 'Split into the separate Rincon Mountain and Tucson Mountain districts, this park is evidence that the dry Sonoran Desert is still home to a great variety of life spanning six biotic communities. Beyond the namesake giant saguaro cacti, there are barrel cacti, chollas, and prickly pears, as well as lesser long-nosed bats, spotted owls, and javelinas.',
                    :nps_link => 'https://www.nps.gov/sagu/index.htm',
                    :states => [
                      'Arizona'
                    ],
                    :visitors => 820426,
                    :world_heritage_site => false,
                    :location => '32.25,-110.5',
                    :acres => 91715.72,
                    :square_km => 371.2,
                    :date_established => '1994-10-14T05:00:00Z'
                  }
                ]

                index_document_results = client.index_documents(engine_name, documents)
                # [
                #   {
                #     "id" => "park_rocky-mountain",
                #     "errors" => []
                #   },
                #   {
                #     "id" => "park_saguaro",
                #     "errors" => []
                #   }
                # ]
              `}`
      },
      {
        value: PYTHON,
        text: 'Python',
        example: `${`
                from elastic_app_search import Client
                client = Client('${accountKey}', '${apiKey}')
                engine_name = '${engineName}'

                documents = [
                    {
                      'id': 'park_rocky-mountain',
                      'title': 'Rocky Mountain',
                      'description': 'Bisected north to south by the Continental Divide, this portion of the Rockies has ecosystems varying from over 150 riparian lakes to montane and subalpine forests to treeless alpine tundra. Wildlife including mule deer, bighorn sheep, black bears, and cougars inhabit its igneous mountains and glacial valleys. Longs Peak, a classic Colorado fourteener, and the scenic Bear Lake are popular destinations, as well as the historic Trail Ridge Road, which reaches an elevation of more than 12,000 feet (3,700 m).',
                      'nps_link': 'https://www.nps.gov/romo/index.htm',
                      'states': [
                        'Colorado'
                      ],
                      'visitors': 4517585,
                      'world_heritage_site': False,
                      'location': '40.4,-105.58',
                      'acres': 265795.2,
                      'square_km': 1075.6,
                      'date_established': '1915-01-26T06:00:00Z'
                    },
                    {
                      'id': 'park_saguaro',
                      'title': 'Saguaro',
                      'description': 'Split into the separate Rincon Mountain and Tucson Mountain districts, this park is evidence that the dry Sonoran Desert is still home to a great variety of life spanning six biotic communities. Beyond the namesake giant saguaro cacti, there are barrel cacti, chollas, and prickly pears, as well as lesser long-nosed bats, spotted owls, and javelinas.',
                      'nps_link': 'https://www.nps.gov/sagu/index.htm',
                      'states': [
                        'Arizona'
                      ],
                      'visitors': 820426,
                      'world_heritage_site': False,
                      'location': '32.25,-110.5',
                      'acres': 91715.72,
                      'square_km': 371.2,
                      'date_established': '1994-10-14T05:00:00Z'
                    }
                ]

                index_document_results = client.index_documents(engine_name, documents)
                # [
                #   {
                #     "id": "park_rocky-mountain",
                #     "errors": []
                #   },
                #   {
                #     "id": "park_saguaro",
                #     "errors": []
                #   }
                # ]
              `}`
      },
      {
        value: JAVASCRIPT,
        text: 'Node',
        example: `${`
                const AppSearchClient = require('@elastic/app-search-node')
                const client = new AppSearchClient('${accountKey}', '${apiKey}')
                const engineName = '${engineName}'
                const documents = [
                  {
                    id: 'park_rocky-mountain',
                    title: 'Rocky Mountain',
                    description: 'Bisected north to south by the Continental Divide, this portion of the Rockies has ecosystems varying from over 150 riparian lakes to montane and subalpine forests to treeless alpine tundra. Wildlife including mule deer, bighorn sheep, black bears, and cougars inhabit its igneous mountains and glacial valleys. Longs Peak, a classic Colorado fourteener, and the scenic Bear Lake are popular destinations, as well as the historic Trail Ridge Road, which reaches an elevation of more than 12,000 feet (3,700 m).',
                    nps_link: 'https://www.nps.gov/romo/index.htm',
                    states: [
                      'Colorado'
                    ],
                    visitors: 4517585,
                    world_heritage_site: false,
                    location: '40.4,-105.58',
                    acres: 265795.2,
                    square_km: 1075.6,
                    date_established: '1915-01-26T06:00:00Z'
                  },
                  {
                    id: 'park_saguaro',
                    title: 'Saguaro',
                    description: 'Split into the separate Rincon Mountain and Tucson Mountain districts, this park is evidence that the dry Sonoran Desert is still home to a great variety of life spanning six biotic communities. Beyond the namesake giant saguaro cacti, there are barrel cacti, chollas, and prickly pears, as well as lesser long-nosed bats, spotted owls, and javelinas.',
                    nps_link: 'https://www.nps.gov/sagu/index.htm',
                    states: [
                      'Arizona'
                    ],
                    visitors: 820426,
                    world_heritage_site: false,
                    location: '32.25,-110.5',
                    acres: 91715.72,
                    square_km: 371.2,
                    date_established: '1994-10-14T05:00:00Z'
                  }
                ]

                client.indexDocuments(engineName, documents)
                  .then((docs) => {
                    // handle docs
                    // [
                    //   {
                    //     id: "park_rocky-mountain",
                    //     errors: []
                    //   },
                    //   {
                    //     id: "park_saguaro",
                    //     errors: []
                    //   }
                    // ]
                  })
                  .catch((error) => {
                    // handle error
                  })
              `}`
      },
      {
        value: JAVA,
        text: 'Java',
        example: `${`
                import com.swiftype.appsearch.Client;
                Client client = new Client("${accountKey}", "${apiKey}");
                String engineName = "${engineName}";

                Map<String, Object> doc1 = new HashMap<>();
                doc1.put("id", "park_rocky-mountain");
                doc1.put("title", "Rocky Mountain");
                doc1.put("description", "Bisected north to south by the Continental Divide, this portion of the Rockies has ecosystems varying from over 150 riparian lakes to montane and subalpine forests to treeless alpine tundra. Wildlife including mule deer, bighorn sheep, black bears, and cougars inhabit its igneous mountains and glacial valleys. Longs Peak, a classic Colorado fourteener, and the scenic Bear Lake are popular destinations, as well as the historic Trail Ridge Road, which reaches an elevation of more than 12,000 feet (3,700 m).");
                doc1.put("nps_link", "https://www.nps.gov/romo/index.htm");
                doc1.put("states", Arrays.asList("Colorado"));
                doc1.put("visitors", 4517585);
                doc1.put("world_heritage_site", false);
                doc1.put("location", "40.4,-105.58");
                doc1.put("acres", 265795.2);
                doc1.put("square_km", 1075.6);
                doc1.put("date_established", "1915-01-26T06:00:00Z");

                Map<String, Object> doc2 = new HashMap<>();
                doc2.put("id", "park_saguaro");
                doc2.put("title", "Saguaro");
                doc2.put("description", "Split into the separate Rincon Mountain and Tucson Mountain districts, this park is evidence that the dry Sonoran Desert is still home to a great variety of life spanning six biotic communities. Beyond the namesake giant saguaro cacti, there are barrel cacti, chollas, and prickly pears, as well as lesser long-nosed bats, spotted owls, and javelinas.");
                doc2.put("nps_link", "https://www.nps.gov/sagu/index.htm");
                doc2.put("states", Arrays.asList("Arizona"));
                doc2.put("visitors", 820426);
                doc2.put("world_heritage_site", false);
                doc2.put("location", "32.25,-110.5");
                doc2.put("acres", 91715.72);
                doc2.put("square_km", 371.2);
                doc2.put("date_established", "1994-10-14T05:00:00Z");

                List<Map<String, Object>> documents = Arrays.asList(doc1, doc2);
                client.indexDocuments(engineName, documents);
              `}`
      }
    )
  }

  return (
    <div className="c-stui-multi-code-block-container">
      <div className="c-stui-multi-code-block-container__header">
        <EuiFlexGroup>
          <EuiFlexItem className="c-stui-multi-code-block-container__description">
            <div>
              <strong>Example </strong>
              — Using the API to index JSON into the <code className="o-stui-code-snippet">
                {engineName}</code> Engine
              </div>
          </EuiFlexItem>
          {!process.env.LOCO_TOGO &&
            <EuiFlexItem grow={false}>
              <EuiSelect
                name="language"
                value={currentLanguage}
                options={languages}
                onChange={handleLanguageChange}
                compressed={true}
              />
            </EuiFlexItem>
          }
        </EuiFlexGroup>
      </div>
      <div className="c-stui-multi-code-block-container__code-blocks">
        {languages.map((lang) => (
          <StuiCode key={lang.value} language={lang.value} className={classNames({ [activeCssClass]: currentLanguage === lang.value })}>
            {lang.example}
          </StuiCode>
        ))}
      </div>
    </div>
  )
}

export default CodeExample
