import React from 'react'

import {
  isJsonParseable,
  safeJsonParseAndStringify
} from 'app_search/utils'

import { IApiLog } from 'app_search/types'

import {
  EuiBadge,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer
} from '@elastic/eui'

import StuiCode from 'stui/Code'
import StuiHeading from 'stui/Heading'

import { getStatusColor } from './ApiLogsLogic'

interface IApiLogDetailProps {
  log: IApiLog
  onClose()
}

const ApiLogDetail: React.SFC<IApiLogDetailProps> = ({ log, onClose }) => (
  <EuiOverlayMask>
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Request details</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiSpacer />
        <EuiFlexGroup>
          <EuiFlexItem>
            <StuiHeading type="title">Method</StuiHeading>
            <div>
              <EuiBadge className="eui-displayInlineBlock" color="primary">{log.http_method}</EuiBadge>
            </div>
          </EuiFlexItem>
          <EuiFlexItem>
            <StuiHeading type="title">Status</StuiHeading>
            <EuiHealth color={getStatusColor(log.status)}>{log.status}</EuiHealth>
          </EuiFlexItem>
          <EuiFlexItem>
            <StuiHeading type="title">Timestamp</StuiHeading>
            <div>{log.timestamp}</div>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />
        <StuiHeading type="title">User Agent</StuiHeading>
        <code className="o-stui-code-snippet">{log.user_agent}</code>
        <EuiSpacer />
        <StuiHeading type="title">Request Path</StuiHeading>
        <code className="o-stui-code-snippet">{log.full_request_path}</code>
        <EuiSpacer />
        <StuiHeading type="title">Request Body</StuiHeading>
        <StuiCode language="json" isParseable={isJsonParseable(log.request_body)}>
          {safeJsonParseAndStringify(log.request_body)}
        </StuiCode>
        <EuiSpacer />
        <StuiHeading type="title">Response Body</StuiHeading>
        <StuiCode language="json" isParseable={isJsonParseable(log.response_body)}>
          {safeJsonParseAndStringify(log.response_body)}
        </StuiCode>
        <EuiSpacer />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Close</EuiButtonEmpty>
      </EuiModalFooter>
    </EuiModal>
  </EuiOverlayMask>
)

export default ApiLogDetail
