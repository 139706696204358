import React, { useEffect } from 'react'

import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import { Loading } from 'app_search/components/Loading'
import { capitalize } from 'app_search/utils'
import { getUserRoute } from 'app_search/utils/routePaths'
import useAbilities from 'app_search/utils/useAbilities'

import { UsersLogic } from './UsersLogic'

import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiPanel,
  EuiSelect,
  EuiSpacer,
  EuiTextColor
} from '@elastic/eui'

import StuiCopyToCipboard from 'stui/CopyToClipboard'
import StuiFlashMessages from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiIcon from 'stui/Icon'
import StuiMain from 'stui/Main'
import StuiSubHeading from 'stui/SubHeading'
import {
  StuiTable,
  StuiTableBody,
  StuiTableBodyCell,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'

import { Role } from 'app_search/classes'
import { IObject, IUsersState, TRole } from 'app_search/types'

import routes from 'app_search/routes'

export interface IUsersProps {
  actions: {
    initializeUsersData()
    toggleInvitationsModal()
    setUsersState(updatedUsersState: IObject)
    handleInviteButtonSubmit()
    handleLTGInviteButtonSubmit()
    handleResendInvitation(event: any, code: string)
    handleRevokeInvitation(event: any, code: string)
    setLoadingOnExit()
  }
  usersState: IUsersState
}

const Users: React.SFC<IUsersProps> = ({
  actions: {
    initializeUsersData,
    toggleInvitationsModal,
    setUsersState,
    handleInviteButtonSubmit,
    handleLTGInviteButtonSubmit,
    handleResendInvitation,
    handleRevokeInvitation,
    setLoadingOnExit
  },
  usersState: state
}) => {
  const { canManageUsers } = useAbilities()

  useEffect(() => {
    initializeUsersData()
    return setLoadingOnExit
  }, [])

  if (state.dataLoading) { return <Loading /> }

  const myRole = new Role(state.myRole)

  const inviteButton = canManageUsers && <EuiButton color="secondary" fill={true} onClick={toggleInvitationsModal} data-test-subj="UsersInviteUsersButton">Invite {process.env.LOCO_TOGO ? 'User' : 'Users'}</EuiButton>
  const hasInvitations = state.invitations.length > 0

  const inviteModalRoleSelectAndSubmitGroup = myRole.ability.can('manage', 'account_users') && state.showInvitationsModal && (
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiSelect
          value={state.invitationRoleType}
          options={myRole.ability.invitableRoleTypes().map(roleType => ({ value: roleType, text: capitalize(roleType) }))}
          onChange={e => setUsersState({ invitationRoleType: e.target.value as TRole })}
          data-test-subj="UsersInviteFormRoleField"
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButton
          type="submit"
          fill={true}
          data-test-subj="UsersInviteFormSubmitButton"
        >
          Invite
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  const inviteModal = !process.env.LOCO_TOGO && canManageUsers && state.showInvitationsModal && (
    <EuiOverlayMask>
      <EuiModal onClose={toggleInvitationsModal} data-test-subj="UsersInviteModal">
        <EuiModalHeader>
          <EuiModalHeaderTitle>Invite your teammates</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiForm>
            <form onSubmit={handleInviteButtonSubmit}>
              <p>Add teammates by email address, separated with a comma</p>
              <EuiSpacer />
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFieldText
                    value={state.emails}
                    autoComplete="off"
                    autoFocus={true}
                    fullWidth={true}
                    onChange={e => setUsersState({ emails: e.target.value })}
                    data-test-subj="UsersInviteFormEmailsField"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiSpacer />
              <p>And specify their role</p>
              <EuiSpacer />
              {inviteModalRoleSelectAndSubmitGroup}
            </form>
          </EuiForm>
        </EuiModalBody>
      </EuiModal>
    </EuiOverlayMask>
  )

  const ltgInviteModal = process.env.LOCO_TOGO && myRole.ability.can('manage', 'account_users') && state.showInvitationsModal && (
    <EuiOverlayMask>
      <EuiModal onClose={toggleInvitationsModal} data-test-subj="UsersInviteModal">
        <EuiModalHeader>
          <EuiModalHeaderTitle>Invite a teammate</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiForm>
            <form onSubmit={handleLTGInviteButtonSubmit}>
              <p>Add a teammate by email address and username</p>
              <EuiSpacer size="m" />

              <EuiFormRow label="Email">
                <EuiFieldText
                  value={state.emails}
                  autoComplete="off"
                  autoFocus={true}
                  fullWidth={true}
                  required={true}
                  onChange={e => setUsersState({ emails: e.target.value })}
                  data-test-subj="UsersInviteFormEmailsField"
                />
              </EuiFormRow>

              <EuiFormRow label="Username">
                <EuiFieldText
                  value={state.username}
                  autoComplete="off"
                  fullWidth={true}
                  required={true}
                  onChange={e => setUsersState({ username: e.target.value })}
                  data-test-subj="UsersInviteFormUsernameField"
                />
              </EuiFormRow>

              <p>And specify their role</p>
              <EuiSpacer size="m" />

              {inviteModalRoleSelectAndSubmitGroup}
            </form>
          </EuiForm>
        </EuiModalBody>
      </EuiModal>
    </EuiOverlayMask>
  )

  const pendingInvitations = () => {
    const invitationsContainerClass = classNames(
      'pending-invitations',
      { 'pending-invitations--empty': state.invitations.length === 0 }
    )

    const invitationParagraph = state.showInvitationLink ? (
      <p>The following invitation links have been created for you to send to your users</p>
    ) : (
        <p>The following invitations have been sent out but have yet to be accepted</p>
      )
    const emptyStateParagraph = state.showInvitationLink ? (
      <p>Send invitation links and start collaborating.</p>
    ) : (
        <p>Send an email invitation and start collaborating.</p>
      )

    const invitationContainer = hasInvitations ? (
      <StuiHeader>
        <StuiHeading type="section">Pending Invitations</StuiHeading>
        {invitationParagraph}
      </StuiHeader>
    ) : (
        <>
          <img src={state.invitationsEmptyState} alt="Invite Users" />
          <StuiHeader>
            <StuiHeading type="section">Bring the Team</StuiHeading>
            {emptyStateParagraph}
          </StuiHeader>
        </>
      )

    const invitationAction = (code) => {
      return state.showInvitationLink ? (
        <StuiCopyToCipboard content={routes.locoMocoInvitationUrl(code)} />
      ) : (
          <a onClick={(e) => handleResendInvitation(e, code)}>
            Resend
          </a>
        )
    }

    return canManageUsers && (
      <div className="users-layout__invitations">
        <div className={invitationsContainerClass} data-test-subj="UsersInvitations">
          <EuiPanel hasShadow={true}>
            {invitationContainer}
            {hasInvitations && <ul className="user-invitations-list">
              {state.invitations.map((invitation) =>
                <li className="user-invitation" key={invitation.email}>
                  <div>
                    {invitation.email}
                  </div>
                  <div className="user-invitation--actions">
                    {invitationAction(invitation.code)}
                    <a
                      className="o-stui-table-action o-stui-delete-action"
                      onClick={(e) => handleRevokeInvitation(e, invitation.code)}
                      data-test-subj="UsersInvitationsRevokeButton"
                    >
                      <StuiIcon name="delete" />
                    </a>
                  </div>
                </li>
              )}
            </ul>}
            {!hasInvitations &&
              <>
                <EuiSpacer />
                {inviteButton}
              </>
            }
          </EuiPanel>
        </div>
      </div>
    )
  }

  const engineList = (engines) => {
    if (engines.length === 0) {
      return <EuiTextColor color="subdued">&mdash;</EuiTextColor>
    }

    return engines.map((engine) =>
      <li key={engine.name} className="credentials-engine-list__engine">{engine.name}</li>)
  }

  const existingRoles = (
    <StuiTable>
      <StuiTableHead>
        <StuiTableRow>
          <StuiTableHeadCell>Name</StuiTableHeadCell>
          <StuiTableHeadCell>Email</StuiTableHeadCell>
          <StuiTableHeadCell>Role</StuiTableHeadCell>
          <StuiTableHeadCell>Engines</StuiTableHeadCell>
          <StuiTableHeadCell />
        </StuiTableRow>
      </StuiTableHead>
      <StuiTableBody className="list">
        {state.users.map((user, index) => {
          return (
            <StuiTableRow className="users-layout__users--row" key={index}>
              <StuiTableBodyCell>{user.name}</StuiTableBodyCell>
              <StuiTableBodyCell>{user.email}</StuiTableBodyCell>
              <StuiTableBodyCell className="users-layout__users--roletype">
                {user.role.roleType}
              </StuiTableBodyCell>
              <StuiTableBodyCell className="users-layout__users--engines">
                <ul className="credentials-engine-list">
                  {user.accessAllEngines ? 'All' : engineList(user.engines)}
                </ul>
              </StuiTableBodyCell>
              <StuiTableBodyCell className="users-layout__users--roletype" />
              <StuiTableBodyCell className="users-layout__users--actions">
                {myRole.ability.canChangeRole(user.role) && !state.demo && <Link to={getUserRoute(user.role.id)}>Manage</Link>}
              </StuiTableBodyCell>
            </StuiTableRow>
          )
        })}
      </StuiTableBody>
    </StuiTable>
  )

  const heading = canManageUsers ? (
    <StuiHeader contained={true} viewHeader={true} actions={hasInvitations && inviteButton}>
      <StuiHeading type="view">Manage Users</StuiHeading>
    </StuiHeader>
  ) : (
      <StuiHeader contained={true} viewHeader={true} actions={hasInvitations && inviteButton}>
        <StuiHeading type="view">Owners and Admins</StuiHeading>
        <StuiSubHeading>
          Contact an Owner or Admin if you need help with your account.
        </StuiSubHeading>
      </StuiHeader>
    )

  return (
    <div data-test-subj="Users">
      {heading}
      <StuiMain>
        <StuiFlashMessages {...state.flashMessages} />
        <EuiSpacer size="xxl" />
        <div className="users-layout">
          <div className="users-layout__users">
            {existingRoles}
          </div>
          {pendingInvitations()}
        </div>
      </StuiMain>
      {process.env.LOCO_TOGO ? ltgInviteModal : inviteModal}
    </div>
  )
}

export default withRouter(UsersLogic(Users))
