import React from 'react'

import { Link } from 'react-router-dom'

import { ENGINE_CURATIONS_PATH, getEngineRoute } from 'app_search/utils/routePaths'

interface ICurationsViewAllButtonProps {
  engineName: string
}

const CurationsViewAllButton: React.SFC<ICurationsViewAllButtonProps> = ({ engineName }) => (
  <div className="stui-view-topbar" key="breadcrumbs">
    <div className="o-stui-breadcrumbs">
      <Link className="o-stui-breadcrumb" to={getEngineRoute(ENGINE_CURATIONS_PATH, engineName)}>&larr; View all curations</Link>
    </div>
  </div>
)

export default CurationsViewAllButton
