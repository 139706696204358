import moment from 'moment'
import React from 'react'

import { IObject } from 'app_search/types'

import {
  EuiButton,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer
} from '@elastic/eui'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'

interface IManageCurrentPlanPanelProps {
  canManagePlan: boolean
  plan: IObject
  onUpdatePlan()
}

const ManageCurrentPlanPanel: React.SFC< IManageCurrentPlanPanelProps > = ({ onUpdatePlan, plan, canManagePlan }) => {
  if (!plan) {
    return null
  }

  return (
    <EuiFlexItem data-test-subj="ManageCurrentPlanPanel">
      <EuiPanel paddingSize="m">
        <StuiHeader>
          <StuiHeading type="section">Current Plan</StuiHeading>
        </StuiHeader>
        <EuiSpacer />

        <div className="current-plan">
          <div className="current-plan__content">
            <div
              className="current-plan__title"
              data-test-subj="ManageCurrentPlanPanelPlanTitle"
            >
              {plan.description}
            </div>
            <div className="current-plan__description">Since {moment(plan.subscription.startDate).format('MMMM D, YYYY')}</div>
          </div>
          { canManagePlan && (
            <div className="current-plan__action">
              <EuiButton onClick={onUpdatePlan} data-test-subj="ManageCurrentPlanPanelUpdatePlanButton">Update Plan</EuiButton>
            </div>
          )}
        </div>
      </EuiPanel>
    </EuiFlexItem>
  )
}

export default ManageCurrentPlanPanel
