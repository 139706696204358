import React, { useEffect } from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import CredentialFlyout from './CredentialFlyout'
import CredentialsHeaderItem from './CredentialsHeaderItem'
import CredentialsList from './CredentialsList'
import { CredentialsLogic } from './CredentialsLogic'

import { Loading } from 'app_search/components/Loading'
import { ICredentialsDetails } from 'app_search/types'

interface ICredentialsProps extends ICredentialsDetails {
  actions: {
    initializeCredentialsData()
    resetCredentials()
  }
  showCredentialsForm: boolean
  dataLoading: boolean
}

const Credentials: React.SFC<ICredentialsProps> = ({
  actions: { initializeCredentialsData, resetCredentials },
  lmAccount,
  apiUrl,
  dataLoading,
  showCredentialsForm
}) => {
  useEffect(() => {
    initializeCredentialsData()
    return resetCredentials
  }, [])

  if (dataLoading) { return <Loading /> }

  return (
    <div data-test-subj="Credentials">
      <StuiHeader key="header" contained={true} viewHeader={true}>
        <StuiHeading type="view">Credentials</StuiHeading>
      </StuiHeader>
      <StuiMain>
        <div id="account-info">
          <EuiFlexGroup>
            {!process.env.LOCO_TOGO &&
              <EuiFlexItem>
                <CredentialsHeaderItem title="Host Identifier" value={lmAccount.key} />
              </EuiFlexItem>
            }
            <EuiFlexItem>
              <CredentialsHeaderItem title="API Endpoint" value={apiUrl} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
        <EuiSpacer size="xxl" />
        <CredentialsList />
      </StuiMain>
      {showCredentialsForm && <CredentialFlyout />}
    </div>
  )
}

export default CredentialsLogic(Credentials)
