import React from 'react'

import routes from 'app_search/routes'
import { Link, withRouter } from 'react-router-dom'
import http from 'shared/http'

import { getCurationRoute, getQueryDetailRoute } from 'app_search/utils/routePaths'

import { EuiButtonIcon } from '@elastic/eui'
import { IRouter } from 'app_search/types'

const handleEditClick = (e, engineName, query, history) => {
  e.preventDefault()
  const route = routes.findOrCreateLocoMocoEngineDocumentPositionsPath(engineName, { query })
  http(route).then(({ data: { id } }) => history.push(getCurationRoute(engineName, id)))
}

interface IQueryDetailMenuProps extends IRouter {
  engineName: string
  query: string
}

const QueryDetailMenu: React.SFC<IQueryDetailMenuProps> = ({ engineName, query, history }) => (
  <div className="o-stui-dropdown">
    <EuiButtonIcon tabIndex={-1} iconType="iInCircle" aria-label="Open query details menu" />
    <div className="o-stui-dropdown__container o-stui-dropdown__container--right">
      <nav className="o-stui-dropdown-nav">
        <Link className="o-stui-nav-item" to={getQueryDetailRoute(engineName, query)} tabIndex={-1}>View Query Analytics</Link>
        <a className="o-stui-nav-item" onClick={e => handleEditClick(e, engineName, query, history)} tabIndex={-1}>Edit Query</a>
      </nav>
    </div>
  </div>
)

export default withRouter(QueryDetailMenu)
