import { AppLogic } from 'app_search/App'
import routes from 'app_search/routes'
import { IApiToken, ICredentialsDetails, IMeta } from 'app_search/types'
import { formatApiName } from 'app_search/utils'
import handleAPIError from 'app_search/utils/handleAPIError'
import { ADMIN, PRIVATE } from 'shared/constants/credentials'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

const defaultApiToken = {
  name: '',
  type: PRIVATE,
  read: true,
  write: true,
  access_all_engines: true
}

const CREATE_MESSAGE = 'Successfully created key.'
const UPDATE_MESSAGE = 'Successfully updated API Key.'
const DELETE_MESSAGE = 'Successfully deleted key.'

export const apiTokenSort = (apiTokenA, apiTokenB) => {
  if (!apiTokenA.id) {
    return -1
  }
  if (!apiTokenB.id) {
    return 1
  }
  return apiTokenA.id - apiTokenB.id
}

export const CredentialsLogic = storeLogic({
  connect: () => ({
    values: [
      AppLogic, ['myRole']
    ]
  }),
  actions: () => ({
    addEngineName: (engineName: string) => engineName,
    onApiKeyDelete: (tokenName: string) => tokenName,
    onApiTokenCreateSuccess: (apiToken: IApiToken) => apiToken,
    onApiTokenError: (formErrors: string[]) => formErrors,
    onApiTokenUpdateSuccess: (apiToken: IApiToken) => apiToken,
    removeEngineName: (engineName: string) => engineName,
    setAccessAllEngines: (accessAll: boolean) => accessAll,
    setCredentialsData: (meta: IMeta, apiTokens: IApiToken[]) => ({ meta, apiTokens }),
    setCredentialsDetails: (details: ICredentialsDetails) => details,
    setNameInputBlurred: (nameInputBlurred: boolean) => nameInputBlurred,
    setTokenReadWrite: ({ name, checked }) => ({ name, checked }),
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages }),
    setTokenName: (name: string) => name,
    setTokenType: (tokenType: string) => tokenType,
    toggleCredentialsForm: (apiToken: IApiToken = { ...defaultApiToken }) => apiToken,
    hideCredentialsForm: false,
    resetCredentials: false
  }),
  reducers: ({ actions }) => ({
    apiTokens: [[], {
      [actions.setCredentialsData]: (_, { apiTokens }) => apiTokens,
      [actions.onApiTokenCreateSuccess]: (apiTokens, apiToken) => [...apiTokens, apiToken],
      [actions.onApiTokenUpdateSuccess]: (apiTokens, apiToken) => [...apiTokens.filter(token => token.name !== apiToken.name), apiToken],
      [actions.onApiKeyDelete]: (apiTokens, tokenName) => apiTokens.filter(token => token.name !== tokenName)
    }],
    meta: ['', {
      [actions.setCredentialsData]: (_, { meta }) => meta
    }],
    dataLoading: [true, {
      [actions.setCredentialsDetails]: () => false,
      [actions.resetCredentials]: () => true
    }],
    engines: [[], {
      [actions.setCredentialsDetails]: (_, { engines }) => engines
    }],
    lmAccount: [{ key: '' }, {
      [actions.setCredentialsDetails]: (_, { lmAccount }) => lmAccount
    }],
    nameInputBlurred: [false, {
      [actions.setNameInputBlurred]: (_, nameInputBlurred) => nameInputBlurred
    }],
    premiumAccount: [false, {
      [actions.setCredentialsDetails]: (_, { premiumAccount }) => premiumAccount
    }],
    apiUrl: ['', {
      [actions.setCredentialsDetails]: (_, { apiUrl }) => apiUrl
    }],
    activeApiToken: [defaultApiToken, {
      [actions.addEngineName]: (activeApiToken, engineName) => ({
        ...activeApiToken,
        engines: [...(activeApiToken.engines || []), engineName]
      }),
      [actions.removeEngineName]: (activeApiToken, engineName) => ({
        ...activeApiToken,
        engines: (activeApiToken.engines || []).filter(name => name !== engineName)
      }),
      [actions.setAccessAllEngines]: (activeApiToken, accessAll) => ({
        ...activeApiToken,
        access_all_engines: accessAll,
        engines: accessAll ? [] : activeApiToken.engines
      }),
      [actions.toggleCredentialsForm]: () => defaultApiToken,
      [actions.onApiTokenCreateSuccess]: () => defaultApiToken,
      [actions.onApiTokenUpdateSuccess]: () => defaultApiToken,
      [actions.setTokenName]: (activeApiToken, name) => ({ ...activeApiToken, name: formatApiName(name) }),
      [actions.setTokenReadWrite]: (activeApiToken, { name, checked }) => ({ ...activeApiToken, [name]: checked }),
      [actions.setTokenType]: (activeApiToken, tokenType) => ({
        ...activeApiToken,
        access_all_engines: tokenType === ADMIN ? false : (activeApiToken.type === ADMIN || activeApiToken.access_all_engines),
        engines: tokenType === ADMIN ? [] : activeApiToken.engines,
        write: tokenType === PRIVATE,
        read: tokenType === PRIVATE,
        type: tokenType
      }),
      [actions.toggleCredentialsForm]: (_, activeApiToken) => activeApiToken
    }],
    activeApiTokenRawName: ['', {
      [actions.setTokenName]: (_, activeApiTokenRawName) => activeApiTokenRawName,
      [actions.toggleCredentialsForm]: (activeApiTokenRawName, activeApiToken) => activeApiToken.name || activeApiTokenRawName,
      [actions.hideCredentialsForm]: () => '',
      [actions.onApiTokenCreateSuccess]: () => '',
      [actions.onApiTokenUpdateSuccess]: () => ''
    }],
    activeApiTokenIsExisting: [false, {
      [actions.toggleCredentialsForm]: (_, activeApiToken) => !!activeApiToken.id
    }],
    showCredentialsForm: [false, {
      [actions.toggleCredentialsForm]: showCredentialsForm => !showCredentialsForm,
      [actions.hideCredentialsForm]: () => false,
      [actions.onApiTokenCreateSuccess]: () => false,
      [actions.onApiTokenUpdateSuccess]: () => false
    }],
    formErrors: [[], {
      [actions.onApiTokenError]: (_, formErrors) => formErrors,
      [actions.onApiTokenCreateSuccess]: () => [],
      [actions.onApiTokenCreateSuccess]: () => [],
      [actions.toggleCredentialsForm]: () => [],
      [actions.resetCredentials]: () => []
    }],
    flashMessages: [{}, {
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages,
      [actions.onApiTokenCreateSuccess]: () => ({ success: [CREATE_MESSAGE] }),
      [actions.onApiTokenUpdateSuccess]: () => ({ success: [UPDATE_MESSAGE] }),
      [actions.onApiKeyDelete]: () => ({ success: [DELETE_MESSAGE] }),
      [actions.resetCredentials]: () => ({})
    }]
  }),
  selectors: ({ selectors }) => ({
    fullEngineAccessChecked: [
      () => [selectors.myRole, selectors.activeApiToken],
      (myRole, activeApiToken) => myRole && myRole.ability && myRole.ability.accessAllEngines() && !!activeApiToken.access_all_engines
    ],
    keyHelpText: [
      () => [selectors.activeApiToken, selectors.activeApiTokenRawName],
      (activeApiToken, activeApiTokenRawName) => {
        const { name } = activeApiToken
        if (!!name && name !== activeApiTokenRawName) {
          return `Your key will be named: ${name}`
        }
      }
    ]
  }),
  thunks: ({ actions, get }) => ({
    initializeCredentialsData: () => {
      actions.fetchCredentials()
      actions.fetchDetails()
    },
    fetchCredentials: (page = 1) => {
      const url = routes.locoMocoCredentialsPath({
        page: {
          current: page
        }
      })
      http(url).then(({ data: { meta, results } }) => {
        actions.setCredentialsData(meta, results)
      })
    },
    fetchDetails: () => {
      const url = routes.detailsLocoMocoCredentialsPath()
      http(url)
        .then(({ data }) => actions.setCredentialsDetails(data))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    deleteApiKey: tokenName => {
      http
        .delete(routes.locoMocoCredentialPath(tokenName))
        .then(() => actions.onApiKeyDelete(tokenName))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    onApiTokenChange: () => {
      const {
        id,
        name,
        engines,
        type,
        read,
        write,
        access_all_engines: accessAllEngines
      } = get('activeApiToken')
      const myRole = get('myRole')

      let successCallback
      let url
      let method

      const data: IApiToken = {
        name,
        type
      }

      if (type === PRIVATE) {
        data.read = read
        data.write = write
      }

      if (type !== ADMIN) {
        data.access_all_engines = accessAllEngines && myRole.ability.accessAllEngines()
        data.engines = engines
      }

      if (id) {
        successCallback = actions.onApiTokenUpdateSuccess
        url = routes.locoMocoCredentialPath(name)
        method = 'put'
      } else {
        successCallback = actions.onApiTokenCreateSuccess
        url = routes.locoMocoCredentialsPath()
        method = 'post'
      }

      return http({ url, method, data })
        .then(response => successCallback(response.data))
        .catch(handleAPIError(messages => actions.onApiTokenError(messages)))
    },
    onEngineSelect: (engineName) => {
      const activeApiToken = get('activeApiToken')
      activeApiToken.engines && activeApiToken.engines.indexOf(engineName) > -1 ?
        actions.removeEngineName(engineName) :
        actions.addEngineName(engineName)
    }
  })
})
