import React, { useState } from 'react'

import { EuiCopy } from '@elastic/eui'

import StuiIcon from 'stui/Icon'
import StuiTooltip from 'stui/Tooltip'

interface IStuiCopyToClipboardProps {
  content?: string
  dataCy?: string
}

const StuiCopyToClipboard: React.SFC<IStuiCopyToClipboardProps> = ({ content = '', dataCy = '' }) => {
  const [copying, setCopying] = useState(false)
  const setIsCopying = () => setCopying(true)
  const setDoneCopying = () => setCopying(false)

  return (
    <StuiTooltip placement="top" content="Copy to clipboard">
      <EuiCopy textToCopy={content} afterMessage="">
        {copy => {
          const onClick = () => {
            setIsCopying()
            setTimeout(setDoneCopying, 1500)
            copy()
          }
          return (
            <span data-test-subj={dataCy} onClick={onClick} className="o-stui-copy-to-clipboard">
              <StuiIcon name={copying ? 'success-fill' : 'copy'} />
            </span>
          )
        }}
      </EuiCopy>
    </StuiTooltip>
  )
}

export default StuiCopyToClipboard
