import React from 'react'

import { Link } from 'react-router-dom'

import { useValues } from 'kea'

import { AppLogic } from 'app_search/App'
import { BILLING_PATH, EXTEND_TRIAL_PATH } from 'app_search/utils/routePaths'
import { distanceOfDaysInWords } from 'shared/utils'

const TrialBanner: React.SFC<{}> = () => {
  const { account: { freeTrialExpired, freeTrialExpires, freeTrialExtended } } = useValues(AppLogic)

  const mainText = freeTrialExpired ?
    'Your free trial has expired. ' :
    `You have ${distanceOfDaysInWords(freeTrialExpires)} left in your free trial.`

  const selectLink = <Link className="free-trial-message__action" data-test-subj="TrialSelectPlanLink" to={BILLING_PATH}>Select a plan</Link>
  const extendLink = <Link className="free-trial-message__action" data-test-subj="ExtendTrialLink" to={EXTEND_TRIAL_PATH}>Extend your trial</Link>

  return (
    <div className="free-trial-message">
      {mainText} {selectLink}.
      {!freeTrialExtended &&
      <div className="free-trial-message__extend">
        Need more time? {extendLink}.
      </div>}
    </div>
  )
}

export default TrialBanner
