import React from 'react'

import { useValues } from 'kea'

import { EngineLogic } from 'app_search/Engine'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import QueryTesterSearch from './QueryTesterSearch'

const QueryTester: React.SFC<{}> = _ => {
  const { engine: { name: engineName, schema, result_fields: resultFields } } = useValues(EngineLogic)
  return (
    <div data-test-subj="QueryTester">
      <StuiHeader key="header" contained={true} viewHeader={true} isSticky={true}>
        <StuiHeading type="view">Query Tester</StuiHeading>
      </StuiHeader>
      <StuiMain key="main">
        <QueryTesterSearch
          resultFields={resultFields}
          engineName={engineName}
          schema={schema}
        />
      </StuiMain>
    </div>
  )
}

export default QueryTester
