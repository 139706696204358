import React from 'react'

import classNames from 'classnames'

import { Accordion } from 'react-accessible-accordion'

interface IStuiAccordionProps {
  children: any
  className?: string
}

const StuiAccordion: React.SFC<IStuiAccordionProps> = (props) => {
  const { ...other } = props
  const className = classNames(
    'o-stui-accordion',
    props.className
  )

  return (
    <Accordion
      {...other}
      className={className}
    />
  )
}

export default StuiAccordion
