import React from 'react'

import StuiCopyToCipboard from 'stui/CopyToClipboard'
import StuiHiddenKey from 'stui/HiddenKey'

interface ICredentialsTokenProps {
  token: string
  isPrivate: boolean
}

const CredentialsToken: React.SFC<ICredentialsTokenProps> = ({ token, isPrivate }) => (
  <div className="o-stui-credential__token">
    <StuiCopyToCipboard content={token} />
    <StuiHiddenKey content={token} data-test-subj={isPrivate ? 'CredentialsPrivateKey' : 'CredentialsSearchKey'} />
  </div>
)

export default CredentialsToken
