import React from 'react'

import AutosizeInput from 'react-input-autosize'

import { EuiSpacer } from '@elastic/eui'

import StuiCopyToClipboard from 'stui/CopyToClipboard'
import StuiHeading from 'stui/Heading'

interface ICredentialsListProps {
  title: string
  value: string
}

const handleClick = e => e.target.select()
const CredentialsHeaderItem: React.SFC<ICredentialsListProps> = ({ title, value }) => (
  <div className="o-stui-card o-stui-card--centered">
    <StuiHeading type="section">{title}</StuiHeading>
    <EuiSpacer size="s" />
    <div className="o-stui-flex-group">
      <StuiCopyToClipboard content={value} />
      <AutosizeInput onClick={handleClick} className="o-stui-copy-field" readOnly={true} value={value} />
    </div>
  </div>
)

export default CredentialsHeaderItem
