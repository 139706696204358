import React from 'react'

import { EuiSpacer } from '@elastic/eui'

interface IAnalyticsMetricCardProps {
  title: string
  value: number
}

const AnalyticsMetricCard: React.SFC<IAnalyticsMetricCardProps> = ({ title, value }) => (
  <div className="o-stui-card o-stui-card--centered metric metric--total-queries u-stui-color--blue-dark">
    <div className="metric__title">{title}</div>
    <EuiSpacer />
    <div className="metric__value">{value.toLocaleString()}</div>
  </div>
)

export default AnalyticsMetricCard
