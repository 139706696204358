import { EngineLogic } from 'app_search/Engine'
import routes from 'app_search/routes'
import { IAnalyticsData, IQueryDetails } from 'app_search/types'
import handleAPIError from 'app_search/utils/handleAPIError'
import { parseQueryParams } from 'app_search/utils/queryParams'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

const NUM_RESULTS_LIMITED = 10
const NUM_RESULTS_FULL = 20

export const AnalyticsLogic = storeLogic({
  connect: () => ({
    values: [
      EngineLogic, ['engineName']
    ]
  }),
  actions: () => ({
    setAnalyticsData: (data: IAnalyticsData) => data,
    setQueryData: (data: IQueryDetails) => data,
    setDataLoading: (dataLoading: boolean) => ({ dataLoading }),
    updateSearchInputValue: (searchInputValue) => ({ searchInputValue }),
    setAnalyticsUnavailable: (analyticsUnavailable: boolean) => ({ analyticsUnavailable }),
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages })
  }),
  reducers: ({ actions }) => ({
    allTags: [[], {
      [actions.setAnalyticsData]: (_, { allTags }) => allTags,
      [actions.setQueryData]: (_, { allTags }) => allTags
    }],
    analyticsUnavailable: [false, {
      [actions.setAnalyticsData]: (_, { analyticsUnavailable }) => analyticsUnavailable,
      [actions.setAnalyticsUnavailable]: (_, { analyticsUnavailable }) => analyticsUnavailable
    }],
    clicksPerDay: [[], {
      [actions.setAnalyticsData]: (_, { clicksPerDay }) => clicksPerDay
    }],
    currentTag: ['', {
      [actions.setAnalyticsData]: (_, { currentTag }) => currentTag,
      [actions.setQueryData]: (_, { currentTag }) => currentTag
    }],
    endDate: ['', {
      [actions.setAnalyticsData]: (_, { endDate }) => endDate,
      [actions.setQueryData]: (_, { endDate }) => endDate
    }],
    planLimitMessage: [{}, {
      [actions.setAnalyticsData]: (_, { planLimitMessage }) => planLimitMessage,
      [actions.setQueryData]: (_, { planLimitMessage }) => planLimitMessage
    }],
    queriesNoResultsPerDay: [[], {
      [actions.setAnalyticsData]: (_, { queriesNoResultsPerDay }) => queriesNoResultsPerDay
    }],
    queriesPerDay: [[], {
      [actions.setAnalyticsData]: (_, { queriesPerDay }) => queriesPerDay
    }],
    recentQueries: [[], {
      [actions.setAnalyticsData]: (_, { recentQueries }) => recentQueries
    }],
    startDate: ['', {
      [actions.setAnalyticsData]: (_, { startDate }) => startDate,
      [actions.setQueryData]: (_, { startDate }) => startDate
    }],
    showPlanLimitMessage: [false, {
      [actions.setAnalyticsData]: (_, { showPlanLimitMessage }) => showPlanLimitMessage,
      [actions.setQueryData]: (_, { showPlanLimitMessage }) => showPlanLimitMessage
    }],
    topQueries: [[], {
      [actions.setAnalyticsData]: (_, { topQueries }) => topQueries
    }],
    topQueriesNoClicks: [[], {
      [actions.setAnalyticsData]: (_, { topQueriesNoClicks }) => topQueriesNoClicks
    }],
    topQueriesNoResults: [[], {
      [actions.setAnalyticsData]: (_, { topQueriesNoResults }) => topQueriesNoResults
    }],
    topQueriesWithClicks: [[], {
      [actions.setAnalyticsData]: (_, { topQueriesWithClicks }) => topQueriesWithClicks
    }],
    totalClicks: [0, {
      [actions.setAnalyticsData]: (_, { totalClicks }) => totalClicks
    }],
    totalQueries: [0, {
      [actions.setAnalyticsData]: (_, { totalQueries }) => totalQueries
    }],
    totalQueriesNoResults: [0, {
      [actions.setAnalyticsData]: (_, { totalQueriesNoResults }) => totalQueriesNoResults
    }],
    totalQueriesForQuery: [0, {
      [actions.setQueryData]: (_, { totalQueriesForQuery }) => totalQueriesForQuery
    }],
    queriesPerDayForQuery: [0, {
      [actions.setQueryData]: (_, { queriesPerDayForQuery }) => queriesPerDayForQuery
    }],
    topClicksForQuery: [[], {
      [actions.setQueryData]: (_, { topClicksForQuery }) => topClicksForQuery
    }],
    dataLoading: [true, {
      [actions.setDataLoading]: (_, { dataLoading }) => dataLoading,
      [actions.setAnalyticsUnavailable]: () => false,
      [actions.setAnalyticsData]: () => false,
      [actions.setQueryData]: () => false
    }],
    searchInputValue: ['', {
      [actions.updateSearchInputValue]: (_, { searchInputValue }) => searchInputValue
    }],
    flashMessages: [{}, {
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages
    }]
  }),
  thunks: ({ actions, get }) => ({
    initializeAnalyticsData: (location, isFull) => {
      actions.setDataLoading(true)
      const { start, end, tag } = parseQueryParams(location.search)
      const size = isFull ? NUM_RESULTS_FULL : NUM_RESULTS_LIMITED
      const route = routes.queriesLocoMocoEngineAnalyticsPath(get('engineName'), { start, end, tag, size })
      http(route)
        .then(({ data }) => data.analyticsUnavailable ?
          actions.setAnalyticsUnavailable(true) :
          actions.setAnalyticsData(data))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    initializeQueryData: (location, query) => {
      actions.setDataLoading(true)
      const { start, end, tag } = parseQueryParams(location.search)
      const route = routes.queryLocoMocoEngineAnalyticsPath(get('engineName'), { start, end, tag, query })
      http(route)
        .then(({ data }) => data.analyticsUnavailable ?
          actions.setAnalyticsUnavailable(true) :
          actions.setQueryData(data))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    }
  })
})
