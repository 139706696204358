import routes from 'app_search/routes'
import http from 'shared/http'
import { storeLogic } from 'shared/store'

import { AppLogic } from 'app_search/App'

export interface IOnboardingDetails {
  onboardingState: string
  engineName: string
  apiKey: string
}

export const OnboardingLogic = storeLogic({
  connect: () => ({
    values: [
      AppLogic, ['currentUser', 'supportedLanguages']
    ],
    actions: [
      AppLogic, ['setOnboardingComplete']
    ]
  }),
  actions: () => ({
    setOnboardingDetails: (details: IOnboardingDetails) => details,
    setOnboardingState: (onboardingState) => onboardingState
  }),
  reducers: ({ actions }) => ({
    existingEngineName: ['', {
      [actions.setOnboardingDetails]: (_, { engineName }) => engineName
    }],
    apiKey: ['', {
      [actions.setOnboardingDetails]: (_, { apiKey }) => apiKey
    }],
    onboardingState: ['', {
      [actions.setOnboardingDetails]: (_, { onboardingState }) => onboardingState,
      [actions.setOnboardingState]: (_, onboardingState) => onboardingState
    }],
    dataLoading: [true, {
      [actions.setOnboardingDetails]: () => false
    }]
  }),
  thunks: ({ actions }) => ({
    initializeOnboardingDetails: () => {
      http(routes.locoMocoOnboardingDetailsPath()).then(({ data }) => {
        actions.setOnboardingDetails(data)
      })
    }
  })
})
