import React from 'react'

import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { IObject } from 'app_search/types'
import { getInvoiceRoute } from 'app_search/utils/routePaths'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiIcon from 'stui/Icon'
import {
  StuiTable,
  StuiTableBody,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'

interface IInvoicesTableProps {
  invoices: IObject[]
}

const InvoicesTable: React.SFC< IInvoicesTableProps > = ({ invoices }) => {
  if (!invoices || !invoices.length) {
    return null
  }
  return (
    <>
      <StuiHeader>
        <StuiHeading type="sub">Invoices</StuiHeading>
      </StuiHeader>

      <StuiTable>
        <StuiTableHead>
          <StuiTableRow>
            <StuiTableHeadCell>Status</StuiTableHeadCell>
            <StuiTableHeadCell>Billing Period</StuiTableHeadCell>
            <StuiTableHeadCell>Amount</StuiTableHeadCell>
            <StuiTableHeadCell />
          </StuiTableRow>
        </StuiTableHead>
        <StuiTableBody>
          { invoices.map((invoice, invoiceIndex) => {
            const billingStatusClassNames = classNames(
              'billing-status',
              { 'billing-status--paid': invoice.state === 'paid' }
            )

            return (
              <StuiTableRow key={invoiceIndex}>
                <td className="c-stui-table-cell">
                  <div className="c-stui-table-cell__content">
                    <div className={billingStatusClassNames}>
                    {invoice.state === 'paid' && <StuiIcon name="check" />}
                    {invoice.humanizedState}
                    </div>
                  </div>
                </td>
                <td className="c-stui-table-cell">
                  <div className="c-stui-table-cell__content">
                  {invoice.periodStart} - {invoice.periodEnd}
                  </div>
                </td>
                <td className="c-stui-table-cell">
                  <div className="c-stui-table-cell__content">
                    {invoice.totalDueInDollars}
                  </div>
                </td>
                <td className="c-stui-table-cell">
                  <div className="c-stui-table-cell__content">
                    <Link to={getInvoiceRoute(invoice.id)} className="o-stui-table-action">View Invoice</Link>
                  </div>
                </td>
              </StuiTableRow>
            )
          })}
        </StuiTableBody>
      </StuiTable>
    </>
  )
}

export default InvoicesTable
