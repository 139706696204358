import routes from 'app_search/routes'
import http from 'shared/http'
import { storeLogic } from 'shared/store'

import {
  IFlashMessagesAuth,
  ILMCurrentUser,
  ILMInvitation
} from 'app_search/types'

interface IInvitationData {
  invitation: ILMInvitation
  currentUser: ILMCurrentUser
  flashMessages: IFlashMessagesAuth
  minPasswordScore: number
}

export const InvitationLogic = storeLogic({
  actions: () => ({
    setInvitationData: (data: IInvitationData) => data
  }),
  reducers: ({ actions }) => ({
    invitation: [null, {
      [actions.setInvitationData]: (_, { invitation }) => invitation
    }],
    currentUser: [null, {
      [actions.setInvitationData]: (_, { currentUser }) => currentUser || null
    }],
    flashMessages: [{}, {
      [actions.setInvitationData]: (_, { flashMessages }) => flashMessages || {}
    }],
    minPasswordScore: [0, {
      [actions.setInvitationData]: (_, { minPasswordScore }) => minPasswordScore
    }]
  }),
  thunks: ({ actions }) => ({
    initializeData: (invitationCode) => {
      const url = routes.infoLocoMocoInvitationPath(invitationCode)
      http(url).then(({ data }) => { actions.setInvitationData(data) })
    }
  })
})
