import React from 'react'

import { Route, Switch, withRouter } from 'react-router-dom'

import {
  ENGINE_ANALYTICS_PATH,
  ENGINE_ANALYTICS_QUERY_DETAIL_PATH,
  ENGINE_ANALYTICS_RECENT_QUERIES_PATH,
  ENGINE_ANALYTICS_TOP_QUERIES_NO_CLICKS_PATH,
  ENGINE_ANALYTICS_TOP_QUERIES_NO_RESULTS_PATH,
  ENGINE_ANALYTICS_TOP_QUERIES_PATH,
  ENGINE_ANALYTICS_TOP_QUERIES_WITH_CLICKS_PATH
} from 'app_search/utils/routePaths'

import Analytics, { IAnalyticsProps } from './Analytics'
import { AnalyticsLogic } from './AnalyticsLogic'
import { IAnalyticsSectionContentProps } from './AnalyticsSectionContent'
import QueryDetail from './QueryDetail'
import RecentQueries from './RecentQueries'
import TopQueries from './TopQueries'
import TopQueriesNoClicks from './TopQueriesNoClicks'
import TopQueriesNoResults from './TopQueriesNoResults'
import TopQueriesWithClicks from './TopQueriesWithClicks'

const AnalyticsRouter: React.SFC<IAnalyticsProps | IAnalyticsSectionContentProps> = props => (
  <Switch>
    <Route
      exact={true}
      path={ENGINE_ANALYTICS_PATH}
      render={() => <Analytics {...props as IAnalyticsProps} />}
    />
    <Route
      exact={true}
      path={ENGINE_ANALYTICS_TOP_QUERIES_PATH}
      render={() => <TopQueries {...props as IAnalyticsSectionContentProps} subSection="topQueries" />}
    />
    <Route
      exact={true}
      path={ENGINE_ANALYTICS_TOP_QUERIES_NO_CLICKS_PATH}
      render={() => <TopQueriesNoClicks {...props as IAnalyticsSectionContentProps} subSection="topQueriesNoClicks" />}
    />
    <Route
      exact={true}
      path={ENGINE_ANALYTICS_TOP_QUERIES_NO_RESULTS_PATH}
      render={() => <TopQueriesNoResults {...props as IAnalyticsSectionContentProps} subSection="topQueriesNoResults" />}
    />
    <Route
      exact={true}
      path={ENGINE_ANALYTICS_TOP_QUERIES_WITH_CLICKS_PATH}
      render={() => <TopQueriesWithClicks {...props as IAnalyticsSectionContentProps} subSection="topQueriesWithClicks" />}
    />
    <Route
      exact={true}
      path={ENGINE_ANALYTICS_RECENT_QUERIES_PATH}
      render={() => <RecentQueries {...props as IAnalyticsSectionContentProps} />}
    />
    <Route
      exact={true}
      path={ENGINE_ANALYTICS_QUERY_DETAIL_PATH}
      render={() => <QueryDetail {...props as IAnalyticsSectionContentProps} />}
    />
  </Switch>
)

export default withRouter(AnalyticsLogic(AnalyticsRouter))
