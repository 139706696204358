import React from 'react'

import { withRouter } from 'react-router-dom'

import { IAnalyticsData, IRouter } from 'app_search/types'
import { setQueryParams } from 'app_search/utils/queryParams'

import { AnalyticsLogic } from './AnalyticsLogic'

import {
  EuiButtonEmpty,
  EuiEmptyPrompt,
  EuiModal,
  EuiModalBody,
  EuiOverlayMask
} from '@elastic/eui'

const smasLimitHref = `mailto:app-search-feedback@elastic.co?subject=${encodeURIComponent('App Search self managed limit increase request')}`

interface IPlanLimitModal extends IAnalyticsData, IRouter {}

const PlanLimitModal: React.SFC<IPlanLimitModal> = ({
  history,
  currentTag,
  planLimitMessage
 }) => {
  const onModalClose = () => {
    setQueryParams({ tag: currentTag }, history)
  }
  const modalAction = (
    <EuiButtonEmpty onClick={onModalClose}>Hide this</EuiButtonEmpty>
  )
  const modalDescription = (
    <>
      Your account is currently configured to track and store analytics events from the last {planLimitMessage.analyticsLogRetentionDays} days. <br /><br />
      Need different limits? <a href={smasLimitHref}>Email us.</a>
    </>
  )

  return (
    <EuiOverlayMask>
      <EuiModal onClose={onModalClose}>
        <EuiModalBody>
          <EuiEmptyPrompt
            iconType="visualizeApp"
            title={<h2>Whoops!</h2>}
            body={modalDescription}
            actions={modalAction}
          />
        </EuiModalBody>
      </EuiModal>
    </EuiOverlayMask>
  )
}

export default withRouter(AnalyticsLogic(PlanLimitModal))
