import routes from 'app_search/routes'
import http from 'shared/http'
import { storeLogic } from 'shared/store'

import { AppLogic } from 'app_search/App'
import handleAPIError from 'app_search/utils/handleAPIError'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

const defaultFormData = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
  reason: 'Reason Not Listed', // same as in AccountHelper
  closeAccountPassword: ''
}

export const AccountSettingsLogic = storeLogic({
  connect: () => ({
    actions: [
      AppLogic, ['telemetryUpdate']
    ],
    values: [
      AppLogic, ['account']
    ]
  }),
  actions: () => ({
    updateUserInfo: (userInfo: object) => userInfo,
    setUserInfoValue: (key: string, value: string) => ({ key, value }),
    setTelemetryOptedInUpdating: (updating: boolean) => ({ updating }),
    setFormValue: (key: string, value: string) => ({ key, value }),
    setFormValues: (formData: object) => formData,
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages }),
    setIsLoading: true,
    resetAccountSettings: true
  }),
  reducers: ({ actions }) => ({
    userInfo: [{}, {
      [actions.updateUserInfo]: (state, newState) => ({ ...state, ...newState }),
      [actions.setUserInfoValue]: (state, payload) => ({ ...state, [payload.key]: payload.value })
    }],
    telemetryOptedInUpdating: [false, {
      [actions.setTelemetryOptedInUpdating]: (_, { updating }) => updating
    }],
    dataLoading: [true, {
      [actions.setIsLoading]: () => true,
      [actions.updateUserInfo]: () => false,
      [actions.setFlashMessages]: () => false,
      [actions.resetAccountSettings]: () => true
    }],
    formData: [defaultFormData, {
      [actions.setFormValue]: (state, payload) => ({ ...state, [payload.key]: payload.value }),
      [actions.setFormValues]: (state, newState) => ({ ...state, ...newState })
    }],
    flashMessages: [{}, {
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages,
      [actions.resetAccountSettings]: () => ({})
    }]
  }),
  thunks: ({ actions, get }) => ({
    initializeUserInfo: () => {
      http(routes.locoMocoSettingsUserInfoPath())
        .then(({ data }) => {
          actions.updateUserInfo(data.userInfo)
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    updateAccount: () => {
      actions.setIsLoading()
      http.post(routes.locoMocoSettingsUpdateAccountPath(), { name: get('userInfo').accountName })
        .then(({ data }) => {
          actions.updateUserInfo(data.userInfo)
          actions.setFlashMessages({ success: data.messages })
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    updateUser: () => {
      const { email } = get('userInfo')
      const { currentPassword, newPassword, confirmPassword } = get('formData')
      const userData = {
        email,
        password: newPassword,
        current_password: currentPassword,
        password_confirmation: confirmPassword
      }
      actions.setIsLoading()
      http.post(routes.locoMocoSettingsUpdateUserPath(), userData)
        .then(({ data }) => {
          actions.updateUserInfo(data.userInfo)
          actions.setFlashMessages({ success: data.messages })
          actions.setFormValues(defaultFormData)
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    closeAccount: (role, googleOnlyUser) => {
      const { reason, closeAccountPassword } = get('formData')
      const userData = {
        reason,
        password: closeAccountPassword,
        role_id: role.id
      }

      actions.setIsLoading()

      if (googleOnlyUser) {
        http.post(routes.authenticateCloseLmAccountGoogleOauthInitiatePath(), userData)
          .then(({ data }) => window.location = data.googleAuthUrl)
          .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
      } else {
        http.post(routes.locoMocoSettingsCloseAccountPath(), userData)
          .then(({ data }) => window.location = data.goodbyePath)
          .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
      }
    },
    toggleTelemetryOptIn: (checked) => {
      actions.setTelemetryOptedInUpdating(true)
      http.put('/telemetry', { opt_in: checked })
        .then(({ data: { newGlobalState: { account } } }) => {
          actions.setTelemetryOptedInUpdating(false)
          actions.telemetryUpdate(account)
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    }
  })
})
