import React from 'react'

import classNames from 'classnames'

interface IStuiMainProps {
  children: React.ReactNode
  className?: string
}

const StuiMain: React.SFC<IStuiMainProps> = ({ className, children }) => (
  <main className={classNames('stui-view-content', className)}>
    <div className="stui-view-content__main">
      {children}
    </div>
  </main>
)

export default StuiMain
