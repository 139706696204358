import React from 'react'

import {
  EuiButton,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer
} from '@elastic/eui'

import ResultSettingsIllustrationSVG from './assets/result-settings-illustration.svg'

interface IConfirmModifyModalProps {
  onConfirm()
}

const ConfirmModifyModal: React.SFC<IConfirmModifyModalProps> = ({ onConfirm }) => (
  <div data-test-subj="ConfirmModifyModal" className="result-settings-confirm-modify-modal euiModal">
    <div className="result-settings-confirm-modify-modal__graphic">
      <img
        width="280"
        height="280"
        src={ResultSettingsIllustrationSVG}
        alt="Result Settings Illustration"
      />
    </div>
    <div className="result-settings-confirm-modify-modal__content">
      <EuiModalHeader>
        <EuiModalHeaderTitle>Faster, Richer Results</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <p>Adjusting these settings can have a great impact on your search performance.</p>
        <p>We'll report how your choices impact query speed, so you can find the right balance.</p>
        <p>Be sure to follow the documentation if you're new!</p>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton
          color="primary"
          fill={true}
          onClick={onConfirm}
          data-test-subj="ResultSettingsConfirmModifyButton"
        >
          Refine my settings
        </EuiButton>
      </EuiModalFooter>
      <EuiSpacer size="s" />
    </div>
  </div>
)

export default ConfirmModifyModal
