import React from 'react'

import routes from 'app_search/routes'

import WordmarkElasticSVG from './assets/wordmark-elastic.svg'

const AuthHeader: React.SFC = () => {
  return (
    <div className="auth-header" data-test-subj="AuthHeader">
      <div className="auth-header__wrapper">
        <a href={routes.rootPath()}>
          <img src={WordmarkElasticSVG} />
        </a>
      </div>
    </div>
  )
}

export default AuthHeader
