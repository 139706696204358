import React from 'react'

import StuiEmptyState from 'stui/EmptyState'

const AnalyticsUnavailable: React.SFC = () => (
  <StuiEmptyState
    icon="analytics-fill"
    title="Analytics are currently unavailable"
    description="Please try again in a few minutes"
  />
)

export default AnalyticsUnavailable
