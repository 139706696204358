import React from 'react'

import classNames from 'classnames'
import PrismCode from 'react-prism'

import 'prismjs'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-css'
import 'prismjs/components/prism-java'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-ruby'
import 'prismjs/plugins/line-numbers/prism-line-numbers.js'
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace.js'

import StuiIcon from 'stui/Icon'

interface IStuiCodeProps {
  children: React.ReactNode
  language?: string
  className?: string
  isParseable?: boolean
  isCollapsible?: boolean
  isScrollable?: boolean
  error?: React.ReactNode
}

interface IStuiCodeState {
  open?: boolean
}

class StuiCode extends React.Component<IStuiCodeProps, IStuiCodeState> {
  readonly state: IStuiCodeState = {
    open: false
  }

  render() {
    const { language, isParseable, isCollapsible, isScrollable, children, className, error } = this.props
    const { open } = this.state

    const containerClasses = classNames(
      'c-stui-code-block-container',
      {
        'c-stui-code-block-container--has-error': !!error,
        'c-stui-code-block-container--collapsed': !!isCollapsible && !open,
        'c-stui-code-block-container--expanded': !!isCollapsible && open
      }
    )

    const codeBlockClasses = classNames(
      'c-stui-code-block',
      className,
      'line-numbers',
      `language-${language}`,
      {
        'c-stui-code-block--non-parseable': !isParseable,
        'c-stui-code-block--scrollable': !!isScrollable
      }
    )

    const errorContainer = error && <div className="c-stui-code-block__error">
        <StuiIcon name="error-fill" />
        {error}
      </div>

    return (
      <div className={containerClasses}>
        <pre>
          <PrismCode component="code" className={`language-${language} ${codeBlockClasses}`}>
            {children}
          </PrismCode>
        </pre>
        {isCollapsible &&
          <div className="c-stui-code-block-container__control" onClick={this.controlOnClick}>
            {open ? <StuiIcon name="arrow-up" /> : <StuiIcon name="arrow-down" />}
          </div>
        }
        {isCollapsible ? open && errorContainer : errorContainer}
      </div>
    )
  }

  private controlOnClick = () => {
    this.setState({ open: !this.state.open })
  }
}

export default StuiCode
