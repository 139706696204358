import React from 'react'

import classNames from 'classnames'

interface IStuiTableControlsProps {
  children: React.ReactNode
  className?: string
}

const StuiTableControls: React.SFC<IStuiTableControlsProps> = ({ children, className }) => (
  <div className={classNames('c-stui-table-controls', className)}>
    {children}
  </div>
)

export default StuiTableControls
