import React from 'react'

import { Link } from 'react-router-dom'

import { BILLING_PATH } from 'app_search/utils/routePaths'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiCheckbox,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiForm,
  EuiFormRow,
  EuiPanel,
  EuiRadio,
  EuiSelect,
  EuiSpacer,
  EuiText,
  EuiTitle
} from '@elastic/eui'

import StuiFlashMessages from 'stui/FlashMessages'

import { IApiToken, IEngine, IObject, IRole } from 'app_search/types'

import {
  ADMIN,
  PRIVATE,
  TOKEN_TYPE_DESCRIPTION,
  TOKEN_TYPE_INFO
} from 'shared/constants/credentials'

import { CredentialsLogic } from './CredentialsLogic'

interface ICredentialFlyoutProps {
  actions: {
    hideCredentialsForm()
    onApiTokenChange()
    onEngineSelect(engineName: string)
    setTokenName(name: string)
    setNameInputBlurred(isBlurred: boolean)
    setAccessAllEngines(accessAll: boolean)
    setTokenReadWrite(target: IObject)
    setTokenType(tokenType: string)
  }
  premiumAccount: boolean
  fullEngineAccessChecked: boolean
  engines: IEngine[]
  formErrors: string[]
  activeApiToken: IApiToken
  activeApiTokenRawName: string
  activeApiTokenIsExisting: boolean
  nameInputBlurred: boolean
  myRole: IRole
  keyHelpText: string
}

const DOCS_HREF = 'https://swiftype.com/documentation/app-search/authentication'

const CredentialFlyout: React.SFC<ICredentialFlyoutProps> = ({
  actions: {
    onApiTokenChange,
    onEngineSelect,
    setNameInputBlurred,
    setAccessAllEngines,
    setTokenName,
    setTokenReadWrite,
    setTokenType,
    hideCredentialsForm
  },
  activeApiToken,
  activeApiTokenIsExisting,
  activeApiTokenRawName: rawName,
  formErrors,
  myRole,
  engines,
  premiumAccount,
  fullEngineAccessChecked,
  keyHelpText
}) => {
  const handleNameChange = e => setTokenName(e.target.value)
  const handleNameInputBlurred = () => setNameInputBlurred(true)
  const handleReadWriteCheckboxChange = e => setTokenReadWrite(e.target)
  const handleTokenTypeChange = e => setTokenType(e.target.value)
  const handleCloseClick = () => hideCredentialsForm()
  const handleSubmit = e => {
    e.preventDefault()
    onApiTokenChange()
  }

  return (
    <EuiFlyout
      onClose={hideCredentialsForm}
      hideCloseButton={true}
      ownFocus={true}
      aria-labelledby="credentialsFlyoutTitle"
      size="s"
    >
      <EuiFlyoutHeader hasBorder={true}>
        <EuiTitle size="m">
          <h2 id="credentialsFlyoutTitle">
            {activeApiToken.id ? `Update ${activeApiToken.name}` : 'Create A New Key'}
          </h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody style={{ display: 'flex' }}>
        <EuiForm
          className="new_loco_moco_create_api_token"
          acceptCharset="UTF-8"
          onSubmit={handleSubmit}
        >
          <EuiSpacer />
          {formErrors.length > 0 && <StuiFlashMessages error={formErrors} />}
          <EuiFormRow
            label="Key name"
            helpText={keyHelpText}
            fullWidth={true}
          >
            <EuiFieldText
              name="raw_name"
              id="raw_name"
              placeholder="i.e, my-engine-key"
              data-test-subj="APIKeyField"
              value={rawName}
              onChange={handleNameChange}
              onBlur={handleNameInputBlurred}
              autoFocus={true}
              required={true}
              autoComplete="off"
              maxLength={64}
              disabled={!!activeApiToken.id}
              fullWidth={true}
            />
          </EuiFormRow>
          <EuiFormRow
            label="Key type"
            fullWidth={true}
            helpText={
              <EuiText size="xs">
                <p>
                  {TOKEN_TYPE_DESCRIPTION[activeApiToken.type] &&
                    <strong>{TOKEN_TYPE_DESCRIPTION[activeApiToken.type]} </strong>
                  }
                  <a href={DOCS_HREF}>Visit the documentation</a> to learn more about keys.
                </p>
              </EuiText>
            }
          >
            <EuiSelect
              name="token-type"
              required={true}
              options={TOKEN_TYPE_INFO.filter(typeInfo => myRole.ability.credentialTypes().includes(typeInfo.value))}
              value={activeApiToken.type}
              onChange={handleTokenTypeChange}
              placeholder="Select a key type"
              disabled={!!activeApiToken.id}
              fullWidth={true}
            />
          </EuiFormRow>

          {activeApiToken.type === PRIVATE &&
            <>
              <EuiPanel className="read-write-access-input-container">
                <EuiTitle size="xs">
                  <h4>Read and Write Access Levels</h4>
                </EuiTitle>
                <EuiText>Only applies to Private API Keys.</EuiText>
                <EuiSpacer />
                <EuiCheckbox
                  name="read"
                  id="read"
                  checked={activeApiToken.read}
                  onChange={handleReadWriteCheckboxChange}
                  label="Read Access"
                />
                <EuiCheckbox
                  name="write"
                  id="write"
                  checked={activeApiToken.write}
                  onChange={handleReadWriteCheckboxChange}
                  label="Write Access"
                />
              </EuiPanel>
            </>
          }
          {activeApiToken.type && activeApiToken.type !== ADMIN &&
            <>
              <EuiSpacer />
              <EuiFormRow label="Engine Access Control">
                <>
                  <EuiRadio
                    id="all_engines"
                    hidden={!myRole.ability.accessAllEngines()}
                    label={
                      <>
                        <EuiTitle size="xxs">
                          <h5>Full Engine Access</h5>
                        </EuiTitle>
                        <EuiText size="s">Access to all current and future Engines.</EuiText>
                      </>
                    }
                    checked={fullEngineAccessChecked}
                    name="engine_access"
                    value={fullEngineAccessChecked.toString()}
                    onChange={() => setAccessAllEngines(true)}
                  />
                  <EuiSpacer size="s" />
                  <EuiRadio
                    id="specific_engines"
                    label={
                      <>
                        <EuiTitle size="xxs">
                          <h5>Limited Engine Access</h5>
                        </EuiTitle>
                        <EuiText size="s">
                          {premiumAccount
                            ? <>Limit key access to specific Engines.</>
                            : <>Engine access restrictions are limited to Pro and Premium plans. <Link to={BILLING_PATH}>Upgrade</Link>.</>
                          }
                        </EuiText>
                      </>
                    }
                    checked={!fullEngineAccessChecked}
                    disabled={!premiumAccount}
                    name="engine_access"
                    value={(!fullEngineAccessChecked).toString()}
                    onChange={() => setAccessAllEngines(false)}
                  />
                </>
              </EuiFormRow>
            </>
          }
          {!fullEngineAccessChecked && activeApiToken.type !== ADMIN &&
            <EuiPanel className="access-specific-engines">
              <EuiTitle size="xs">
                <h4>Select Engines</h4>
              </EuiTitle>
              <EuiText>Engines which the key can access:</EuiText>
              <EuiSpacer />
              {engines.map(engine => (
                <EuiCheckbox
                  name={engine.name}
                  label={engine.name}
                  id={`engine_${engine.name}`}
                  checked={activeApiToken.engines && activeApiToken.engines.indexOf(engine.name) > -1}
                  onChange={() => onEngineSelect(engine.name)}
                  key={engine.name}
                />
              ))}
            </EuiPanel>
          }
        </EuiForm>
        <EuiSpacer />
        {!!activeApiToken.id &&
          <EuiCallOut title="Proceed with caution!" color="warning" iconType="alert">
            <p>Existing API keys may be shared between users. Changing permissions for this key will affect all users who have access to this key.</p>
          </EuiCallOut>
        }
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              iconType="cross"
              onClick={handleCloseClick}
            >
              Close
            </EuiButtonEmpty>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              onClick={handleSubmit}
              fill={true}
              color="secondary"
              iconType="check"
              data-test-subj="APIKeyActionButton"
            >
              {activeApiTokenIsExisting ? 'Update' : 'Save'}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )
}

export default CredentialsLogic(CredentialFlyout)
