import React from 'react'

import { Route } from 'react-router-dom'

import { BILLING_PATH, INVOICE_PATH } from 'app_search/utils/routePaths'

import Billing from './Billing'
import Invoice from './Invoice'

const BillingRouter: React.SFC = () => (
  <div>
    <Route exact={true} path={BILLING_PATH} component={Billing} />
    <Route exact={true} path={INVOICE_PATH} component={Invoice} />
  </div>
)

export default BillingRouter
