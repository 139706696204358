import React from 'react'

import { Route, Switch, withRouter } from 'react-router-dom'

import { IMatch } from 'app_search/types'
import {
  META_ENGINES_PATH,
  META_ENGINE_NEW_PATH
} from 'app_search/utils/routePaths'

import CreateMetaEngine from './CreateMetaEngine'
import MetaEngines from './MetaEngines'

interface IMetaEnginesRouterProps {
  match?: IMatch
}

const MetaEnginesRouter: React.SFC<IMetaEnginesRouterProps> = (props) => {
  return (
    <Switch>
      <Route
        path={META_ENGINES_PATH}
        exact={true}
        render={() => <MetaEngines {...props} />}
      />
      <Route
        path={META_ENGINE_NEW_PATH}
        exact={true}
        render={() => <CreateMetaEngine {...props} />}
      />
    </Switch>
  )
}

export default withRouter(MetaEnginesRouter)
