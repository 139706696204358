import React from 'react'

import classNames from 'classnames'

import { SchemaSelect } from 'shared/components/Schema'

interface ISchemaExistingFieldProps {
  disabled?: boolean
  fieldName: string
  fieldType?: string
  unconfirmed?: boolean
  hideName?: boolean
  updateExistingFieldType?(fieldName: string, fieldType: string)
}

const SchemaExistingField: React.SFC<ISchemaExistingFieldProps> = ({
  disabled,
  fieldName,
  fieldType,
  unconfirmed,
  hideName,
  updateExistingFieldType
}) => {
  const fieldCssClass = classNames(
    'c-stui-engine-schema-field',
    { 'c-stui-engine-schema-field--recently-added': unconfirmed }
  )

  return (
    <div className={fieldCssClass} id={`field_${fieldName}`}>
      <div className="c-stui-engine-schema-field__name">{!hideName ? fieldName : ''}</div>
      {unconfirmed && <div className="c-stui-engine-schema-field__status">Recently Added</div>}
      {(fieldType && updateExistingFieldType) &&
        <div className="o-stui-select-container o-stui-select-container--align-right">
          <SchemaSelect
            fieldName={fieldName}
            fieldType={fieldType}
            handleFieldTypeChange={type => updateExistingFieldType(fieldName, type)}
            disabled={disabled}
          />
        </div>}
    </div>
  )
}

export default SchemaExistingField
