import React, { useEffect } from 'react'

import { Loading } from 'app_search/components/Loading'
import { ISynonymSet } from 'app_search/types'

import {
  EuiButton,
  EuiSpacer
} from '@elastic/eui'

import StuiEmptyState from 'stui/EmptyState'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import { SynonymsLogic } from './SynonymsLogic'

import SynonymsCard from './SynonymsCard'
import SynonymsForm from './SynonymsForm'

interface ISynonymsProps {
  actions: {
    initializeSynonyms()
    resetSynonyms()
    showSynonymsForm()
  }
  synonymSets: ISynonymSet[]
  synonymsFormVisible?: boolean
  activeSynonymSet?: ISynonymSet | null
  dataLoading: boolean
  flashMessages: IStuiFlashMessagesProps
}

const Synonyms: React.SFC<ISynonymsProps> = ({
  actions: {
    initializeSynonyms,
    resetSynonyms,
    showSynonymsForm
  },
  synonymSets,
  synonymsFormVisible,
  dataLoading,
  flashMessages
}) => {
  useEffect(() => {
    initializeSynonyms()
    return resetSynonyms
  }, [])

  if (dataLoading) { return <Loading /> }

  const hasSynonyms = synonymSets.length > 0

  const createSynonymSetButton = (
    <EuiButton data-test-subj="SynonymListCreateButton" color="secondary" fill={true} onClick={showSynonymsForm}>
      Create a Synonym Set
    </EuiButton>
  )

  const synonymsContent = !hasSynonyms ?
    <StuiEmptyState
      icon="synonyms-fill"
      title="No synonym sets yet"
      action={createSynonymSetButton}
    /> :
    <SynonymsCard />

  return (
    <div data-test-subj="Synonyms">
      <StuiHeader
        key="header"
        contained={true}
        viewHeader={true}
        isSticky={true}
        actions={hasSynonyms ? createSynonymSetButton : null}
      >
        <StuiHeading type="view">Synonyms</StuiHeading>
      </StuiHeader>
      <EuiSpacer key="spacer" />
      <StuiMain key="main">
        <StuiFlashMessages {...flashMessages} />
        {synonymsContent}
      </StuiMain>

      {synonymsFormVisible && <SynonymsForm />}
    </div>
  )
}

export default SynonymsLogic(Synonyms)
