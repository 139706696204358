import React from 'react'

interface IStuiSubHeadingProps {
  children: React.ReactNode
}

const StuiSubHeading: React.SFC<IStuiSubHeadingProps> = ({ children }) => (
  <div className="c-stui-view-header__subtitle">{children}</div>
)

export default StuiSubHeading
