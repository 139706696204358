import 'core-js/stable'
import 'expose-loader?dragula!dragula'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'

import { RavenBoundary, ravenInstall } from 'app_search/sentry'
import store from 'shared/store'

declare const window: {
  sentry?: any
} & Window

if (window.sentry) {
  ravenInstall(window.sentry)
}

declare const require: {
  context
} & NodeRequire

// TODO: This is a temp hack until EuiBasicTable and EuiInMemoryTable gets converted to TypeScript.
declare module '@elastic/eui' {
  export const EuiBasicTable
  export const EuiInMemoryTable
}

const context = require.context('app_search', true, /^\.(\/[a-z0-9]+)+$/i)

document.addEventListener('DOMContentLoaded', () => {
  const els = document.querySelectorAll('[data-react-component]')
  Array.prototype.forEach.call(els, (el: Element) => {
    const componentName = el.getAttribute('data-react-component')
    const componentProps = el.getAttribute('data-react-props')

    if (!componentName || !componentProps) {
      throw new Error(`Invalid React component, name: ${componentName}, props: ${componentProps}`)
    }
    const Component = context(`./${componentName}`).default
    const props = JSON.parse(componentProps)
    ReactDOM.render(
      <RavenBoundary>
        <Provider store={store}>
          <HashRouter>
            <Component {...props} />
          </HashRouter>
        </Provider>
      </RavenBoundary>,
      el
    )
  })
})
