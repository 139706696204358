import React from 'react'

import { setQueryParams } from 'app_search/utils/queryParams'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'

import { IObject, IRouter } from 'app_search/types'

import { EuiButton } from '@elastic/eui'
import { StuiDateRangeInput } from 'stui/DateRangeInput'

interface IAnalyticsHeaderActionsProps extends IRouter {
  query?: string
  history: IObject
  allTags: string[]
  currentTag: string
  startDate: string
  endDate: string
  disableClick: boolean
}

const AnalyticsHeaderActions: React.SFC<IAnalyticsHeaderActionsProps> = ({
  query,
  history,
  allTags,
  currentTag,
  startDate,
  endDate
}) => (
    <>
      <div className="o-stui-dropdown stui-select">
        <EuiButton
          iconSide="right"
          iconType="arrowDown"
        >
          {currentTag}
        </EuiButton>
        <div className="o-stui-dropdown__container">
          <nav className="o-stui-dropdown-nav">
            {allTags.map((tag, index) => {
              const tagLinkCss = classNames(
                'o-stui-nav-item',
                'o-stui-nav-item--default',
                { 'o-stui-nav-item--active': tag === currentTag }
              )

              const handleTagClick = e => {
                e.preventDefault()
                setQueryParams({ tag, query, end: endDate, start: startDate }, history)
              }

              return (
                <a
                  onClick={handleTagClick}
                  className={tagLinkCss}
                  key={index}
                  href="#"
                  data-attribute-value={tag}
                >
                  <span className="o-stui-nav-item__label">{tag}</span>
                </a>
              )
            })}
          </nav>
        </div>
      </div>
      <StuiDateRangeInput
        orientation="right"
        onRangeUpdate={({ start, end }) => setQueryParams({ tag: currentTag, end, start, query }, history)}
        startDate={startDate}
        endDate={endDate}
        disableOnClickOutside={true}
      />
    </>
  )

export default withRouter(AnalyticsHeaderActions)
