import { useMemo } from 'react'

import { useValues } from 'kea'

import { AppLogic } from 'app_search/App'

const useAbilities = () => {
  const { myRole } = useValues(AppLogic)

  const abilities = useMemo(() => ({
    canViewEngines: myRole.ability.can('view', 'account_engines'),
    canViewMetaEngines: myRole.ability.can('view', 'account_meta_engines'),
    canViewAccountCredentials: myRole.ability.can('view', 'account_credentials'),
    canViewAccountUsers: myRole.ability.can('view', 'account_users'),
    canViewEngineAnalytics: myRole.ability.can('view', 'engine_analytics'),
    canViewEngineApiLogs: myRole.ability.can('view', 'engine_api_logs'),
    canViewEngineCredentials: myRole.ability.can('view', 'engine_credentials'),
    canViewEngineDocuments: myRole.ability.can('view', 'engine_documents'),
    canViewEngineSchema: myRole.ability.can('view', 'engine_schema'),
    canViewEngineQueryTester: myRole.ability.can('view', 'engine_query_tester'),
    canViewMetaEngineSourceEngines: myRole.ability.can('view', 'meta_engine_source_engines'),
    canViewUsers: myRole.ability.can('view', 'account_users'),
    canViewSettings: myRole.ability.can('view', 'account_settings'),
    canViewRoleMappings: myRole.ability.can('view', 'role_mappings'),
    canViewBilling: myRole.ability.can('view', 'account_billing'),
    canManageEngines: myRole.ability.can('manage', 'account_engines'),
    canManageMetaEngines: myRole.ability.can('manage', 'account_meta_engines'),
    canManageSettings: myRole.ability.can('manage', 'account_settings'),
    canManageUsers: myRole.ability.can('manage', 'account_users'),
    canManageEngineDocuments: myRole.ability.can('manage', 'engine_documents'),
    canManageEngineSynonyms: myRole.ability.can('manage', 'engine_synonyms'),
    canManageEngineCredentials: myRole.ability.can('manage', 'engine_credentials'),
    canManageEngineCurations: myRole.ability.can('manage', 'engine_curations'),
    canManageEngineRelevanceTuning: myRole.ability.can('manage', 'engine_relevance_tuning'),
    canManageEngineReferenceUi: myRole.ability.can('manage', 'engine_reference_ui'),
    canManageEngineResultSettings: myRole.ability.can('manage', 'engine_result_settings'),
    canManageEngineSchema: myRole.ability.can('manage', 'engine_schema'),
    canManageMetaEngineSourceEngines: myRole.ability.can('manage', 'meta_engine_source_engines'),
    canSendTelemetryData: myRole.ability.can('send', 'telemetry_data'),
    canManageTelemetryData: myRole.ability.can('manage', 'telemetry_data'),
    canDestroySession: myRole.ability.can('destroy', 'session'),
    canUpdateUserPassword: myRole.ability.can('update', 'user_password')
  }), [myRole])

  return abilities
}

export default useAbilities
