import React, { useState } from 'react'

import {
  EuiForm,
  EuiFormRow,
  EuiModalBody,
  EuiSpacer,
  EuiTextArea
} from '@elastic/eui'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiIcon from 'stui/Icon'
import DocumentCreationPanelFooter from './DocumentCreationPanelFooter'

interface IDocumentCreationTextareaProps {
  value: string
  errors: string[]
  uploading: boolean
  toggleDocumentCreation()
  submitText(value: string)
}

const DEFAULT_JSON = `[
  {
    "id": "park_rocky-mountain",
    "title": "Rocky Mountain",
    "description": "Bisected north to south by the Continental Divide, this portion of the Rockies has ecosystems varying from over 150 riparian lakes to montane and subalpine forests to treeless alpine tundra. Wildlife including mule deer, bighorn sheep, black bears, and cougars inhabit its igneous mountains and glacial valleys. Longs Peak, a classic Colorado fourteener, and the scenic Bear Lake are popular destinations, as well as the historic Trail Ridge Road, which reaches an elevation of more than 12,000 feet (3,700 m).",
    "nps_link": "https://www.nps.gov/romo/index.htm",
    "states": [
      "Colorado"
    ],
    "visitors": 4517585,
    "world_heritage_site": false,
    "location": "40.4,-105.58",
    "acres": 265795.2,
    "square_km": 1075.6,
    "date_established": "1915-01-26T06:00:00Z"
  },
  {
    "id": "park_saguaro",
    "title": "Saguaro",
    "description": "Split into the separate Rincon Mountain and Tucson Mountain districts, this park is evidence that the dry Sonoran Desert is still home to a great variety of life spanning six biotic communities. Beyond the namesake giant saguaro cacti, there are barrel cacti, chollas, and prickly pears, as well as lesser long-nosed bats, spotted owls, and javelinas.",
    "nps_link": "https://www.nps.gov/sagu/index.htm",
    "states": [
      "Arizona"
    ],
    "visitors": 820426,
    "world_heritage_site": false,
    "location": "32.25,-110.5",
    "acres": 91715.72,
    "square_km": 371.2,
    "date_established": "1994-10-14T05:00:00Z"
  }
]`

const DocumentCreationTextarea: React.SFC<IDocumentCreationTextareaProps> = ({
  value: initialValue,
  errors,
  uploading,
  toggleDocumentCreation,
  submitText
}) => {
  const [state, setNewState] = useState({ value: initialValue || DEFAULT_JSON })
  const setState = newState => setNewState({ ...state, ...newState })
  const { value } = state
  const handleSubmit = e => {
    e.preventDefault()
    submitText(value)
  }

  const handleChange = e => setState({ value: e.target.value })

  const errorsContainer = errors.length > 0 &&
    <div className="c-stui-code-block__error">
      <StuiIcon name="error-fill" />
      <StuiHeader>
        <StuiHeading type="title">Invalid input!</StuiHeading>
        {errors.map((errorMessage, index) => <pre key={index}>{errorMessage}</pre>)}
      </StuiHeader>
    </div>

  return (
    <>
      <EuiModalBody>
        <EuiForm>
          <form onSubmit={handleSubmit}>
            <StuiHeader>
              <StuiHeading type="section">Create Documents</StuiHeading>
              <p>Paste an array of JSON documents. Ensure the JSON is valid and that each document object is less than 102400 bytes.</p>
            </StuiHeader>
            <EuiSpacer />
            <EuiFormRow fullWidth={true}>
              <EuiTextArea
                value={value}
                className="u-stui-type--monospace"
                onChange={handleChange}
                aria-label="Paste JSON here"
                fullWidth={true}
                rows={12}
              />
            </EuiFormRow>
            {errorsContainer}
          </form>
        </EuiForm>
      </EuiModalBody>
      <DocumentCreationPanelFooter
        uploading={uploading}
        handleSubmit={handleSubmit}
        handleClose={toggleDocumentCreation}
      />
    </>
  )
}

export default DocumentCreationTextarea
