import React from 'react'

import Lottie from 'react-lottie'
import data from './animation/app-search-spinner.json'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: data,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const Loading: React.SFC<{}> = () => (
  <div className="o-stui-app-search-loading">
    <Lottie
      options={defaultOptions}
      isClickToPauseDisabled={true}
      ariaRole="img"
      ariaLabel="loading"
      width="100px"
      height="100px"
    />
  </div>
)

export default Loading
