import React from 'react'

import {
  EuiCheckbox,
  EuiFieldNumber,
  EuiHealth,
  EuiIcon,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiTableRowCellCheckbox,
  EuiText,
  EuiToolTip
} from '@elastic/eui'

import { ISchemaConflicts } from 'app_search/types'

import { ResultSettingsLogic } from './ResultSettingsLogic'
import {
  IFieldResultSettingObject
} from './types'

interface IResultSettingsTableProps {
  actions: {
    clearRawSizeForField(fieldName: string)
    clearSnippetSizeForField(fieldName: string)
    toggleRawForField(fieldName: string)
    toggleSnippetFallbackForField(fieldName: string)
    toggleSnippetForField(fieldName: string)
    updateRawSizeForField(fieldName: string, size: number)
    updateSnippetSizeForField(fieldName: string, size: number)
  }
  nonTextFields: IFieldResultSettingObject
  schemaConflicts: ISchemaConflicts
  textFields: IFieldResultSettingObject
}

const SIZE_FIELD_MINIMUM = 20
const SIZE_FIELD_MAXIMUM = 1000

const getResultSettingsArray = (fields: IFieldResultSettingObject) => {
  return Object.entries(fields).sort(([aFieldName], [bFieldName]) => aFieldName > bFieldName ? 1 : -1)
}

const updateOrClearSizeForField = (fieldName, fieldValue, updateAction, clearAction) => {
  if (typeof fieldValue === 'number' && !isNaN(fieldValue)) {
    updateAction(fieldName, fieldValue)
  } else {
    clearAction(fieldName)
  }
}

const ResultSettingsTable: React.SFC<IResultSettingsTableProps> = ({
  actions: {
    clearRawSizeForField,
    clearSnippetSizeForField,
    toggleRawForField,
    toggleSnippetFallbackForField,
    toggleSnippetForField,
    updateRawSizeForField,
    updateSnippetSizeForField
  },
  nonTextFields,
  schemaConflicts,
  textFields
}) => (
    <EuiTable
      className="result-settings-table"
      responsive={false}
    >
      <EuiTableHeader className="result-settings-table__column-labels">
        <EuiTableHeaderCell align="left" />
        <EuiTableHeaderCell align="center" colSpan={2}>
          Raw
        <EuiToolTip position="top" content="A raw field is an exact representation of a field value. Must be at least 20 characters. Defaults to the entire field.">
            <EuiIcon type="questionInCircle" />
          </EuiToolTip>
        </EuiTableHeaderCell>
        <EuiTableHeaderCell align="center" colSpan={3}>
          Highlighting
        <EuiToolTip position="top" content="A snippet is an escaped representaiton of a field value. Query matches are encapsulated in <em> tags for highlighting. Fallback will look for a snippet match, but fallback to an escaped raw value if none is found. Range is between 20-1000. Defaults to 100.">
            <EuiIcon type="questionInCircle" />
          </EuiToolTip>
        </EuiTableHeaderCell>
      </EuiTableHeader>
      <EuiTableHeader className="result-settings-table__text-header">
        <EuiTableHeaderCell align="left">Text Fields</EuiTableHeaderCell>
        <EuiTableHeaderCell align="center">Raw</EuiTableHeaderCell>
        <EuiTableHeaderCell align="center">Max Size</EuiTableHeaderCell>
        <EuiTableHeaderCell align="center">Snippet</EuiTableHeaderCell>
        <EuiTableHeaderCell align="center">Fallback</EuiTableHeaderCell>
        <EuiTableHeaderCell align="center">Max Size</EuiTableHeaderCell>
      </EuiTableHeader>
      <EuiTableBody>
        {
          getResultSettingsArray(textFields)
            .map(([fieldName, fieldSettings]) => (
              <EuiTableRow key={fieldName}>
                <EuiTableRowCell className="c-stui-engine-schema-field__name">{fieldName}</EuiTableRowCell>
                <EuiTableRowCellCheckbox>
                  <EuiCheckbox
                    id={`${fieldName}-raw}`}
                    checked={!!fieldSettings.raw}
                    onChange={_ => { toggleRawForField(fieldName) }}
                  />
                </EuiTableRowCellCheckbox>
                <EuiTableRowCell align="center">
                  <EuiFieldNumber
                    value={typeof fieldSettings.rawSize === 'number' ? fieldSettings.rawSize : ''}
                    placeholder="No limit"
                    disabled={!fieldSettings.raw}
                    min={SIZE_FIELD_MINIMUM}
                    max={SIZE_FIELD_MAXIMUM}
                    onChange={e => {
                      const fieldValue = parseInt(e.target.value, 10)
                      updateOrClearSizeForField(fieldName, fieldValue, updateRawSizeForField, clearRawSizeForField)
                    }}
                    onBlur={e => {
                      const fieldValue = Math.min(SIZE_FIELD_MAXIMUM, Math.max(SIZE_FIELD_MINIMUM, parseInt(e.target.value, 10)))
                      updateOrClearSizeForField(fieldName, fieldValue, updateRawSizeForField, clearRawSizeForField)
                    }}
                    size={4}
                  />
                </EuiTableRowCell>
                <EuiTableRowCellCheckbox>
                  <EuiCheckbox
                    id={`${fieldName}-snippet}`}
                    checked={!!fieldSettings.snippet}
                    onChange={_ => { toggleSnippetForField(fieldName) }}
                  />
                </EuiTableRowCellCheckbox>
                <EuiTableRowCellCheckbox>
                  <EuiCheckbox
                    id={`${fieldName}-snippetFallback}`}
                    checked={fieldSettings.snippetFallback}
                    disabled={!fieldSettings.snippet}
                    onChange={_ => { toggleSnippetFallbackForField(fieldName) }}
                  />
                </EuiTableRowCellCheckbox>
                <EuiTableRowCell align="center">
                  <EuiFieldNumber
                    value={typeof fieldSettings.snippetSize === 'number' ? fieldSettings.snippetSize : ''}
                    disabled={!fieldSettings.snippet}
                    placeholder="100"
                    min={SIZE_FIELD_MINIMUM}
                    max={SIZE_FIELD_MAXIMUM}
                    onChange={e => {
                      const fieldValue = parseInt(e.target.value, 10)
                      updateOrClearSizeForField(fieldName, fieldValue, updateSnippetSizeForField, clearSnippetSizeForField)
                    }}
                    onBlur={e => {
                      const fieldValue = Math.min(SIZE_FIELD_MAXIMUM, Math.max(SIZE_FIELD_MINIMUM, parseInt(e.target.value, 10)))
                      updateOrClearSizeForField(fieldName, fieldValue, updateSnippetSizeForField, clearSnippetSizeForField)
                    }}
                    size={4}
                  />
                </EuiTableRowCell>
              </EuiTableRow>
            ))
        }
      </EuiTableBody>
      <EuiTableHeader className="result-settings-table__non-text-header">
        <EuiTableHeaderCell align="left">Non-Text Fields</EuiTableHeaderCell>
        <EuiTableHeaderCell align="center">Raw</EuiTableHeaderCell>
        <EuiTableHeaderCell colSpan={4} />
      </EuiTableHeader>
      <EuiTableBody>
        {
          getResultSettingsArray(nonTextFields)
            .map(([fieldName, fieldSettings]) => (
              <EuiTableRow key={fieldName}>
                <EuiTableRowCell className="c-stui-engine-schema-field__name">{fieldName}</EuiTableRowCell>
                <EuiTableRowCellCheckbox>
                  <EuiCheckbox
                    id={`${fieldName}-raw}`}
                    checked={!!fieldSettings.raw}
                    onChange={_ => { toggleRawForField(fieldName) }}
                  />
                </EuiTableRowCellCheckbox>
                <EuiTableRowCell colSpan={4} />
              </EuiTableRow>
            ))
        }
      </EuiTableBody>
      {!!Object.keys(schemaConflicts).length &&
        <>
          <EuiTableHeader className="result-settings-table__disabled-header">
            <EuiTableHeaderCell align="left">Disabled Fields</EuiTableHeaderCell>
            <EuiTableHeaderCell colSpan={5} />
          </EuiTableHeader>
          <EuiTableBody>
            {
              Object.keys(schemaConflicts)
                .map(fieldName => (
                  <EuiTableRow key={fieldName}>
                    <EuiTableRowCell>
                      <EuiText
                        className="c-stui-engine-schema-field__name has-conflcit"
                        size="s"
                      >
                        {fieldName}
                      </EuiText>
                      <EuiHealth color="warning">Field-type conflict</EuiHealth>
                    </EuiTableRowCell>
                    <EuiTableRowCell colSpan={5} />
                  </EuiTableRow>
                ))
            }
          </EuiTableBody>
        </>
      }
    </EuiTable>
  )

export default ResultSettingsLogic(ResultSettingsTable)
