import routes from 'app_search/routes'
import { IInvoice, ILMAccount } from 'app_search/types'
import http from 'shared/http'
import { storeLogic } from 'shared/store'

export const InvoiceLogic = storeLogic({
  actions: () => ({
    setInitialData: (account: ILMAccount, invoice: IInvoice) => ({ account, invoice })
  }),
  reducers: ({ actions }) => ({
    account: [{}, {
      [actions.setInitialData]: (_, { account }) => account
    }],
    invoice: [{}, {
      [actions.setInitialData]: (_, { invoice }) => invoice
    }],
    dataLoading: [true, {
      [actions.setInitialData]: () => false
    }]
  }),
  thunks: ({ actions }) => ({
    initializeData: (invoiceId) => {
      http(routes.invoiceDetailsLocoMocoSettingsBillingPath(invoiceId)).then(({ data: { account, invoice } }) => {
        actions.setInitialData(account, invoice)
      })
    }
  })
})
