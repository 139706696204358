import React, { useState } from 'react'

import Moment from 'moment'
import http from 'shared/http'

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiSpacer,
  EuiText,
  EuiTitle
} from '@elastic/eui'

import { IObject } from 'app_search/types'

interface INewFeatuersFlyoutProps {
  entries: IObject[]
}

const BLOG_ROOT = 'https://www.elastic.co'
const UTM_PARAMS = { baymax: 'app-search-product', elektra: 'new-features' }

Moment.locale('en')

const NewFeatuersFlyout: React.SFC<INewFeatuersFlyoutProps> = ({ entries }) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false)

  const toggleFlyout = () => { setIsFlyoutOpen(!isFlyoutOpen) }

  const flyout = isFlyoutOpen && (
    <EuiFlyout
      className="new-features-flyout"
      onClose={toggleFlyout}
      hideCloseButton={true}
      ownFocus={true}
      size="s"
      data-test-subj="NewFeaturesFlyout"
      aria-labelledby="newFeaturesFlyoutTitle"
    >
      <EuiFlyoutHeader hasBorder={true}>
        <EuiTitle size="m">
          <h2 id="newFeaturesFlyoutTitle">🎉&nbsp;&nbsp;New&nbsp;Features</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <ul className="new-feature-list">
          {entries.map((result: any, index: number) =>
            <li key={index}>
              <span
                className="new-feature-publish-date"
              >
                {Moment(result.publish_date).format('MMM d YYYY')}
              </span>
              <EuiSpacer size="s" />
              <h4 className="new-feature-heading">
                <a
                  href={http.getUri({ url: BLOG_ROOT + result.url, params: UTM_PARAMS })}
                  className="new-feature-link"
                  target="_blank"
                  data-test-subj="NewFeaturesFlyoutLink"
                >
                  <strong>{result.title}</strong>
                </a>
              </h4>
              <EuiSpacer size="xs" />
              <EuiText grow={false} size="s">
                <p className="new-feature-abstract">
                  {result.abstract_l10n}
                </p>
              </EuiText>
            </li>
          )}
        </ul>
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              iconType="cross"
              onClick={toggleFlyout}
            >
              Close
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )

  return <>
    <EuiButtonEmpty iconType="starFilled" data-test-subj="newFeaturesFlyoutButton" onClick={toggleFlyout}>New&nbsp;Features</EuiButtonEmpty>
    {flyout}
  </>
}

export default NewFeatuersFlyout
