import React, { useEffect } from 'react'

import { IFieldCoercionErrors, SchemaLogic } from './SchemaLogic'

import { getDocumentDetailRoute } from 'app_search/utils/routePaths'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import SchemaErrorsAccordion from 'shared/components/Schema/SchemaErrorsAccordion'

import { IObject } from 'app_search/types'

interface ISchemaChangeErrorsProps {
  activeReindexJobId: string
  actions: {
    initializeSchemaFieldErrors(activeReindexJobId: string)
  }
  engineName: string
  fieldCoercionErrors: IFieldCoercionErrors
  serverSchema: IObject
}

const SchemaChangeErrors: React.SFC<ISchemaChangeErrorsProps> = ({
  activeReindexJobId,
  actions: {
    initializeSchemaFieldErrors
  },
  engineName,
  fieldCoercionErrors,
  serverSchema
}) => {
  useEffect(() => { initializeSchemaFieldErrors(activeReindexJobId) }, [])

  return (
    <div>
      <StuiHeader key="header" contained={true} viewHeader={true}>
        <StuiHeading type="view">Schema Change Errors</StuiHeading>
      </StuiHeader>
      <StuiMain key="main">
        <SchemaErrorsAccordion
          fieldCoercionErrors={fieldCoercionErrors}
          schema={serverSchema}
          itemId={engineName}
          getRoute={getDocumentDetailRoute}
        />
      </StuiMain>
    </div>
  )
}

export default SchemaLogic(SchemaChangeErrors)
