import React, { useState } from 'react'

import classNames from 'classnames'

import { TEXT } from 'shared/constants/fieldTypes'

import { IBoost } from 'app_search/types'

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiRange,
  EuiSwitch,
  EuiText,
  EuiTextColor,
  EuiTitle
} from '@elastic/eui'

import {
  StuiAccordion,
  StuiAccordionItem,
  StuiAccordionItemBody,
  StuiAccordionItemHeader
} from 'stui/Accordion'
import StuiHeading from 'stui/Heading'
import StuiIcon from 'stui/Icon'

import Boost from './Boost'

interface ISearchSettingsFieldProps {
  name: string
  type: string
  field?: {
    weight: number
  }
  boosts?: IBoost[]
  addBoost(name: string, type: string)
  deleteBoost(name: string, index: number)
  toggleSearchField(name: string, disableField: boolean)
  updateFieldWeight(name: string, weight: number)
  updateBoostFactor(name: string, index: number, factor: number)
  addBoostValue(name: string, boostIndex: number)
  removeBoostValue(name: string, boostIndex: number, valueIndex: number)
  updateBoostValue(name: string, boostIndex: number, valueIndex: number, value: string | number)
  updateBoostSelectOption(name: string, boostIndex: number, optionType: string, value: string)
  updateBoostCenter(name: string, boostIndex: number, value: string | number)
}

const SearchSettingsField: React.SFC<ISearchSettingsFieldProps> = ({
  name,
  type,
  field,
  boosts = [],
  addBoost,
  toggleSearchField,
  updateFieldWeight,
  deleteBoost,
  updateBoostFactor,
  addBoostValue,
  removeBoostValue,
  updateBoostValue,
  updateBoostSelectOption,
  updateBoostCenter
}) => {
  const [addBoostMenuOpen, setBoostMenuOpen] = useState(false)

  const openAddBoostMenu = () => setBoostMenuOpen(true)
  const closeAddBoostMenu = () => setBoostMenuOpen(false)
  const addBoostClick = (value) => {
    setBoostMenuOpen(false)
    addBoost(name, value)
  }

  const handleRangeChange = e => updateFieldWeight(name, parseFloat(e.target.value))

  const fieldPropertyWeightCss = classNames(
    'c-stui-field-property',
    { 'c-stui-field-property--disabled': !field || field.weight === 0 }
  )

  const boostsArray = Array.isArray(boosts) ? boosts : [boosts]

  return (
    <StuiAccordionItem expanded={false} className="c-stui-field-manager__field">
      <StuiAccordionItemHeader className="c-stui-field-manager__field-header">
        <div className="c-stui-field">
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem className="c-stui-field__title">
              <EuiTitle size="xs">
                <h4>{name}</h4>
              </EuiTitle>
              <EuiText>
                <h6>
                  <EuiTextColor color="subdued">{type}</EuiTextColor>
                </h6>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup gutterSize="s" alignItems="center" justifyContent="flexEnd" wrap>
                {boostsArray.map((boost, index) => (
                  <EuiFlexItem grow={false} key={index}>
                    <div key={index} className="c-stui-field-property">
                      <StuiIcon name={boost.type} />
                    </div>
                  </EuiFlexItem>
                ))}
                <EuiFlexItem grow={false}>
                  <span className={fieldPropertyWeightCss}>
                    <StuiIcon name="weights" />
                    <span>{!!field ? field.weight : 0}</span>
                  </span>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </StuiAccordionItemHeader>
      <StuiAccordionItemBody className="c-stui-field-manager__field-body">
        <div className="o-stui-panel-section">
          <div className="o-stui-panel-section__header">
            <StuiHeading type="section" className="o-stui-panel-section__title">
              Text Search
            </StuiHeading>
          </div>
          <div className="o-stui-panel-section__body">
            <EuiSwitch
              label={type !== TEXT ? 'Search can only be enabled on text fields' : 'Search this field'}
              onChange={() => type === TEXT && toggleSearchField(name, !!field)}
              checked={!!field}
              disabled={type !== TEXT}
            />
          </div>
        </div>
        {field && <div className="o-stui-panel-section">
          <div className="o-stui-panel-section__header">
            <StuiHeading type="section" className="o-stui-panel-section__title">
              Weight
            </StuiHeading>
          </div>
          <div className="o-stui-panel-section__body">
            <EuiRange
              min={0}
              max={10}
              step={.1}
              value={field.weight || 0}
              onChange={handleRangeChange}
              showInput={true}
              compressed={true}
              fullWidth={true}
            />
          </div>
        </div>}
        <div className="o-stui-panel-section">
          <div className="o-stui-panel-section__header">
            <StuiHeading type="section" className="o-stui-panel-section__title">
              Boosts
              </StuiHeading>
            <EuiPopover
              id="add_boost"
              isOpen={addBoostMenuOpen}
              closePopover={closeAddBoostMenu}
              panelPaddingSize="s"
              button={
                <EuiButton
                  iconType="arrowDown"
                  iconSide="right"
                  onClick={openAddBoostMenu}
                  size="s"
                >
                  Add Boost
                </EuiButton>
              }
            >
              <nav>
                <span onClick={() => addBoostClick('value')} className="o-stui-nav-item">
                  <StuiIcon name="value" />
                  <span>Value</span>
                </span>
                {type !== TEXT && <>
                  <span onClick={() => addBoostClick('functional')} className="o-stui-nav-item">
                    <StuiIcon name="functional" />
                    <span>Functional</span>
                  </span>
                  <span onClick={() => addBoostClick('proximity')} className="o-stui-nav-item">
                    <StuiIcon name="proximity" />
                    <span>Proximity</span>
                  </span>
                </>}
              </nav>
            </EuiPopover>
          </div>
          {boostsArray && <div className="o-stui-panel-section__body">
            <StuiAccordion className="c-stui-boosts">
              {boostsArray.map((boost, index) => (
                <Boost
                  name={name}
                  index={index}
                  key={`${name}-${index}`}
                  boost={boost}
                  deleteBoost={deleteBoost}
                  updateBoostFactor={updateBoostFactor}
                  addBoostValue={addBoostValue}
                  removeBoostValue={removeBoostValue}
                  updateBoostValue={updateBoostValue}
                  updateBoostSelectOption={updateBoostSelectOption}
                  updateBoostCenter={updateBoostCenter}
                />
              ))}
            </StuiAccordion>
          </div>}
        </div>
      </StuiAccordionItemBody>
    </StuiAccordionItem>
  )
}

export default SearchSettingsField
