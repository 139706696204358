import React from 'react'

import classNames from 'classnames'

interface IStuiTableHeadCellProps {
  children?: React.ReactNode
  className?: string
  fixed?: boolean
}

const StuiTableHeadCell: React.SFC<IStuiTableHeadCellProps> = ({ children, className, fixed }) => (
  <th className={classNames('c-stui-table-cell', { 'c-stui-table-cell--fixed': fixed }, className)}>
    <div className="c-stui-table-cell__content">
      {children}
    </div>
  </th>
)

export default StuiTableHeadCell
