import React, { useEffect } from 'react'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionProps,
  EuiInMemoryTable,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiText
} from '@elastic/eui'
import {
  useActions,
  useValues
} from 'kea'
import _slice from 'lodash/slice'
import { Link } from 'react-router-dom'

import { Loading } from 'app_search/components/Loading'
import { IEngineDetails } from 'app_search/types'
import { getMetaEngineRoute } from 'app_search/utils/routePaths'
import useAbilities from 'app_search/utils/useAbilities'
import StuiEmptyState from 'stui/EmptyState'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import {
  EOpenModalOptions,
  MetaEngineSourceEnginesLogic
} from './MetaEngineSourceEnginesLogic'

interface IMetaEngineSourceEngineActions {
  addEngines(engineNames: string[])
  closeModals()
  initializeMetaEngineSourceEnginesData()
  openRemoveEngineModal(name: string)
  openAddEnginesModal()
  removeEngine(engineName: string)
  resetMetaEnginesData()
  setSelectedIndexedEngineOptions(options: EuiComboBoxOptionProps[])
}

interface IMetaEngineSourceEngineValues {
  dataLoading: boolean
  flashMessages: IStuiFlashMessagesProps
  indexedEngineOptions: EuiComboBoxOptionProps[]
  openModal: EOpenModalOptions
  selectedEngineToBeRemoved?: string
  selectedIndexedEngineOptions: EuiComboBoxOptionProps[]
  sourceEngines: IEngineDetails[]
}

const MetaEngineSourceEngines: React.SFC = () => {
  const { canManageMetaEngineSourceEngines } = useAbilities()

  const {
    addEngines,
    closeModals,
    initializeMetaEngineSourceEnginesData,
    openAddEnginesModal,
    openRemoveEngineModal,
    removeEngine,
    setSelectedIndexedEngineOptions
  } = useActions(MetaEngineSourceEnginesLogic) as IMetaEngineSourceEngineActions

  const {
    dataLoading,
    flashMessages,
    indexedEngineOptions,
    openModal,
    selectedEngineToBeRemoved,
    selectedIndexedEngineOptions,
    sourceEngines
  } = useValues(MetaEngineSourceEnginesLogic) as IMetaEngineSourceEngineValues

  useEffect(() => {
    initializeMetaEngineSourceEnginesData()
  }, [])

  if (dataLoading) { return (<Loading />) }

  const AddEnginesButton = () => (
    <EuiButton
      color="secondary"
      data-test-subj="MetaEngineEnginesAddEngineButton"
      fill={true}
      onClick={() => openAddEnginesModal()}
    >
      Add Engines
    </EuiButton>
  )

  // Normally this would be an SFC component, but that's causing
  // issues re-rendering the EuiComboBox, specifically when one
  // selects an option from the dropdown, it closes the dropdown
  // while expected behavior is that it stays open.
  const addEnginesModalContents = (
    <>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Add Engines</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiText>Add additional Engines to this Meta Engine</EuiText>
        <EuiSpacer />
        <EuiComboBox
          options={indexedEngineOptions}
          selectedOptions={selectedIndexedEngineOptions}
          onChange={setSelectedIndexedEngineOptions}
        />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModals}>Cancel</EuiButtonEmpty>
        <EuiButton
          onClick={
            selectedIndexedEngineOptions.length ?
              () => addEngines(selectedIndexedEngineOptions.map(option => option.label))
              : closeModals
          }
          fill
        >
          Save
        </EuiButton>
      </EuiModalFooter>
    </>
  )

  const RemoveEngineModalContents = () => (
    <>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Removing Engine from Meta Engine</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiText>
          This will remove the Engine, <strong>{selectedEngineToBeRemoved}</strong>, from this Meta Engine. All existing settings will be lost. Are you sure?
        </EuiText>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModals}>Cancel</EuiButtonEmpty>
        <EuiButton onClick={() => selectedEngineToBeRemoved && removeEngine(selectedEngineToBeRemoved)} fill>
          Remove Engine
        </EuiButton>
      </EuiModalFooter>
    </>
  )

  const MetaEngineSourceEnginesTableNameContent = ({ name }) => (
    <Link
      to={getMetaEngineRoute(name)}
    >
      <strong>{name}</strong>
    </Link>
  )

  const metaEngineSourceEnginesTableActions = canManageMetaEngineSourceEngines ? [
    {
      render: (item) => (
        <a
          onClick={() => openRemoveEngineModal(item.name)}
        >
          Remove from Meta Engine
        </a>
      )
    }
  ] : []

  const columns = [
    {
      name: 'Name',
      field: 'name',
      render: (name) => <MetaEngineSourceEnginesTableNameContent name={name} />
    },
    {
      name: 'Documents',
      field: 'document_count',
      width: '20%'
    },
    {
      name: 'Fields',
      field: 'field_count',
      width: '20%'
    },
    {
      name: '',
      actions: metaEngineSourceEnginesTableActions,
      width: '20%'
    }
  ]

  return (
    <div
      data-test-subj="MetaEngines"
      className="meta-engine-source-engines"
    >
      <StuiHeader
        key="header"
        contained={true}
        viewHeader={true}
        actions={canManageMetaEngineSourceEngines && <AddEnginesButton />}
      >
        <StuiHeading type="view">Manage Engines</StuiHeading>
      </StuiHeader>
      <StuiMain key="content">
        <StuiFlashMessages {...flashMessages} />
        {
          sourceEngines.length
            ? (
              <EuiInMemoryTable
                className="source-engines-table"
                items={sourceEngines}
                columns={columns}
                pagination={(sourceEngines.length > 10)}
                sorting={true}
                allowNeutralSort={true}
                search={({ box: { incremental: true } })}
                responsive={false}
              />
            ) : (
              <StuiEmptyState
                title="No engines yet"
                description="Meta Engines combine the documents from your existing engines to enable search over multiple engines at once."
                action={canManageMetaEngineSourceEngines && <AddEnginesButton />}
              />
            )
        }

      </StuiMain>
      {openModal !== EOpenModalOptions.None &&
        <EuiOverlayMask>
          <EuiModal onClose={closeModals}>
            {openModal === EOpenModalOptions.AddEnginesModal && addEnginesModalContents}
            {openModal === EOpenModalOptions.RemoveEngineModal && <RemoveEngineModalContents />}
          </EuiModal>
        </EuiOverlayMask>
      }
    </div>
  )
}

export default MetaEngineSourceEngines
