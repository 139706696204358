import React from 'react'

import {
  EuiProgress,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'

interface IIndexingStatusContentProps {
  percentageComplete: number
}

const IndexingStatusContent: React.SFC<IIndexingStatusContentProps> = ({ percentageComplete }) => {
  return (
    <div data-test-subj="IndexingStatusProgressMeter">
      <EuiTitle size="s">
        <h3>Indexing progress</h3>
      </EuiTitle>
      <EuiSpacer size="s" />
      <EuiProgress color="primary" size="m" value={percentageComplete} max={100} />
    </div>
  )
}

export default IndexingStatusContent
