import React from 'react'

import moment from 'moment'

import {
  EuiBadge,
  EuiHealth
} from '@elastic/eui'

import {
  StuiTableBodyCell,
  StuiTableRow
} from 'stui/Table'

import { IApiLog } from 'app_search/types'

import { getStatusColor } from './ApiLogsLogic'

interface IApiLogsRowProps {
  log: IApiLog
  setActiveApiLog(log: IApiLog)
}

const ApiLogsRow: React.SFC<IApiLogsRowProps> = ({ log, setActiveApiLog }) => {
  const handleLogDetailClick = e => {
    e.preventDefault()
    setActiveApiLog(log)
  }

  return (
    <StuiTableRow>
      <StuiTableBodyCell>
        <EuiBadge color="primary">{log.http_method}</EuiBadge>
      </StuiTableBodyCell>
      <StuiTableBodyCell>{moment(log.timestamp).fromNow()}</StuiTableBodyCell>
      <StuiTableBodyCell>{log.full_request_path}</StuiTableBodyCell>
      <StuiTableBodyCell>
        <EuiHealth color={getStatusColor(log.status)}>{log.status}</EuiHealth>
      </StuiTableBodyCell>
      <StuiTableBodyCell>
        <a href="#" onClick={handleLogDetailClick} data-test-subj="ApiLogsRowDetailsLink">Details</a>
      </StuiTableBodyCell>
    </StuiTableRow>
  )
}

export default ApiLogsRow
