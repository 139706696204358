import React, { useEffect } from 'react'

import {
  EuiButton,
  EuiComboBox,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiCallOut,
  EuiFieldText,
  EuiText,
  EuiComboBoxOptionProps
} from '@elastic/eui'
import {
  useActions,
  useValues
} from 'kea'
import { withRouter } from 'react-router-dom'

import { Loading } from 'app_search/components/Loading'
import {
  IObject,
  IAccountProps
} from 'app_search/types'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'
import StuiSubHeading from 'stui/SubHeading'

import { CreateMetaEngineLogic } from './CreateMetaEngineLogic'

const ALLOWED_CHARS_NOTE = 'Meta-engine names can only contain lowercase letters, numbers, and hyphens'

interface ICreateMetaEngineProps {
  history: IObject
}

interface ICreateMetaEngineActions {
  initializeCreateMetaEngineData()
  resetIndexedEnginesData()
  setRawName(rawName: string)
  setSelectedIndexedEngineOptions(options: EuiComboBoxOptionProps[])
  submitMetaEngine(name: string, history: IObject)
}

interface ICreateMetaEngineValues {
  account: IAccountProps
  dataLoading: boolean
  flashMessages: IStuiFlashMessagesProps
  indexedEngineOptions: EuiComboBoxOptionProps[]
  name: string
  rawName: string
  selectedIndexedEngineOptions: EuiComboBoxOptionProps[]
}

const CreateMetaEngineButton = ({ color }) =>
  <EuiButton
    color={color}
    type="submit"
    data-test-subj="NewMetaEngineSubmitButton"
    fill={true}
  >
    Create Meta Engine
  </EuiButton>

const CreateMetaEngineCallout = ({ type, title, children }) =>
  <EuiCallOut
    title={title}
    color={type}
  >
    <p>{children}</p>
    <EuiSpacer size="s" />
    <CreateMetaEngineButton color={type} />
  </EuiCallOut>

const CreateMetaEngine: React.SFC<ICreateMetaEngineProps> = ({
  history
}) => {
  const {
    initializeCreateMetaEngineData,
    resetIndexedEnginesData,
    setRawName,
    setSelectedIndexedEngineOptions,
    submitMetaEngine
  } = useActions(CreateMetaEngineLogic) as ICreateMetaEngineActions

  const {
    account,
    dataLoading,
    flashMessages,
    indexedEngineOptions,
    name,
    rawName,
    selectedIndexedEngineOptions
  } = useValues(CreateMetaEngineLogic) as ICreateMetaEngineValues

  const engineNameNote = rawName !== name ? <>Your meta-engine will be named <strong>{name}</strong></> : ALLOWED_CHARS_NOTE

  const handleSubmit = e => {
    e.preventDefault()
    submitMetaEngine(name, history)
  }

  useEffect(() => {
    initializeCreateMetaEngineData()
    return () => resetIndexedEnginesData()
  }, [])

  if (dataLoading) { return (<Loading />) }

  const maxEnginesPerMetaEngine = account.maxEnginesPerMetaEngine || Infinity

  return (
    <div data-test-subj="MetaEnginesNew">
      <StuiHeader contained={true} viewHeader={true}>
        <StuiHeading type="view">Create a Meta Engine</StuiHeading>
        <StuiSubHeading>
          <EuiText>Meta Engines allow you to combine multiple Engines into one, searchable Engine.</EuiText>
          <EuiText>
            <a
              href="https://swiftype.com/documentation/app-search/guides/meta-engines"
              target="_blank"
            >
              Read the Documentation
            </a> for information about how to get started.</EuiText>
        </StuiSubHeading>
      </StuiHeader>
      <StuiMain>
        <StuiFlashMessages {...flashMessages} />
        <div className="o-stui-layout-container--narrow">
          <div className="o-stui-card">
            <EuiForm>
              <form onSubmit={handleSubmit}>
                <StuiHeader>
                  <StuiHeading type="sub">Name your Engine</StuiHeading>
                </StuiHeader>
                <EuiSpacer />
                <EuiFormRow
                  label="Engine name"
                  helpText={engineNameNote}
                  fullWidth={true}
                >
                  <EuiFieldText
                    name="engine-name"
                    value={rawName}
                    onChange={event => setRawName(event.currentTarget.value)}
                    autoComplete="off"
                    fullWidth={true}
                    data-test-subj="NewMetaEngineNameInput"
                    placeholder="i.e., my-meta-engine"
                    autoFocus={true}
                  />
                </EuiFormRow>
                <EuiSpacer />
                <EuiFormRow
                  label="Add Engines to this Meta Engine"
                  fullWidth={true}
                >
                  <EuiComboBox
                    options={indexedEngineOptions}
                    selectedOptions={selectedIndexedEngineOptions}
                    onChange={(options) => setSelectedIndexedEngineOptions(options)}
                  />
                </EuiFormRow>
                <EuiSpacer />
                {selectedIndexedEngineOptions.length >= maxEnginesPerMetaEngine &&
                  <EuiCallOut
                    color="warning"
                    title={`Meta Engines have a limit of ${maxEnginesPerMetaEngine} engines`}
                  />
                }
                <EuiSpacer />
                {
                  account.metered && !account.freeTrial ?
                    (
                      <>
                        {
                          account.numRemainingEngines > 0 ?
                            (
                              <CreateMetaEngineCallout
                                type="secondary"
                                title={`You have ${account.numRemainingEngines} available Engine${account.numRemainingEngines > 1 ? 's' : ''}.`}
                              >
                                No additional fees will appear within your bill.
                              </CreateMetaEngineCallout>
                            ) : (
                              <CreateMetaEngineCallout
                                type="warning"
                                title="You have reached your Engine limit"
                              >
                                A monthly charge of ${account.usdPricePerEngine} will begin once you create this Meta-engine.
                              </CreateMetaEngineCallout>
                            )
                        }
                      </>
                    ) : (
                      <CreateMetaEngineButton color="secondary" />
                    )
                }
              </form>
            </EuiForm>
          </div>
        </div>
      </StuiMain>
    </div>
  )
}

export default withRouter(CreateMetaEngine)
