import React from 'react'

import { toSentenceCase } from '@elastic/eui/lib/services/string'

import {
  EuiFormRow,
  EuiRadio
} from '@elastic/eui'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'

interface IRoleSelectorProps {
  disabled?: boolean
  roleType?: string
  roleTypeOption: string
  description: string
  onChange(roleTypeOption: string)
}

const RoleSelector: React.SFC<IRoleSelectorProps> = ({
  disabled,
  roleType,
  roleTypeOption,
  description,
  onChange
}) => (
  <EuiFormRow>
    <EuiRadio
      disabled={disabled}
      id={roleTypeOption}
      checked={roleTypeOption === roleType}
      onChange={() => { onChange(roleTypeOption) }}
      label={
        <StuiHeader>
          <StuiHeading className="users-layout__users--roletype" type="title">{toSentenceCase(roleTypeOption)}</StuiHeading>
          <p>{description}</p>
        </StuiHeader>
      }
    />
  </EuiFormRow>
)

export default RoleSelector
