import { AppLogic } from 'app_search/App'
import routes from 'app_search/routes'
import { formatApiName } from 'app_search/utils'
import handleAPIError from 'app_search/utils/handleAPIError'
import {
  ENGINE_PATH,
  getEngineRoute
} from 'app_search/utils/routePaths'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

const DEFAULT_LANGUAGE = 'Universal'

export const CreateEngineLogic = storeLogic({
  connect: () => ({
    values: [
      AppLogic, ['account', 'supportedLanguages']
    ]
  }),
  actions: () => ({
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages }),
    setLanguage: (language: string) => ({ language }),
    setRawName: (rawName: string) => ({ rawName })
  }),
  reducers: ({ actions }) => ({
    flashMessages: [{}, {
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages
    }],
    language: [DEFAULT_LANGUAGE, {
      [actions.setLanguage]: (_, { language }) => language
    }],
    rawName: ['', {
      [actions.setRawName]: (_, { rawName }) => rawName
    }]
  }),
  selectors: ({ selectors }) => ({
    name: [
      () => [selectors.rawName],
      (rawName) => formatApiName(rawName)
    ]
  }),
  thunks: ({ actions }) => ({
    submitEngine: (name, language, history) => {
      http({
        data: { name, language },
        method: 'post',
        url: routes.locoMocoEnginesPath()
      })
        .then(({ data }) => history.push(getEngineRoute(ENGINE_PATH, data.name)))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))

    }
  })
})
