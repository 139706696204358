import React from 'react'

import classNames from 'classnames'
import moment from 'moment'
import { DateRangePicker } from 'react-date-range'
import onClickOutside, { InjectedOnClickOutProps } from 'react-onclickoutside'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText
} from '@elastic/eui'

const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY'
const DATE_DATA_FORMAT = 'YYYY-MM-DD'
const RANGE_KEY = 'selectionRange'

const formatDisplayDate = (date) => moment(date).format(DATE_DISPLAY_FORMAT)
const formatDataDate = (date) => moment(date).format(DATE_DATA_FORMAT)
const getStateDateFromString = (date) => date ? moment(date).toDate() : new Date()

interface IStuiDateRangeInputProps {
  orientation?: 'left' | 'right' | 'center'
  hideDefinedRanges?: boolean
  startDate?: string
  endDate?: string
  onRangeUpdate({
    start,
    end
  })
}

interface IStuiDateRangeInputState {
  showPicker: boolean
  startDateInput: string
  endDateInput: string
  selectionRange: {
    startDate: Date
    endDate: Date
    key: string
  }
}

class StuiDateRangeInput extends React.Component<
  IStuiDateRangeInputProps & InjectedOnClickOutProps,
  IStuiDateRangeInputState
> {
  readonly state: IStuiDateRangeInputState = {
    endDateInput: this.props.endDate || formatDisplayDate(new Date()),
    selectionRange: {
      key: RANGE_KEY,
      endDate: getStateDateFromString(this.props.endDate),
      startDate: getStateDateFromString(this.props.startDate)
    },
    showPicker: false,
    startDateInput: this.props.startDate || formatDisplayDate(new Date())
  }

  handleClickOutside = () => this.close()

  render() {
    const now = new Date()
    const { showPicker, selectionRange, startDateInput, endDateInput } = this.state
    const { orientation, hideDefinedRanges } = this.props
    const { startDate, endDate } = selectionRange
    const formattedStartDate = formatDisplayDate(startDate)
    const formattedEndDate = formatDisplayDate(endDate)

    const pickerCSS = classNames(
      'daterangepicker',
      'daterangepicker--react',
      orientation && `opens${orientation}`,
      {
        'daterangepicker--hideDefinedRanges': hideDefinedRanges,
        'daterangepicker--visible': showPicker
      }
    )

    return (
      <div className="o-stui-input-container--daterange">
        <EuiFieldText
          icon="calendar"
          value={`${formattedStartDate} - ${formattedEndDate}`}
          onFocus={this.onFocus}
          aria-label="Analytics date range"
          data-test-subj="DateRangeInputTextField"
          onChange={() => null}
        />

        <div className={pickerCSS}>
          <div className="calendar head-inputs">
            <div className="daterangepicker_input">
              <input
                type="text"
                onChange={this.onInputChange}
                onBlur={(e) => this.onStartInputBlur(e, formattedStartDate)}
                name="startDate"
                value={startDateInput}
              />
            </div>
            <div className="daterangepicker_input last">
              <input
                type="text"
                onChange={this.onInputChange}
                onBlur={(e) => this.onEndInputBlur(e, formattedEndDate)}
                name="endDate"
                value={endDateInput}
              />
            </div>
          </div>
          <DateRangePicker
            onChange={(range) => this.handleRangeChange(range)}
            months={2}
            showDateDisplay={false}
            maxDate={now}
            direction="horizontal"
            ranges={[selectionRange]}
          />
          <div className="ranges">
            <div className="range_inputs">
              <EuiButtonEmpty onClick={this.close}>Cancel</EuiButtonEmpty>
              {' '}
              <EuiButton fill={true} onClick={this.update}>Apply</EuiButton>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private onFocus = () => {
    this.setState({ showPicker: true })
  }

  private close = () => {
    this.setState({ showPicker: false })
  }

  private update = () => {
    const { startDate, endDate } = this.state.selectionRange
    this.props.onRangeUpdate({
      end: formatDataDate(endDate),
      start: formatDataDate(startDate)
    })

    this.setState({
      endDateInput: formatDisplayDate(endDate),
      startDateInput: formatDisplayDate(startDate)
    })

    this.close()
  }

  private handleRangeChange = ({ selectionRange }) => {
    const { startDate, endDate } = selectionRange

    this.setState({
      selectionRange,
      endDateInput: formatDisplayDate(endDate),
      startDateInput: formatDisplayDate(startDate)
    })
  }

  private onInputChange = (e) => {
    const { value, name } = e.target
    this.updateRange(value, name)
  }

  private onStartInputBlur = (e, lastValidValue) => {
    if (this.canUpdateInput(e)) {
      this.setState({ startDateInput: lastValidValue })
    }
  }

  private onEndInputBlur = (e, lastValidValue) => {
    if (this.canUpdateInput(e)) {
      this.setState({ endDateInput: lastValidValue })
    }
  }

  private canUpdateInput = (e) => {
    const now = new Date()
    const { value } = e.target
    const date = moment(value)
    return !date.isValid() || date.isAfter(now)
  }

  private updateRange = (value, name) => {
    const now = new Date()
    const date = moment(value)
    if (date.isValid() && date.isBefore(now)) {
      this.setState(({ selectionRange }) => {
        return {
          selectionRange: {
            ...selectionRange,
            [name]: date.toDate()
          }
        }
      })
    }
  }
}

export default onClickOutside(StuiDateRangeInput)
