import React from 'react'

import { Route, Switch, withRouter } from 'react-router-dom'

import {
  ENGINE_NEW_PATH,
  ENGINE_PATH,
  ENGINES_PATH
} from 'app_search/utils/routePaths'

import CreateEngine from 'app_search/CreateEngine'
import { EngineRouter } from 'app_search/Engine'
import Engines from 'app_search/Engines'

import { IMatch } from 'app_search/types'

interface IEnginesRouterProps {
  match?: IMatch
}

const EnginesRouter: React.SFC<IEnginesRouterProps> = (props) => {
  return (
    <Switch>
      <Route
        path={ENGINES_PATH}
        exact={true}
        render={({ match }) => <Engines match={match} {...props} />}
      />
      <Route
        path={ENGINE_NEW_PATH}
        exact={true}
        render={({ match }) => <CreateEngine match={match} {...props} />}
      />
      <Route
        path={ENGINE_PATH}
        component={EngineRouter}
      />
    </Switch>
  )
}

export default withRouter(EnginesRouter)
