import React from 'react'

import classNames from 'classnames'

interface IStuiIconProps {
  name: string
  className?: string
}

const StuiIcon: React.SFC<IStuiIconProps> = (props) => {
  const className = classNames(
    'o-stui-icon',
    props.className
  )

  return (
    <span className={className}>
      <svg>
        <use xlinkHref={`#${props.name}`} />
      </svg>
    </span>
  )
}

export default StuiIcon
