import React from 'react'
import { Link } from 'react-router-dom'

import { IFieldCoercionErrors, IObject } from 'shared/types'

import { EuiButtonEmpty, EuiSpacer } from '@elastic/eui'

import {
  StuiAccordion,
  StuiAccordionItem,
  StuiAccordionItemBody,
  StuiAccordionItemHeader
} from 'stui/Accordion'
import StuiHeading from 'stui/Heading'
import StuiIcon from 'stui/Icon'
import {
  StuiTable,
  StuiTableBody,
  StuiTableBodyCell,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'
import StuiTruncatedContent from 'stui/TruncatedContent'

interface ISchemaErrorsAccordionProps {
  fieldCoercionErrors: IFieldCoercionErrors
  schema: IObject
  itemId?: string
  getRoute?(itemId: string, externalId: string)
}

const SchemaErrorsAccordion: React.SFC<ISchemaErrorsAccordionProps> = ({
  fieldCoercionErrors,
  schema,
  itemId,
  getRoute
}) => (
  <StuiAccordion className="c-stui-field-errors">
    {Object.keys(fieldCoercionErrors).map((fieldName, fieldNameIndex) => {
      const errorInfos = fieldCoercionErrors[fieldName]
      return (<StuiAccordionItem className="c-stui-field-error" key={`c-stui-field-error-${fieldNameIndex}`}>
        {<StuiAccordionItemHeader className="c-stui-field-error__header">
          <StuiIcon name="arrow-right" />
          <div className="c-stui-field-error__field-name">
            <StuiTruncatedContent content={fieldName} length={32} />
          </div>
          <div className="c-stui-field-error__field-type">
            {schema[fieldName]}
          </div>
          <EuiButtonEmpty
            className="c-stui-field-error__control button"
            onClick={e => e.preventDefault()}
          >
            Review
          </EuiButtonEmpty>
        </StuiAccordionItemHeader>}
        {<StuiAccordionItemBody className={'c-stui-field-error__body'}>
          <EuiSpacer />
          <StuiHeading type="title">
            {errorInfos.length === 10 ? 'Sample of documents with errors' : ''}
          </StuiHeading>
          <EuiSpacer />
          <StuiTable>
            <StuiTableHead>
              <StuiTableRow className="c-stui-field-error-document">
                <StuiTableHeadCell className="c-stui-field-error-document__id">
                  id
                </StuiTableHeadCell>
                <StuiTableHeadCell className="c-stui-field-error-document__field-content">
                  Error
                </StuiTableHeadCell>
                <StuiTableHeadCell className="c-stui-field-error-document__actions" />
              </StuiTableRow>
            </StuiTableHead>
            <StuiTableBody>
              {errorInfos.map((error, errorIndex) => (
                <StuiTableRow key={`schema-change-document-error-${fieldName}-${errorIndex}`}>
                  <StuiTableBodyCell className="c-stui-field-error-document__id">
                    <div className="u-stui-data-type--id">{error.external_id}</div>
                  </StuiTableBodyCell>
                  <StuiTableBodyCell className="c-stui-field-error-document__field-content">
                    {error.error}
                  </StuiTableBodyCell>
                  {(itemId && getRoute) && <StuiTableBodyCell className="c-stui-field-error-document__actions">
                    <Link className="euiButtonEmpty euiButtonEmpty--primary euiButtonEmpty--xSmall" to={getRoute(itemId, error.external_id)}>
                      <span className="euiButtonEmpty__content">
                        <span className="euiButtonEmpty__text">View</span>
                      </span>
                    </Link>
                  </StuiTableBodyCell>}
                </StuiTableRow>
              ))}
            </StuiTableBody>
          </StuiTable>
        </StuiAccordionItemBody>}
      </StuiAccordionItem>)
    })}
  </StuiAccordion>
)

export default SchemaErrorsAccordion
