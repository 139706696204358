import React from 'react'

import { EuiSpacer } from '@elastic/eui'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'

interface IAnalyticsTableCardProps {
  title: string
  description: string
}

const AnalyticsTableCard: React.SFC<IAnalyticsTableCardProps> = ({ title, description, children }) => (
  <div className="o-stui-card">
    <StuiHeader>
      <StuiHeading type="section">{title}</StuiHeading>
      <p>{description}</p>
    </StuiHeader>
    <EuiSpacer />
    {children}
  </div>
)

export default AnalyticsTableCard
