import React from 'react'

import classNames from 'classnames'

import { AccordionItem } from 'react-accessible-accordion'

interface IStuiAccordionItemProps {
  children: any
  className?: string
  expanded?: boolean
  hideBodyClassName?: string
}

const StuiAccordionItem: React.SFC<IStuiAccordionItemProps> = (props) => {
  const { ...other } = props
  const className = classNames(
    'o-stui-accordion-item',
    props.className
  )
  const hideBodyClassName = classNames(
    'o-stui-accordion-item--collapsed',
    props.className && `${props.className}--collapsed`,
    props.hideBodyClassName
  )

  return (
    <AccordionItem
      {...other}
      className={className}
      hideBodyClassName={hideBodyClassName}
    />
  )
}

export default StuiAccordionItem
