// Sometimes we don't want to fire the initial useEffect call.
// This custom Hook only fires after the intial render has completed.
import { useEffect, useRef } from 'react'

export const useDidUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      fn()
    }  else {
      didMountRef.current = true
    }
  }, inputs)
}
