import React, { useEffect, useState } from 'react'

import classNames from 'classnames'
import _debounce from 'lodash/debounce'

import routes from 'app_search/routes'
import { IObject } from 'app_search/types'
import http from 'shared/http'

import {
  EuiButtonEmpty,
  EuiFieldSearch,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer
} from '@elastic/eui'

import StuiCode from 'stui/Code'
import StuiHeading from 'stui/Heading'
import { StuiResult } from 'stui/Result'

interface IQueryTesterSearchProps {
  condensed?: boolean
  engineName: string
  resultFields: IObject[]
  schema: IObject
}

const getSearchResults = _debounce(
  (setLoading, query, slug, setSearchResults) => {
    setLoading(true)
    http.post(routes.queryTesterSearchLocoMocoEnginePath({
      format: 'json',
      query,
      slug
    })).then(({ data }) => {
      setLoading(false)
      setSearchResults(data.results)
    }).catch(() => setLoading(false))
  },
  100)

const QueryTesterSearch: React.SFC<IQueryTesterSearchProps> = ({
  resultFields,
  schema,
  condensed = false,
  engineName
}) => {
  const [loading, setLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([] as IObject[])
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  useEffect(() => { getSearchResults(setLoading, searchQuery, engineName, setSearchResults) }, [searchQuery]) // Only re-run the effect if query changes

  const containerCssClass = classNames(
    'o-stui-replaceable-results',
    { loading },
    { 'no-results': !searchResults || searchResults.length < 1 }
  )

  const codeDisplay = {
    query: searchQuery,
    page: {
      current: 1,
      size: 10
    },
    result_fields: resultFields
  }

  return (
    <>
      <div className="query-tester-search-input-container">
        <EuiFieldSearch
          onChange={e => setSearchQuery(e.target.value)}
          autoFocus={true}
          data-test-subj="QueryTesterSearchInput"
          placeholder="Search engine documents..."
          fullWidth={true}
        />

        {!condensed &&
          <EuiButtonEmpty
            onClick={() => setShowDetailsModal(!showDetailsModal)}
            iconType="eye"
          >
            Query Details
          </EuiButtonEmpty>
        }
      </div>
      <EuiSpacer key="spacer" />
      <div key="results" className={containerCssClass}>
        {searchResults && searchResults.map((result, index) => {
          return (
            <StuiResult
              key={index}
              resultObject={result}
              condensed={condensed}
              showScore={true}
              schema={schema}
              engineName={engineName}
              query={searchQuery}
            />
          )
        })}
      </div>
      {(!condensed && showDetailsModal) &&
        <EuiOverlayMask>
          <EuiModal className="c-stui-query-tester-details" onClose={() => setShowDetailsModal(!showDetailsModal)}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Query Details</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <EuiSpacer />
              <StuiHeading type="section">Request Body</StuiHeading>
              <EuiSpacer />
              <StuiCode language="json">{JSON.stringify(codeDisplay, null, 2)}</StuiCode>
              <EuiSpacer />
            </EuiModalBody>
          </EuiModal>
        </EuiOverlayMask>
      }
    </>
  )
}

export default QueryTesterSearch
