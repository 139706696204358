import React from 'react'

import { Route } from 'react-router-dom'

import { USER_PATH, USERS_PATH } from 'app_search/utils/routePaths'

import User from './User'
import Users from './Users'

const UsersRouter: React.SFC = () => (
  <div>
    <Route exact={true} path={USERS_PATH} component={Users} />
    <Route exact={true} path={USER_PATH} component={User} />
  </div>
)

export default UsersRouter
