import React from 'react'

import classNames from 'classnames'

interface IStuiTableRowProps {
  children: React.ReactNode
  className?: string
  dragDropReference?: string
}

const StuiTableRow: React.SFC<IStuiTableRowProps> = ({ children, className, dragDropReference }) => (
  <tr className={classNames('c-stui-table-row', className)} data-ref={dragDropReference}>
    {children}
  </tr>
)

export default StuiTableRow
