import React, { useEffect } from 'react'

import { useActions, useValues } from 'kea'
import ReactGA from 'react-ga'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'

import { AppLogic } from './AppLogic'

import {
  BASENAME,
  BILLING_PATH,
  CREDENTIALS_PATH,
  ENGINE_NEW_PATH,
  ENGINES_PATH,
  EXTEND_TRIAL_PATH,
  META_ENGINES_PATH,
  ONBOARDING_PATH,
  ROLE_MAPPINGS_PATH,
  SETTINGS_PATH,
  TRIAL_OFFBOARDING_PATH,
  USERS_PATH
} from 'app_search/utils/routePaths'

import { IRole, IServerAppProps } from 'app_search/types'
import useAbilities from 'app_search/utils/useAbilities'

import AccountSettings from 'app_search/AccountSettings'
import Billing from 'app_search/Billing'
import Credentials from 'app_search/Credentials'
import EnginesRouter from 'app_search/Engines/EnginesRouter'
import FreeTrialExtend from 'app_search/FreeTrialExtend'
import FreeTrialOffboarding from 'app_search/FreeTrialOffboarding'
import AppChrome from 'app_search/Layout'
import MetaEnginesRouter from 'app_search/MetaEngines/MetaEnginesRouter'
import NotFound from 'app_search/NotFound'
import Onboarding from 'app_search/Onboarding'
import RoleMappingsRouter from 'app_search/RoleMappings'
import UsersRouter from 'app_search/Users'

declare const window: {
  sessionStorage: {
    getItem()
    removeItem()
  }
} & Window

const isSaaSProduction = !process.env.LOCO_TOGO && process.env.NODE_ENV === 'production'
const GOOGLE_TRACKING_ID = 'UA-26187662-12'

if (isSaaSProduction) {
  ReactGA.initialize(GOOGLE_TRACKING_ID)
}

const trackPage = (pathname) => {
  const page = BASENAME + pathname
  if (!isSaaSProduction) { return }
  ReactGA.set({ page })
  ReactGA.pageview(page)
}

const handleLoginRedirect = (history) => {
  const hashValue = window.sessionStorage.getItem('loginHash')
  window.sessionStorage.removeItem('loginHash')
  if (hashValue) {
    history.push(hashValue)
  }
}

const App: React.SFC<IServerAppProps> = props => {
  const { location: { pathname }, history, role } = props
  const { initializeAppData, setServerRole } = useActions(AppLogic)
  setServerRole(role as IRole)

  useEffect(() => {
    initializeAppData(props as IServerAppProps)
  }, [])

  // If a user nanvigates to a page and is not authenticated, they are redirected
  // to the login page with the hash intact. This enables the ability to route the user
  // to the desired location and resets the session storage.
  handleLoginRedirect(history)

  // Google Analytics
  useEffect(() => { trackPage(pathname) }, [pathname])

  const {
    account: {
      accountDisabled,
      onboardingComplete
    },
    currentUser: {
      pendingReconfirmation
    },
    serverStateInitialized
  } = useValues(AppLogic)

  const {
    canViewAccountCredentials,
    canViewEngines,
    canViewBilling,
    canManageSettings,
    canManageTelemetryData,
    canViewMetaEngines,
    canViewRoleMappings,
    canViewUsers,
    canUpdateUserPassword
  } = useAbilities()

  // If we render before the `useEffect` call mounts the server state, the router below
  // will route to the Onboarding view because onBoardingComplete is not yet true
  if (!serverStateInitialized) { return <div /> }

  const canVisitSettings = canManageSettings || canManageTelemetryData || canUpdateUserPassword || pendingReconfirmation

  let initialPath = SETTINGS_PATH
  if (canViewEngines) {
    initialPath = ENGINES_PATH
  } else if (canViewBilling) {
    initialPath = BILLING_PATH
  }

  return (
    <Switch>
      <Route exact={true} path={ONBOARDING_PATH} component={Onboarding} />
      {!onboardingComplete && <Redirect to={ONBOARDING_PATH} />}
      {accountDisabled && <Route exact={true} path={TRIAL_OFFBOARDING_PATH} render={() => <AppChrome><FreeTrialOffboarding /></AppChrome>} />}
      {!accountDisabled && <Redirect exact={true} from={TRIAL_OFFBOARDING_PATH} to={BILLING_PATH} />}
      <Route exact={true} path={EXTEND_TRIAL_PATH} render={() => <AppChrome><FreeTrialExtend /></AppChrome>} />
      {canVisitSettings && <Route exact={true} path={SETTINGS_PATH} render={() => <AppChrome><AccountSettings /></AppChrome>} />}
      {canViewRoleMappings && <Route path={ROLE_MAPPINGS_PATH} render={() => <AppChrome><RoleMappingsRouter /></AppChrome>} />}
      {accountDisabled && <Redirect to={TRIAL_OFFBOARDING_PATH} />}
      {canViewEngines && <Route path={ENGINES_PATH} render={() => <AppChrome isEngine={(pathname !== ENGINES_PATH && pathname !== ENGINE_NEW_PATH)}><EnginesRouter /></AppChrome>} />}
      {canViewMetaEngines && <Route path={META_ENGINES_PATH} render={() => <AppChrome><MetaEnginesRouter /></AppChrome>} />}
      {canViewAccountCredentials && <Route exact={true} path={CREDENTIALS_PATH} render={() => <AppChrome><Credentials /></AppChrome>} />}
      {canViewUsers && <Route path={USERS_PATH} render={() => <AppChrome><UsersRouter /></AppChrome>} />}
      {canViewBilling && <Route path={BILLING_PATH} render={() => <AppChrome><Billing /></AppChrome>} />}
      <Redirect exact={true} from="/" to={initialPath} />
      <Route component={NotFound} />
    </Switch>
  )
}
export default withRouter(App)
