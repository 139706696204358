import React from 'react'

import { TResult } from 'app_search/types'

import StuiTruncatedContent from 'stui/TruncatedContent'

interface IResultMetaFieldProps {
  field: string
  value?: string | number
  type: TResult
}

const MAX_CHARACTER_LENGTH = 30

const ResultMetaField: React.SFC<IResultMetaFieldProps> = ({ field, type, value }) => {
  const resultDataTypeClass = `u-stui-data-type--${type}`

  let formattedValue = '-'
  if (typeof value === 'string') {
    formattedValue = value
  } else if (typeof value === 'number') {
    formattedValue = parseFloat((value as number).toFixed(2)).toString()
  }

  return (
    <div className="c-stui-result-metadata" key={field}>
      <div className="c-stui-result-metadata__key">
        <StuiTruncatedContent content={field} length={MAX_CHARACTER_LENGTH} />
      </div>
      <div className={resultDataTypeClass}>
        <StuiTruncatedContent beginning={type === 'id'} content={formattedValue} length={MAX_CHARACTER_LENGTH} />
      </div>
    </div>
  )
}

export default ResultMetaField
