import React from 'react'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiModalFooter
} from '@elastic/eui'

interface IDocumentCreationPanelFooterProps {
  uploading: boolean
  handleSubmit(event: any)
  handleClose()
}

const DocumentCreationPanelFooter: React.SFC<IDocumentCreationPanelFooterProps> = ({
  uploading,
  handleSubmit,
  handleClose
}) => (
  <EuiModalFooter>
    <EuiButtonEmpty onClick={handleClose}>Cancel</EuiButtonEmpty>
    <EuiButton
      name="button"
      onClick={handleSubmit}
      isLoading={uploading}
      fill={true}
      data-test-subj="DocumentImportContinueButton"
    >
      Continue
    </EuiButton>
  </EuiModalFooter>
)
export default DocumentCreationPanelFooter
