import React from 'react'

import {
  EuiFlexGroup,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

const ProcessingPaymentModal: React.SFC = ({}) => {
  return (
    <EuiOverlayMask>
      <EuiModal
        className="billing-processing-payment-modal"
        onClose={() => { /* noop */ }}
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>We're processing your payment information</EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiFlexGroup direction="column" alignItems="center">
            <EuiSpacer size="s" />
            <EuiLoadingSpinner size="xl" />
            <EuiSpacer size="m" />
            <EuiText>
              <p>Hold tight! This should only take a few seconds.</p>
            </EuiText>
          </EuiFlexGroup>
        </EuiModalBody>
      </EuiModal>
    </EuiOverlayMask>
  )
}

export default ProcessingPaymentModal
