import React, { useEffect } from 'react'

import { Link, withRouter } from 'react-router-dom'
import { Elements, StripeProvider } from 'react-stripe-elements'

import { useValues } from 'kea'

import { AppLogic } from 'app_search/App'
import { Loading } from 'app_search/components/Loading'
import { PaymentVerificationRequiredModal } from 'app_search/components/PaymentVerificationRequiredModal'
import { PlanSelector } from 'app_search/components/PlanSelector'
import { ProcessingPaymentModal } from 'app_search/components/ProcessingPaymentModal'
import { UpdatePaymentMethodModal } from 'app_search/components/UpdatePaymentMethodModal'
import { IAccount, IObject, ISupportedLanguages } from 'app_search/types'

import {
  EuiButton,
  EuiCallOut,
  EuiSpacer
} from '@elastic/eui'

import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import { BillingLogic } from 'app_search/logic/BillingLogic'
import { withStripe } from 'app_search/logic/withStripe'

import { EXTEND_TRIAL_PATH, SETTINGS_PATH } from 'app_search/utils/routePaths'

interface IFreeTrialOffboardingProps {
  actions: {
    initializeBillingData()
    openUpdatePaymentMethodModalWithPlan()
    savePaymentMethod(stripeToken: string, onSuccess?: () => any, onError?: () => any, selectedPlan?: string)
    saveSelectedPlan(planName: string, onSuccess?: () => any, onError?: () => any)
    toggleUpdatePaymentMethodModal()
    closeAllModals()
    verifyPayment()
    cancelPaymentVerification()
  }
  accountBillingMeta: IObject
  accountBillingUsage: IObject
  canManagePlan: boolean
  dataLoading: boolean
  lmAccount: IAccount
  flashMessages: IStuiFlashMessagesProps
  stripe: any | null
  uiState: IObject
  selectedPlan: IObject | null
  paymentIntentClientSecret: string | null
  company: string
  domain: string
  selectedBusiness: string
  selectedEmployeesNumber: string
  businessOptions: ISupportedLanguages[]
  employeesNumberOptions: ISupportedLanguages[]
  countryOptions: ISupportedLanguages[]
  address1: string
  address2: string
  city: string
  state: string
  postal_code: string
  country: string
}

const FreeTrialOffboarding: React.SFC<IFreeTrialOffboardingProps> = ({
  actions: {
    initializeBillingData,
    openUpdatePaymentMethodModalWithPlan,
    savePaymentMethod,
    saveSelectedPlan,
    closeAllModals,
    verifyPayment,
    cancelPaymentVerification,
    toggleUpdatePaymentMethodModal
  },
  accountBillingMeta,
  canManagePlan,
  dataLoading,
  flashMessages,
  selectedPlan,
  stripe,
  uiState,
  company,
  domain,
  selectedBusiness,
  selectedEmployeesNumber,
  businessOptions,
  employeesNumberOptions,
  countryOptions,
  address1,
  address2,
  city,
  state,
  postal_code,
  country
}) => {
  const { account: { accountDisabled, freeTrialExtendable } } = useValues(AppLogic)

  useEffect(() => { initializeBillingData() }, [])

  if (!accountDisabled || dataLoading) { return <Loading /> }

  const ExtendTrialCallout = () => (
    <EuiCallOut
      color="warning"
      title="Trial has expired. Need more time to explore?"
    >
      <EuiButton fill={true} color="warning">
        <Link to={EXTEND_TRIAL_PATH}>
          Extend trial by 14 days
        </Link>
      </EuiButton>
    </EuiCallOut>
  )

  return (
    <div>
      <StuiFlashMessages {...flashMessages} />
      <header className="c-stui-view-header">
        <StuiHeading type="view">Choose Your Plan</StuiHeading>
        <EuiSpacer />
        {freeTrialExtendable && <ExtendTrialCallout />}
      </header>
      <StuiMain key="main">
        <PlanSelector
          accountBillingMeta={accountBillingMeta}
          canManagePlan={canManagePlan}
          onPlanSelect={s => saveSelectedPlan(s, closeAllModals, closeAllModals)}
          openUpdatePaymentMethodModalWithPlan={openUpdatePaymentMethodModalWithPlan}
        />
        <EuiSpacer />
        <EuiCallOut
          color="danger"
          title="Please note that deleting your account is permanent and cannot be undone."
        >
          <EuiButton fill={true} color="danger">
            <Link to={SETTINGS_PATH}>
              Close Account
            </Link>
          </EuiButton>
        </EuiCallOut>
      </StuiMain>

      {uiState.updatePaymentMethodModalOpen &&
        <StripeProvider stripe={stripe}>
          <Elements>
            <UpdatePaymentMethodModal
              accountBillingMeta={accountBillingMeta}
              closeUpdatePaymentMethodModal={toggleUpdatePaymentMethodModal}
              selectedPlan={selectedPlan}
              countryOptions={countryOptions}
              address1={address1}
              address2={address2}
              city={city}
              state={state}
              postal_code={postal_code}
              country={country}
              company={company}
              domain={domain}
              selectedBusiness={selectedBusiness}
              selectedEmployeesNumber={selectedEmployeesNumber}
              businessOptions={businessOptions}
              employeesNumberOptions={employeesNumberOptions}
              onSavePaymentMethod={stripeToken => savePaymentMethod(stripeToken, closeAllModals, closeAllModals, selectedPlan && selectedPlan.name)}
            />
          </Elements>
        </StripeProvider>
      }

      {uiState.processingPaymentModalOpen &&
        <ProcessingPaymentModal />
      }

      {uiState.paymentVerificationRequiredModalOpen &&
        <PaymentVerificationRequiredModal selectedPlan={selectedPlan} cancelPaymentVerification={cancelPaymentVerification} verifyPayment={verifyPayment} />
      }
    </div>
  )
}

export default withStripe(withRouter(BillingLogic(FreeTrialOffboarding)))
