import React from 'react'

import AnalyticsSectionContent, { IAnalyticsSectionContentProps } from './AnalyticsSectionContent'

const RecentQueries: React.SFC<IAnalyticsSectionContentProps> = (props) => (
    <AnalyticsSectionContent
      title="Recent Queries"
      isRecentQueries={true}
      {...props}
    />
  )

export default RecentQueries
