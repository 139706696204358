import React, { useEffect, useState, useRef } from 'react'

import axios, { Canceler } from 'axios'

import {
  EuiCallOut,
  EuiPopover,
  EuiSpacer
} from '@elastic/eui'

import http from 'shared/http'
import StuiIcon from 'stui/Icon'

import IndexingStatusContent from './IndexingStatusContent'
import IndexingStatusFetcher from './IndexingStatusFetcher'

interface IIndexingStatusPopoverProps {
  activeReindexJobId: number
  itemId: string
  percentageComplete: number
  numDocumentsWithErrors: number
  viewLink: React.ReactNode
  getStatusPath(itemId: string, activeReindexJobId: number)
  getItemDetailPath(itemId: string)
  setGlobalIndexingStatus(activeReindexJobId: number)
}

const IndexingStatusPopover: React.SFC<IIndexingStatusPopoverProps> = props => {
  const [visible, setVisible] = useState(false)
  const pollingTimeout = useRef<number>()
  const pollingCancelFunction = useRef<Canceler>()

  useEffect(() => {
    fetchItemData()

    return () => {
      if (pollingTimeout.current) {
        window.clearTimeout(pollingTimeout.current)
      }
      if (pollingCancelFunction.current) {
        pollingCancelFunction.current()
      }
    }
  }, [])

  const { itemId, setGlobalIndexingStatus, getItemDetailPath } = props

  const close = () => setVisible(false)
  const onClick = () => setVisible(!visible)
  const onKeyUp: React.KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.keyCode === 27) {
      close()
    }
  }

  // We want the server to dictate whether the UI shows the popover.
  // There is a timeout on the server of 5 minutes and this will poll to
  // see if the server still has an active job and clear it if not.
  const fetchItemData = () => {
    const CancelToken = axios.CancelToken
    http.get(getItemDetailPath(itemId), {
      cancelToken: new CancelToken((cancelFunction) => {
        pollingCancelFunction.current = cancelFunction
      })
    }).then(({ data: { activeReindexJob } }) => {
      if (setGlobalIndexingStatus) {
        setGlobalIndexingStatus(activeReindexJob)
      }
      if (activeReindexJob) {
        pollForIndexingStatus()
      }
    }).catch(error => {
      if (!axios.isCancel(error)) {
        throw (error)
      }
    })
  }

  const pollForIndexingStatus = () => {
    const POLLING_DURATION = 30000
    pollingTimeout.current = window.setTimeout(fetchItemData, POLLING_DURATION)
  }

  return (
    <IndexingStatusFetcher {...props} onComplete={pollForIndexingStatus}>
      {(percentageComplete, numDocumentsWithErrors) => (
        <div
          className="c-stui-popover c-stui-indexing-status-popover"
          role="none"
          tabIndex={-1}
          onKeyUp={onKeyUp}
        >
          <EuiPopover
            id="indexing_status"
            isOpen={visible}
            ownFocus={true}
            button={
              <button className="c-stui-indexing-indicator" onClick={onClick}>
                {numDocumentsWithErrors > 0 ? (
                  <StuiIcon name="error-fill" className="u-stui-color--red" />
                ) : percentageComplete >= 100 ? (
                  <StuiIcon name="check" className="u-stui-color--green" />
                ) : (
                      <div className="o-stui-loader o-stui-loader--m" />
                    )}
                <div className="c-stui-indexing-indicator__percent" data-percentage={Math.floor(percentageComplete)} />
              </button>}
            closePopover={close}
            anchorPosition="downRight"
          >
            <div className="c-stui-indexing-progress">
              <IndexingStatusContent percentageComplete={percentageComplete} />
              {numDocumentsWithErrors > 0 &&
                <>
                  <EuiSpacer size="s" />
                  <EuiCallOut
                    className="c-stui-indexing-status-errors"
                    color="danger"
                    iconType="cross"
                    title={
                      <>
                        Several documents have field conversion errors.
                          {' '}{props.viewLink}
                      </>
                    }
                    size="s"
                  />
                </>
              }
            </div>
          </EuiPopover>
        </div>
      )}
    </IndexingStatusFetcher>
  )
}

export default IndexingStatusPopover
