import React from 'react'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'

interface ICurationsTableHeaderProps {
  icon: string
  title: string | React.ReactNode
  subtitle?: string
  actions?: React.ReactNode
}

const CurationsTableHeader: React.SFC<ICurationsTableHeaderProps> = ({ icon, title, subtitle, actions }) => (
  <StuiHeader
    className="curations-table-header"
    icon={icon}
    actions={actions}
  >
    <StuiHeading type="title">{title}</StuiHeading>
    {subtitle && <p className="c-stui-header__subtitle">{subtitle}</p>}
  </StuiHeader>
)

export default CurationsTableHeader
