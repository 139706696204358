import React from 'react'

import {
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiSelect,
  EuiSpacer
} from '@elastic/eui'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'

interface IAttributeSelectorProps {
  attributeName: string
  attributeValue?: string
  attributes: string[]
  elasticsearchRoles: string[]
  disabled: boolean
  handleAttributeSelectorChange(value: string, elasticsearchRole: string)
  handleAttributeValueChange(value: string)
}

const attributeValueExamples = {
  username: 'elastic,kibana,*_system',
  email: 'user@example.com,*@example.org',
  metadata: '{"_reserved": true}'
}

const AttributeSelector: React.SFC<IAttributeSelectorProps> = ({
  attributeName,
  attributeValue = '',
  attributes,
  elasticsearchRoles,
  disabled,
  handleAttributeSelectorChange,
  handleAttributeValueChange
}) => (
  <EuiPanel paddingSize="l" className={disabled ? 'euiPanel--disabled' : ''}>
    <StuiHeader>
      <StuiHeading type="section">Attribute Mapping</StuiHeading>
    </StuiHeader>
    <EuiSpacer />
    <EuiFlexGroup alignItems="stretch">
      <EuiFlexItem>
        <EuiFormRow label="External Attribute" fullWidth={true}>
          <EuiSelect
            name="external-attribute"
            value={attributeName}
            required={true}
            options={attributes.map(attribute => ({ value: attribute, text: attribute }))}
            onChange={e => { handleAttributeSelectorChange(e.target.value, elasticsearchRoles[0]) }}
            fullWidth={true}
            disabled={disabled}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFormRow label="Attribute Value" fullWidth={true}>
          {
            attributeName === 'role' ? (
              <EuiSelect
                value={attributeValue}
                name="elasticsearch-role"
                required={true}
                options={elasticsearchRoles.map(elasticsearchRole => ({ value: elasticsearchRole, text: elasticsearchRole }))}
                onChange={e => { handleAttributeValueChange(e.target.value) }}
                fullWidth={true}
                disabled={disabled}
              />
            ) : (
                <EuiFieldText
                  value={attributeValue}
                  name="attribute-value"
                  placeholder={attributeValueExamples[attributeName]}
                  onChange={e => { handleAttributeValueChange(e.target.value) }}
                  fullWidth={true}
                  disabled={disabled}
                />
              )
          }
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiPanel>
)

export default AttributeSelector
