import React from 'react'

import classNames from 'classnames'

interface IStuiTableFootProps {
  children: React.ReactNode
  className?: string
}

const StuiTableFoot: React.SFC<IStuiTableFootProps> = ({ children, className }) => (
  <tfoot className={classNames('c-stui-table-footer', className)}>
    {children}
  </tfoot>
)

export default StuiTableFoot
