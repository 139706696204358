interface ICurationsResult {
  id: {
    raw: string
  }
}

/**
 * This helper ensures that we're using the right result IDs for both engines and meta engines.
 */
export default function getResultId(result: ICurationsResult) {
  return `${result.id.raw}`
}
