import React, { useEffect } from 'react'

import {
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink
} from '@elastic/eui'

import axios from 'axios'
import { useActions } from 'kea'
import http from 'shared/http'

import { AppLogic } from 'app_search/App'

interface ITelemetryProps {
  showCallout: boolean
  optedIn: boolean
  lastSentAt?: string
  sendIntervalHours: number
}

const Telemetry: React.FC<ITelemetryProps> = ({ showCallout, optedIn, lastSentAt, sendIntervalHours }) => {
  const { telemetryUpdate, telemetrySent } = useActions(AppLogic)

  const updateTelemetrySetting = (payload) => {
    http.put('/telemetry', payload).then(({ data: { newGlobalState: { account } } }) => {
      telemetryUpdate(account)
    })
  }

  useEffect(() => {
    if (!optedIn) return

    if (lastSentAt) {
      const lastSentAtDate = new Date(lastSentAt)
      const now = new Date()
      const hoursSinceLastSent = (now.getTime() - lastSentAtDate.getTime()) / (1000 * 60 * 60)

      if (hoursSinceLastSent < sendIntervalHours) return
    }

    http('/telemetry').then(({ data, headers }) => {
      telemetrySent(headers['x-telemetry-last-sent-at'])

      // Use new axios instance in order to not send the x-csrf-token header
      axios.post(headers['access-control-allow-origin'], data, { headers: { 'content-type': 'application/json' } })
    })
  }, [])

  return showCallout ? (
    <div className="stui-layout-callout-container telemetry-callout">
      <EuiCallOut
        title="Help us improve App Search by providing usage statistics."
        iconType="questionInCircle"
      >
        <p>
          We will not share this data outside of Elastic. No information about the data you process or store will be sent. This feature will periodically send basic feature usage statistics. <br />
          <EuiLink href="https://www.elastic.co/legal/telemetry-privacy-statement" target="_blank">Read our telemetry privacy statement</EuiLink>
        </p>
        {/* specifically not adding `fill` to either button since we shouldn't influence the user here */}
        <EuiFlexGroup gutterSize="s" alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiButton color="primary" data-test-subj="UsageDataOptIn" onClick={() => updateTelemetrySetting({ opt_in: true })}>Yes</EuiButton>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButton color="primary" data-test-subj="UsageDataOptOut" onClick={() => updateTelemetrySetting({ opt_in: false })}>No</EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiCallOut>
    </div>
  ) : null
}

export default Telemetry
