import React from 'react'

import { Link } from 'react-router-dom'

import {
  EuiButton,
  EuiSpacer
} from '@elastic/eui'

interface IViewAllButtonProps {
  path: string
}

const ViewAllButton: React.SFC<IViewAllButtonProps> = ({ path }) => (
<>
  <EuiSpacer size="s" />
  <Link to={path}><EuiButton>View All</EuiButton></Link>
</>
)

export default ViewAllButton
