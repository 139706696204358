import moment from 'moment'

const MINUTES_IN_DAY = 1440
const isOneDay = minutes => minutes >= MINUTES_IN_DAY && minutes < MINUTES_IN_DAY * 2

export const distanceOfDaysInWords = dateString => {
  const from = moment(dateString).format('x') as any
  const distanceInSeconds = ((Date.now() - from) / 1000)
  const distanceInMinutes = Math.abs(Math.floor(distanceInSeconds / 60))
  return isOneDay(distanceInMinutes) ? '1 day' : `${Math.floor(distanceInMinutes / MINUTES_IN_DAY)} days`
}
