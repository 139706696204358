import React from 'react'

import {
  EuiButton,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer
} from '@elastic/eui'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'

import AmexCreditCardSVG from './assets/credit_cards/amex.svg'
import DiscoverCreditCardSVG from './assets/credit_cards/discover.svg'
import MastercardCreditCardSVG from './assets/credit_cards/mastercard.svg'
import VisaCreditCardSVG from './assets/credit_cards/visa.svg'

const creditCardSVGs = {
  amex: AmexCreditCardSVG,
  discover: DiscoverCreditCardSVG,
  mastercard: MastercardCreditCardSVG,
  visa: VisaCreditCardSVG
}

interface IAddOrUpdatePaymentMethodPanelProps {
  canManagePlan: boolean
  creditCard: any
  onUpdatePaymentMethod
}

const AddOrUpdatePaymentMethodPanel: React.SFC< IAddOrUpdatePaymentMethodPanelProps > = ({ canManagePlan, creditCard, onUpdatePaymentMethod }) => {
  const hasCard = creditCard && creditCard.isPresent
  const typeSlug = hasCard && creditCard.type && creditCard.type.toLowerCase()

  return (
    <EuiFlexItem>
      <EuiPanel paddingSize="m">
        <StuiHeader>
          <StuiHeading type="section">Payment Method</StuiHeading>
        </StuiHeader>

      <EuiSpacer />

        <div className="current-payment-method">
          {hasCard && <>
            {(Object.keys(creditCardSVGs).indexOf(typeSlug) >= 0) &&
              <img
                className="current-payment-method__card"
                src={creditCardSVGs[typeSlug]}
              />}
            <div className="current-payment-method__content">
              <div className="current-payment-method__title">{creditCard.type}</div>
              <div className="current-payment-method__description">{`Ending in ${creditCard.last4}, Expires ${creditCard.expMonth}/${creditCard.expYear}`}</div>
            </div>
          </>}
          { canManagePlan && (
            <div className="current-payment-method__action">
              <EuiButton color="primary" onClick={onUpdatePaymentMethod} data-test-subj="AddOrUpdatePaymentMethodPanelAddOrUpdatePaymentMethodButton">
                {hasCard ? 'Update' : 'Add'} Payment Method
              </EuiButton>
            </div>
          )}
        </div>
      </EuiPanel>
    </EuiFlexItem>
  )
}

export default AddOrUpdatePaymentMethodPanel
