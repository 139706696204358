import React from 'react'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask
} from '@elastic/eui'

interface IConfirmSaveModalProps {
  saving: boolean
  onClose()
  onConfirm()
}

const ConfirmSaveModal: React.SFC<IConfirmSaveModalProps> = ({
  onClose,
  onConfirm,
  saving
}) => (
  <EuiOverlayMask>
    <EuiModal onClose={onClose} data-test-subj="ConfirmSaveModal">
      <EuiModalHeader>
        <EuiModalHeaderTitle>Are you sure?</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <p>The changes will start immediately. </p>
        <p>Make sure your applications are ready to accept the new search results!</p>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>
          Cancel
        </EuiButtonEmpty>
        <EuiButton isLoading={saving} color="secondary" fill={true} onClick={onConfirm}>{saving ? 'Saving...' : 'Save Result Settings'}</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  </EuiOverlayMask>
)

export default ConfirmSaveModal
