import React from 'react'

import {
  EuiCallOut,
  EuiSpacer
} from '@elastic/eui'

export interface IStuiFlashMessagesProps {
  info?: string[],
  warning?: string[],
  error?: string[],
  success?: string[]
  isWrapped?: boolean
  children?: React.ReactNode
}

const FLASH_MESSAGE_TYPES = {
  success: { color: 'success', icon: 'check' },
  info: { color: 'primary', icon: 'iInCircle' },
  warning: { color: 'warning', icon: 'alert' },
  error: { color: 'danger', icon: 'cross' }
}

const StuiFlashMessages: React.SFC<IStuiFlashMessagesProps> = ({ info, warning, error, success, isWrapped, children }) => {
  // If we  have no messages to display, do not render the element at all
  if (!(info || warning || error || success)) {
    return null
  }

  const renderMessages = (type, messages) => (
    <div className="o-stui-flash-message__container">
      <EuiCallOut
        color={FLASH_MESSAGE_TYPES[type].color}
        iconType={FLASH_MESSAGE_TYPES[type].icon}
        title={messages[0]}
      >
        {messages.length > 1 && messages.slice(1).map((message) => <p key={message}>{message}</p>)}
        {children}
      </EuiCallOut>
      <EuiSpacer />
    </div>
  )

  return (
    <div data-test-subj="FlashMessages" className={isWrapped ? 'o-stui-flash-message__wrapped' : ''}>
      {!!success && renderMessages('success', success)}
      {!!info && renderMessages('info', info)}
      {!!warning && renderMessages('warning', warning)}
      {!!error && renderMessages('error', error)}
    </div>
  )
}

export default StuiFlashMessages
