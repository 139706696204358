import React, { useEffect, useState } from 'react'

import {
  EuiButton,
  EuiCallOut,
  EuiFieldPassword,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiPanel,
  EuiSpacer,
  EuiTextAlign
} from '@elastic/eui'

import routes from 'app_search/routes'
import {
  IFlashMessagesAuth,
  IZxcvbnResult
} from 'app_search/types'
import {
  getPasswordStrengthMessage,
  getZxcvbnResult,
  newPasswordIsNotStrongEnough
} from 'app_search/utils'
import http from 'shared/http'
import StuiPasswordStrength from 'stui/PasswordStrength'

import GoogleAuthIconSVG from './assets/icon-google-auth.svg'

interface IAuthBoxProps {
  authorizationStrategies: string[]
  buttonLabel: string
  capturedEmail?: string
  capturedPassword?: string
  flashMessages: IFlashMessagesAuth
  minPasswordScore?: number
  extraSubmitParams?: any
  googleOauthAction?: string
  redirectUrl: string
  submitUrl: string
}

const AuthBox: React.SFC<IAuthBoxProps> = ({
  authorizationStrategies,
  buttonLabel,
  capturedEmail = '',
  capturedPassword = '',
  flashMessages = {},
  minPasswordScore = 0,
  extraSubmitParams = {},
  googleOauthAction,
  redirectUrl,
  submitUrl
}) => {
  const [email, setEmail] = useState<string>(capturedEmail)
  const [password, setPassword] = useState<string>(capturedPassword)
  const [zxcvbnResult, setZxcvbnResult] = useState<IZxcvbnResult>(getZxcvbnResult(password, email))
  const [samlErrorMessages, setSamlErrorMessages] = useState<string[]>(flashMessages.samlError || [])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    setZxcvbnResult(getZxcvbnResult(password, email))
  }, [email, password])

  function submitSaml(event) {
    event.preventDefault()

    const params = {
      auth_strategy: 'password',
      user: {
        email,
        password
      },
      ...extraSubmitParams
    }

    setSamlErrorMessages([])
    setIsSubmitting(true)

    http.post(submitUrl, params)
      .then(({ data }) => {
        setIsSubmitting(false)
        window.location = data.redirect_url || redirectUrl
      })
      .catch(({ response: { data } }) => {
        setSamlErrorMessages([data])
        setIsSubmitting(false)
      })

  }

  return (
    <div className="auth-box" data-test-subj="AuthBox">
      <EuiSpacer />
      { flashMessages.notice &&
        <>
          <EuiCallOut iconType="iInCircle" title={flashMessages.notice} />
          <EuiSpacer />
        </>
      }
      {
        flashMessages.alert && flashMessages.timedOut &&
          <>
            <EuiCallOut iconType="cross" title={flashMessages.alert} />
          </>
      }
      {
        authorizationStrategies.indexOf('google') > -1 &&
          <div>
            <EuiTextAlign textAlign="center">
              <EuiButton
                color="primary"
                href={routes.authenticateGoogleOauthInitiatePath({
                  redirect_url: redirectUrl,
                  google_oauth_action: googleOauthAction,
                  ...extraSubmitParams
                })}
                iconType={GoogleAuthIconSVG}
              >
                {`${buttonLabel} with Google`}
              </EuiButton>
            </EuiTextAlign>
          </div>
      }
      {
        authorizationStrategies.indexOf('google') > -1 && authorizationStrategies.indexOf('password') > -1 &&
          <div className="separator" />
      }
      {
        authorizationStrategies.indexOf('password') > -1 &&
          <div>
            {samlErrorMessages && samlErrorMessages.length > 0 &&
              <div data-type="inline">
                <EuiCallOut color="danger" title="Authentication has failed" iconType="cross">
                <p>
                  {samlErrorMessages[0]}
                </p>
                </EuiCallOut>
              </div>
            }
            <EuiPanel paddingSize="l">
              <EuiForm>
                <form
                  onSubmit={submitSaml}
                >
                  <EuiFormRow label="Email">
                    <EuiFieldText
                      name="email"
                      value={email}
                      required={true}
                      autoFocus={true}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </EuiFormRow>
                  <EuiFormRow
                    label="Password"
                    helpText={getPasswordStrengthMessage(zxcvbnResult, minPasswordScore)}
                  >
                    <div>
                      <EuiFieldPassword
                        autoComplete="off"
                        name="password"
                        onChange={e => setPassword(e.target.value)}
                        required={true}
                        value={password}
                      />
                      {(password !== '' && zxcvbnResult !== null) &&
                        <StuiPasswordStrength strengthScore={zxcvbnResult.score || 0} />
                      }
                    </div>
                  </EuiFormRow>
                  <EuiButton
                    color="primary"
                    disabled={isSubmitting || password.length === 0 || newPasswordIsNotStrongEnough(zxcvbnResult, minPasswordScore)}
                    fill={true}
                    fullWidth={true}
                    id="auth-submit"
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    {isSubmitting ? 'Signing in...' : buttonLabel}
                  </EuiButton>
                </form>
              </EuiForm>
            </EuiPanel>
          </div>
      }
    </div>
  )

}

export default AuthBox
