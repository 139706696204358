import React from 'react'

import { Link } from 'react-router-dom'

import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

const NotFound: React.SFC<{}> = () => {
  return (
    <EuiEmptyPrompt
      className="notfound"
      title={
        <div>
          <svg width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="134" height="134" rx="67" fill="white" stroke="#DAE2EB" strokeWidth="2" strokeDasharray="8 4" />
            <path fillRule="evenodd" clipRule="evenodd" d="M105.305 43.2825L108.239 41.5904C111.254 45.2835 113 49.9396 113 54.8566V82.571C113 90.0741 108.998 97.0042 102.5 100.757L79.9995 113.747V73.9339C79.9995 69.0048 78.2474 64.3457 75.2264 60.6497L105.305 43.2825Z" fill="#DAE2EB" />
            <path fillRule="evenodd" clipRule="evenodd" d="M66.5006 55.747C60.0025 59.5 56.0004 66.4301 56.0004 73.9332V113.75L33.5001 100.757C27.0021 97.0035 23 90.0734 23 82.5703V54.859C23 47.3559 27.0021 40.4198 33.5001 36.6697L34.9782 35.8163L35.0002 35.8297L68.0006 54.88L66.5006 55.747Z" fill="#DAE2EB" />
            <path fillRule="evenodd" clipRule="evenodd" d="M78.5007 22.8125C72.0027 19.0625 63.9985 19.0625 57.5005 22.8125L35.0002 35.8297L68.0006 54.88L101.001 35.8297L78.5007 22.8125Z" fill="#A5B4C4" />
          </svg>
          <h5>404 Error</h5>
        </div>}
      body={<p>The page you're looking for was not found</p>}
      actions={
        <EuiFlexGroup justifyContent="center">
          <EuiFlexItem>
            <Link className="euiButton euiButton--primary euiButton--fill" to="/">
              <span className="euiButton__content">
                <span className="euiButton__text">Back to your Dashboard</span>
              </span>
            </Link>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton href="mailto:support@swiftype.com">Contact Support</EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>}
    />
  )
}

export default NotFound
