import routes from 'app_search/routes'
import http from 'shared/http'
import { storeLogic } from 'shared/store'

import { EnginesLogic } from 'app_search/Engines'

import { IIndexingStatus } from 'shared/types'

export const EngineLogic = storeLogic({
  connect: () => ({
    values: [
      EnginesLogic, ['engines']
    ],
    actions: [
      EnginesLogic, ['initializeEnginesData']
    ]
  }),
  actions: () => ({
    setIsLoading: () => true,
    setEngineData: (engine) => ({ engine }),
    setEngineName: (engineName) => ({ engineName }),
    setIndexingStatus: (activeReindexJob: IIndexingStatus) => activeReindexJob,
    clearEngine: true
  }),
  reducers: ({ actions }) => ({
    dataLoading: [true, {
      [actions.setIsLoading]: () => true
    }],
    engine: [{}, {
      [actions.setEngineData]: (_, { engine }) => engine,
      [actions.clearEngine]: () => ({}),
      [actions.setIndexingStatus]: (state, activeReindexJob) => ({
        ...state,
        activeReindexJob
      })
    }],
    engineName: ['', {
      [actions.setEngineName]: (_, { engineName }) => engineName
    }]
  }),
  thunks: ({ actions, get }) => ({
    initializeEngine: () => http(routes.detailsLocoMocoEnginePath(get('engineName')))
      .then(({ data: engine }) => {
        actions.setEngineData(engine)
      })
  })
})
