import React from 'react'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask
} from '@elastic/eui'

interface IConfirmResetModalProps {
  onClose()
  onConfirm()
}

const ConfirmResetModal: React.SFC<IConfirmResetModalProps> = ({
  onClose,
  onConfirm
}) => (
  <EuiOverlayMask>
    <EuiModal onClose={onClose} data-test-subj="ConfirmResetModal">
      <EuiModalHeader>
        <EuiModalHeaderTitle>Are You Sure?</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <p>This will revert your settings back to the default: all fields set to raw.</p>
        <p>The default will take over immediately and impact your search results.</p>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>
          Cancel
        </EuiButtonEmpty>
        <EuiButton color="primary" fill={true} onClick={onConfirm}>Restore Defaults</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  </EuiOverlayMask>
)

export default ConfirmResetModal
