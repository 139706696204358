export enum EOpenModal {
  None = 'None',
  ConfirmModifyModal = 'Confirm Modify',
  ConfirmResetModal = 'Confirm Reset',
  ConfirmSaveModal = 'Confirm Save'
}

export interface IServerFieldResultSetting {
  raw?: {
    size?: number
  } | boolean
  snippet?: {
    size?: number
    fallback?: boolean
  } | boolean
}

export interface IFieldResultSetting {
  raw: boolean
  rawSize?: number
  snippet: boolean
  snippetSize?: number
  snippetFallback: boolean

}

export interface IServerFieldResultSettingObject {
  [s: string]: IServerFieldResultSetting
}

export interface IFieldResultSettingObject {
  [s: string]: IFieldResultSetting
}
