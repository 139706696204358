import React, { useEffect, useState, useRef } from 'react'

import { withRouter } from 'react-router-dom'

import routes from 'app_search/routes'
import http from 'shared/http'

import { Loading } from 'app_search/components/Loading'
import { ENGINES_PATH, SAMPLE_ENGINE_PATH } from 'app_search/utils/routePaths'
import useAbilities from 'app_search/utils/useAbilities'

import { IOnboardingDetails, OnboardingLogic } from './OnboardingLogic'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiEmptyPrompt,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiPanel,
  EuiPopover,
  EuiSelect,
  EuiSpacer,
  EuiTabbedContent,
  EuiText,
  EuiTextColor,
  EuiTitle
} from '@elastic/eui'

import StuiFlashMessages from 'stui/FlashMessages'

import CurrentUser, { ICurrentUserProps } from 'app_search/components/CurrentUser'
import { DocumentCreation, DocumentCreationButtons } from 'app_search/components/DocumentCreation'
import { CodeExample } from 'app_search/Onboarding'
import { ICurrentUser, IObject, ISupportedLanguages } from 'app_search/types'
import { formatApiName } from 'app_search/utils'

const CREATE_ENGINE_STATE = 'create_engine'
const INDEX_STATE = 'index_documents'
const COMPLETE_STATE = 'complete'
const POLLING_DURATION = 5000
const OB_COMPLETE_PATH = routes.locoMocoOnboardingCompletePath()
const OB_ADVANCE_PATH = routes.locoMocoOnboardingAdvancePath()
const NEW_ENGINES_PATH = routes.locoMocoEnginesPath()

const onUnmount = () => {
  document.body.className = ''
}

interface IOnboardingProps extends ICurrentUserProps, IOnboardingDetails {
  history: IObject
  actions: {
    initializeOnboardingDetails()
    setOnboardingComplete()
    setOnboardingState(onboardingState: string)
  }
  existingEngineName: string
  supportedLanguages: ISupportedLanguages[]
  dataLoading: boolean
  currentUser: ICurrentUser
}

interface IOnboardingState {
  creatingEngine: boolean
  creationMode: string
  errors: string[]
  engineName: string
  language: string | undefined
  rawEngineName: string
  showDocumentCreationModal: boolean
  supportedLanguages: ISupportedLanguages[]
  userMenuOpen: boolean
}

const defaultState = {
  creatingEngine: false,
  creationMode: 'manual',
  errors: [],
  language: undefined,
  rawEngineName: '',
  engineName: '',
  showDocumentCreationModal: false,
  supportedLanguages: [],
  userMenuOpen: false
} as IOnboardingState

const Onboarding: React.SFC<IOnboardingProps> = ({
  actions: {
    initializeOnboardingDetails,
    setOnboardingComplete,
    setOnboardingState
  },
  currentUser: { name, email, pictureUrl },
  supportedLanguages,
  existingEngineName,
  onboardingState,
  history,
  dataLoading,
  apiKey
}) => {
  const { canDestroySession } = useAbilities()

  const [state, setState] = useState(defaultState)
  const setData = data => setState({ ...state, ...data })

  const redirect = path => {
    setOnboardingComplete()
    history.push(path)
  }

  const completeOnboarding = () => http.post(OB_COMPLETE_PATH).then(() => redirect(ENGINES_PATH))
  const goToSampleEngine = () => http.post(OB_COMPLETE_PATH).then(() => redirect(SAMPLE_ENGINE_PATH))

  const {
    creatingEngine,
    creationMode,
    engineName,
    errors,
    language,
    rawEngineName,
    showDocumentCreationModal,
    userMenuOpen
  } = state

  useEffect(() => {
    initializeOnboardingDetails()
    document.body.className = 'view-onboarding'
    return onUnmount
  }, [])

  const pollInterval = useRef<number>()
  useEffect(() => {
    if (engineName && onboardingState === INDEX_STATE) {
      pollInterval.current = window.setInterval(pollForDocuments, POLLING_DURATION)
    }
    return () => clearInterval(pollInterval.current)
  }, [onboardingState, engineName])

  useEffect(() => {
    setData({ engineName: existingEngineName })
  }, [existingEngineName])

  if (dataLoading) { return <Loading /> }

  const advanceOnboardingState = () => {
    http.post(OB_ADVANCE_PATH).then(({ data }) => {
      const { onboarding_state } = data
      setOnboardingState(onboarding_state)
    })
  }

  const pollForDocuments = () => {
    const route = routes.locoMocoEngineDocumentsPath({ engine_slug: engineName, format: 'json' })

    if (showDocumentCreationModal) {
      return
    }

    http.post(route, { page: { current: 1, size: 1 } })
      .then(({ data }) => {
        const { results } = data
        if (results.length) {
          advanceOnboardingState()
          clearInterval(pollInterval.current)
        }
      })
  }

  const handleChange: React.FormEventHandler<HTMLInputElement> = e => {
    const nameValue = e.currentTarget.value
    setData({ rawEngineName: nameValue, engineName: formatApiName(nameValue) })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setData({ errors: [], creatingEngine: true })
    http({
      data: { name: engineName, language },
      method: 'post',
      url: NEW_ENGINES_PATH
    }).then(() => {
      setData({ creatingEngine: false })
      advanceOnboardingState()
    }).catch(({ response }) => {
      setData({ errors: response.data.errors, creatingEngine: false })
    })
  }

  const handleLanguageChange = e => setData({ language: e.target.value })
  const openDocumentCreation = path => setData({ showDocumentCreationModal: true, creationMode: path })
  const toggleDocumentCreation = () => setData({ showDocumentCreationModal: !showDocumentCreationModal })
  const closeDocumentCreation = () => setData({ showDocumentCreationModal: false })
  const openUserMenu = () => setData({ userMenuOpen: true })
  const closeUserMenu = () => setData({ userMenuOpen: false })
  const engineNameNote = () => rawEngineName !== engineName ? <>Your engine will be named <strong>{engineName}</strong></> : 'Engine names can only contain lowercase letters, numbers, and hyphens'
  const handleDocumentCreated = () => {
    advanceOnboardingState()
    closeDocumentCreation()
  }
  const createSearchEngineContent = (
    <>
      <EuiTitle size="l">
        <h2>Let’s start by naming your first App&nbsp;Search&nbsp;Engine</h2>
      </EuiTitle>
      <EuiSpacer size="m" />
      <EuiText>
        <p>Your Engine name will be used as the identifier when making API calls or issuing search queries.</p>
      </EuiText>
      <EuiSpacer />
      {errors && errors.length > 0 &&
        <>
          <StuiFlashMessages error={errors} />
          <EuiSpacer size="s" />
        </>
      }
      <form onSubmit={handleSubmit}>
        <EuiForm>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow
                label="Engine name"
                helpText={engineNameNote()}
                fullWidth={true}
              >
                <EuiFieldText
                  name="engine-name"
                  value={rawEngineName}
                  onChange={handleChange}
                  autoComplete="off"
                  data-test-subj="OnBoardingEngineTextField"
                  fullWidth={true}
                  placeholder="i.e., my-search-engine"
                  autoFocus={true}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFormRow
                label="Language"
              >
                <EuiSelect
                  name="engine-language"
                  value={language}
                  options={supportedLanguages}
                  onChange={handleLanguageChange}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <EuiButton
            disabled={creatingEngine || rawEngineName.length === 0}
            fill={true}
            isLoading={creatingEngine}
            data-test-subj="OnBoardingContinueButton"
            onClick={handleSubmit}
          >
            Continue
          </EuiButton>
        </EuiForm>
      </form>
      <EuiSpacer size="xxl" />
      {!process.env.LOCO_TOGO && <EuiPanel>
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem>
            <EuiTitle size="s">
              <h3>Just kicking the tires?</h3>
            </EuiTitle>
            <EuiText size="s">
              <p>Test an engine with sample data.</p>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton data-test-subj="OnboardingSampleEngineButton" onClick={goToSampleEngine}>Try a Sample Engine</EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>}
    </>
  )

  const indexingDataContent = () => {
    const tabs = [{
      id: 'uploadJson',
      name: 'Upload JSON',
      content: (
        <>
          <EuiSpacer />
          <DocumentCreationButtons openDocumentCreation={openDocumentCreation} />
          <EuiSpacer />
          <p>Once indexing is complete, a green check-mark will appear. It may take a moment or two.</p>
          {showDocumentCreationModal &&
            <DocumentCreation
              engineName={engineName}
              toggleDocumentCreation={toggleDocumentCreation}
              creationMode={creationMode}
              onSuccess={handleDocumentCreated}
            />
          }
        </>
      )
    }, {
      id: 'api',
      name: 'Use the API',
      content: (
        <>
          <EuiSpacer />
          <CodeExample apiKey={apiKey} engineName={engineName} />
        </>
      )
    }]

    return (
      <>
        <EuiTitle size="l">
          <h2>Add documents to your Engine</h2>
        </EuiTitle>
        <EuiSpacer size="m" />
        <EuiText>
          <p>You can paste JSON, upload a JSON file, or use the API to post JSON data into your new Engine.</p>
        </EuiText>
        <EuiSpacer />
        <EuiTabbedContent tabs={tabs} initialSelectedTab={tabs[0]} />
      </>
    )
  }

  const visitDashboardButton = <EuiButton fill={true} onClick={completeOnboarding}>
    Visit the Dashboard
  </EuiButton>

  const onboardingCompleteContent = (
    <EuiEmptyPrompt
      iconType="check"
      iconColor="secondary"
      title={<h2>Data indexed successfully!</h2>}
      body={<p>Explore the dashboard to tune your Engine, modify your schema and dig into features like Curations, Analytics, and more.</p>}
      titleSize="l"
      actions={visitDashboardButton}
      data-test-subj="OnboardingCompleteMessage"
    />
  )

  return (
    <>
      <EuiFlexGroup className="o-stui-wrapper onboarding-header" justifyContent="spaceBetween" alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiButton
            color="ghost"
            iconType="arrowRight"
            iconSide="right"
            data-test-subj="SkipOnBoardingButton"
            onClick={completeOnboarding}
            size="s"
          >
            Skip Onboarding
          </EuiButton>
        </EuiFlexItem>
        {canDestroySession && <EuiFlexItem grow={false}>
          <EuiPopover
            anchorPosition="downRight"
            id="currentUser"
            closePopover={closeUserMenu}
            isOpen={userMenuOpen}
            panelPaddingSize="none"
            button={<EuiButtonEmpty color="ghost" iconType="arrowDown" iconSide="right" onClick={openUserMenu}>
              <div className="c-stui-current-account">
                <CurrentUser name={name} email={process.env.LOCO_TOGO ? null : email} pictureUrl={pictureUrl} />
              </div>
            </EuiButtonEmpty>}
          >
            <EuiContextMenuPanel
              items={[
                <EuiContextMenuItem
                  key="logout"
                  icon="exit"
                  onClick={() => { window.location = routes.sessionsLogoutPath() }}
                >
                  Logout
                </EuiContextMenuItem>
              ]}
            />
          </EuiPopover>
        </EuiFlexItem>}
      </EuiFlexGroup>
      <div className="o-stui-wrapper onboarding-container">
        <div className="onboarding-container__header">
          <div className="onboarding-brand">
            <EuiIcon type="logoAppSearch" size="xxl" />
          </div>
        </div>
        <div className="onboarding-container__content">
          <div className="onboarding-step-header">
            <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
              <EuiFlexItem>
                <EuiTitle size="xxs" textTransform="uppercase">
                  <h1>
                    <EuiTextColor color="subdued">
                      Welcome to Elastic App Search
                    </EuiTextColor>
                  </h1>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <div className="onboarding-steps">
                  <span className={`onboarding-step ${onboardingState === CREATE_ENGINE_STATE && 'onboarding-step--active'}`} />
                  <span className={`onboarding-step ${onboardingState === INDEX_STATE && 'onboarding-step--active'}`} />
                  <span className={`onboarding-step ${onboardingState === COMPLETE_STATE && 'onboarding-step--active'}`} />
                </div>
              </EuiFlexItem>
            </EuiFlexGroup>
          </div>
          <EuiSpacer />
          <div className="onboarding-step-content">
            {(!onboardingState || onboardingState === CREATE_ENGINE_STATE) && createSearchEngineContent}
            {onboardingState === INDEX_STATE && indexingDataContent()}
            {onboardingState === COMPLETE_STATE && onboardingCompleteContent}
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(OnboardingLogic(Onboarding))
