import React from 'react'

import classNames from 'classnames'

interface IStuiTableControlsGroupProps {
  children?: React.ReactNode
  className?: string
}

const StuiTableControlsGroup: React.SFC<IStuiTableControlsGroupProps> = ({ children, className }) => (
  <div className={classNames('c-stui-table-controls-group', className)}>
    {children}
  </div>
)

export default StuiTableControlsGroup
