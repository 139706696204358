import React from 'react'

import { StuiTableBodyCell } from 'stui/Table'

interface ISchemaMetaFieldCellProps {
  children: React.ReactNode
  isFieldName?: boolean
}

const SchemaMetaFieldCell: React.SFC<ISchemaMetaFieldCellProps> = ({ children, isFieldName }) => (
  <StuiTableBodyCell>
    <div className="c-stui-engine-schema-field">
      {isFieldName ? <div className="c-stui-engine-schema-field__name">{children}</div> : children}
    </div>
  </StuiTableBodyCell>
)

export default SchemaMetaFieldCell
