import React, { useEffect } from 'react'

import Dragula from 'react-dragula'
import { Link, withRouter } from 'react-router-dom'

import { Loading } from 'app_search/components/Loading'
import { getCurationAddRoute } from 'app_search/utils/routePaths'

import { CurationsLogic } from './CurationsLogic'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiEmptyPrompt,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiPanel,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'
import StuiTooltip from 'stui/Tooltip'

import CurationQueries from './CurationQueries'
import CurationsTable from './CurationsTable'
import CurationsTableHeader from './CurationsTableHeader'
import CurationsViewAllButton from './CurationsViewAllButton'

import { ICuration, ICurationQueryItem, IEngineDetails, IMatch, IObject } from 'app_search/types'

const promotedContainerClass = 'c-stui-documents-container--promoted'
const hasClass = (elm, cls) => (` ${elm.className} `).indexOf(` ${cls} `) > -1

let drake
let dragulaDecorator

interface ICurationDetailProps {
  actions: {
    initializeCurationDetail(id: string)
    showHiddenTable()
    hideManageQueries()
    launchManageQueries()
    resetCurations()
    setPromoted(id: string)
    removePromoted(id: string)
    setHidden(id: string)
    removeHidden(id: string)
    demoteAll()
    restoreAll()
    resetCuration()
    setDragging(isDragging: boolean)
    setOver(isOver: boolean)
    setUpdating(isUpdating: boolean)
    onPromotedDrop({ promotedIds, reordering })
  }
  engine: IEngineDetails
  engineName: string
  match: IMatch
  preLoadedCuration: ICuration
  curation: ICuration
  preLoadedSchema: IObject
  curationQueries: ICurationQueryItem[]
  manageQueriesVisible: boolean
  hiddenTableVisible: boolean
  hiddenResultsText?: string
  dragging?: boolean
  over?: boolean
  flashMessages: IStuiFlashMessagesProps
  updating?: boolean
  dataLoading: boolean
}

const CurationDetail: React.SFC<ICurationDetailProps> = ({
  actions: {
    initializeCurationDetail,
    showHiddenTable,
    hideManageQueries,
    launchManageQueries,
    resetCurations,
    setPromoted,
    removePromoted,
    setHidden,
    removeHidden,
    demoteAll,
    restoreAll,
    resetCuration,
    setDragging,
    setOver,
    setUpdating,
    onPromotedDrop
  },
  curation: {
    promoted,
    hidden,
    organic = [],
    queries
  },
  engine,
  engineName,
  curationQueries,
  hiddenTableVisible,
  manageQueriesVisible,
  hiddenResultsText,
  dragging,
  over,
  flashMessages,
  updating,
  match: { params: { curationId } },
  dataLoading
}) => {

  const initializeDragula = () => {
    const dragulaOptions = {
      accepts: (_, target) => hasClass(target, promotedContainerClass),
      moves: (_, __, handle) => hasClass(handle, 'o-stui-pinning-control--move') || hasClass(handle, 'handle-bar'),
      isContainer: () => false,
      invalid: () => false,              // don't prevent any drags from initiating by default
      direction: 'vertical',             // Y axis is considered when determining where an element would be dropped
      copySortSource: true,              // Allow reordering of promoted.
      copy: (_, source) => !hasClass(source, promotedContainerClass), // elements are copied so React can update accordingly.
      revertOnSpill: true,               // spilling will put the element back where it was dragged from, if this is true
      removeOnSpill: false,              // spilling will `.remove` the element, if this is true
      ignoreInputTextSelection: false,    // allows users to select input text
      mirrorContainer: document.querySelector('.stui-layout-view-container')
    }

    drake = Dragula([], dragulaOptions)

    dragulaDecorator = r => drake.containers.push(r)

    const onDragStart = () => setDragging(true)
    const onDragEnd = () => setDragging(false)
    const onDragOver = () => setOver(true)
    const onDragOut = () => setOver(false)
    const onDrop = (el, target, source) => {
      const isReorderingPromoted = hasClass(source, promotedContainerClass)
      const isDroppingPromoted = hasClass(target, promotedContainerClass)

      if (isDroppingPromoted) {
        setUpdating(true)
      } else {
        return
      }

      const promotedIds: string[] = []
      const rows: HTMLElement[] = target.children
      Array.from(rows).forEach(row => promotedIds.push(row.dataset.ref || ''))
      // The Dragula plugin and React both leave the element in the table so we remove the Dragula one.
      if (!isReorderingPromoted) {
        el.remove()
      }
      onPromotedDrop({ promotedIds, reordering: isReorderingPromoted })
    }

    drake.on('drop', onDrop)
    drake.on('drag', onDragStart)
    drake.on('dragend', onDragEnd)
    drake.on('over', onDragOver)
    drake.on('out', onDragOut)
  }

  useEffect(() => {
    initializeDragula()
    initializeCurationDetail(curationId)
    return onUnmount
  }, [])

  if (dataLoading) { return <Loading /> }

  const onUnmount = () => {
    resetCuration()
    drake.destroy()
  }

  const headerActions = <>
    <EuiButton onClick={launchManageQueries} data-test-subj="ManageQueriesCurationButton">Manage Queries</EuiButton>
    <EuiButton color="danger" disabled={promoted.length === 0 && hidden.length === 0} onClick={resetCurations}>Restore Defaults</EuiButton>
  </>

  const promotedTable = (
    <CurationsTable
      header={
        <CurationsTableHeader
          icon="star-fill"
          title="Promoted Documents"
          subtitle="Promoted results appear before organic results. Documents can be re-ordered."
          actions={
            <>
              <Link to={getCurationAddRoute(engineName, curationId)}>
                <EuiButton color="secondary" fill={true} size="s" data-test-subj="AddResultManuallyButton">
                  Add Result Manually
                </EuiButton>
              </Link>
              {promoted.length > 0 && <EuiButton onClick={demoteAll} size="s">Demote All</EuiButton>}
            </>
          }
        />
      }
      dragging={dragging}
      over={over}
      docType="promoted"
      className={promotedContainerClass}
      documents={promoted}
      reference={dragulaDecorator}
      handlePromoteClick={removePromoted}
      schema={engine.schema}
      updating={updating}
    />
  )

  const organicTableHeaderTitle = <>
    {organic.length} organic documents for the active&nbsp;
      <StuiTooltip placement="right" content="Select Queries">
      <button title="Select Query" className="curation-manage-control" onClick={launchManageQueries}>
        "{queries[0]}"
      </button>
    </StuiTooltip>
    &nbsp;query
    </>

  const organicTableHeaderActions = <>
    There {curationQueries.length > 1 ? 'are' : 'is'}&nbsp;
      <strong>{curationQueries.length}</strong>
    &nbsp;{curationQueries.length > 1 ? 'queries.' : 'query.'}&nbsp;
      <EuiButton size="s" onClick={launchManageQueries}>Manage</EuiButton>
  </>

  const organicTable = organic.length > 0 ?
    <CurationsTable
      header={
        <CurationsTableHeader
          icon="leaf-fill"
          title={organicTableHeaderTitle}
          subtitle="Promote results by clicking the star, hide them by clicking the eye. Click Manage to set a new active query."
          actions={organicTableHeaderActions}
        />
      }
      dragging={dragging}
      over={over}
      docType="organic"
      documents={organic}
      reference={dragulaDecorator}
      handleHideClick={setHidden}
      handlePromoteClick={setPromoted}
      schema={engine.schema}
    /> :
    <EuiPanel paddingSize="s">
      <EuiEmptyPrompt
        iconType="bullseye"
        iconColor="secondary"
        titleSize="s"
        title={<h2>No organic results to display for the active <strong>"{queries[0]}"</strong> query</h2>}
        actions={<EuiButton onClick={launchManageQueries}>Manage Queries</EuiButton>}
      />
    </EuiPanel>

  const hiddenTable = hiddenTableVisible ?
    <CurationsTable
      header={
        <CurationsTableHeader
          icon="hide-fill"
          title="Hidden Documents"
          subtitle="Hidden documents will not appear in organic results."
          actions={<EuiButton size="s" onClick={restoreAll}>Restore All</EuiButton>}
        />
      }
      dragging={dragging}
      over={over}
      docType="hidden"
      documents={hidden}
      handleHideClick={removeHidden}
      schema={engine.schema}
    /> :
    <EuiPanel paddingSize="s">
      <EuiEmptyPrompt
        iconType="eyeClosed"
        iconColor="danger"
        title={<h2>{hiddenResultsText}</h2>}
        titleSize="s"
        body={<p>Click below to view or restore {hidden.length === 1 ? 'it' : 'them'}.</p>}
        actions={<EuiButton onClick={showHiddenTable} data-test-subj="ShowHiddenCurationsButton">View hidden results</EuiButton>}
      />
    </EuiPanel>

  const manageQueries = manageQueriesVisible && (
    <EuiOverlayMask>
      <EuiModal onClose={hideManageQueries}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Manage Queries</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiText>
            <p>Click a query to make it active, then view and curate its organic results.</p>
          </EuiText>
          <EuiSpacer />
          <CurationQueries curationQueries={curationQueries} isNewCuration={false} className="curation-queries--manage" />
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={hideManageQueries}>Done</EuiButtonEmpty>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )

  const headerQueryList = () => {
    const queryNodes: React.ReactNode[] = []
    queries.map(query => { queryNodes.push(<strong>&ldquo;{query}&rdquo;</strong>) })

    switch (queryNodes.length) {
      case 1:
        return (queryNodes[0])
      case 2:
        return <>{queryNodes[0]} and {queryNodes[1]}</>
      case 3:
        return <>{queryNodes[0]}, {queryNodes[1]}, and {queryNodes[2]}</>
      default:
        return <>{queryNodes[0]}, {queryNodes[1]}, {queryNodes[2]}, and {queryNodes.slice(2, -1).length} more</>
    }
  }

  return (
    <div>
      <CurationsViewAllButton engineName={engineName} key="breadcrumbs" />
      <StuiHeader key="header" contained={true} viewHeader={true} actions={headerActions}>
        <StuiHeading type="view">Manage Curation</StuiHeading>
        <p className="c-stui-view-header__subtitle">
          {queries.length > 0 ?
            <>For the {curationQueries.length > 1 ? 'queries' : 'query'} {headerQueryList()}.</> :
            <>&nbsp;</>
          }
        </p>
      </StuiHeader>
      <StuiMain key="main">
        <StuiFlashMessages {...flashMessages} />
        {promotedTable}
        <EuiSpacer size="xxl" />
        {organicTable}
        <EuiSpacer size="xxl" />
        {hidden.length > 0 && hiddenTable}
      </StuiMain>
      {manageQueries}
    </div>
  )
}

export default withRouter(CurationsLogic(CurationDetail))
