var routes = {};

(function() {
  /*
File generated by js-routes 1.4.3
Based on Rails routes of Swiftype::Application
 */

(function() {
  var DeprecatedBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, Utils, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message) {
    this.message = message;
  };

  ParameterMissing.prototype = new Error();

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  SpecialOptionsKey = "_options";

  DeprecatedBehavior = false;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = DeprecatedBehavior || actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          if (value != null) {
            delete parameters[left];
            return this.path_identifier(value);
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      if (left.replace(/^\*/i, "") !== left) {
        route[1] = left = left.replace(/^\*/i, "");
      }
      value = parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      parameters[left] = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      return this.visit(route, parameters, optional);
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace.split(".");
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// api_loco_moco_v1_engines => /api/as/v1/engines(.:format)
  // function(options)
  apiLocoMocoV1EnginesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"engines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
apiLocoMocoV1EnginesUrl: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"engines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// authenticate_accept_invitation => /authenticate/accept_invitation(.:format)
  // function(options)
  authenticateAcceptInvitationPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"authenticate",false],[2,[7,"/",false],[2,[6,"accept_invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
authenticateAcceptInvitationUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"authenticate",false],[2,[7,"/",false],[2,[6,"accept_invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// bulk_create_loco_moco_engine_source_engines => /as/engines/:engine_slug/source_engines/bulk_create(.:format)
  // function(engine_slug, options)
  bulkCreateLocoMocoEngineSourceEnginesPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"source_engines",false],[2,[7,"/",false],[2,[6,"bulk_create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
bulkCreateLocoMocoEngineSourceEnginesUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"source_engines",false],[2,[7,"/",false],[2,[6,"bulk_create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// collection_loco_togo_role_mappings => /role_mappings/collection(.:format)
  // function(options)
  collectionLocoTogoRoleMappingsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"role_mappings",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
collectionLocoTogoRoleMappingsUrl: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"role_mappings",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// create_engine_loco_moco_engines => /as/engines/create_engine(.:format)
  // function(options)
  createEngineLocoMocoEnginesPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[6,"create_engine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
createEngineLocoMocoEnginesUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[6,"create_engine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// create_loco_moco_engine_documents => /as/engines/:engine_slug/documents/new(.:format)
  // function(engine_slug, options)
  createLocoMocoEngineDocumentsPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
createLocoMocoEngineDocumentsUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// details_loco_moco_credentials => /as/credentials/details(.:format)
  // function(options)
  detailsLocoMocoCredentialsPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"credentials",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
detailsLocoMocoCredentialsUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"credentials",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// details_loco_moco_engine => /as/engines/:slug/details(.:format)
  // function(slug, options)
  detailsLocoMocoEnginePath: Utils.route([["slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
detailsLocoMocoEngineUrl: Utils.route([["slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// details_loco_moco_engine_api_logs => /as/engines/:engine_slug/api_logs/details(.:format)
  // function(engine_slug, options)
  detailsLocoMocoEngineApiLogsPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"api_logs",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
detailsLocoMocoEngineApiLogsUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"api_logs",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// details_loco_moco_engine_result_settings => /as/engines/:engine_slug/result_settings/details(.:format)
  // function(engine_slug, options)
  detailsLocoMocoEngineResultSettingsPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"result_settings",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
detailsLocoMocoEngineResultSettingsUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"result_settings",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// details_loco_moco_engine_search_settings => /as/engines/:engine_slug/search_settings/details(.:format)
  // function(engine_slug, options)
  detailsLocoMocoEngineSearchSettingsPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"search_settings",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
detailsLocoMocoEngineSearchSettingsUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"search_settings",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// download_loco_moco_engine_reference_application => /as/engines/:engine_slug/reference_application/download(.:format)
  // function(engine_slug, options)
  downloadLocoMocoEngineReferenceApplicationPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"reference_application",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
downloadLocoMocoEngineReferenceApplicationUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"reference_application",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// field_config_loco_moco_engine_reference_application => /as/engines/:engine_slug/reference_application/field_config(.:format)
  // function(engine_slug, options)
  fieldConfigLocoMocoEngineReferenceApplicationPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"reference_application",false],[2,[7,"/",false],[2,[6,"field_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
fieldConfigLocoMocoEngineReferenceApplicationUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"reference_application",false],[2,[7,"/",false],[2,[6,"field_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// find_or_create_loco_moco_engine_document_positions => /as/engines/:engine_slug/curations/find_or_create(.:format)
  // function(engine_slug, options)
  findOrCreateLocoMocoEngineDocumentPositionsPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"curations",false],[2,[7,"/",false],[2,[6,"find_or_create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
findOrCreateLocoMocoEngineDocumentPositionsUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"curations",false],[2,[7,"/",false],[2,[6,"find_or_create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// global_account_state_loco_moco_account => /as/accounts/:id/global_account_state(.:format)
  // function(id, options)
  globalAccountStateLocoMocoAccountPath: Utils.route([["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"global_account_state",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
globalAccountStateLocoMocoAccountUrl: Utils.route([["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"global_account_state",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// loco_moco_credential => /as/credentials/:api_token_name(.:format)
  // function(api_token_name, options)
  locoMocoCredentialPath: Utils.route([["api_token_name",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"credentials",false],[2,[7,"/",false],[2,[3,"api_token_name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
locoMocoCredentialUrl: Utils.route([["api_token_name",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"credentials",false],[2,[7,"/",false],[2,[3,"api_token_name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// loco_moco_credentials => /as/credentials/collection(.:format)
  // function(options)
  locoMocoCredentialsPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"credentials",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
locoMocoCredentialsUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"credentials",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// loco_moco_engine => /as/engines/:slug(.:format)
  // function(slug, options)
  locoMocoEnginePath: Utils.route([["slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
locoMocoEngineUrl: Utils.route([["slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// loco_moco_engine_api_logs => /as/engines/:engine_slug/api_logs/collection(.:format)
  // function(engine_slug, options)
  locoMocoEngineApiLogsPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"api_logs",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
locoMocoEngineApiLogsUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"api_logs",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// loco_moco_engine_document => /as/engines/:engine_slug/documents/:id(.:format)
  // function(engine_slug, id, options)
  locoMocoEngineDocumentPath: Utils.route([["engine_slug",true],["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
locoMocoEngineDocumentUrl: Utils.route([["engine_slug",true],["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// loco_moco_engine_document_click => /as/engines/:engine_slug/documents/:document_id/click(.:format)
  // function(engine_slug, document_id, options)
  locoMocoEngineDocumentClickPath: Utils.route([["engine_slug",true],["document_id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"document_id",false],[2,[7,"/",false],[2,[6,"click",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
locoMocoEngineDocumentClickUrl: Utils.route([["engine_slug",true],["document_id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"document_id",false],[2,[7,"/",false],[2,[6,"click",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// loco_moco_engine_document_position => /as/engines/:engine_slug/curations/:id(.:format)
  // function(engine_slug, id, options)
  locoMocoEngineDocumentPositionPath: Utils.route([["engine_slug",true],["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"curations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
locoMocoEngineDocumentPositionUrl: Utils.route([["engine_slug",true],["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"curations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// loco_moco_engine_document_positions => /as/engines/:engine_slug/curations/collection(.:format)
  // function(engine_slug, options)
  locoMocoEngineDocumentPositionsPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"curations",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
locoMocoEngineDocumentPositionsUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"curations",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// loco_moco_engine_documents => /as/engines/:engine_slug/documents(.:format)
  // function(engine_slug, options)
  locoMocoEngineDocumentsPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
locoMocoEngineDocumentsUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// loco_moco_engine_multi_search => /as/engines/:engine_slug/multi_search(.:format)
  // function(engine_slug, options)
  locoMocoEngineMultiSearchPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"multi_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
locoMocoEngineMultiSearchUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"multi_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// loco_moco_engine_reindex_job => /as/engines/:engine_slug/reindex_job/:id(.:format)
  // function(engine_slug, id, options)
  locoMocoEngineReindexJobPath: Utils.route([["engine_slug",true],["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"reindex_job",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
locoMocoEngineReindexJobUrl: Utils.route([["engine_slug",true],["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"reindex_job",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// loco_moco_engine_result_settings => /as/engines/:engine_slug/result_settings(.:format)
  // function(engine_slug, options)
  locoMocoEngineResultSettingsPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"result_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
locoMocoEngineResultSettingsUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"result_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// loco_moco_engine_schema => /as/engines/:engine_slug/schema(.:format)
  // function(engine_slug, options)
  locoMocoEngineSchemaPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"schema",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
locoMocoEngineSchemaUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"schema",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// loco_moco_engine_search_settings => /as/engines/:engine_slug/search_settings(.:format)
  // function(engine_slug, options)
  locoMocoEngineSearchSettingsPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"search_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
locoMocoEngineSearchSettingsUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"search_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// loco_moco_engine_source_engine => /as/engines/:engine_slug/source_engines/:source_engine_slug(.:format)
  // function(engine_slug, source_engine_slug, options)
  locoMocoEngineSourceEnginePath: Utils.route([["engine_slug",true],["source_engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"source_engines",false],[2,[7,"/",false],[2,[3,"source_engine_slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
locoMocoEngineSourceEngineUrl: Utils.route([["engine_slug",true],["source_engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"source_engines",false],[2,[7,"/",false],[2,[3,"source_engine_slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// loco_moco_engine_source_engines => /as/engines/:engine_slug/source_engines(.:format)
  // function(engine_slug, options)
  locoMocoEngineSourceEnginesPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"source_engines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
locoMocoEngineSourceEnginesUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"source_engines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// loco_moco_engine_synonym => /as/engines/:engine_slug/synonyms/:id(.:format)
  // function(engine_slug, id, options)
  locoMocoEngineSynonymPath: Utils.route([["engine_slug",true],["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"synonyms",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
locoMocoEngineSynonymUrl: Utils.route([["engine_slug",true],["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"synonyms",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// loco_moco_engine_synonyms => /as/engines/:engine_slug/synonyms/collection(.:format)
  // function(engine_slug, options)
  locoMocoEngineSynonymsPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"synonyms",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
locoMocoEngineSynonymsUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"synonyms",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// loco_moco_engines => /as/engines/collection(.:format)
  // function(options)
  locoMocoEnginesPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
locoMocoEnginesUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// loco_moco_extend_trial => /as/extend-trial(.:format)
  // function(options)
  locoMocoExtendTrialPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"extend-trial",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
locoMocoExtendTrialUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"extend-trial",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// loco_moco_invitation => /as/invitations/:code(.:format)
  // function(code, options)
  locoMocoInvitationPath: Utils.route([["code",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[3,"code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
locoMocoInvitationUrl: Utils.route([["code",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[3,"code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// loco_moco_invitations => /as/invitations(.:format)
  // function(options)
  locoMocoInvitationsPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"invitations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
locoMocoInvitationsUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"invitations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// loco_moco_onboarding_advance => /as/onboarding/advance(.:format)
  // function(options)
  locoMocoOnboardingAdvancePath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"advance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
locoMocoOnboardingAdvanceUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"advance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// loco_moco_onboarding_complete => /as/onboarding/complete(.:format)
  // function(options)
  locoMocoOnboardingCompletePath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"complete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
locoMocoOnboardingCompleteUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"complete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// loco_moco_onboarding_details => /as/onboarding/details(.:format)
  // function(options)
  locoMocoOnboardingDetailsPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
locoMocoOnboardingDetailsUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// loco_moco_plan => /as/plan(.:format)
  // function(options)
  locoMocoPlanPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"plan",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
locoMocoPlanUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"plan",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// loco_moco_role => /as/roles/:id(.:format)
  // function(id, options)
  locoMocoRolePath: Utils.route([["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
locoMocoRoleUrl: Utils.route([["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// loco_moco_root => /as(.:format)
  // function(options)
  locoMocoRootPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
locoMocoRootUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// loco_moco_settings_update_account => /as/settings/update_account(.:format)
  // function(options)
  locoMocoSettingsUpdateAccountPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"update_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
locoMocoSettingsUpdateAccountUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"update_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// loco_moco_settings_update_user => /as/settings/update_user(.:format)
  // function(options)
  locoMocoSettingsUpdateUserPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"update_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
locoMocoSettingsUpdateUserUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"update_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// loco_moco_settings_user_info => /as/settings/user_info(.:format)
  // function(options)
  locoMocoSettingsUserInfoPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"user_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
locoMocoSettingsUserInfoUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"user_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// loco_moco_users => /as/users/collection(.:format)
  // function(options)
  locoMocoUsersPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
locoMocoUsersUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// loco_togo_role_mapping => /role_mappings/:id(.:format)
  // function(id, options)
  locoTogoRoleMappingPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"role_mappings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
locoTogoRoleMappingUrl: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"role_mappings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// loco_togo_role_mappings => /role_mappings(.:format)
  // function(options)
  locoTogoRoleMappingsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"role_mappings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
locoTogoRoleMappingsUrl: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"role_mappings",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// new_loco_moco_engine_reference_application => /as/engines/:engine_slug/reference_application/new(.:format)
  // function(engine_slug, options)
  newLocoMocoEngineReferenceApplicationPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"reference_application",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
newLocoMocoEngineReferenceApplicationUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"reference_application",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_loco_togo_role_mapping => /role_mappings/new(.:format)
  // function(options)
  newLocoTogoRoleMappingPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"role_mappings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
newLocoTogoRoleMappingUrl: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"role_mappings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// overview_metrics_loco_moco_engine => /as/engines/:slug/overview_metrics(.:format)
  // function(slug, options)
  overviewMetricsLocoMocoEnginePath: Utils.route([["slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"overview_metrics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
overviewMetricsLocoMocoEngineUrl: Utils.route([["slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"overview_metrics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// preview_loco_moco_engine_reference_application => /as/engines/:engine_slug/reference_application/preview(.:format)
  // function(engine_slug, options)
  previewLocoMocoEngineReferenceApplicationPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"reference_application",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
previewLocoMocoEngineReferenceApplicationUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"reference_application",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// queries_loco_moco_engine_analytics => /as/engines/:engine_slug/analytics/queries(.:format)
  // function(engine_slug, options)
  queriesLocoMocoEngineAnalyticsPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"analytics",false],[2,[7,"/",false],[2,[6,"queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
queriesLocoMocoEngineAnalyticsUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"analytics",false],[2,[7,"/",false],[2,[6,"queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// query_loco_moco_engine_analytics => /as/engines/:engine_slug/analytics/query/:query(.:format)
  // function(engine_slug, query, options)
  queryLocoMocoEngineAnalyticsPath: Utils.route([["engine_slug",true],["query",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"analytics",false],[2,[7,"/",false],[2,[6,"query",false],[2,[7,"/",false],[2,[3,"query",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
queryLocoMocoEngineAnalyticsUrl: Utils.route([["engine_slug",true],["query",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"analytics",false],[2,[7,"/",false],[2,[6,"query",false],[2,[7,"/",false],[2,[3,"query",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// query_tester_search_loco_moco_engine => /as/engines/:slug/query_tester_search(.:format)
  // function(slug, options)
  queryTesterSearchLocoMocoEnginePath: Utils.route([["slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"query_tester_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
queryTesterSearchLocoMocoEngineUrl: Utils.route([["slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"query_tester_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// resend_loco_moco_invitation => /as/invitations/:code/resend(.:format)
  // function(code, options)
  resendLocoMocoInvitationPath: Utils.route([["code",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[3,"code",false],[2,[7,"/",false],[2,[6,"resend",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
resendLocoMocoInvitationUrl: Utils.route([["code",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[3,"code",false],[2,[7,"/",false],[2,[6,"resend",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// reset_loco_moco_engine_search_settings => /as/engines/:engine_slug/search_settings/reset(.:format)
  // function(engine_slug, options)
  resetLocoMocoEngineSearchSettingsPath: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"search_settings",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
resetLocoMocoEngineSearchSettingsUrl: Utils.route([["engine_slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"search_settings",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// reset_loco_togo_role_mappings => /role_mappings/reset(.:format)
  // function(options)
  resetLocoTogoRoleMappingsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"role_mappings",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
resetLocoTogoRoleMappingsUrl: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"role_mappings",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// root => /
  // function(options)
  rootPath: Utils.route([], {"host":"localhost:3002","protocol":"http"}, [7,"/",false]),
rootUrl: Utils.route([], {"host":"localhost:3002","protocol":"http"}, [7,"/",false], true),
// sample_response_search_loco_moco_engine => /as/engines/:slug/sample_response_search(.:format)
  // function(slug, options)
  sampleResponseSearchLocoMocoEnginePath: Utils.route([["slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"sample_response_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
sampleResponseSearchLocoMocoEngineUrl: Utils.route([["slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"sample_response_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// search_settings_search_loco_moco_engine => /as/engines/:slug/search_settings_search(.:format)
  // function(slug, options)
  searchSettingsSearchLocoMocoEnginePath: Utils.route([["slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"search_settings_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
searchSettingsSearchLocoMocoEngineUrl: Utils.route([["slug",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"search_settings_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// seeding_sample_engine_loco_moco_account => /as/accounts/:id/seeding_sample_engine(.:format)
  // function(id, options)
  seedingSampleEngineLocoMocoAccountPath: Utils.route([["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"seeding_sample_engine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
seedingSampleEngineLocoMocoAccountUrl: Utils.route([["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"seeding_sample_engine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// sessions_login => /login(.:format)
  // function(options)
  sessionsLoginPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
sessionsLoginUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// sessions_logout => /logout(.:format)
  // function(options)
  sessionsLogoutPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
sessionsLogoutUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// sessions_select_product => /select(.:format)
  // function(options)
  sessionsSelectProductPath: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"select",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
sessionsSelectProductUrl: Utils.route([["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"select",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// status_loco_moco_engine_reindex_job => /as/engines/:engine_slug/reindex_job/:id/status(.:format)
  // function(engine_slug, id, options)
  statusLocoMocoEngineReindexJobPath: Utils.route([["engine_slug",true],["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"reindex_job",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
statusLocoMocoEngineReindexJobUrl: Utils.route([["engine_slug",true],["id",true],["format",false]], {"host":"localhost:3002","protocol":"http"}, [2,[7,"/",false],[2,[6,"as",false],[2,[7,"/",false],[2,[6,"engines",false],[2,[7,"/",false],[2,[3,"engine_slug",false],[2,[7,"/",false],[2,[6,"reindex_job",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true)}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return Utils.make();
    });
  } else {
    Utils.make();
  }

}).call(this);

}).call(routes);

export default routes.Routes;
