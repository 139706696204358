import React from 'react'

import { Link } from 'react-router-dom'

import {
  EuiButton,
  EuiCallOut
} from '@elastic/eui'

interface IIndexingStatusErrorsProps {
  viewLinkPath: string
}

const IndexingStatusErrors: React.SFC<IIndexingStatusErrorsProps> = ({ viewLinkPath }) => (
  <EuiCallOut
    className="c-stui-indexing-status-errors"
    color="danger"
    iconType="cross"
    title="There was an error"
    data-test-subj="IndexingStatusErrors"
  >
    <p>Several documents have field conversion errors.</p>
    <EuiButton
      color="danger"
      fill={true}
      size="s"
    >
      <Link to={viewLinkPath}>View Errors</Link>
    </EuiButton>
  </EuiCallOut>
)

export default IndexingStatusErrors
