import React, { useEffect } from 'react'

import { Link, withRouter } from 'react-router-dom'

import { Loading } from 'app_search/components/Loading'

import {
  ENGINE_ANALYTICS_PATH,
  getEngineRoute,
  getQueryDetailRoute
} from 'app_search/utils/routePaths'

import { AnalyticsLogic } from './AnalyticsLogic'

import {
  EuiButton,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'
import {
  StuiTableControls,
  StuiTableControlsGroup
} from 'stui/Table'

import AnalyticsHeaderActions from './AnalyticsHeaderActions'
import AnalyticsUnavailable from './AnalyticsUnavailable'
import PlanLimitModal from './PlanLimitModal'
import QueryDetailContent from './QueryDetailContent'
import RecentQueryTable from './RecentQueryTable'
import TopResultsTable from './TopResultsTable'

import { IMatch, IObject, IQueryClick, IRecentQuery, IRouter } from 'app_search/types'

export interface IAnalyticsSectionContentProps extends IRouter {
  actions: {
    initializeQueryData(history: IObject, query: string)
    initializeAnalyticsData(history: IObject, isFull: boolean)
    updateSearchInputValue(value: string)
  }
  title?: string
  engineName: string
  startDate: string
  endDate: string
  query?: string
  totalQueriesForQuery?: number
  queriesPerDayForQuery?: number
  topClicksForQuery?: IQueryClick[]
  recentQueries?: IRecentQuery[]
  allTags: string[]
  currentTag: string
  analyticsUnavailable: boolean
  isDetails?: boolean
  isRecentQueries?: boolean
  dataLoading?: boolean,
  searchInputValue?: string
  subSection?: string
  match: IMatch
  showPlanLimitMessage: boolean
}

const AnalyticsSectionContent: React.SFC<IAnalyticsSectionContentProps> = props => {
  const {
    history,
    actions: {
      initializeQueryData,
      initializeAnalyticsData,
      updateSearchInputValue
    },
    match: { params: { query } },
    title,
    engineName,
    isDetails,
    isRecentQueries,
    startDate,
    endDate,
    totalQueriesForQuery = 0,
    queriesPerDayForQuery = 0,
    topClicksForQuery = [],
    recentQueries,
    allTags,
    currentTag,
    analyticsUnavailable,
    dataLoading,
    searchInputValue,
    subSection,
    showPlanLimitMessage
  } = props

  useEffect(() => {
    isDetails ?
      initializeQueryData(history.location, query) :
      initializeAnalyticsData(history.location, true)
  }, [history.location.search])

  if (dataLoading) { return <Loading /> }

  const queries = subSection && props[subSection]

  const headerActions = (
    <AnalyticsHeaderActions
      allTags={allTags}
      currentTag={currentTag}
      startDate={startDate}
      endDate={endDate}
      query={query}
    />
  )

  const handleViewQueryDetails = () => {
    history.push(getQueryDetailRoute(engineName, searchInputValue || '""'))
  }

  const tableProps = { currentTag, startDate, endDate, engineName }

  const decodedQuery = decodeURIComponent(query)
  const quotedQuery = (decodedQuery === '""' ? '""' : `"${decodedQuery}"`)

  return (
    <div>
      {analyticsUnavailable ? <AnalyticsUnavailable /> :
        <>
          <div className="stui-view-topbar" key="breadcrumbs">
            <div className="o-stui-breadcrumbs">
              <Link to={getEngineRoute(ENGINE_ANALYTICS_PATH, engineName)} className="o-stui-breadcrumb">Analytics</Link>
              <div className="o-stui-breadcrumb o-stui-breadcrumb--active">{title}</div>
            </div>
          </div>
          <StuiHeader key="header" contained={true} viewHeader={true} actions={headerActions}>
            <StuiHeading type="view">{isDetails ? quotedQuery : title}</StuiHeading>
          </StuiHeader>
          <StuiMain key="content">
            {showPlanLimitMessage && <PlanLimitModal />}

            {!isDetails && <StuiTableControls>
              <form onSubmit={handleViewQueryDetails}>
                <StuiTableControlsGroup>
                  <EuiFlexGroup
                    alignItems="center"
                    gutterSize="s"
                  >
                    <EuiFlexItem>
                      <EuiFieldSearch
                        value={searchInputValue}
                        onChange={e => updateSearchInputValue(e.target.value)}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton type="submit">View Details</EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </StuiTableControlsGroup>
              </form>
            </StuiTableControls>}
            {isRecentQueries && recentQueries && <RecentQueryTable queries={recentQueries} {...tableProps} />}
            {queries && <TopResultsTable showClicks={true} queries={queries} {...tableProps} />}
            {isDetails && <QueryDetailContent
              query={decodedQuery}
              startDate={startDate}
              endDate={endDate}
              totalQueriesForQuery={totalQueriesForQuery}
              queriesPerDayForQuery={queriesPerDayForQuery}
              topClicksForQuery={topClicksForQuery}
            />}
          </StuiMain>
        </>}
    </div>
  )
}

export default withRouter(AnalyticsLogic(AnalyticsSectionContent))
