import {
  EuiComboBoxOptionProps
} from '@elastic/eui'

import { AppLogic } from 'app_search/App'
import routes from 'app_search/routes'
import { IEngineDetails, IMeta } from 'app_search/types'
import { formatApiName } from 'app_search/utils'
import { handleAPIError } from 'app_search/utils/handleAPIError'
import { getMetaEngineRoute } from 'app_search/utils/routePaths'
import { DEFAULT_META } from 'shared/constants/defaultMeta'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

export const CreateMetaEngineLogic = storeLogic({
  connect: () => ({
    actions: [
      AppLogic, ['fetchLatestAccountState']
    ],
    values: [
      AppLogic, ['account']
    ]
  }),
  actions: () => ({
    resetIndexedEnginesData: true,
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages }),
    setIndexedEnginesData: (engines: IEngineDetails[], meta: IMeta) => ({ engines, meta }),
    setRawName: (rawName: string) => ({ rawName }),
    setSelectedIndexedEngineOptions: (options: EuiComboBoxOptionProps[]) => ({ options })
  }),
  reducers: ({ actions }) => ({
    dataLoading: [true, {
      [actions.setIndexedEnginesData]: () => false
    }],
    flashMessages: [{}, {
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages,
      [actions.setIndexedEnginesData]: () => ({})
    }],
    indexedEngines: [[], {
      [actions.resetIndexedEnginesData]: () => ([]),
      [actions.setIndexedEnginesData]: (_, { engines }) => engines
    }],
    meta: [DEFAULT_META, {
      [actions.resetIndexedEnginesData]: () => DEFAULT_META,
      [actions.setIndexedEnginesData]: (_, { meta }) => meta
    }],
    rawName: ['', {
      [actions.setRawName]: (_, { rawName }) => rawName
    }],
    selectedIndexedEngineOptions: [[], {
      [actions.setSelectedIndexedEngineOptions]: (_, { options }) => options
    }]
  }),
  selectors: ({ selectors }) => ({
    indexedEngineOptions: [
      () => [selectors.indexedEngines],
      (indexedEngines) => indexedEngines.map(engine => ({ label: engine.name }))
    ],
    name: [
      () => [selectors.rawName],
      (rawName) => formatApiName(rawName)
    ],
    selectedIndexedEngines: [
      () => [selectors.selectedIndexedEngineOptions],
      (selectedIndexedEngineOptions) => selectedIndexedEngineOptions.map(option => option.label)
    ]
  }),
  thunks: ({ actions, get }) => ({
    fetchAllIndexedEngines: () => {
      let engines: IEngineDetails[] = []

      const recursiveFetchIndexedEngines = (page = 1) => {
        const url = routes.locoMocoEnginesPath({
          page: {
            current: page,
            size: DEFAULT_META.page.size
          },
          type: 'indexed'
        })

        return http(url)
          .then(({ data: { meta, results } }) => {
            engines = [...engines, ...results]

            if (page >= meta.page.total_pages) {
              actions.setIndexedEnginesData(engines, meta)
            } else {
              recursiveFetchIndexedEngines(page + 1)
            }
          })
          .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))

      }

      recursiveFetchIndexedEngines()
    },
    initializeCreateMetaEngineData: () => {
      actions.resetIndexedEnginesData()
      actions.fetchAllIndexedEngines()
    },
    submitMetaEngine: (name, history) => {
      http({
        data: {
          name,
          type: 'meta',
          source_engines: get('selectedIndexedEngines')
        },
        method: 'post',
        url: routes.locoMocoEnginesPath()
      })
        .then(({ data }) => {
          actions.fetchLatestAccountState()
          history.push(getMetaEngineRoute(data.name))
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    }
  })
})
