import axios from 'axios'

const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')

const dashboardClient = axios.create({
  headers: {
    'x-requested-with': 'XMLHttpRequest',
    'x-csrf-token': metaCsrfToken ? metaCsrfToken.getAttribute('content') : null
  }
})

dashboardClient.interceptors.response.use(
  response => response,
  error => {
    // If the user session times out, XHR requests will be redirected to /login.json.
    // That will respond with status 406 because only the HTML format is supported.
    // Here we intercept this response and do a browser-redirect to the login page.
    if (
      !axios.isCancel(error)
      && error?.response?.status === 406
      && (error?.response?.request?.responseURL || '').match(/\/login\.json$/)
    ) {
      window.location.href = '/login'
      return new Promise(() => { /* should not resolve/reject as we're now redirecting */ })
    }

    return Promise.reject(error)
  }
)

export {
  dashboardClient as default
}
