import React from 'react'

import { Route, Switch, withRouter } from 'react-router-dom'

import {
  ENGINE_DOCUMENT_DETAIL_PATH,
  ENGINE_DOCUMENTS_PATH
} from 'app_search/utils/routePaths'

import { IMatch } from 'app_search/types'

import DocumentDetail from './DocumentDetail'
import Documents from './Documents'

interface IDocumentsRouterProps {
  match: IMatch
}

const DocumentsRouter: React.SFC<IDocumentsRouterProps> = props => (
  <Switch>
    <Route exact={true} path={ENGINE_DOCUMENTS_PATH} render={match => <Documents match={match} {...props} />} />
    <Route exact={true} path={ENGINE_DOCUMENT_DETAIL_PATH} render={match => <DocumentDetail match={match} {...props} />} />
  </Switch>
)

export default withRouter(DocumentsRouter)
