import axios from 'axios'

import routes from 'app_search/routes'
import { POLLING_DURATION } from 'shared/constants/polling'
import http from 'shared/http'

import { storeLogic } from 'shared/store'

import { EngineLogic } from 'app_search/Engine'

import { IApiLog, IEngineMetrics } from 'app_search/types'

export const EngineOverviewLogic = storeLogic({
  connect: () => ({
    values: [
      EngineLogic, ['engine', 'engineName']
    ]
  }),
  actions: () => ({
    setPolledData: (engineMetrics: IEngineMetrics) => engineMetrics,
    setActiveApiLog: (activeApiLog: IApiLog) => ({ activeApiLog }),
    setTimoutId: (timeoutId: number) => ({ timeoutId }),
    toggleDocumentCreation: (showDocumentCreationModal: boolean) => ({ showDocumentCreationModal }),
    setLoading: (dataLoading: boolean) => ({ dataLoading }),
    openDocumentCreation: (creationMode) => ({ creationMode }),
    closeDocumentCreation: false,
    closeDetails: true
  }),
  reducers: ({ actions }) => ({
    dataLoading: [true, {
      [actions.setPolledData]: () => false,
      [actions.setLoading]: (_, { dataLoading }) => dataLoading
    }],
    apiLogsUnavailable: [false, {
      [actions.setPolledData]: (_, { apiLogsUnavailable }) => apiLogsUnavailable
    }],
    activeApiLog: [null, {
      [actions.setActiveApiLog]: (_, { activeApiLog }) => activeApiLog,
      [actions.closeDetails]: () => null
    }],
    showDocumentCreationModal: [false, {
      [actions.toggleDocumentCreation]: state => !state,
      [actions.openDocumentCreation]: () => true,
      [actions.closeDocumentCreation]: () => false
    }],
    creationMode: ['manual', {
      [actions.openDocumentCreation]: (_, { creationMode }) => creationMode
    }],
    startDate: ['', {
      [actions.setPolledData]: (_, { startDate }) => startDate
    }],
    endDate: ['', {
      [actions.setPolledData]: (_, { endDate }) => endDate
    }],
    queriesPerDay: [[], {
      [actions.setPolledData]: (_, { queriesPerDay }) => queriesPerDay
    }],
    operationsPerDay: [[], {
      [actions.setPolledData]: (_, { operationsPerDay }) => operationsPerDay
    }],
    totalQueries: [0, {
      [actions.setPolledData]: (_, { totalQueries }) => totalQueries
    }],
    totalClicks: [0, {
      [actions.setPolledData]: (_, { totalClicks }) => totalClicks
    }],
    documentCount: [0, {
      [actions.setPolledData]: (_, { documentCount }) => documentCount
    }],
    pollingCancelTokenSource: [axios.CancelToken.source(), {}],
    timeoutId: [null, {
      [actions.setPolledData]: (_, { timeoutId }) => timeoutId
    }]
  }),
  thunks: ({ actions, get }) => ({
    pollForOverviewMetrics: () => {
      const route = routes.overviewMetricsLocoMocoEnginePath(get('engineName'))
      http.get(route, { cancelToken: get('pollingCancelTokenSource').token })
        .then(({ data }) => actions.onPollingSuccess(data))
        .catch((error) => {
          if (!axios.isCancel(error)) {
            throw error
          }
        })
    },
    onPollingSuccess: (engineMetrics) => {
      const timeoutId = window.setTimeout(actions.pollForOverviewMetrics, POLLING_DURATION)
      actions.setPolledData({ ...engineMetrics, timeoutId })
    }
  }),
  events: ({ actions, values }) => ({
    beforeUnmount() {
      values.pollingCancelTokenSource.cancel()
      clearTimeout(values.timeoutId)
      actions.setLoading(true)
    }
  })
})
