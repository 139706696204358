import React, { useEffect } from 'react'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import { Loading } from 'app_search/components/Loading'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'
import StuiSubHeading from 'stui/SubHeading'

import SchemaConflictedFields from './SchemaConflictedFields'
import { SchemaLogic } from './SchemaLogic'
import SchemaMetaFields from './SchemaMetaFields'

import { IObject } from 'app_search/types'

interface ISchemaProps {
  actions: {
    initializeSchemaMeta()
  }
  schema: IObject
  conflictingFields: IObject
  fields: IObject
  activeSchema: IObject
  dataLoading: boolean
}

const SchemaMetaEngine: React.SFC<ISchemaProps> = ({
  actions: {
    initializeSchemaMeta
  },
  activeSchema,
  conflictingFields,
  fields,
  dataLoading
}) => {
  useEffect(() => {
    initializeSchemaMeta()
  }, [])

  if (dataLoading) { return <Loading /> }

  const conflictingFieldsCount = Object.keys(conflictingFields).length
  const hasConflicts = conflictingFieldsCount > 0

  return (
    <>
      <StuiHeader contained={true} viewHeader={true}>
        <StuiHeading type="view">Meta Engine Schema</StuiHeading>
        <StuiSubHeading>Active and inactive fields, by Engine.</StuiSubHeading>
      </StuiHeader>
      <StuiMain>
        {hasConflicts && (
          <EuiCallOut
            color="warning"
            title={`This Meta Engine contains ${conflictingFieldsCount} fields that exist across multiple Engines, which have a field-type conflict.`}
          >
            <p>
              These fields will be disabled for searching until the field-type
              conflicts are resolved.
            </p>
          </EuiCallOut>
        )}
        <EuiSpacer />
        <SchemaMetaFields schema={activeSchema} fields={fields} />
        <EuiSpacer size="xl" />
        {hasConflicts && <SchemaConflictedFields conflictingFields={conflictingFields} />}
      </StuiMain>
    </>
  )
}

export default SchemaLogic(SchemaMetaEngine)
