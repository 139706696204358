import React from 'react'

import classNames from 'classnames'

import { AccordionItemBody } from 'react-accessible-accordion'

interface IStuiAccordionItemBodyProps {
  children: any
  className?: string
  hideBodyClassName?: string
}

const StuiAccordionItemBody: React.SFC<IStuiAccordionItemBodyProps> = (props) => {
  const { ...other } = props
  const className = classNames(
    'o-stui-accordion-item__body',
    props.className
  )
  const hideBodyClassName = classNames(
    'o-stui-accordion-item__body--hidden',
    props.className && `${props.className}--hidden`,
    props.hideBodyClassName
  )

  return (
    <AccordionItemBody
      {...other}
      className={className}
      hideBodyClassName={hideBodyClassName}
    />
  )
}

export default StuiAccordionItemBody
