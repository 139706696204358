import React, { useEffect, useState } from 'react'

import { useValues } from 'kea'
import _debounce from 'lodash/debounce'

import { EngineLogic } from 'app_search/Engine'

import {
  EuiBadge,
  EuiBadgeProps,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'

import routes from 'app_search/routes'
import { IObject } from 'app_search/types'
import http from 'shared/http'
import StuiCode from 'stui/Code'

import {
  IServerFieldResultSettingObject
} from './types'

interface ISampleResponseProps {
  resultSettings: IServerFieldResultSettingObject
  schema: IObject
}

enum EQueryPerformanceRating {
  Unknown = 'Unknown',
  Good = 'Good',
  Decent = 'Decent',
  Bad = 'Bad',
  VeryBad = 'Very Bad'
}

type TQueryPerformanceBadgePropsCollection = {
  [x in EQueryPerformanceRating]: EuiBadgeProps;
}

type TQueryPerformanceBadgeContentCollection = {
  [x in EQueryPerformanceRating]: string;
}

const QueryPerformanceBadgeProps: TQueryPerformanceBadgePropsCollection = {
  [EQueryPerformanceRating.Unknown]: {
  },
  [EQueryPerformanceRating.Good]: {
    color: '#59deb4'
  },
  [EQueryPerformanceRating.Decent]: {
    color: '#40bfff'
  },
  [EQueryPerformanceRating.Bad]: {
    color: '#fed566'
  },
  [EQueryPerformanceRating.VeryBad]: {
    color: '#ff9173'
  }
}

const QueryPerformanceBadgeContents: TQueryPerformanceBadgeContentCollection = {
  [EQueryPerformanceRating.Unknown]: '',
  [EQueryPerformanceRating.Good]: 'Query Performance: Optimal',
  [EQueryPerformanceRating.Decent]: 'Query Performance: Good',
  [EQueryPerformanceRating.Bad]: 'Query Performance: Standard',
  [EQueryPerformanceRating.VeryBad]: 'Query Performance: Delayed'
}

const QueryPerformance = ({ queryPerformanceRating }: {queryPerformanceRating: EQueryPerformanceRating}) => {
  if (queryPerformanceRating === EQueryPerformanceRating.Unknown) {
    return null
  }
  return (
    <EuiBadge
      className="response-feedback"
      tabIndex={-1}
      {...QueryPerformanceBadgeProps[queryPerformanceRating]}
    >
      {QueryPerformanceBadgeContents[queryPerformanceRating]}
    </EuiBadge>
  )
}

const getSearchResults = _debounce(
  (query: string, slug: string, resultFields: IServerFieldResultSettingObject, setResponse) => {
    http.post(routes.sampleResponseSearchLocoMocoEnginePath({
      format: 'json',
      slug
    }), {
      query: query || '',
      result_fields: resultFields
    }).then(({ data: { results } }) => {
      setResponse(results && results[0])
    }).catch(({ response: { data } }) => {
      if (data) {
        setResponse(data)
      } else {
        setResponse({
          errors: ['There was an error.']
        })
      }
    })
  },
  100)

const getQueryPerformanceRatingForScore = (score) => {
  switch (true) {
    case score < 6:
      return EQueryPerformanceRating.Good
    case score < 11:
      return EQueryPerformanceRating.Decent
    case score < 21:
      return EQueryPerformanceRating.Bad
    default:
      return EQueryPerformanceRating.VeryBad
  }
}
const getQueryPerformanceScore = (resultSettings: IServerFieldResultSettingObject, schema: IObject) => {
  return Object.entries(resultSettings)
    .reduce((acc, [fieldName, resultSetting]) => {
      let newAcc = acc
      if (resultSetting.raw) {
        if (schema[fieldName] !== 'text') {
          newAcc += 0.2
        } else if (
          typeof resultSetting.raw === 'object'
          && resultSetting.raw.size
          && resultSetting.raw.size <= 250
        ) {
          newAcc += 1.0
        } else {
          newAcc += 1.5
        }
      }
      if (resultSetting.snippet) {
        newAcc += 2.0
      }
      return newAcc
    }, 0)
}

const SampleResponse: React.SFC<ISampleResponseProps> = ({
  resultSettings,
  schema
}) => {
  const [queryPerformanceRating, setQueryPerformanceRating] = useState<EQueryPerformanceRating>(EQueryPerformanceRating.Unknown)
  const [response, setResponse] = useState<IObject>()
  const [query, setQuery] = useState<string>('')
  const { engine: { name: engineName } } = useValues(EngineLogic)

  useEffect(() => {
    getSearchResults(query, engineName, resultSettings, setResponse)
  }, [resultSettings, query, schema])

  useEffect(() => {
    const score = getQueryPerformanceScore(resultSettings, schema)
    const rating = getQueryPerformanceRatingForScore(score)
    setQueryPerformanceRating(rating)
  }, [resultSettings, schema])

  return (
    <EuiPanel
      hasShadow={true}
      className="sample-response"
    >
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem>
          <EuiTitle size="s">
            <h3>Sample Response</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <QueryPerformance queryPerformanceRating={queryPerformanceRating} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFieldSearch
        onChange={e => setQuery(e.target.value || '')}
        placeholder={'Type a search query to test a response...'}
      />
      <EuiSpacer />
      <StuiCode
        language="json"
        isScrollable={true}
      >
        {response ? JSON.stringify({ ...response, _meta: undefined }, null, 2) : 'No Results.'}
      </StuiCode>
    </EuiPanel>
  )
}

export default SampleResponse
