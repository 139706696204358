import React from 'react'

import {
  EuiButtonIcon,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import { SynonymsLogic } from './SynonymsLogic'

interface ISynonymInputProps {
  actions: {
    setFormSynonymValue(value: string, index: number)
    removeSynonymInput(index: number)
  }
  index: number
  formSynonyms: string[]
  isFocused?: boolean
  preventAutofocus?: boolean
}

const SynonymInput: React.SFC<ISynonymInputProps> = ({
  actions: {
    setFormSynonymValue,
    removeSynonymInput
  },
  index,
  preventAutofocus,
  isFocused,
  formSynonyms
}) => {
  const handleInputChange = e => setFormSynonymValue(e.target.value, index)
  const handleRemoveInput = () => removeSynonymInput(index)

  return (
    <EuiFlexGroup alignItems="center" gutterSize="s">
      <EuiFlexItem>
        <EuiFieldText
          placeholder="name"
          value={formSynonyms[index]}
          autoFocus={!preventAutofocus || isFocused}
          name="synonyms[]"
          onChange={handleInputChange}
          fullWidth={true}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          data-test-subj="SynonymInputRemoveInput"
          iconType="trash"
          color="danger"
          tabIndex={0}
          onClick={handleRemoveInput}
          aria-label="Remove input"
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default SynonymsLogic(SynonymInput)
