import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'

import { Loading } from 'app_search/components/Loading'
import { ENGINE_ANALYTICS_PATH, ENGINE_API_LOGS_PATH, getEngineRoute } from 'app_search/utils/routePaths'
import useAbilities from 'app_search/utils/useAbilities'

import {
  EuiBadge,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import StuiDivider from 'stui/Divider'
import StuiEmptyState from 'stui/EmptyState'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import { IApiLog, IApiLogsMeta, IEngineDetails, IEngineMetrics, IObject, IRole } from 'app_search/types'

import AnalyticsMetricCard from 'app_search/Analytics/AnalyticsMetricCard'
import ApiLogDetail from 'app_search/ApiLogs/ApiLogDetail'
import ApiLogsTable from 'app_search/ApiLogs/ApiLogsTable'
import { DocumentCreation, DocumentCreationButtons } from 'app_search/components/DocumentCreation'
import { CodeExample } from 'app_search/Onboarding'
import EngineAnalytics from './EngineAnalytics'
import { EngineOverviewLogic } from './EngineOverviewLogic'

interface IEngineOverviewProps extends IEngineMetrics {
  actions: IObject
  preLoadedApiLogsMeta: IApiLogsMeta
  preLoadedApiLogs: IApiLog[]
  apiKey: string
  apiUrl: string
  accountKey: string
  apiLogsUnavailable: boolean
  role: IRole
  engine: IEngineDetails
  activeApiLog?: IApiLog
  timeoutId?: number
  pollingCancelTokenSource: any
  showDocumentCreationModal: boolean
  creationMode: string
  dataLoading: boolean
}

const EngineOverview: React.SFC<IEngineOverviewProps> = ({
  actions: {
    pollForOverviewMetrics,
    setActiveApiLog,
    openDocumentCreation,
    toggleDocumentCreation,
    closeDocumentCreation,
    closeDetails
  },
  dataLoading,
  engine,
  startDate,
  endDate,
  preLoadedApiLogs,
  preLoadedApiLogsMeta,
  apiLogsUnavailable,
  activeApiLog,
  queriesPerDay,
  operationsPerDay,
  totalQueries,
  totalClicks,
  documentCount,
  showDocumentCreationModal,
  creationMode
}) => {
  const { canManageEngineDocuments, canViewEngineCredentials } = useAbilities()
  const { name: engineName, type: engineType } = engine

  useEffect(() => {
    pollForOverviewMetrics()
  }, [])

  if (dataLoading) { return <Loading /> }

  const engineHasDocuments = documentCount > 0
  const engineIsMeta = engineType === 'meta'
  const canViewEngineSetup = canManageEngineDocuments && canViewEngineCredentials
  const showEngineOverview = engineHasDocuments || engineIsMeta || !canViewEngineSetup

  const divider = <>
    <EuiSpacer size="xxl" />
    <StuiDivider />
    <EuiSpacer size="xxl" />
  </>

  const analyticsButton = (
    <EuiButton size="s">
      <Link to={getEngineRoute(ENGINE_ANALYTICS_PATH, engineName)}>View Analytics</Link>
    </EuiButton>)

  const apiLogsButton = (
    <EuiButton size="s">
      <Link to={getEngineRoute(ENGINE_API_LOGS_PATH, engineName)}>View API Logs</Link>
    </EuiButton>)

  const metricsUnavailable = <StuiEmptyState
    icon="logs-fill"
    title="Dashboard metrics are currently unavailable"
    description="Please try again in a few minutes"
  />

  const engineAnalytics = <StuiMain>
    <EuiFlexGroup>
      <EuiFlexItem data-test-subj="TotalQueriesCard">
        <AnalyticsMetricCard title="Total Queries" value={totalQueries} />
      </EuiFlexItem>
      <EuiFlexItem data-test-subj="TotalDocumentsCard">
        <AnalyticsMetricCard title="Total Documents" value={documentCount} />
      </EuiFlexItem>
      <EuiFlexItem data-test-subj="TotalClicksCard">
        <AnalyticsMetricCard title="Total Clicks" value={totalClicks} />
      </EuiFlexItem>
    </EuiFlexGroup>
    {divider}
    <EuiFlexGroup gutterSize="xl">
      <EuiFlexItem>
        <EngineAnalytics
          title="Queries"
          label="Total Queries"
          chartButton={analyticsButton}
          data={[queriesPerDay]}
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EngineAnalytics
          title="Operations"
          label="Total API Operations"
          chartButton={apiLogsButton}
          data={[operationsPerDay]}
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
    {divider}
    {engineName && <ApiLogsTable
      hidePagination={true}
      engine={engine}
      preLoadedEngine={engine}
      logs={preLoadedApiLogs}
      logsMeta={preLoadedApiLogsMeta}
      headerAction={apiLogsButton}
      setActiveApiLog={setActiveApiLog}
    />}
    </StuiMain>

  const emptyEngine = <StuiMain>
    <StuiHeader>
      <StuiHeading type="section">Setup the “{engineName}" Engine</StuiHeading>
    </StuiHeader>
    <EuiSpacer />
    <p>
      There are three ways to send documents to your Engine for indexing. You can paste raw JSON, upload a <code>.json</code> file, or <code>POST</code> to the <a target="_blank" href="https://www.swiftype.com/documentation/app-search/guides/indexing-documents">Documents API</a> endpoint.
    </p>
    <p>
      Click on your choice below or see <strong>Indexing by API</strong>.
    </p>
    <EuiSpacer />
    <DocumentCreationButtons openDocumentCreation={openDocumentCreation} />
    {showDocumentCreationModal &&
      <DocumentCreation
        engineName={engineName}
        toggleDocumentCreation={toggleDocumentCreation}
        creationMode={creationMode}
        onSuccess={closeDocumentCreation}
      />}
    <EuiSpacer />
    <StuiHeader>
      <StuiHeading type="section">Indexing by API</StuiHeading>
    </StuiHeader>
    <EuiSpacer />
    <p>
      The <a target="_blank" href="https://www.swiftype.com/documentation/app-search/guides/indexing-documents">Documents API</a> can be used to add new documents to your Engine, update documents, retrieve documents by id, and delete documents. There are a variety of <a target="_blank" href="https://swiftype.com/documentation/app-search/clients/api">client libraries</a> to help you get started.
    </p>
    <p>
      To see the API in action, you can experiment with the example request below using a command line or a client library.
    </p>
    <EuiSpacer />
    <div className="o-stui-documentation-endpoint">
      <EuiBadge color="primary">POST</EuiBadge>
      <code className="o-stui-code-snippet o-stui-documentation-endpoint__url">/api/as/v1/engines/{engineName}/documents</code>
    </div>
    <EuiSpacer />
    <CodeExample engineName={engineName} apiKey={engine.apiKey} />
    </StuiMain>

  return (
    <div data-test-subj="EngineOverview">
      <StuiHeader key="header" contained={true} viewHeader={true} isSticky={true}>
        <StuiHeading type="view">
          {showEngineOverview ?
            'Engine Overview' :
            <>Engine Setup &mdash;&nbsp;<a href="https://www.swiftype.com/documentation/app-search/getting-started" target="_blank">View Documentation</a></>
          }
        </StuiHeading>
      </StuiHeader>
      {showEngineOverview ? (apiLogsUnavailable ? metricsUnavailable : engineAnalytics) : emptyEngine}
      {activeApiLog && <ApiLogDetail onClose={closeDetails} log={activeApiLog} />}
    </div>
  )
}

export default EngineOverviewLogic(EngineOverview)
