import React from 'react'

import wrap from 'word-wrap'

import { truncate } from 'app_search/utils'

interface IStuiFormattedContentProps {
  content: string
  lineWidth?: number
  length?: number
}

const DEFAULT_MAX_LENGTH = 170
const DEFAULT_LINE_WIDTH = 60
const NEWLINE = '\n'

const StuiFormattedContent: React.SFC<IStuiFormattedContentProps> = ({ content, length = DEFAULT_MAX_LENGTH, lineWidth = DEFAULT_LINE_WIDTH }) => {
  const truncatedContent = content.length <= length ? content : truncate(content, length)

  return (
    <p className="o-stui-formatted-content">
      {wrap(truncatedContent, { newline: NEWLINE, width: lineWidth }).trim()}
    </p>
  )
}

export default StuiFormattedContent
