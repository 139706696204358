import React from 'react'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer
} from '@elastic/eui'

import { ISynonymSet } from 'app_search/types'

import SynonymInput from './SynonymInput'
import { SynonymsLogic } from './SynonymsLogic'

const CONFIRMATION_MESSAGE = 'Are you sure you want to delete this synonym set?'

const shouldFocusInput = (index, isExistingSet, synonymsLength) => (
  (isExistingSet && index === synonymsLength - 1) ||
  (!isExistingSet && index === 0) ||
  (!isExistingSet && synonymsLength > 2 && index === synonymsLength - 1)
)

interface ISynonymsFormProps {
  actions: {
    submitSynonymSet()
    deleteSynonymSet(synonymId: string)
    setFormSynonyms(formSynonyms: string[])
    hideSynonymsForm()
    addSynonymInput()
  }
  activeSynonymSet: ISynonymSet
  formErrors: string[]
  formSynonyms: string[]
}

class SynonymsForm extends React.Component<ISynonymsFormProps> {
  componentDidMount() {
    const { activeSynonymSet, actions: { setFormSynonyms } } = this.props
    if (activeSynonymSet) {
      setFormSynonyms([...activeSynonymSet.synonyms, ''])
    }
  }

  render() {
    const {
      actions: {
        submitSynonymSet,
        deleteSynonymSet,
        hideSynonymsForm,
        addSynonymInput
      },
      activeSynonymSet,
      formSynonyms,
      formErrors
    } = this.props
    const isExistingSet = !!activeSynonymSet

    const handleSubmit = e => {
      e.preventDefault()
      submitSynonymSet()
    }

    const handleDeleteSynonymSet = () => {
      if (activeSynonymSet && window.confirm(CONFIRMATION_MESSAGE)) {
        deleteSynonymSet(activeSynonymSet.id)
      }
    }

    return (
      <EuiOverlayMask>
        <form onSubmit={handleSubmit}>
          <EuiModal onClose={hideSynonymsForm}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>{isExistingSet ? 'Manage' : 'Add a'} Synonym Set</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              {formErrors.length > 0 &&
                <EuiCallOut
                  color="danger"
                  iconType="cross"
                  title={formErrors[0]}
                >
                  {formErrors.length > 1 && formErrors.slice(1).map(error => <p key={`error-${error}`}>{error}</p>)}
                </EuiCallOut>
              }
              <EuiSpacer />
              {formSynonyms.map((_, index) => (
                <SynonymInput
                  isFocused={shouldFocusInput(index, isExistingSet, formSynonyms.length)}
                  preventAutofocus={formSynonyms.length === 2}
                  key={index}
                  index={index}
                />
              ))}
              <EuiSpacer size="s" />
              <EuiButtonEmpty
                data-test-subj="SynonymsFormAddValueButton"
                iconType="plusInCircle"
                size="s"
                onClick={addSynonymInput}
              >
                Add Value
              </EuiButtonEmpty>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiFlexGroup justifyContent="spaceBetween">
                {isExistingSet &&
                  <EuiFlexItem grow={false}>
                    <EuiButtonEmpty
                      data-test-subj="SynonymsFormDeleteButton"
                      color="danger"
                      onClick={handleDeleteSynonymSet}
                      iconType="trash"
                    >
                      Delete
                    </EuiButtonEmpty>
                  </EuiFlexItem>
                }
                <EuiFlexItem>
                  <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiButtonEmpty onClick={hideSynonymsForm}>Cancel</EuiButtonEmpty>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton data-test-subj="SynonymsFormSubmitButton" fill={true} type="submit">Save</EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiModalFooter>
          </EuiModal>
        </form>
      </EuiOverlayMask>
    )
  }
}

export default SynonymsLogic(SynonymsForm)
