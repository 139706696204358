import React from 'react'

import {
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import routes from 'app_search/routes'
import {
  IFlashMessagesAuth,
  ILMCurrentUser,
  ILMInvitation
} from 'app_search/types'

import AuthBox from './AuthBox'

interface ICurrentUserHeaderProps {
  currentUser: ILMCurrentUser
  flashMessages: IFlashMessagesAuth
  invitation: ILMInvitation
  minPasswordScore: number
}
const CurrentUserHeader: React.SFC<ICurrentUserHeaderProps> = ({
  currentUser,
  flashMessages,
  invitation,
  minPasswordScore
}) => {
  const authorizationStrategies = invitation.account.authorizationStrategies || []
  if (currentUser.googleIdentityOnly && authorizationStrategies.indexOf('google') === -1) {
    authorizationStrategies.push('google')
  }

  return (
    <div data-test-subj="CurrentUserHeader">
      <header className="euiTextAlign euiTextAlign--center">
        <h2>You've been invited to join {invitation.accountName}.</h2>
        <EuiSpacer size="s" />
        <EuiText>
          <p>
            { currentUser.locoMocoAccounts.find((locoMocoAccount) => locoMocoAccount.id === invitation.account.id) ?
                'You are already logged in as a member of this account with a different email address than the email address for the invitation. Please log out before accepting the invitation.'
                : `You are signed in as ${currentUser.email}`
            }
          </p>
        </EuiText>
      </header>
      { !currentUser.locoMocoAccounts.find((locoMocoAccount) => locoMocoAccount.id === invitation.account.id) &&
          <AuthBox
            authorizationStrategies={authorizationStrategies}
            buttonLabel="Accept Invitation"
            capturedEmail={invitation.email}
            extraSubmitParams={{ lm_invitation_code: invitation.code }} // back-end API and Google Oauth expects snake-case for lm_invitation_code
            flashMessages={flashMessages}
            minPasswordScore={minPasswordScore}
            googleOauthAction={'accept_invitation'}
            redirectUrl={routes.sessionsLoginPath()}
            submitUrl={routes.authenticateAcceptInvitationPath()}
          />
      }
    </div>
  )
}

export default CurrentUserHeader
