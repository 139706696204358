import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import _pull from 'lodash/pull'
import _uniqBy from 'lodash/uniqBy'

import { EngineLogic } from 'app_search/Engine/EngineLogic'
import routes from 'app_search/routes'
import { ICuration, ICurationData, ICurationQueryItem, IMeta, IObject } from 'app_search/types'
import handleAPIError from 'app_search/utils/handleAPIError'
import { getCurationRoute } from 'app_search/utils/routePaths'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

import getResultId from './getResultId'

export interface ICurationInitProps {
  curation: ICuration
}

const DELETE_MESSAGE = 'Are you sure you want to remove this curation?'
const SUCCESS_MESSAGE = 'Successfully removed curation.'
const RESTORE_CONFIRMATION = 'Are you sure you want to clear your changes and return to your default results?'
const emptyQuery = { text: '' }
const emptyQueryFocused = { text: '', focusInput: true }
const emptyCuration = { organic: [], promoted: [], hidden: [], queries: [] }

export const CurationsLogic = storeLogic({
  connect: () => ({
    values: [
      EngineLogic, ['engine', 'engineName']
    ]
  }),
  actions: () => ({
    setCurations: ({ curations, meta }: ICurationData) => ({ curations, meta }),
    setPreloadedCuration: (curation: ICuration) => ({ curation }),
    initializeNewCuration: true,
    removeFlashMessages: true,
    addQueryItem: true,
    addQueryFormErrors: (errors: string[]) => ({ errors }),
    updateMeta: (meta: IMeta) => ({ meta }),
    updateCurations: ({ curations, meta }: ICurationData) => ({ curations, meta }),
    updateCuration: (curation: ICuration) => ({ curation }),
    removeCuration: (id: string) => ({ id }),
    resetCuration: true,
    updateCurationAndQueries: (curation: ICuration) => ({ curation }),
    updateCurationQueries: (curationQueries: ICurationQueryItem[]) => ({ curationQueries }),
    toggleMultiQuery: (isMultiQuery: boolean) => ({ isMultiQuery }),
    setQueryItemInputVisibility: (hideInput: boolean, index: number = 0) => ({ hideInput, index }),
    setNewQueryCachedText: (index: number = 0) => ({ index }),
    updateQueryItemText: (text: string, index: number) => ({ text, index }),
    handleCurationQueryBlur: (text: string, index: number) => ({ text, index }),
    removeQueryItem: (index: number) => ({ index }),
    resetCurationQueries: true,
    showHiddenTable: true,
    showManageQueries: true,
    hideManageQueries: false,
    setDragging: (dragging: boolean) => ({ dragging }),
    setOver: (over: boolean) => ({ over }),
    setUpdating: (updating: boolean) => ({ updating }),
    setReordering: (reordering: boolean) => ({ reordering }),
    setPromotedIds: (promotedIds: string[]) => ({ promotedIds }),
    setHiddenIds: (hiddenIds: string[]) => ({ hiddenIds }),
    setSearchQuery: (query: string) => ({ query }),
    setSearchResults: (searchResults: IObject[]) => ({ searchResults }),
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages })
  }),
  reducers: ({ actions }) => ({
    dataLoading: [true, {
      [actions.setCurations]: () => false,
      [actions.setPreloadedCuration]: () => false,
      [actions.setFlashMessages]: () => false,
      [actions.updateSearchQuery]: () => true,
      [actions.resetCuration]: () => true,
      [actions.setSearchResults]: () => false
    }],
    meta: [null, {
      [actions.setCurations]: (_, { meta }) => meta,
      [actions.updateCurations]: (_, { meta }) => meta
    }],
    flashMessages: [{}, {
      [actions.removeCuration]: () => ({ success: [SUCCESS_MESSAGE] }),
      [actions.resetCuration]: () => ({}),
      [actions.removeFlashMessages]: () => ({}),
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages
    }],
    curations: [[], {
      [actions.setCurations]: (_, { curations }) => curations,
      [actions.updateCurations]: (_, { curations }) => curations,
      [actions.resetCuration]: () => [],
      [actions.removeCuration]: (state, { id }) => state.filter((curation) => curation.id !== id)
    }],
    curation: [emptyCuration, {
      [actions.setPreloadedCuration]: (_, { curation }) => curation,
      [actions.updateCuration]: (_, { curation }) => curation,
      [actions.resetCuration]: () => emptyCuration,
      [actions.updateCurationAndQueries]: (_, { curation }) => curation
    }],
    curationQueries: [[emptyQueryFocused], {
      [actions.setPreloadedCuration]: (_, { curation }) => getQueriesObject(curation.queries),
      [actions.updateCurationAndQueries]: (_, { curation }) => getQueriesObject(curation.queries),
      [actions.initializeNewCuration]: () => getQueriesObject(['']),
      [actions.setNewQueryCachedText]: (state, { index }) => {
        const curationQueries = _cloneDeep(state)
        curationQueries[index].cachedText = curationQueries[index].text
        return curationQueries
      },
      [actions.updateCurationQueries]: (_, { curationQueries }) => ([...curationQueries]),
      [actions.resetCurationQueries]: (state) => resetCurationQueries(state),
      [actions.addQueryFormErrors]: (state) => resetCurationQueries(state),
      [actions.hideManageQueries]: (state) => resetCurationQueries(state),
      [actions.setQueryItemInputVisibility]: (state, { hideInput, index }) => {
        const curationQueries = _cloneDeep(state)
        curationQueries[index].hideInput = hideInput
        return curationQueries
      },
      [actions.updateQueryItemText]: (state, { text, index }) => {
        const curationQueries = _cloneDeep(state)
        curationQueries[index].text = text
        return curationQueries
      },
      [actions.handleCurationQueryBlur]: (state, { text, index }) => {
        const curationQueries = _cloneDeep(state)
        curationQueries[index].text = text.trim()
        return curationQueries
      },
      [actions.addQueryItem]: (state) => _uniqBy([...state, emptyQueryFocused], 'text'),
      [actions.removeQueryItem]: (state, { index }) => state.filter((_, i) => i !== index),
      [actions.toggleMultiQuery]: (state, { isMultiQuery }) => isMultiQuery ? [state[0]] : _uniqBy([...state, emptyQuery], 'text')
    }],
    isMultiQuery: [false, {
      [actions.toggleMultiQuery]: state => !state
    }],
    queryFormFlashMessages: [{}, {
      [actions.addQueryFormErrors]: (_, { errors }) => ({ error: errors }),
      [actions.removeFlashMessages]: () => ({}),
      [actions.hideManageQueries]: () => ({}),
      [actions.resetCurationQueries]: () => ({})
    }],
    hiddenTableVisible: [false, {
      [actions.showHiddenTable]: () => true
    }],
    manageQueriesVisible: [false, {
      [actions.showManageQueries]: () => true,
      [actions.hideManageQueries]: () => false
    }],
    dragging: [null, {
      [actions.setDragging]: (_, { dragging }) => dragging
    }],
    over: [null, {
      [actions.setOver]: (_, { over }) => over
    }],
    updating: [null, {
      [actions.setUpdating]: (_, { updating }) => updating
    }],
    reordering: [null, {
      [actions.onPromotedDrop]: (_, { reordering }) => reordering,
      [actions.setReordering]: (_, { reordering }) => reordering
    }],
    promotedIds: [null, {
      [actions.setPreloadedCuration]: (_, { curation }) => getIds(curation.promoted),
      [actions.onPromotedDrop]: (_, { promotedIds }) => promotedIds,
      [actions.setPromotedIds]: (_, { promotedIds }) => promotedIds
    }],
    hiddenIds: [null, {
      [actions.setPreloadedCuration]: (_, { curation }) => getIds(curation.hidden),
      [actions.setHiddenIds]: (_, { hiddenIds }) => hiddenIds
    }],
    searchQuery: ['', {
      [actions.setSearchQuery]: (_, { query }) => query
    }],
    rawSearchResults: [[], {
      [actions.setSearchResults]: (_, { searchResults }) => searchResults
    }]
  }),
  selectors: ({ selectors }) => ({
    hiddenResultsText: [
      () => [selectors.curation],
      curation => {
        const { length } = curation.hidden
        const numWords = ['no', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']
        return `You have ${length < 11 ? numWords[length] : length} hidden result${length === 1 ? '' : 's'}`
      }
    ],
    canSubmitNewQueriesForm: [
      () => [selectors.curationQueries],
      queries => queries.filter(query => query.text.length > 0).length > 0
    ],
    searchResults: [
      () => [selectors.rawSearchResults, selectors.promotedIds],
      (rawSearchResults, promotedIds) => {
        if (rawSearchResults && promotedIds) {
          return rawSearchResults.map(result => {
            const resultId = getResultId(result)
            return { ...result, isPromoted: promotedIds.includes(resultId) }
          })
        }
        return rawSearchResults
      }
    ]
  }),
  thunks: ({ actions, get }) => ({
    handlePageClick: (page) => {
      const route = routes.locoMocoEngineDocumentPositionsPath({
        engine_slug: get('engineName'),
        page: { current: page, size: get('meta').page.size }
      })

      http(route)
        .then(({ data }) => actions.updateCurations({ curations: data.results, meta: data.meta }))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    deleteCurationSet: (id) => {
      actions.removeFlashMessages()
      if (window.confirm(DELETE_MESSAGE)) {
        http.delete(routes.locoMocoEngineDocumentPositionPath(get('engineName'), { id }))
          .then(() => actions.removeCuration(id))
          .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
      }
    },
    submitCurationSet: (history) => {
      actions.removeFlashMessages()
      const queries = filterQueries(get('curationQueries'))
      const engineName = get('engineName')

      http.post(routes.locoMocoEngineDocumentPositionsPath(engineName), { queries })
        .then(({ data }) => history.push(getCurationRoute(engineName, data.id)))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    setServerCuration: (isFormSubmission?: boolean) => {
      actions.removeFlashMessages()
      const curation = _cloneDeep(get('curation'))
      curation.queries = filterQueries(get('curationQueries'))
      const { queries, id } = curation
      const url = routes.locoMocoEngineDocumentPositionPath(get('engineName'), id)
      const data = {
        queries,
        query: queries[0],
        promoted: get('promotedIds') || [],
        hidden: get('hiddenIds') || []
      }

      http({ url, data, method: 'put' })
        .then(response => {
          actions.updateViewData(response.data)
          if (isFormSubmission) {
            actions.getQueryCounts()
          }
        })
        .catch(handleAPIError(messages => {
          isFormSubmission ?
            actions.addQueryFormErrors(messages) :
            actions.setFlashMessages({ error: messages })
        }))
    },
    initializeCurations: () => {
      const route = routes.locoMocoEngineDocumentPositionsPath(get('engineName'))

      http(route)
        .then(({ data: { results: curations, meta } }) => actions.setCurations({ curations, meta }))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    initializeCurationDetail: (id) => {
      const route = routes.locoMocoEngineDocumentPositionPath(get('engineName'), id, { format: 'json' })

      http(route)
        .then(({ data }) => actions.setPreloadedCuration(data))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    updateViewData: (curation: ICuration) => {
      // There's a issue with the non-react plugin Dragula and the React view fighting over
      // the sorting of the promoted list. The only way around this was to let  leave the UI
      // rendering to Dragula when only reordering promoted docs and let React re-render the
      // organic table each time if pulling an item from the list.
      if (get('reordering')) {
        actions.setReordering(false)
      } else {
        actions.updateCurationAndQueries(curation)
      }
      actions.setUpdating(false)
    },
    deleteQueryItem: (id) => {
      actions.removeQueryItem(id)
      actions.setServerCuration(true)
    },
    resetCurations: () => {
      if (window.confirm(RESTORE_CONFIRMATION)) {
        actions.setPromotedIds([])
        actions.setHiddenIds([])
        actions.setServerCuration()
      }
    },
    onPromotedDrop: ({ promotedIds, reordering }) => {
      actions.setReordering(reordering)
      actions.setPromotedIds(promotedIds)
      actions.setServerCuration()
    },
    setPromoted: (id) => {
      actions.setUpdating(true)
      const promotedIds = get('promotedIds').slice()
      promotedIds.push(id)
      actions.setPromotedIds(promotedIds)
      actions.setServerCuration()
    },
    removePromoted: (id) => {
      actions.setUpdating(true)
      const promotedIds = get('promotedIds').slice()
      _pull(promotedIds, id)
      actions.setPromotedIds(promotedIds)
      actions.setServerCuration()
    },
    setHidden: (id) => {
      const hiddenIds = get('hiddenIds').slice()
      hiddenIds.push(id)
      actions.setHiddenIds(hiddenIds)
      actions.setServerCuration()
    },
    removeHidden: (id) => {
      const hiddenIds = get('hiddenIds').slice()
      _pull(hiddenIds, id)
      actions.setHiddenIds(hiddenIds)
      actions.setServerCuration()
    },
    demoteAll: () => {
      actions.setUpdating(true)
      actions.setPromotedIds([])
      actions.setServerCuration()
    },
    restoreAll: () => {
      actions.setHiddenIds([])
      actions.setServerCuration()
    },
    saveCurationQueries: (inputVisible, index) => {
      actions.setQueryItemInputVisibility(inputVisible, index)
      actions.setServerCuration(true)
    },
    getQueryCounts: () => {
      const queries = [] as IObject[]
      get('curationQueries').forEach(query => {
        queries.push({
          query: query.text,
          page: {
            current: 1,
            size: 0
          }
        })
      })
      const route = routes.locoMocoEngineMultiSearchPath({
        engine_slug: get('engineName'),
        format: 'json'
      })

      http.post(route, { queries })
        .then(({ data }) => actions.updateQueryCounts(data))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    updateQueryCounts: (results) => {
      const curationQueries = _cloneDeep(get('curationQueries'))
      curationQueries.forEach((query, i) => query.count = results[i].meta.page.total_results)
      actions.updateCurationQueries(curationQueries)
    },
    launchManageQueries: () => {
      actions.showManageQueries()
      actions.getQueryCounts()
    },
    setNewCurationQueryItemInputVisibility: (inputVisible, index) => {
      actions.setQueryItemInputVisibility(inputVisible, index)
      if (inputVisible) {
        actions.getQueryCounts()
        actions.setNewQueryCachedText(index)
      }
    },
    setActiveQuery: (query) => {
      const curation = _cloneDeep(get('curation'))
      curation.queries.splice(curation.queries.indexOf(query), 1)
      curation.queries.unshift(query)
      actions.updateCurationAndQueries(curation)
      actions.setServerCuration(true)
    },
    search: _debounce(
      () => {
        // If this fails, we're not displaying any flash messages because
        // it is unlikely the user can take any actions to remedy the problem
        http.post(routes.searchSettingsSearchLocoMocoEnginePath({
          format: 'json',
          query: get('searchQuery'),
          slug: get('engineName')
        }))
          .then(({ data }) => actions.setSearchResults(data.results))

      },
      100
    ),
    updateSearchQuery: (query) => {
      actions.setSearchQuery(query)
      actions.search()
    }
  })
})

const getIds = (documents) => documents.map(document => document.id)

const getQueriesObject = (queries: string[]) => ([
  ...queries.map(query => ({
    text: query,
    cachedText: query,
    hideInput: query.length > 0
  }))
])

const resetCurationQueries = (queries: ICurationQueryItem[]) => {
  return queries
    .map(query => ({
      text: query.cachedText,
      cachedText: query.cachedText,
      count: query.count,
      hideInput: query.cachedText && query.cachedText.length > 0
    }))
    .filter(query => query.text && query.text.length > 0)
}

const filterQueries = (queries: ICurationQueryItem[]) => queries
  .filter(query => query.text.length > 0)
  .map(query => query.text)
