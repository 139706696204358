import React from 'react'

import { Link } from 'react-router-dom'

import StuiIcon from 'stui/Icon'

import {
  EuiButton
} from '@elastic/eui'

import { ENGINE_NEW_PATH } from 'app_search/utils/routePaths'
import useAbilities from 'app_search/utils/useAbilities'

const SampleEngineBanner: React.SFC<{}> = () => {
  const { canManageEngines } = useAbilities()
  return (
    <div className="sample-engine-banner" data-test-subj="SampleEngineBanner">
      <div className="sample-engine-banner__content">
        <StuiIcon name="info-fill" />
        <p>Welcome to the National Parks sample engine. Explore the functionality of Elastic App Search using example data.</p>
      </div>
      {canManageEngines && <div className="sample-engine-banner__action">
        <p>Ready to bring your own data?</p>
        <EuiButton size="s" color="secondary" fill={true}>
          <Link to={ENGINE_NEW_PATH}>Create an engine</Link>
        </EuiButton>
      </div>}
    </div>
  )
}

export default SampleEngineBanner
