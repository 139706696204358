import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'

import { ENGINE_SEARCH_SETTINGS_PATH, getEngineRoute, getReindexJobRoute } from 'app_search/utils/routePaths'

import { Loading } from 'app_search/components/Loading'
import routes from 'app_search/routes'
import useAbilities from 'app_search/utils/useAbilities'

import {
  EuiButton,
  EuiCallOut,
  EuiSpacer
} from '@elastic/eui'

import StuiEmptyState from 'stui/EmptyState'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'
import StuiSubHeading from 'stui/SubHeading'

import IndexingStatus from 'shared/components/IndexingStatus'
import { SchemaAddFieldModal } from 'shared/components/Schema'

import SchemaFields from './SchemaFields'
import { SchemaLogic } from './SchemaLogic'

import { IIndexingStatusProps, IObject } from 'shared/types'

interface ISchemaProps {
  actions: {
    initializeSchema()
    onIndexingComplete(numDocumentsWithErrors: number)
    addNewField(fieldName: string, newFieldType: string)
    updateFields()
    openAddFieldModal()
    closeAddFieldModal()
    resetSchemaState()
  },
  engineName: string
  schema: IObject
  mostRecentIndexJob: IIndexingStatusProps
  unsearchedUnconfirmedFields: boolean
  unconfirmedFields: string[]
  flashMessages: IStuiFlashMessagesProps
  newFieldType: string
  activeSchema: IObject
  serverSchema: IObject
  formUnchanged: boolean
  showAddFieldModal: boolean
  addFieldFormErrors?: string[]
  dataLoading: boolean
}

const Schema: React.SFC<ISchemaProps> = ({
  actions: {
    initializeSchema,
    onIndexingComplete,
    addNewField,
    updateFields,
    openAddFieldModal,
    closeAddFieldModal,
    resetSchemaState
  },
  engineName,
  flashMessages,
  activeSchema,
  formUnchanged,
  mostRecentIndexJob,
  unsearchedUnconfirmedFields,
  unconfirmedFields,
  showAddFieldModal,
  addFieldFormErrors,
  dataLoading
}) => {
  const { canManageEngineSchema } = useAbilities()

  useEffect(() => {
    initializeSchema()
    return resetSchemaState
  }, [])

  if (dataLoading) { return <Loading /> }

  const hasSchemaFields = Object.keys(activeSchema).length > 0
  const { isActive, hasErrors } = mostRecentIndexJob
  const unsearchedUnconfirmedFieldsCallout = (
    <EuiCallOut
      color="warning"
      iconType="iInCircle"
      title="Recently added fields are not being searched by default."
    >
      <p>Confirm the new field types here or update your <Link to={getEngineRoute(ENGINE_SEARCH_SETTINGS_PATH, engineName)}>search settings</Link> to include them.</p>
    </EuiCallOut>
  )

  const changeTypesButton = <EuiButton
    disabled={(formUnchanged && unconfirmedFields.length === 0) || isActive}
    onClick={updateFields}
    data-test-subj="SchemaUpdateTypesButton"
  >
    Update Types
  </EuiButton>

  const addFieldButton = <EuiButton
    onClick={openAddFieldModal}
    fill={true}
    color="secondary"
    data-test-subj="SchemaCreateSchemaFieldButton"
  >
    Create a Schema Field
  </EuiButton>

  const viewActions = canManageEngineSchema && hasSchemaFields && (
    <>
      {addFieldButton}
      {changeTypesButton}
    </>
  )
  return (
    <div data-test-subj="Schema">
      <StuiHeader contained={true} viewHeader={true} actions={viewActions} isSticky={true}>
        <StuiHeading type="view">Manage Engine Schema</StuiHeading>
        <StuiSubHeading>Add new fields or change the types of existing ones.</StuiSubHeading>
      </StuiHeader>
      <StuiMain>
        {unsearchedUnconfirmedFields && unsearchedUnconfirmedFieldsCallout}
        <StuiFlashMessages {...flashMessages} />
        {(isActive || hasErrors) && (
          <IndexingStatus
            itemId={engineName}
            viewLinkPath={getReindexJobRoute(engineName, mostRecentIndexJob.activeReindexJobId)}
            getStatusPath={routes.statusLocoMocoEngineReindexJobPath}
            getItemDetailPath={routes.detailsLocoMocoEnginePath}
            onComplete={onIndexingComplete}
            {...mostRecentIndexJob}
          />
        )}
        <EuiSpacer />
        {hasSchemaFields
          ? <SchemaFields disabled={!canManageEngineSchema} />
          : <StuiEmptyState icon="settings" title="Engine does not have a schema" description="A schema is created for you once you index some documents. Click below to create schema fields in advance." action={addFieldButton} />}

        {showAddFieldModal && <SchemaAddFieldModal
          disableForm={!!isActive && mostRecentIndexJob.percentageComplete < 100}
          addNewField={addNewField}
          addFieldFormErrors={addFieldFormErrors}
          closeAddFieldModal={closeAddFieldModal}
        />}
      </StuiMain>
    </div>
  )
}

export default SchemaLogic(Schema)
