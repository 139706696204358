import React from 'react'
import { CardElement, injectStripe } from 'react-stripe-elements'

import { BillingLogic } from 'app_search/logic/BillingLogic'
import { IObject, ISupportedLanguages } from 'app_search/types'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSelect,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

interface IUpdatePaymentMethodModalProps {
  accountBillingMeta: IObject
  selectedPlan: IObject |  null
  stripe?: any
  address1: string
  address2: string
  city: string
  state: string
  postal_code: string
  country: string
  countryOptions: ISupportedLanguages[]
  company: string
  domain: string
  selectedBusiness: string
  selectedEmployeesNumber: string
  businessOptions: ISupportedLanguages[]
  employeesNumberOptions: ISupportedLanguages[]
  actions: {
    setAddress1(address1: string)
    setAddress2(address2: string)
    setCity(city: string)
    setState(state: string)
    setPostalCode(postalCode: string)
    setCountry(country: string)
    setCompany(company: string)
    setDomain(domain: string)
    setSelectedBusiness(selectedBusiness: string)
    setSelectedEmployeesNumber(selectedEmployeesNumber: string)
  }
  closeUpdatePaymentMethodModal()
  onSavePaymentMethod(stripeToken: string)
}

interface IUpdatePaymentMethodModalState {
  submitting: boolean
  stripeError: string
  card?: any
}

class UpdatePaymentMethodModal extends React.Component<IUpdatePaymentMethodModalProps, IUpdatePaymentMethodModalState> {
  readonly state: IUpdatePaymentMethodModalState = {
    submitting: false,
    stripeError: ''
  }

  _card?: any | null

  onCardChange = (event) => {
    if (event.error) {
      this.setState({ stripeError:  event.error.message })
    } else {
      this.setState({ stripeError: '' })
    }
  }

  onClose = () => {
    const {
      closeUpdatePaymentMethodModal
    } = this.props

    if (this._card) {
      this._card.clear()
    }

    this.setState({ stripeError: '' })

    closeUpdatePaymentMethodModal()
  }

  render () {
    const { selectedPlan, address1, address2, city, state, postal_code, country, countryOptions, company, domain, selectedBusiness, selectedEmployeesNumber, businessOptions, employeesNumberOptions } = this.props
    const { stripeError, submitting } = this.state
    const { setAddress1, setAddress2, setCity, setState, setPostalCode, setCountry, setCompany, setDomain, setSelectedBusiness, setSelectedEmployeesNumber } = this.props.actions

    const onSubmitStripePaymentForm = (event: any) => {
      const {
        stripe,
        onSavePaymentMethod
      } = this.props

      event.preventDefault()
      this.setState({ submitting: true })

      stripe.createToken().then((result: any) => {
        if (result.error) {
          this.setState({ stripeError: result.error.message, submitting: false })
        } else {
          this.setState({ submitting: false })
          onSavePaymentMethod(result.token.id)
        }
      })
    }

    return <EuiOverlayMask>
      <form id="stripe_payment_form" onSubmit={onSubmitStripePaymentForm}>
        <EuiModal onClose={this.onClose} data-test-subj="UpdatePaymentMethodModal">
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              {selectedPlan ? `You chose the ${selectedPlan.description} plan — $${selectedPlan.price}` : 'Update payment method'}
            </EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiText>
              <p>Please enter your payment information</p>
            </EuiText>
            <EuiSpacer size="l" />
            {selectedPlan && <input type="hidden" id="plan_name" name="plan[name]" value={selectedPlan.name} />}
            <CardElement id="card_element" hidePostalCode={true} onChange={this.onCardChange} onReady={el => this._card = el}/>
            { stripeError && <>
              <EuiSpacer size="s" />
              <EuiCallOut
                title={stripeError}
                color="danger"
                iconType="cross"
                size="s"
              />
            </> }
            <EuiSpacer size="l" />
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="Address 1" fullWidth={true}>
                  <EuiFieldText
                    required={true}
                    fullWidth={true}
                    name="firmographics-address1"
                    data-test-subj="UpdatePaymentMethodModalFirmographicsAddress1"
                    value={address1}
                    onChange={e => setAddress1(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label="Address 2" fullWidth={true}>
                  <EuiFieldText
                    fullWidth={true}
                    name="firmographics-address2"
                    data-test-subj="UpdatePaymentMethodModalFirmographicsAddress2"
                    value={address2}
                    onChange={e => setAddress2(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="City" fullWidth={true}>
                  <EuiFieldText
                    required={true}
                    fullWidth={true}
                    name="firmographics-city"
                    data-test-subj="UpdatePaymentMethodModalFirmographicsCity"
                    value={city}
                    onChange={e => setCity(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label="State / Province / Region" fullWidth={true}>
                  <EuiFieldText
                    required={true}
                    fullWidth={true}
                    name="firmographics-state"
                    data-test-subj="UpdatePaymentMethodModalFirmographicsState"
                    value={state}
                    onChange={e => setState(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="Zip / Postal Code" fullWidth={true}>
                  <EuiFieldText
                    required={true}
                    fullWidth={true}
                    name="firmographics-postal-code"
                    data-test-subj="UpdatePaymentMethodModalFirmographicsPostalCode"
                    value={postal_code}
                    onChange={e => setPostalCode(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label="Country" fullWidth={true}>
                  <EuiSelect
                    required={true}
                    fullWidth={true}
                    name="firmographics-country"
                    data-test-subj="UpdatePaymentMethodModalFirmographicsCountry"
                    value={country}
                    options={countryOptions}
                    onChange={e => setCountry(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="l" />
            <EuiText>
              <p>Please complete your account information</p>
            </EuiText>
            <EuiSpacer size="l" />
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="Company Name" fullWidth={true}>
                  <EuiFieldText
                    required={true}
                    fullWidth={true}
                    name="firmographics-company"
                    data-test-subj="UpdatePaymentMethodModalFirmographicsCompany"
                    value={company}
                    onChange={e => setCompany(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label="Company Website" fullWidth={true}>
                  <EuiFieldText
                    required={true}
                    fullWidth={true}
                    name="firmographics-domain"
                    data-test-subj="UpdatePaymentMethodModalFirmographicsDomain"
                    value={domain}
                    onChange={e => setDomain(e.target.value)}
                    placeholder="http://example.com"
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="Select Your Industry" fullWidth={true}>
                  <EuiSelect
                    required={true}
                    fullWidth={true}
                    name="firmographics-industry"
                    data-test-subj="UpdatePaymentMethodModalFirmographicsIndustry"
                    value={selectedBusiness}
                    options={businessOptions}
                    onChange={e => setSelectedBusiness(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label="Select Your Number of Employees" fullWidth={true}>
                  <EuiSelect
                    required={true}
                    fullWidth={true}
                    name="firmographics-employees"
                    data-test-subj="UpdatePaymentMethodModalFirmographicsEmployees"
                    value={selectedEmployeesNumber}
                    options={employeesNumberOptions}
                    onChange={e => setSelectedEmployeesNumber(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={this.onClose}>Cancel</EuiButtonEmpty>
            <EuiButton
              disabled={!!stripeError || submitting}
              fill={true}
              color="primary"
              id="card_submit"
              type="submit"
              data-test-subj="UpdatePaymentMethodModalSubmitButton"
            >
              Save Payment Method
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </form>
    </EuiOverlayMask>
  }
}

export default BillingLogic(injectStripe(UpdatePaymentMethodModal))
