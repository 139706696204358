import React, { useEffect } from 'react'

import { IApiLog, IApiLogsMeta, IEngineDetails, IObject } from 'app_search/types'

import { ApiLogsLogic } from './ApiLogsLogic'

import { EuiSpacer } from '@elastic/eui'

import StuiEmptyState from 'stui/EmptyState'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiPagination from 'stui/Pagination'
import {
  StuiTable,
  StuiTableBody,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'
import StuiUpdatePrompt from 'stui/UpdatePrompt'

import ApiLogsRow from './ApiLogsRow'

interface IApiLogsTableProps {
  actions: IObject
  engine: IEngineDetails
  preLoadedEngine?: IEngineDetails
  hidePagination?: boolean
  headerAction?: React.ReactNode
  logs: IApiLog[]
  logsMeta: IApiLogsMeta
  polledLogs?: IApiLog[]
  polledLogsMeta?: IApiLogsMeta
  hasNewData: boolean
  timeoutId: number
  pollingCancelTokenSource: any
  setActiveApiLog(log: IApiLog)
}

const ApiLogsTable: React.SFC<IApiLogsTableProps> = ({
  actions: {
    handlePageClick,
    updateLogsView,
    setInitialData,
    pollForApiDocs
  },
  engine: { name: engineName },
  logs,
  logsMeta,
  hasNewData,
  hidePagination,
  headerAction,
  setActiveApiLog,
  preLoadedEngine
}) => {
  useEffect(() => {
    if (!engineName) {
      setInitialData(preLoadedEngine, {})
    }
    pollForApiDocs()
  }, [])

  const handleRefreshClick = e => {
    e.preventDefault()
    updateLogsView()
  }

  const logsUnavailable = <StuiEmptyState
    icon="logs-fill"
    title="No recent logs"
    description="Check back after you've performed some API calls"
    compact={true}
  />

  const logsTable = <>
    <StuiHeader contained={true} key="header" actions={headerAction}>
      <StuiHeading type="title">
        Recent API Events
          {hasNewData && <StuiUpdatePrompt
            text="New events have been logged."
            linkText="Refresh"
            onLinkClick={handleRefreshClick}
          />}
      </StuiHeading>
    </StuiHeader>
    <EuiSpacer />
    <StuiTable key="logsTable">
      <StuiTableHead>
        <StuiTableRow>
          <StuiTableHeadCell>Method</StuiTableHeadCell>
          <StuiTableHeadCell>Time</StuiTableHeadCell>
          <StuiTableHeadCell>Endpoint</StuiTableHeadCell>
          <StuiTableHeadCell>Status</StuiTableHeadCell>
          <StuiTableHeadCell />
        </StuiTableRow>
      </StuiTableHead>
      <StuiTableBody>
        {logs.map((log, index) => <ApiLogsRow setActiveApiLog={setActiveApiLog} key={index} log={log} />)}
      </StuiTableBody>
    </StuiTable>
    <EuiSpacer size="xxl" />
    {(!hidePagination && logsMeta.page.total_pages > 1) && <StuiPagination
      key="pager"
      metaPage={logsMeta.page}
      handlePageClick={handlePageClick}
    />}
  </>

  return (
    logs.length > 0 ? logsTable : logsUnavailable
  )
}

export default ApiLogsLogic(ApiLogsTable)
