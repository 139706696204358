import { formatRoute } from 'react-router-named-routes'

export const BASENAME = '/as'
export const ROOT_PATH = '/'
export const NOT_FOUND = '/error'
export const ONBOARDING_PATH = '/onboarding'
export const CREDENTIALS_PATH = '/credentials'
export const EXTEND_TRIAL_PATH = '/extend-trial'
export const TRIAL_OFFBOARDING_PATH = '/free-trial-upgrade'

export const SETTINGS_PATH = '/settings/account'
export const BILLING_PATH = '/settings/billing'
export const INVOICE_PATH = `${BILLING_PATH}/invoices/:invoiceId`

export const USERS_PATH = '/users'
export const USER_PATH = `${USERS_PATH}/:roleId/edit`

export const ROLE_MAPPINGS_PATH = '/role-mappings'
export const ROLE_MAPPING_PATH = '/role-mappings/:roleId/edit'
export const ROLE_MAPPING_NEW_PATH = '/role-mappings/new'

export const ENGINES_PATH = '/engines'
export const ENGINE_NEW_PATH = '/engines/new'
export const ENGINE_PATH = '/engines/:engineName'
export const SAMPLE_ENGINE_PATH = '/engines/national-parks-demo'

export const ENGINE_ANALYTICS_PATH = `${ENGINE_PATH}/analytics`
export const ENGINE_ANALYTICS_TOP_QUERIES_PATH = `${ENGINE_ANALYTICS_PATH}/top-queries`
export const ENGINE_ANALYTICS_TOP_QUERIES_NO_CLICKS_PATH = `${ENGINE_ANALYTICS_PATH}/top-queries-no-clicks`
export const ENGINE_ANALYTICS_TOP_QUERIES_NO_RESULTS_PATH = `${ENGINE_ANALYTICS_PATH}/top-queries-no-results`
export const ENGINE_ANALYTICS_TOP_QUERIES_WITH_CLICKS_PATH = `${ENGINE_ANALYTICS_PATH}/top-queries-with-clicks`
export const ENGINE_ANALYTICS_RECENT_QUERIES_PATH = `${ENGINE_ANALYTICS_PATH}/recent-queries`
export const ENGINE_ANALYTICS_QUERY_DETAIL_PATH = `${ENGINE_ANALYTICS_PATH}/query-detail/:query`

export const ENGINE_QUERY_TESTER_PATH = `${ENGINE_PATH}/query-tester`
export const ENGINE_REFERENCE_APPLICATION_PATH = `${ENGINE_PATH}/reference_application/new`
export const ENGINE_DOCUMENTS_PATH = `${ENGINE_PATH}/documents`
export const ENGINE_DOCUMENT_DETAIL_PATH = `${ENGINE_DOCUMENTS_PATH}/:documentId`

export const ENGINE_SCHEMA_PATH = `${ENGINE_PATH}/schema/edit`
export const ENGINE_API_LOGS_PATH = `${ENGINE_PATH}/api-logs`
export const ENGINE_SYNONYMS_PATH = `${ENGINE_PATH}/synonyms`

export const ENGINE_CURATIONS_PATH = `${ENGINE_PATH}/curations`
export const ENGINE_CURATIONS_NEW_PATH = `${ENGINE_CURATIONS_PATH}/new`
export const ENGINE_CURATIONS_EDIT_PATH = `${ENGINE_CURATIONS_PATH}/:curationId/edit`
export const ENGINE_CURATIONS_ADD_PATH = `${ENGINE_CURATIONS_PATH}/:curationId/add-manually`

export const ENGINE_RESULT_SETTINGS_PATH = `${ENGINE_PATH}/result-settings`
export const ENGINE_SEARCH_SETTINGS_PATH = `${ENGINE_PATH}/search-settings`
export const ENGINE_REINDEX_JOB_PATH = `${ENGINE_PATH}/reindex-job/:activeReindexJobId`

export const META_ENGINES_PATH = '/meta-engines'
export const META_ENGINE_NEW_PATH = `${META_ENGINES_PATH}/new`
export const META_ENGINE_PATH = `${ENGINE_PATH}` // for now, meta-engines are accessed on the same path as indexed engines
export const META_ENGINE_SOURCE_ENGINES_PATH = `${META_ENGINE_PATH}/engines`

export const getEngineRoute = (path, engineName) => formatRoute(path, { engineName })
export const getMetaEngineRoute = (engineName) => getEngineRoute(ENGINE_PATH, engineName)
export const getDocumentDetailRoute = (engineName, documentId) => formatRoute(ENGINE_DOCUMENT_DETAIL_PATH, { engineName, documentId })
export const getDocumentsRoute = (engineName) => formatRoute(ENGINE_DOCUMENTS_PATH, { engineName })
export const getQueryDetailRoute = (engineName, query) => formatRoute(ENGINE_ANALYTICS_QUERY_DETAIL_PATH, { engineName, query })
export const getCurationRoute = (engineName, curationId) => formatRoute(ENGINE_CURATIONS_EDIT_PATH, { engineName, curationId })
export const getCurationAddRoute = (engineName, curationId) => formatRoute(ENGINE_CURATIONS_ADD_PATH, { engineName, curationId })
export const getReindexJobRoute = (engineName, activeReindexJobId) => formatRoute(ENGINE_REINDEX_JOB_PATH, { engineName, activeReindexJobId })
export const getInvoiceRoute = (invoiceId) => formatRoute(INVOICE_PATH, { invoiceId })
export const getUserRoute = (roleId) => formatRoute(USER_PATH, { roleId })
export const getRoleMappingRoute = (roleId) => formatRoute(ROLE_MAPPING_PATH, { roleId })
