import React from 'react'

import { capitalize } from 'app_search/utils'

import { IBoost } from 'app_search/types'

import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFieldText,
  EuiFormRow,
  EuiSelect
} from '@elastic/eui'

import {
  StuiAccordionItem,
  StuiAccordionItemBody
} from 'stui/Accordion'
import StuiHeading from 'stui/Heading'

import BoostFooter from './BoostFooter'
import BoostHeader from './BoostHeader'

interface IBoostProps {
  name: string
  index: number
  boost: IBoost
  deleteBoost(name: string, index: number)
  updateBoostFactor(name: string, index: number, factor: number)
  addBoostValue(name: string, boostIndex: number)
  removeBoostValue(name: string, boostIndex: number, valueIndex: number)
  updateBoostValue(name: string, boostIndex: number, valueIndex: number, value: string | number)
  updateBoostSelectOption(name: string, boostIndex: number, optionType: string, value: string)
  updateBoostCenter(name: string, boostIndex: number, value: string | number)
}

const Boost: React.SFC<IBoostProps> = ({
  index,
  name,
  boost,
  deleteBoost,
  updateBoostFactor,
  addBoostValue,
  removeBoostValue,
  updateBoostValue,
  updateBoostSelectOption,
  updateBoostCenter
}) => {
  let headerSummary
  let values

  const { type } = boost

  if (type === 'value') {
    if (!boost.value) {
      values = ['']
    } else {
      values = Array.isArray(boost.value) ? boost.value : [boost.value]
    }
    headerSummary = values.join(',')
  } else {
    headerSummary = type === 'proximity' ? boost.function : `${boost.function || ''} ${boost.operation || ''}`
  }

  const getBoostBody = () => {
    switch (type) {
      case 'value':
        return (
          <div className="o-stui-repeatable-input-group">
            {values.map((value, valueIndex) => (
              <div key={valueIndex} className="o-stui-input-container o-stui-input-container--removable">
                <EuiFieldText
                  value={value}
                  fullWidth={true}
                  onChange={(e) => updateBoostValue(name, index, valueIndex, e.target.value)}
                  aria-label="Value name"
                  autoFocus={true}
                />
                <EuiButtonIcon
                  iconType="trash"
                  onClick={() => removeBoostValue(name, index, valueIndex)}
                  aria-label="Remove value"
                />
              </div>
            ))}
            <div className="o-stui-repeatable-input-group__actions">
              <EuiButtonEmpty size="s" onClick={() => addBoostValue(name, index)}>Add Value</EuiButtonEmpty>
            </div>
          </div>
        )
      case 'functional':
        boost.function = boost.function || 'logarithmic'
        boost.operation = boost.operation || 'multiply'
        return (
          <>
            <EuiFormRow label="Function" fullWidth={true}>
              <EuiSelect
                name={`function-${type}${index}`}
                options={[
                  { value: 'logarithmic', text: 'Logarithmic' },
                  { value: 'exponential', text: 'Exponential' },
                  { value: 'linear', text: 'Linear' }
                ]}
                value={boost.function}
                onChange={(e) => updateBoostSelectOption(name, index, 'function', e.target.value)}
                fullWidth={true}
              />
            </EuiFormRow>
            <EuiFormRow label="Operation" fullWidth={true}>
              <EuiSelect
                name={`operation-${type}${index}`}
                options={[
                  { value: 'multiply', text: 'Multiply' },
                  { value: 'add', text: 'Add' }
                ]}
                value={boost.operation}
                onChange={(e) => updateBoostSelectOption(name, index, 'operation', e.target.value)}
                fullWidth={true}
              />
            </EuiFormRow>
          </>
        )
      case 'proximity':
        let boostCenter = ''
        if (typeof boost.center === 'string' || typeof boost.center === 'number') {
          boostCenter = boost.center.toString()
        }
        boost.function = boost.function || 'gaussian'
        return (
          <>
            <EuiFormRow label="Function" fullWidth={true}>
              <EuiSelect
                name={`proximity-${type}${index}`}
                options={[
                  { value: 'gaussian', text: 'Gaussian' },
                  { value: 'exponential', text: 'Exponential' },
                  { value: 'linear', text: 'Linear' }
                ]}
                value={boost.function}
                onChange={(e) => updateBoostSelectOption(name, index, 'function', e.target.value)}
                fullWidth={true}
              />
            </EuiFormRow>
            <EuiFormRow
              label="Center"
              fullWidth={true}
            >
              <EuiFieldText
                defaultValue={boostCenter}
                onChange={(e) => updateBoostCenter(name, index, e.target.value)}
                fullWidth={true}
              />
            </EuiFormRow>
          </>
        )
    }
  }

  return (
    <StuiAccordionItem expanded={boost.newBoost} className="c-stui-boost-container">
      <BoostHeader
        type={type}
        summary={headerSummary}
        score={boost.factor}
      />
      <StuiAccordionItemBody className="c-stui-boost-container__body">
        <div className="o-stui-panel-section">
          <div className="o-stui-panel-section__header">
            <StuiHeading type="title" className="o-stui-panel-section__title">
              {capitalize(type)}
            </StuiHeading>
          </div>
          <div className="o-stui-panel-section__body">
            {getBoostBody()}
          </div>
        </div>
        <BoostFooter
          name={name}
          index={index}
          score={boost.factor}
          onChange={updateBoostFactor}
          deleteBoost={deleteBoost}
        />
      </StuiAccordionItemBody>
    </StuiAccordionItem>
  )
}

export default Boost
