import React from 'react'

import classNames from 'classnames'

interface IStuiTableBodyProps {
  children: React.ReactNode
  className?: string
  reference?: any
}

const StuiTableBody: React.SFC<IStuiTableBodyProps> = ({
  children,
  className,
  reference,
  ...otherProps
}) => (
  <tbody ref={reference} {...otherProps} className={classNames('c-stui-table-body', className)}>
    {children}
  </tbody>
)

export default StuiTableBody
