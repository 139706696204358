import React from 'react'

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon
} from '@elastic/eui'

const ResultSettingsCallout: React.SFC<{}> = () => (
  <div className="euiCallout euiCallout--primary">
    <EuiFlexGroup
      alignItems="center"
      responsive={false}
    >
      <EuiFlexItem>
        <div className="euiCallOutHeader">
          <EuiIcon type="alert" className="euiCallOutHeader__icon" />
          <span className="euiCallOutHeader__title">Guide Available</span>
        </div>
        <p>These settings impact query performance and all search results. If you're unfamiliar, review the documentation.</p>
      </EuiFlexItem>
      <EuiFlexItem
        grow={false}
      >
        <EuiButton
          href="https://swiftype.com/documentation/app-search/guides/result-settings"
          color="primary"
          target="_blank"
          fill={true}
        >
          Read the documentation
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  </div>
)

export default ResultSettingsCallout
