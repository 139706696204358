import React from 'react'

import { Route, withRouter } from 'react-router-dom'

import { CurationsLogic } from './CurationsLogic'

import {
  ENGINE_CURATIONS_ADD_PATH,
  ENGINE_CURATIONS_EDIT_PATH,
  ENGINE_CURATIONS_NEW_PATH,
  ENGINE_CURATIONS_PATH
} from 'app_search/utils/routePaths'

import { IObject } from 'app_search/types'

import CurationAddManually from './CurationAddManually'
import CurationDetail from './CurationDetail'
import Curations from './Curations'
import CurationsNew from './CurationsNew'

interface ICurationRouterProps {
  history: IObject
  actions: {
    removeFlashMessages()
  }
}

const CurationsRouter: React.SFC<ICurationRouterProps> = props => (
  <div>
    <Route
      exact={true}
      path={ENGINE_CURATIONS_PATH}
      render={match => <Curations match={match} {...props} />}
    />
    <Route
      exact={true}
      path={ENGINE_CURATIONS_NEW_PATH}
      render={match => <CurationsNew match={match} {...props} />}
    />
    <Route
      exact={true}
      path={ENGINE_CURATIONS_EDIT_PATH}
      render={match => <CurationDetail match={match} {...props} />}
    />
    <Route
      exact={true}
      path={ENGINE_CURATIONS_ADD_PATH}
      render={match => <CurationAddManually match={match} {...props} />}
    />
  </div>
)

export default withRouter(CurationsLogic(CurationsRouter))
