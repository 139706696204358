import React, { useEffect } from 'react'

import { Link, withRouter } from 'react-router-dom'

import { Loading } from 'app_search/components/Loading'
import { ENGINE_NEW_PATH, ENGINE_PATH, getEngineRoute } from 'app_search/utils/routePaths'

import _slice from 'lodash/slice'
import moment from 'moment'

import useAbilities from 'app_search/utils/useAbilities'

import {
  EuiButton,
  EuiFieldSearch,
  EuiHealth,
  EuiSpacer
} from '@elastic/eui'

import StuiEmptyState from 'stui/EmptyState'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiIcon from 'stui/Icon'
import StuiMain from 'stui/Main'
import StuiPagination from 'stui/Pagination'
import {
  StuiTable,
  StuiTableBody,
  StuiTableBodyCell,
  StuiTableControls,
  StuiTableControlsGroup,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'
import StuiTruncatedContent from 'stui/TruncatedContent'
import { EnginesLogic } from './EnginesLogic'

import {
  IAccountProps,
  ICurrentUser,
  IEngineDetails,
  IMeta,
  IObject
} from 'app_search/types'

interface IEnginesProps {
  actions: {
    initializeEnginesData(history: IObject)
    deleteEngine(name: string)
    setDisplayedPage(page: number)
    setFilterInputValue(value: string)
    resetEnginesData()
  }
  history: IObject
  dataLoading: boolean
  currentUser: ICurrentUser
  account: IAccountProps
  engines: IEngineDetails[]
  engineMeta: IMeta
  displayedPage: number
  filterInputValue: string
  flashMessages: IStuiFlashMessagesProps
}

function engineLabel(sample, language) {
  if (sample) {
    return <div><EuiHealth className="engine-type" color="primary">Sample Engine</EuiHealth></div>
  }
  return <div className="engine-type">{language}</div>
}

const Engines: React.SFC<IEnginesProps> = ({
  actions: {
    deleteEngine,
    setDisplayedPage,
    setFilterInputValue,
    initializeEnginesData,
    resetEnginesData
  },
  history,
  dataLoading,
  currentUser: { email },
  account: { engineLimit },
  displayedPage,
  engines,
  engineMeta,
  filterInputValue,
  flashMessages
}) => {
  const { canManageEngines } = useAbilities()

  useEffect(() => {
    initializeEnginesData(history)
    return () => resetEnginesData()
  }, [])

  if (dataLoading) { return (<Loading />) }

  const createEngineButton = canManageEngines && (
    <EuiButton color="secondary" data-test-subj="EnginesListCreateButton" fill={true}>
      <Link to={ENGINE_NEW_PATH}>Create an Engine</Link>
    </EuiButton>)
  const emailLink = `mailto:support@swiftype.com?subject=${email}%20would%20like%20more%20App%20Search%20engines`
  const moreEnginesButton = <EuiButton href={emailLink}>Need more engines?</EuiButton>

  const enginesOffset = (displayedPage - 1) * engineMeta.page.size
  // filter out meta-engines, as they have their own separate view
  const engineList = engines.filter(engine => !engine.isMeta)
  const displayedEngines = filterInputValue === ''
    ? _slice(engineList, enginesOffset, enginesOffset + engineMeta.page.size)
    : engineList.filter((engine) => engine.name.includes(filterInputValue))

  const handleFilterValueChange = e => setFilterInputValue(e.target.value)
  const handlePageClick = page => setDisplayedPage(page)

  const deleteEngineButton = name => {
    if (!canManageEngines) {
      return null
    }
    return <a
      className="o-stui-table-action o-stui-delete-action"
      data-test-subj="DeleteEngineButton"
      onClick={() => deleteEngine(name)}
    >
      <StuiIcon name="delete" />
    </a>
  }

  return (
    <div data-test-subj="Engines">
      <StuiHeader key="header" contained={true} viewHeader={true}>
        <StuiHeading type="view">Engines</StuiHeading>
      </StuiHeader>
      <StuiMain key="content">
        <StuiFlashMessages {...flashMessages} />
        {engineList.length > 0
          ? (
            <div id="engines-list">
              <StuiTableControls>
                <StuiTableControlsGroup>
                  {(engineList.length > 10) &&
                    <EuiFieldSearch
                      value={filterInputValue}
                      onChange={handleFilterValueChange}
                      autoFocus={true}
                      placeholder="Filter engines..."
                    />
                  }
                </StuiTableControlsGroup>
                <StuiTableControlsGroup>
                  {(engineLimit && engines.length >= engineLimit) ? moreEnginesButton : createEngineButton}
                </StuiTableControlsGroup>
              </StuiTableControls>
              <StuiTable>
                <StuiTableHead>
                  <StuiTableRow>
                    <StuiTableHeadCell>Name</StuiTableHeadCell>
                    <StuiTableHeadCell>Created</StuiTableHeadCell>
                    <StuiTableHeadCell>Documents</StuiTableHeadCell>
                    <StuiTableHeadCell>Fields</StuiTableHeadCell>
                    <StuiTableHeadCell />
                  </StuiTableRow>
                </StuiTableHead>

                <StuiTableBody className="list">
                  {displayedEngines.map((engine, index) => {
                    const { name, created_at: created, document_count: docsCount, field_count: fieldCount, language, sample } = engine
                    const engineType = engineLabel(sample, language)

                    return (
                      <StuiTableRow key={index}>
                        <StuiTableBodyCell>
                          <div>
                            <strong>
                              <Link className="engine-url" to={getEngineRoute(ENGINE_PATH, name)}>
                                <StuiTruncatedContent content={name} length={32} />
                              </Link>
                            </strong>
                            {engineType}
                          </div>
                        </StuiTableBodyCell>
                        <StuiTableBodyCell>{moment(created).format('MMMM D, YYYY')}</StuiTableBodyCell>
                        <StuiTableBodyCell>{sample && docsCount === 0 ? <>&mdash;</> : docsCount}</StuiTableBodyCell>
                        <StuiTableBodyCell>{sample && docsCount === 0 ? <>&mdash;</> : fieldCount}</StuiTableBodyCell>
                        <StuiTableBodyCell>
                          <Link className="o-stui-table-action" data-test-subj="EnginesListManageButton" to={getEngineRoute(ENGINE_PATH, name)}>
                            Manage
                          </Link>
                          {deleteEngineButton(name)}
                        </StuiTableBodyCell>
                      </StuiTableRow>
                    )
                  })}
                </StuiTableBody>
              </StuiTable>
              <EuiSpacer />
              {engineList.length > engineMeta.page.size && filterInputValue === '' && <StuiPagination
                metaPage={Object.assign({}, engineMeta.page, { current: displayedPage })}
                handlePageClick={handlePageClick}
              />}
            </div>
          ) : (
            <StuiEmptyState
              title="You have no Engines!"
              description={canManageEngines ? 'Create one to explore all that App Search has to offer.' : ''}
              action={createEngineButton}
            />
          )
        }
      </StuiMain>
    </div>
  )
}

export default withRouter(EnginesLogic(Engines))
