import React from 'react'

import {
  EuiButtonEmpty,
  EuiCallOut,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer
} from '@elastic/eui'

import DocumentCreation from './DocumentCreation'
import DocumentCreationButtons from './DocumentCreationButtons'

import { IEngine } from 'app_search/types'

interface IDocumentCreationPopoverProps {
  engine: IEngine
  handleClose()
}

interface IDocumentCreationPopoverState {
  showDocumentCreationButtons: boolean
  showDocumentCreationModal: boolean
  creationMode: string
}

class DocumentCreationPopover extends React.Component<IDocumentCreationPopoverProps, IDocumentCreationPopoverState> {
  readonly state: IDocumentCreationPopoverState = {
    showDocumentCreationButtons: true,
    showDocumentCreationModal: false,
    creationMode: 'manual'
  }

  render() {
    const { engine, handleClose } = this.props
    const { showDocumentCreationButtons, showDocumentCreationModal, creationMode } = this.state

    return (<>
      {showDocumentCreationButtons &&
        <EuiOverlayMask>
          <EuiModal onClose={handleClose}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Add new documents to <strong>&nbsp;{engine.name}</strong></EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <p>There are three ways to send documents to your Engine for indexing. You can paste raw JSON, upload a <code>.json</code> file, or <code>POST</code> to the <a target="_blank" href="https://www.swiftype.com/documentation/app-search/guides/indexing-documents">Documents API</a> endpoint.</p>
              <p>Click on your choice below or see <strong>Indexing by API</strong>.</p>
              <EuiSpacer />
              <DocumentCreationButtons openDocumentCreation={this.openDocumentCreation} />
              <EuiSpacer />
              <EuiCallOut title="Indexing by API" iconType="iInCircle">
                <p>The <a target="_blank" href="https://www.swiftype.com/documentation/app-search/guides/indexing-documents">Documents API</a> can be used to add new documents to your Engine, update documents, retrieve documents by id, and delete documents. There are a variety of <a target="_blank" href="https://swiftype.com/documentation/app-search/clients/api">client libraries</a> to help you get started.</p>
              </EuiCallOut>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButtonEmpty onClick={handleClose}>Cancel</EuiButtonEmpty>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      }
      {showDocumentCreationModal &&
        <DocumentCreation
          engineName={engine.name}
          toggleDocumentCreation={handleClose}
          creationMode={creationMode}
          onSuccess={handleClose}
        />}
    </>)
  }
  private openDocumentCreation = (creationMode) => {
    this.setState({
      showDocumentCreationButtons: false,
      showDocumentCreationModal: true,
      creationMode
    })
  }
}

export default DocumentCreationPopover
