import React from 'react'

import { IObject } from 'app_search/types'

declare let Stripe: any | null // `stripe` is a variable added to the global/window scope by the Stripe library using the `withStripe` HOC below
function withStripe(WrappedComponent) {
  return class extends React.Component<{}, { Stripe: any | null }> {
    constructor(props: IObject) {
      super(props)
      if (document.querySelector('script[src="https://js.stripe.com/v3/"]')) {
        this.state = {
          Stripe
        }
      } else {
        this.state = {
          Stripe: null
        }
        const stripeScriptTag = document.createElement('script')
        stripeScriptTag.setAttribute('src', 'https://js.stripe.com/v3/')
        stripeScriptTag.onload = this.stripeLoaded.bind(this)
        document.head.appendChild(stripeScriptTag)
      }
    }

    stripeLoaded() {
      this.setState({
        Stripe // this comes from the `declare` statement above
      })
    }

    render() {
      return <WrappedComponent Stripe={this.state.Stripe} {...this.props} />
    }
  }
}

export { withStripe }
