import React, { useEffect, useState } from 'react'

import { Redirect, Route, Switch, withRouter } from 'react-router-dom'

import {
  ENGINE_ANALYTICS_PATH,
  ENGINE_API_LOGS_PATH,
  ENGINE_CURATIONS_PATH,
  ENGINE_DOCUMENTS_PATH,
  ENGINE_PATH,
  ENGINE_QUERY_TESTER_PATH,
  ENGINE_REFERENCE_APPLICATION_PATH,
  ENGINE_REINDEX_JOB_PATH,
  ENGINE_RESULT_SETTINGS_PATH,
  ENGINE_SCHEMA_PATH,
  ENGINE_SEARCH_SETTINGS_PATH,
  ENGINE_SYNONYMS_PATH,
  ENGINES_PATH,
  META_ENGINE_SOURCE_ENGINES_PATH
} from 'app_search/utils/routePaths'
import useAbilities from 'app_search/utils/useAbilities'

import AnalyticsRouter from 'app_search/Analytics'
import ApiLogs from 'app_search/ApiLogs'
import CurationsRouter from 'app_search/Curations'
import DocumentsRouter from 'app_search/Documents'
import EngineOverview from 'app_search/EngineOverview'
import MetaEngineSourceEngines from 'app_search/MetaEngineSourceEngines'
import QueryTester from 'app_search/QueryTester'
import ReferenceApplication from 'app_search/ReferenceApplication'
import ResultSettings from 'app_search/ResultSettings'
import Schema from 'app_search/Schema'
import SchemaChangeErrors from 'app_search/Schema/SchemaChangeErrors'
import SchemaMetaEngine from 'app_search/Schema/SchemaMetaEngine'
import SearchSettings from 'app_search/SearchSettings'
import Synonyms from 'app_search/Synonyms'
import { IEngineDetails, IMatch } from 'app_search/types'
import { EngineLogic } from './EngineLogic'

interface IEngineRouterProps {
  actions: {
    initializeEngine()
    clearEngine()
    setEngineName(engineName: string)
  }
  match: IMatch
  engine: IEngineDetails
}

const EngineRouter: React.SFC<IEngineRouterProps> = (props) => {
  const [engineNotFound, setEngineNotFound] = useState(false)

  const {
    canViewEngineAnalytics,
    canViewEngineApiLogs,
    canViewEngineDocuments,
    canManageEngineReferenceUi,
    canManageEngineSynonyms,
    canManageEngineCurations,
    canManageEngineRelevanceTuning,
    canManageEngineResultSettings,
    canViewEngineSchema,
    canViewEngineQueryTester,
    canViewMetaEngineSourceEngines
  } = useAbilities()
  const {
    actions: { initializeEngine, clearEngine, setEngineName },
    match: { params: { engineName } },
    engine
  } = props

  setEngineName(engineName)

  useEffect(() => {
    initializeEngine().catch(() => setEngineNotFound(true))
    return clearEngine
  }, [engineName])

  // If a user tries to manually navigate to an engine that does not exist a redirect
  // with error message occurs.
  const engineNotFoundRedirect = {
    pathname: ENGINES_PATH,
    state: { flashMessages: { error: [`No engine with name '${engineName}' could be found.`] } }
  }
  if (engineNotFound) { return <Redirect to={engineNotFoundRedirect} /> }

  // In other components we use `dataLoading` for this but there is no Kea store
  // so this is here to return until the fetch of engine data is complete.
  if (!engine.name) { return <div /> }

  return (
    <Switch>
      <Route
        path={ENGINE_PATH}
        exact={true}
        render={() => <EngineOverview {...props} />}
      />
      {canViewEngineAnalytics && <Route
        path={ENGINE_ANALYTICS_PATH}
        render={() => <AnalyticsRouter {...props} />}
      />}
      {canViewEngineQueryTester && <Route
        path={ENGINE_QUERY_TESTER_PATH}
        exact={true}
        render={() => <QueryTester {...props} />}
      />}
      {canManageEngineReferenceUi && <Route
        path={ENGINE_REFERENCE_APPLICATION_PATH}
        exact={true}
        render={() => <ReferenceApplication {...props} />}
      />}
      {canViewEngineSchema && <Route
        path={ENGINE_SCHEMA_PATH}
        exact={true}
        render={() => engine.isMeta ? <SchemaMetaEngine {...props} /> : <Schema {...props} />}
      />}
      {canViewEngineApiLogs && <Route
        path={ENGINE_API_LOGS_PATH}
        exact={true}
        render={() => <ApiLogs {...props} />}
      />}
      {canViewEngineDocuments && <Route
        path={ENGINE_DOCUMENTS_PATH}
        render={() => <DocumentsRouter {...props} />}
      />}
      {canManageEngineSynonyms && <Route
        path={ENGINE_SYNONYMS_PATH}
        exact={true}
        render={() => <Synonyms {...props} />}
      />}
      {canManageEngineCurations && <Route
        path={ENGINE_CURATIONS_PATH}
        render={() => <CurationsRouter {...props} />}
      />}
      {canManageEngineResultSettings && <Route
        path={ENGINE_RESULT_SETTINGS_PATH}
        exact={true}
        render={() => <ResultSettings {...props} />}
      />}
      {canManageEngineRelevanceTuning && <Route
        path={ENGINE_SEARCH_SETTINGS_PATH}
        exact={true}
        render={() => <SearchSettings {...props} />}
      />}
      {canViewEngineSchema && <Route
        path={ENGINE_REINDEX_JOB_PATH}
        exact={true}
        render={({ match: { params: { activeReindexJobId } } }) => <SchemaChangeErrors activeReindexJobId={activeReindexJobId} {...props} />}
      />}
      {canViewMetaEngineSourceEngines && <Route
        path={META_ENGINE_SOURCE_ENGINES_PATH}
        exact={true}
        render={() => <MetaEngineSourceEngines {...props} />}
      />}
    </Switch>
  )
}

export default withRouter(EngineLogic(EngineRouter))
