import React from 'react'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import {
  StuiTable,
  StuiTableBody,
  StuiTableControls,
  StuiTableControlsGroup,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'

import SchemaMetaFieldCell from './SchemaMetaFieldCell'
import SchemaTruncatedEngines from './SchemaTruncatedEngines'

import { IObject } from 'app_search/types'

interface ISchemaMetaFieldsProps {
  schema: IObject
  fields: IObject
}

const SchemaMetaFields: React.SFC<ISchemaMetaFieldsProps> = ({ schema, fields }) => (
  <div className="c-stui-engine-schema-fields">
    <StuiTableControls>
      <StuiTableControlsGroup>
        <StuiHeader>
          <StuiHeading type="section">Active Fields</StuiHeading>
          <p>Fields which belong to one or more Engine.</p>
        </StuiHeader>
      </StuiTableControlsGroup>
    </StuiTableControls>
    <StuiTable>
      <StuiTableHead>
        <StuiTableRow>
          <StuiTableHeadCell>Field</StuiTableHeadCell>
          <StuiTableHeadCell>Engines</StuiTableHeadCell>
          <StuiTableHeadCell>Field Type</StuiTableHeadCell>
        </StuiTableRow>
      </StuiTableHead>
      <StuiTableBody>
        <StuiTableRow>
          <SchemaMetaFieldCell isFieldName={true}><span className="disabled">id</span></SchemaMetaFieldCell>
          <SchemaMetaFieldCell>All</SchemaMetaFieldCell>
          <SchemaMetaFieldCell>&nbsp;</SchemaMetaFieldCell>
        </StuiTableRow>
        {Object.keys(schema).map((key, index) => (
          <StuiTableRow key={index}>
            <SchemaMetaFieldCell isFieldName={true}>{key}</SchemaMetaFieldCell>
            <SchemaMetaFieldCell>
              <SchemaTruncatedEngines engines={fields[key][schema[key]]} />
            </SchemaMetaFieldCell>
            <SchemaMetaFieldCell>{schema[key]}</SchemaMetaFieldCell>
          </StuiTableRow>
        ))}
      </StuiTableBody>
    </StuiTable>
  </div>
)

export default SchemaMetaFields
