import React from 'react'

import {
  EuiPanel,
  EuiSpacer
} from '@elastic/eui'

import IndexingStatusContent from './IndexingStatusContent'
import IndexingStatusErrors from './IndexingStatusErrors'
import IndexingStatusFetcher from './IndexingStatusFetcher'

import { IIndexingStatusProps } from 'shared/types'

const IndexingStatus: React.SFC<IIndexingStatusProps> = (props) => {

  const statusPanel = (percentComplete) => (
    <EuiPanel paddingSize="l" hasShadow={true}>
      <IndexingStatusContent percentageComplete={percentComplete} />
    </EuiPanel>
  )

  return (
    <IndexingStatusFetcher {...props}>
      {(percentageComplete, numDocumentsWithErrors) => (
        <div className="c-stui-indexing-status-wrapper">
          {percentageComplete < 100 && statusPanel(percentageComplete)}
          {(percentageComplete === 100 && numDocumentsWithErrors > 0) &&
            <>
              <EuiSpacer />
              <IndexingStatusErrors viewLinkPath={props.viewLinkPath} />
            </>
          }
        </div>
      )}
    </IndexingStatusFetcher>
  )
}

export default IndexingStatus
