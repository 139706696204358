import React from 'react'

import StuiIcon from 'stui/Icon'
import StuiTruncatedContent from 'stui/TruncatedContent'
import StuiResultFieldValue from './StuiResultFieldValue'

import { IResultFieldProps } from 'app_search/types'

const MAX_LENGTH = 24

const ResultField: React.SFC<IResultFieldProps> = ({ field, type, raw, snippet, boosts = [] }) => {
  const resultFieldNode = (
    <div className="o-stui-result-field" key={field}>
      <div className="o-stui-result-field__key">
        <StuiTruncatedContent content={field} length={MAX_LENGTH} />
      </div>
      <div className="o-stui-result-field__separator" />
      <div className="o-stui-result-field__value">
        <StuiResultFieldValue raw={raw} snippet={snippet} type={type} />
      </div>
    </div>
  )

  const boostedResultField = () => {
    if (!boosts.length) {
      return null
    }
    const getClassName = (b) => `o-stui-result-field__icon o-stui-boosted-field__icon ${b}-icon`
    const boostIcons = boosts.map((b) => <StuiIcon name={b} key={b} className={getClassName(b)} />)

    return (
      <div className="o-stui-result-field o-stui-boosted-field">

        <div className="o-stui-boosted-field__wrapper">
          <div className="o-stui-result-field__key o-stui-boosted-field__key">

            <StuiTruncatedContent content={field} length={MAX_LENGTH} />
            <div className="o-stui-result-field__icons o-stui-boosted-field__icons">{boostIcons}</div>
          </div>
          <div className="o-stui-result-field__separator o-stui-boosted-field__separator" />
          <div className="o-stui-result-field__value o-stui-boosted-field__value">
            <div className="o-stui-result-field-value">
              <div className="o-stui-result-raw">
                <StuiResultFieldValue raw={raw} snippet={snippet} type={type} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return boosts.length ? boostedResultField() : resultFieldNode
}
export default ResultField
