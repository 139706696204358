import React from 'react'

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import StuiIcon from 'stui/Icon'

import { ISynonymSet } from 'app_search/types'

import { SynonymsLogic } from './SynonymsLogic'

interface ISynonymsCardProps {
  synonymSets: ISynonymSet[]
  actions: {
    handleActiveSynomymSet(set: ISynonymSet)
  }
}

const SynonymsCard: React.SFC<ISynonymsCardProps> = ({ actions: { handleActiveSynomymSet }, synonymSets }) => (
  <div className="c-stui-synonym-sets">
    {synonymSets.map((set, index) => {
      const [firstSynonym, ...synonyms] = set.synonyms
      const handleManageClick = () => handleActiveSynomymSet(set)
      return (
        <div key={`synonym_set-${set.id}`} className="c-stui-synonym-set" data-test-subj="SynonymSetCard">
          <div className="c-stui-synonym-set__content c-stui-synonyms-list">
            <span className="c-stui-synonyms-list__original">{firstSynonym}</span>
            {synonyms.map((synonym) => (
              <div key={`synonym-${synonym}`} className="c-stui-synonyms-list__synonym">
                <StuiIcon name="synonyms" />
                {synonym}
              </div>
            ))}
          </div>
          <div className="c-stui-synonym-set__actions">
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiButtonEmpty
                  data-id={index}
                  role="link"
                  onClick={handleManageClick}
                >
                  Manage
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </div>
        </div>
      )
    })}
  </div>
)

export default SynonymsLogic(SynonymsCard)
