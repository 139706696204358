import React from 'react'

import { EuiSpacer } from '@elastic/eui'

import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'

import AnalyticsChart from 'app_search/Analytics//AnalyticsChart'

interface IEngineAnalyticsProps {
  label: string
  title: string
  chartButton: React.ReactNode
  data: any
  startDate: Date
  endDate: Date
}

const EngineAnalytics: React.SFC<IEngineAnalyticsProps> = ({ title, label, data, chartButton, startDate, endDate }) => (
  <>
    <StuiHeader contained={true} key="header" actions={chartButton}>
      <StuiHeading type="title">Total {title}</StuiHeading>
      <p>Last 7 days</p>
    </StuiHeader>
    <EuiSpacer />
    <AnalyticsChart
      data={data}
      labels={[label]}
      startDate={startDate}
      endDate={endDate}
    />
  </>
)

export default EngineAnalytics
