import React from 'react'

import Tooltip from 'rc-tooltip'

interface IStuiTooltipProps {
  placement?: 'top' | 'bottom' | 'left' | 'right'
  type?: 'default' | 'danger' | 'warning'
  content: any
  children: any
}

const StuiTooltip: React.SFC<IStuiTooltipProps> = ({ placement, type = 'default', content, children }) => (
  <Tooltip
    placement={placement || 'bottom'}
    prefixCls="o-stui-tooltip"
    overlayClassName={`o-stui-tooltip--${type}`}
    overlay={content}
  >
    <span className="o-stui-tooltip-context">{children}</span>
  </Tooltip>
)

export default StuiTooltip
