import React, { useEffect, useState } from 'react'

import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import {
  CREDENTIALS_PATH,
  ENGINE_ANALYTICS_PATH,
  ENGINE_API_LOGS_PATH,
  ENGINE_CURATIONS_PATH,
  ENGINE_DOCUMENTS_PATH,
  ENGINE_PATH,
  ENGINE_QUERY_TESTER_PATH,
  ENGINE_REFERENCE_APPLICATION_PATH,
  ENGINE_RESULT_SETTINGS_PATH,
  ENGINE_SCHEMA_PATH,
  ENGINE_SEARCH_SETTINGS_PATH,
  ENGINE_SYNONYMS_PATH,
  getEngineRoute,
  META_ENGINE_SOURCE_ENGINES_PATH
} from 'app_search/utils/routePaths'
import useAbilities from 'app_search/utils/useAbilities'

import CredentialsModal from 'app_search/Credentials/CredentialsModal'
import { EngineLogic } from 'app_search/Engine'

import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import StuiIcon from 'stui/Icon'

import { IEngineDetails, ILocation, IObject } from 'app_search/types'

interface IEngineNavigationProps {
  actions: {
    initializeEnginesData(history: IObject)
  }
  engines: IEngineDetails[]
  engine: IEngineDetails
  location: ILocation
  history: IObject
}

const EngineNavigation: React.SFC<IEngineNavigationProps> = ({
  actions: { initializeEnginesData },
  location: { pathname },
  history,
  engines,
  engine: {
    name: engineName,
    invalidBoosts,
    isMeta,
    unsearchedUnconfirmedFields,
    sample: isSampleEngine,
    schemaConflicts = {},
    language: engineLanguage
  }
}) => {
  const [allEnginesVisible, setAllEnginesVisibility] = useState(false)
  const [credentialsModalVisible, setCredentialsModalVisibility] = useState(false)
  const {
    canViewEngineAnalytics,
    canViewEngineApiLogs,
    canManageEngineCredentials,
    canViewEngineDocuments,
    canManageEngineReferenceUi,
    canManageEngineResultSettings,
    canManageEngineSynonyms,
    canManageEngineCurations,
    canManageEngineRelevanceTuning,
    canViewEngineSchema,
    canViewEngineQueryTester,
    canViewMetaEngines,
    canViewMetaEngineSourceEngines
  } = useAbilities()

  useEffect(() => { initializeEnginesData(history) }, [])

  const toggleEngineNavEnginesVisibility = () => setAllEnginesVisibility(!allEnginesVisible)
  const toggleCredentialsModalVisibility = () => setCredentialsModalVisibility(!credentialsModalVisible)

  const canViewManageSection = canViewEngineDocuments || canViewEngineSchema || canViewEngineApiLogs
  const canViewSearchSettingsSection = canManageEngineSynonyms || canManageEngineCurations || canManageEngineRelevanceTuning

  const listMetaEngines = canViewMetaEngines && !!engines.find((engine) => engine.isMeta)

  const navContainerClass = classNames(
    'stui-layout-all-engines-navigation-container',
    'stui-layout-engine-sidebar__inner-container',
    { 'stui-layout-all-engines-navigation-container--visible': allEnginesVisible }
  )
  const sectionSegment = pathname.split('/')[3] || 'overview'
  const linkBaseCssClass = 'o-stui-nav-item o-stui-nav-item--pill'
  const getActiveCssClass = (section: string, hasError?: boolean) => classNames(
    linkBaseCssClass,
    { 'o-stui-nav-item--active': section === sectionSegment },
    { 'o-stui-nav-item--warning': hasError }
  )

  const getLinkIcon = (section: string, iconClass: string) => `${iconClass}${section === sectionSegment ? '-fill' : ''}`
  const errorIcon = label => <span className="o-stui-nav-item__warning" title={`Invalid ${label}!`}><StuiIcon name="warning" /></span>

  const hasConflicts: boolean = Object.keys(schemaConflicts).length > 0

  return (
    <div className="stui-layout-engine-sidebar">
      <div className={navContainerClass}>
        <div className="stui-layout-engine-navigation-control o-stui-header-container">
          <EuiFlexGroup responsive={false} gutterSize="none" alignItems="center">
            <EuiFlexItem>
              <EuiText>
                <h3>All Engines</h3>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false} >
              <div className="" onClick={toggleEngineNavEnginesVisibility}>
                <EuiIcon type="cross" />
              </div>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
        <div className="o-stui-sidebar-nav-container">
          {listMetaEngines &&
            <EuiText className="o-stui-sidebar-heading" size="xs">Engines</EuiText>
          }
          <nav className="o-stui-nav o-stui-nav--engine o-stui-nav--vertical">
            {engines.filter((engine) => !engine.isMeta).map((engine) => {
              const isActiveEngine = engine.name === engineName
              const engineNavClass = classNames(
                'o-stui-nav-item',
                'o-stui-nav-item--pill',
                { 'o-stui-nav-item--active': isActiveEngine }
              )
              return (
                <Link key={engine.name} to={getEngineRoute(ENGINE_PATH, engine.name)} className={engineNavClass} onClick={toggleEngineNavEnginesVisibility}>
                  <span className="o-stui-nav-item__label">{engine.name}</span>
                </Link>
              )
            })}
          </nav>
        </div>
        {listMetaEngines &&
          <div className="o-stui-sidebar-nav-container">
            <EuiText className="o-stui-sidebar-heading" size="xs">META-ENGINES</EuiText>
            <nav className="o-stui-nav o-stui-nav--engine o-stui-nav--vertical">
              {engines.filter((engine) => engine.isMeta).map((engine) => {
                const isActiveEngine = engine.name === engineName
                const engineNavClass = classNames(
                  'o-stui-nav-item',
                  'o-stui-nav-item--pill',
                  { 'o-stui-nav-item--active': isActiveEngine }
                )
                return (
                  <Link key={engine.name} to={getEngineRoute(ENGINE_PATH, engine.name)} className={engineNavClass} onClick={toggleEngineNavEnginesVisibility}>
                    <span className="o-stui-nav-item__label">{engine.name}</span>
                  </Link>
                )
              })}
            </nav>
          </div>
        }
      </div>

      <div className="stui-layout-engine-navigation-container stui-layout-engine-sidebar__inner-container">
        <div className="o-stui-header-container">
          <div className="c-stui-engine-selector-control">
            <div className="c-stui-engine-selector-control__engine-name">
              <div className="c-stui-engine-selector-control__engine-name__inner">
                {engineName}
                {isSampleEngine && <small className="c-stui-engine-selector-control__engine-type">Sample Engine</small>}
                {engineLanguage && <small className="c-stui-engine-selector-control__engine-type">{engineLanguage}</small>}
                {isMeta && <small className="c-stui-engine-selector-control__engine-type"><EuiBadge className="c-stui-engine-selector-control__badge">Meta Engine</EuiBadge></small>}
              </div>
            </div>
            <div className="c-stui-engine-selector-control__icon" onClick={toggleEngineNavEnginesVisibility}>
              <StuiIcon name="engine_selector" />
            </div>
          </div>
        </div>
        <div className="o-stui-sidebar-nav-container">
          <nav className="o-stui-nav o-stui-nav--primary o-stui-nav--vertical">
            <Link to={getEngineRoute(ENGINE_PATH, engineName)} className={getActiveCssClass('overview')} data-test-subj="EngineOverviewLink">
              <StuiIcon name={getLinkIcon('overview', 'overview')} />
              <span className="o-stui-nav-item__label">Overview</span>
            </Link>
            {canViewEngineAnalytics && <Link to={getEngineRoute(ENGINE_ANALYTICS_PATH, engineName)} className={getActiveCssClass('analytics')} data-test-subj="EngineAnalyticsLink">
              <StuiIcon name={getLinkIcon('analytics', 'analytics')} />
              <span className="o-stui-nav-item__label">Analytics</span>
            </Link>}
            {canViewEngineQueryTester && <Link to={getEngineRoute(ENGINE_QUERY_TESTER_PATH, engineName)} className={getActiveCssClass('query-tester')} data-test-subj="EngineQueryTesterLink">
              <StuiIcon name={getLinkIcon('query-tester', 'query_tester')} />
              <span className="o-stui-nav-item__label">Query Tester</span>
            </Link>}
            {canManageEngineReferenceUi && <Link to={getEngineRoute(ENGINE_REFERENCE_APPLICATION_PATH, engineName)} className={getActiveCssClass('reference_application')} data-test-subj="EngineReferenceUILink">
              <StuiIcon name={getLinkIcon('reference_application', 'wand')} />
              <span className="o-stui-nav-item__label">Reference UI</span>
            </Link>}
            {canViewManageSection && <>
              <EuiSpacer />
              <div className="o-stui-sidebar-heading hide-on-mobile">Manage</div>
              {canViewEngineDocuments && <Link to={getEngineRoute(ENGINE_DOCUMENTS_PATH, engineName)} className={getActiveCssClass('documents')} data-test-subj="EngineDocumentsLink">
                <StuiIcon name={getLinkIcon('documents', 'content')} />
                <span className="o-stui-nav-item__label">Documents</span>
              </Link>}
              {canViewEngineSchema && <Link to={getEngineRoute(ENGINE_SCHEMA_PATH, engineName)} className={getActiveCssClass('schema', unsearchedUnconfirmedFields)} data-test-subj="EngineSchemaLink">
                <StuiIcon name={getLinkIcon('schema', 'settings')} />
                <span className="o-stui-nav-item__label">
                  Schema {hasConflicts && <StuiIcon name="warning-fill" className="o-stui-nav-item__label--warning-icon" />}
                  {unsearchedUnconfirmedFields && errorIcon('fields')}
                </span>
              </Link>}
              {canViewEngineApiLogs && <Link to={getEngineRoute(ENGINE_API_LOGS_PATH, engineName)} className={getActiveCssClass('api-logs')} data-test-subj="EngineAPILogsLink">
                <StuiIcon name={getLinkIcon('api_logs', 'logs')} />
                <span className="o-stui-nav-item__label">API Logs</span>
              </Link>}
              {canViewMetaEngineSourceEngines && isMeta &&
                <Link
                  to={getEngineRoute(META_ENGINE_SOURCE_ENGINES_PATH, engineName)}
                  className={getActiveCssClass('engines')}
                  data-test-subj="MetaEngineEnginesLink"
                >
                  <StuiIcon name={getLinkIcon('engines', 'engine')} />
                  <span className="o-stui-nav-item__label">Engines</span>
                </Link>
              }
            </>}
            {canViewSearchSettingsSection && <>
              <EuiSpacer />
              <div className="o-stui-sidebar-heading hide-on-mobile">Search Settings</div>
              {canManageEngineSynonyms && <Link to={getEngineRoute(ENGINE_SYNONYMS_PATH, engineName)} className={getActiveCssClass('synonyms')} data-test-subj="EngineSynonymsLink">
                <StuiIcon name={getLinkIcon('synonyms', 'synonyms')} />
                <span className="o-stui-nav-item__label">Synonyms</span>
              </Link>}
              {canManageEngineCurations && <Link to={getEngineRoute(ENGINE_CURATIONS_PATH, engineName)} className={getActiveCssClass('curations')} data-test-subj="EngineCurationsLink">
                <StuiIcon name={getLinkIcon('curations', 'result_ranking')} />
                <span className="o-stui-nav-item__label">Curations</span>
              </Link>}
              {canManageEngineRelevanceTuning && <Link to={getEngineRoute(ENGINE_SEARCH_SETTINGS_PATH, engineName)} className={getActiveCssClass('search-settings', invalidBoosts)} data-test-subj="EngineRelevanceTuningLink">
                <StuiIcon name={getLinkIcon('search_settings', 'weights')} />
                <span className="o-stui-nav-item__label">
                  Relevance Tuning
                  {invalidBoosts && errorIcon('boosts')}
                </span>
              </Link>}
              {canManageEngineResultSettings && <Link to={getEngineRoute(ENGINE_RESULT_SETTINGS_PATH, engineName)} className={getActiveCssClass('result-settings', invalidBoosts)} data-test-subj="EngineResultSettingsLink">
                <StuiIcon name={getLinkIcon('result_settings', 'result-settings')} />
                <span className="o-stui-nav-item__label">Result Settings</span>
              </Link>}
            </>}
            {canManageEngineCredentials && <>
              <EuiSpacer />
              <div className="o-stui-sidebar-heading hide-on-mobile">Access</div>
              <Link
                to={CREDENTIALS_PATH}
                onClick={(e: React.SyntheticEvent) => {
                  e.preventDefault()
                  toggleCredentialsModalVisibility()
                }}
                className={linkBaseCssClass}
                data-test-subj="EngineCredentialsLink"
              >
                <StuiIcon name="key" />
                <span className="o-stui-nav-item__label">Credentials</span>
              </Link>
            </>}
          </nav>
        </div>
      </div>
      {credentialsModalVisible && <CredentialsModal onClose={toggleCredentialsModalVisibility} />}
    </div>
  )
}

export default withRouter(EngineLogic(EngineNavigation))
