import React from 'react'

import _includes from 'lodash/includes'

import { EuiSpacer } from '@elastic/eui'

import { SchemaExistingField } from 'shared/components/Schema'
import { SchemaLogic } from './SchemaLogic'

import { IObject } from 'app_search/types'

interface ISchemaFieldsProps {
  actions: {
    updateExistingFieldType(fieldName: string, fieldType: string)
  }
  activeSchema: IObject
  disabled?: boolean
  unconfirmedFields: string[]
}

const SchemaFields: React.SFC<ISchemaFieldsProps> = ({
  actions: {
    updateExistingFieldType
  },
  activeSchema,
  disabled,
  unconfirmedFields
}) => (
  <div className="c-stui-engine-schema-fields">
    <SchemaExistingField fieldName="id" />
    {Object.keys(activeSchema).map((key, index) => (
      <SchemaExistingField
        disabled={disabled}
        key={index}
        fieldName={key}
        fieldType={activeSchema[key]}
        unconfirmed={_includes(unconfirmedFields, key)}
        updateExistingFieldType={updateExistingFieldType}
      />
    ))}
    <EuiSpacer />
  </div>
)

export default SchemaLogic(SchemaFields)
