import {
  IEngineDetails,
  ISchemaConflictFieldTypes,
  ISchemaConflicts
} from '../types'

import _flatten from 'lodash/flatten'

const getConflictingEnginesFromConflictingField = (conflictingField: ISchemaConflictFieldTypes): string[] => _flatten(Object.values(conflictingField))
const getConflictingEnginesFromSchemaConflicts = (schemaConflicts: ISchemaConflicts): string[] => _flatten(Object.values(schemaConflicts).map(getConflictingEnginesFromConflictingField))

// Given a meta-engine (represented by IEngineDetails), generate a Set of all source engines
// who have schema conflicts in the context of that meta-engine
//
// A Set allows us to enforce uniqueness and has O(1) lookup time
export const getConflictingEnginesSet = (metaEngine: IEngineDetails): Set<string> => {
  const conflictingEngines: string[] = metaEngine.schemaConflicts ? getConflictingEnginesFromSchemaConflicts(metaEngine.schemaConflicts) : []
  return new Set(conflictingEngines)
}
