import React from 'react'

import classNames from 'classnames'

import { EuiButtonEmpty } from '@elastic/eui'

interface IStuiUpdatePromptProps {
  text: React.ReactNode
  inline?: boolean
  linkIcon?: boolean
  linkText?: string
  onLinkClick?(e)
}

const StuiUpdatePrompt: React.SFC<IStuiUpdatePromptProps> = ({
  text,
  inline = true,
  linkText,
  onLinkClick
}) => {
  const baseClass = 'o-stui-update-prompt'
  const cssClass = classNames(
    baseClass,
    { [`${baseClass}--inline`]: inline }
  )
  return (
    <div className={cssClass}>
      <div className={`${baseClass}__content`}>
        {`${text} `}
        {linkText && onLinkClick ?
          <EuiButtonEmpty
            size="s"
            onClick={onLinkClick}
            iconType={'refresh'}
          >
            {linkText}
          </EuiButtonEmpty> : null}
      </div>
    </div>
  )
}

export default StuiUpdatePrompt
