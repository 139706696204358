import React from 'react'

import SchemaTruncatedEngines from './SchemaTruncatedEngines'

interface ISchemaConflictedFieldProps {
  engines: string[]
  fieldType: string
}

const SchemaConflictedField: React.SFC<ISchemaConflictedFieldProps> = ({ engines, fieldType }) => (
  <div className="c-stui-engine-schema-conflict">
    <div className="c-stui-engine-schema-conflict__field">{fieldType}</div>
    <div className="c-stui-engine-schema-conflict__engines">
      <SchemaTruncatedEngines engines={engines} cutoff={2} />
    </div>
  </div>
)

export default SchemaConflictedField
