import { AppLogic } from 'app_search/App'
import routes from 'app_search/routes'
import { IEngineDetails, IMetaPage } from 'app_search/types'
import { handleAPIError } from 'app_search/utils/handleAPIError'
import {
  META_ENGINE_NEW_PATH
} from 'app_search/utils/routePaths'
import {
  getConflictingEnginesSet
} from 'app_search/utils/schemaConflicts'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { } from 'shared/types'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

const getDeleteMessage = name => `Are you sure you want to permanently delete "${name}" and all of its content?`

export interface IConflictingEnginesSets {
  [key: string]: Set<string>
}

export const MetaEnginesLogic = storeLogic({
  connect: () => ({
    actions: [
      AppLogic, ['fetchLatestAccountState']
    ],
    values: [
      AppLogic, ['myRole']
    ]
  }),
  actions: () => ({
    addSourceEngines: (sourceEngines: object) => sourceEngines,
    displayRow: (itemId: string) => itemId,
    hideRow: (itemId: string) => itemId,
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages }),
    setMetaEnginesAfterDeletion: (name: string) => name,
    setMetaEnginesData: (engines: IEngineDetails[], page: IMetaPage) => ({ engines, page })
  }),
  reducers: ({ actions }) => ({
    dataLoading: [true, {
      [actions.setMetaEnginesData]: () => false,
      [actions.setFlashMessages]: () => false
    }],
    expandedRows: [{}, {
      [actions.displayRow]: (expandedRows, itemId) => ({ ...expandedRows, [itemId]: true }),
      [actions.hideRow]: (expandedRows, itemId) => { const newRows = { ...expandedRows }; delete newRows[itemId]; return newRows }
    }],
    flashMessages: [{}, {
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages,
      [actions.setMetaEnginesAfterDeletion]: () => ({ success: ['Successfully deleted Meta Engine.'] })
    }],
    pagination: [{}, {
      [actions.setMetaEnginesData]: (_, { page }) => ({
        pageIndex: page.current,
        pageSize: page.size,
        totalItemCount: page.total_results
      })
    }],
    metaEngines: [[], {
      [actions.setMetaEnginesAfterDeletion]: (state, name) => state.filter(engine => engine.name !== name),
      [actions.setMetaEnginesData]: (_, { engines }) => engines
    }],
    sourceEngines: [{}, {
      [actions.addSourceEngines]: (sourceEngines, newEngines) => ({ ...sourceEngines, ...newEngines })
    }]
  }),
  selectors: ({ selectors }) => ({
    expandedSourceEngines: [
      () => [selectors.sourceEngines, selectors.expandedRows],
      (sourceEngines: object, expandedRows: string[]) => {
        return Object.keys(expandedRows).reduce((expandedRowMap, engineName) => {
          expandedRowMap[engineName] = sourceEngines[engineName]
          return expandedRowMap
        }, {})
      }
    ],
    conflictingEnginesSets: [
      () => [selectors.metaEngines],
      (metaEngines: IEngineDetails[]): IConflictingEnginesSets => metaEngines.reduce(
        (accumulator, metaEngine) => {
          return {
            ...accumulator,
            [metaEngine.name]: getConflictingEnginesSet(metaEngine)
          }
        },
        {}
      )
    ]
  }),
  thunks: ({ actions, values }) => ({
    deleteMetaEngine: (name: string) => {
      if (window.confirm(getDeleteMessage(name))) {
        http.delete(routes.locoMocoEnginePath(name))
          .then(() => {
            actions.fetchLatestAccountState()
            actions.setMetaEnginesAfterDeletion(name)
          })
          .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
      }
    },
    fetchMetaEngines: (history, page = 1, size = 25) => {
      const url = routes.locoMocoEnginesPath({
        page: {
          current: page,
          size
        },
        type: 'meta'
      })

      return http(url)
        .then(({ data: { meta, results } }) => {
          const hasMetaEngines = !(page === 1 && results.length < 1)
          if (hasMetaEngines) {
            actions.setMetaEnginesData(results, meta.page)
          } else {
            const myRole = values.myRole
            if (myRole.ability.can('manage', 'account_engines')) {
              history.push(META_ENGINE_NEW_PATH)
            } else {
              actions.setMetaEnginesData([], meta.page)
            }
          }
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    fetchOrDisplayRow: (itemId) => {
      const sourceEngines = values.sourceEngines
      if (sourceEngines[itemId]) {
        actions.displayRow(itemId)
      } else {
        actions.fetchSourceEngines(itemId)
      }
    },
    fetchSourceEngines: (engineName) => {
      let engines: IEngineDetails[] = []

      const recursiveFetchSourceEngines = (page = 1) => {
        const url = routes.locoMocoEngineSourceEnginesPath(
          engineName,
          {
            page: {
              current: page,
              size: 25
            }
          }
        )

        return http(url)
          .then(({ data: { meta, results } }) => {
            engines = [...engines, ...results]
            if (page >= meta.page.total_pages) {
              actions.addSourceEngines({ [engineName]: engines })
              actions.displayRow(engineName)
            } else {
              recursiveFetchSourceEngines(page + 1)
            }
          })
          .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
      }

      recursiveFetchSourceEngines()
    },
    initializeMetaEnginesData: (history) => {
      actions.fetchMetaEngines(history)
    }
  })
})
