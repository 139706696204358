import routes from 'app_search/routes'
import http from 'shared/http'

import axios from 'axios'
import moment from 'moment'

import { captureException } from 'app_search/sentry'
import { IAccount, IApiLog, IApiLogsMeta } from 'app_search/types'
import { getDateString } from 'app_search/utils'
import handleAPIError from 'app_search/utils/handleAPIError'
import { DEFAULT_META } from 'shared/constants/defaultMeta'
import { POLLING_DURATION } from 'shared/constants/polling'
import { PAYMENT_REQUIRED_STATUS, UNAVAILABLE_STATUS } from 'shared/constants/statuses'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

const defaultMeta = {
  ...DEFAULT_META,
  filters: {
    date: {
      from: getDateString(-1),
      to: getDateString()
    }
  },
  sort_direction: 'desc'
}

import { EngineLogic } from 'app_search/Engine'

export const ApiLogsLogic = storeLogic({
  connect: () => ({
    values: [
      EngineLogic, ['engine', 'engineName']
    ]
  }),
  actions: () => ({
    setInitialData: (account: IAccount) => ({ account }),
    setData: ({ results, meta }) => ({ logs: results, logsMeta: meta }),
    setPolledData: ({ results, meta, timeoutId, hasNewData }) => ({
      polledLogs: results,
      polledLogsMeta: meta,
      timeoutId,
      hasNewData
    }),
    setTimoutId: (timeoutId: number) => ({ timeoutId }),
    setActiveApiLog: (activeApiLog: IApiLog) => ({ activeApiLog }),
    setUnavailable: (logsUnavailable: boolean) => ({ logsUnavailable }),
    setPlanLimitCalloutVisibility: (showPlanLimitCallout: boolean) => ({ showPlanLimitCallout }),
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages }),
    closeDetails: true
  }),
  reducers: ({ actions }) => ({
    account: [{}, {
      [actions.setInitialData]: (_, { account }) => account
    }],
    flashMessages: [{}, {
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages
    }],
    dataLoading: [true, {
      [actions.setData]: () => false,
      [actions.setUnavailable]: () => false,
      [actions.setPlanLimitCalloutVisibility]: () => false,
      [actions.setFlashMessages]: () => false
    }],
    logsMeta: [defaultMeta as IApiLogsMeta, {
      [actions.setData]: (_, { logsMeta }) => logsMeta
    }],
    polledLogsMeta: [defaultMeta as IApiLogsMeta, {
      [actions.setData]: (_, { logsMeta }) => logsMeta,
      [actions.setPolledData]: (_, { polledLogsMeta }) => polledLogsMeta
    }],
    logs: [[], {
      [actions.setData]: (_, { logs }) => logs
    }],
    polledLogs: [[], {
      [actions.setPolledData]: (_, { polledLogs }) => polledLogs
    }],
    activeApiLog: [null, {
      [actions.setActiveApiLog]: (_, { activeApiLog }) => activeApiLog,
      [actions.closeDetails]: () => null
    }],
    timeoutId: [null, {
      [actions.setPolledData]: (_, { timeoutId }) => timeoutId,
      [actions.setTimoutId]: (_, { timeoutId }) => timeoutId
    }],
    pollingCancelTokenSource: [axios.CancelToken.source(), {}],
    hasNewData: [false, {
      [actions.setPolledData]: (_, { hasNewData }) => hasNewData,
      [actions.setData]: () => false
    }],
    logsUnavailable: [false, {
      [actions.setUnavailable]: (_, { logsUnavailable }) => logsUnavailable
    }],
    showPlanLimitCallout: [false, {
      [actions.setPlanLimitCalloutVisibility]: (_, { showPlanLimitCallout }) => showPlanLimitCallout
    }]
  }),
  selectors: ({ selectors }) => ({
    emptyStateProps: [
      () => [selectors.engine],
      engine => {
        const twoDays = moment().subtract(2, 'days')
        const isOlder = moment(engine.created_at).isBefore(twoDays)

        return {
          description:
            'Check back after you\'ve performed some API calls',
          icon: 'logs-fill',
          title: isOlder ?
            'No recent logs' :
            'No logs yet'
        }
      }
    ]
  }),
  thunks: ({ actions, get }) => ({
    initializeData: () => {
      const route = routes.detailsLocoMocoEngineApiLogsPath(get('engineName'))
      http(route)
        .then(({ data: { account } }) => {
          actions.setInitialData(account)
          actions.getApiLogsFromServer(actions.setData, get('logsMeta').filters)
          actions.pollForApiDocs()
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    getApiLogsFromServer: (successCallback, filters, page: number = 1) => {
      // There is a rare edge case when running Cypress tests where the polling fires when
      // changing routes and the callback fires before component's Kea store has instantiated.
      // When this edge case occurs, we just return and the next polling interval will fire.
      if (!get()) { return }

      const { sort_direction } = get('logsMeta')

      const route = routes.locoMocoEngineApiLogsPath({
        filters,
        sort_direction,
        engine_slug: get('engineName'),
        page: { current: page }
      })

      http.get(route, { cancelToken: get('pollingCancelTokenSource').token })
        .then(({ data }) => successCallback(data))
        .catch((error) => {
          const { response } = error
          if (response) {
            switch (response.status) {
              case UNAVAILABLE_STATUS:
                actions.setUnavailable(true)
                break
              case PAYMENT_REQUIRED_STATUS:
                actions.setPlanLimitCalloutVisibility(true)
                break
              default:
                actions.setFlashMessages({ error: response.data.errors })
            }
          } else if (!axios.isCancel(error)) {
            captureException(
              'Valid response not received from API',
              {
                component: 'ApiLogsLogic',
                route
              }
            )
          }
        })
    },
    handlePageClick: (page: number) => {
      actions.getApiLogsFromServer(actions.setData, get('logsMeta').filters, page)
    },
    pollForApiDocs: () => {
      const filters = {
        date: {
          from: getDateString(-1),
          to: getDateString()
        }
      }
      actions.getApiLogsFromServer(actions.onPollingSuccess, filters)
    },
    onPollingSuccess: ({ results, meta }) => {
      const { total_results: prevTotal } = get('logsMeta').page
      const { total_results: newTotal } = meta.page
      const timeoutId = window.setTimeout(actions.pollForApiDocs, POLLING_DURATION)
      actions.setPolledData({
        meta,
        results,
        timeoutId,
        hasNewData: newTotal > prevTotal
      })

      if (prevTotal < 1 && newTotal > 0) {
        actions.updateLogsView()
      }
    },
    updateLogsView: () => {
      actions.setData({
        results: get('polledLogs'),
        meta: get('polledLogsMeta')
      })
    }
  }),
  events: ({ values }) => ({
    beforeUnmount() {
      values.pollingCancelTokenSource.cancel()
      clearTimeout(values.timeoutId)
    }
  })
})

export const getStatusColor = (status: number) => {
  let color = ''
  if (status >= 100 && status < 300) { color = 'secondary' }
  if (status >= 300 && status < 400) { color = 'primary' }
  if (status >= 400 && status < 500) { color = 'warning' }
  if (status >= 500) { color = 'danger' }
  return color
}
