import {
  EuiComboBoxOptionProps
} from '@elastic/eui'
import { EngineLogic } from 'app_search/Engine'
import { EnginesLogic } from 'app_search/Engines'
import routes from 'app_search/routes'
import { IEngineDetails } from 'app_search/types'
import handleAPIError from 'app_search/utils/handleAPIError'
import { DEFAULT_META } from 'shared/constants/defaultMeta'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'
export enum EOpenModalOptions {
  None = 'None',
  AddEnginesModal = 'Add Engines',
  RemoveEngineModal = 'Remove Engine'
}

export const MetaEngineSourceEnginesLogic = storeLogic({
  connect: () => ({
    values: [
      EngineLogic, ['engineName'],
      EnginesLogic, ['engines']
    ],
    actions: [
      EngineLogic, ['initializeEngine']
    ]
  }),
  actions: () => ({
    closeModals: true,
    openAddEnginesModal: true,
    openRemoveEngineModal: (engineName: string) => ({ engineName }),
    resetIndexedEnginesData: true,
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages }),
    setSelectedIndexedEngineOptions: (selectedOptions: EuiComboBoxOptionProps[]) => ({ selectedOptions }),
    setSourceEnginesData: (sourceEngines: IEngineDetails[]) => ({ sourceEngines })
  }),
  reducers: ({ actions }) => ({
    dataLoading: [true, {
      [actions.setSourceEnginesData]: () => false
    }],
    flashMessages: [{}, {
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages,
      [actions.setSourceEnginesData]: () => ({})
    }],
    openModal: [EOpenModalOptions.None, {
      [actions.setSourceEnginesData]: () => EOpenModalOptions.None,
      [actions.openAddEnginesModal]: () => EOpenModalOptions.AddEnginesModal,
      [actions.openRemoveEngineModal]: () => EOpenModalOptions.RemoveEngineModal,
      [actions.closeModals]: () => EOpenModalOptions.None
    }],
    selectedEngineToBeRemoved: ['', {
      [actions.openRemoveEngineModal]: (_, { engineName }) => engineName
    }],
    selectedIndexedEngineOptions: [[], {
      [actions.openAddEnginesModal]: () => [],
      [actions.setSelectedIndexedEngineOptions]: (_, { selectedOptions }) => selectedOptions
    }],
    sourceEngines: [[], {
      [actions.setSourceEnginesData]: (_, { sourceEngines }) => sourceEngines
    }]
  }),
  selectors: ({ selectors }) => ({
    // we use sourceEngineDictionary to have an O(1) lookup for whether a meta-engine already
    // has an indexed engine as a source engine
    sourceEngineDictionary: [
      () => [selectors.sourceEngines],
      (sourceEngines: IEngineDetails[]) => sourceEngines.reduce((sourceEngineNames, sourceEngine) => ({ ...sourceEngineNames, [sourceEngine.name]: sourceEngine }), {})
    ],
    // we use sourceEngineDictionary to have an O(1) lookup of engine details when we add new engines,
    // since the API endpoints to add or remove source engines from a meta-engine does not return engine details
    engineDictionary: [
      () => [selectors.engines],
      (engines: IEngineDetails[]) => engines.reduce((engineNames, engine) => ({ ...engineNames, [engine.name]: engine }), {})
    ],
    indexedEngineOptions: [
      () => [selectors.engines, selectors.sourceEngineDictionary],
      (engines, sourceEngineDictionary) => {
        const indexedEngines = engines.filter(engine => !engine.isMeta && !sourceEngineDictionary[engine.name])
        return indexedEngines.map(engine => ({ label: engine.name }))
      }
    ]
  }),
  thunks: ({ actions, values }) => ({
    addEngines: (engineNamesToAdd: string[]) => {
      const url = routes.bulkCreateLocoMocoEngineSourceEnginesPath({
        engine_slug: values.engineName,
        source_engine_slugs: engineNamesToAdd
      })

      http.post(url)
        .then(() => {
          const newEngines: IEngineDetails[] = engineNamesToAdd.map(engineName => values.engineDictionary[engineName])
          const newSourceEnginesData: IEngineDetails[] = [...values.sourceEngines, ...newEngines]
          actions.setSourceEnginesData(newSourceEnginesData)
          actions.initializeEngine() // we need to reload Engine data in case schema conflicts have been updated
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
        .then(() => actions.closeModals())
    },
    fetchAllSourceEngines: () => {
      let engines: IEngineDetails[] = []

      const recursiveFetchSourceEngines = (page = 1) => {
        const url = routes.locoMocoEngineSourceEnginesPath(
          values.engineName,
          {
            page: {
              current: page,
              size: DEFAULT_META.page.size
            }
          }
        )

        return http(url)
          .then(({ data: { meta, results } }) => {
            engines = [...engines, ...results]
            if (page >= meta.page.total_pages) {
              actions.setSourceEnginesData(engines)
            } else {
              recursiveFetchSourceEngines(page + 1)
            }
          })
          .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
      }

      recursiveFetchSourceEngines()
    },
    initializeMetaEngineSourceEnginesData: () => {
      actions.fetchAllSourceEngines()
    },
    removeEngine: (engineNameToRemove: string) => {
      const url = routes.locoMocoEngineSourceEnginePath({
        engine_slug: values.engineName,
        source_engine_slug: engineNameToRemove
      })

      http.delete(url)
        .then(() => {
          const newSourceEnginesData = values.sourceEngines.filter(engine => engine.name !== engineNameToRemove)
          actions.setSourceEnginesData(newSourceEnginesData)
          actions.initializeEngine() // we need to reload Engine data in case schema conflicts have been updated
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
        .then(() => actions.closeModals())
    }
  })
})
