import React, { useEffect, useState } from 'react'

import classNames from 'classnames'
import _get from 'lodash/get'
import { withRouter } from 'react-router-dom'

import { useActions, useValues } from 'kea'

import { AppLogic } from 'app_search/App'
import { EngineLogic } from 'app_search/Engine'

import routes from 'app_search/routes'
import useAbilities from 'app_search/utils/useAbilities'
import http from 'shared/http'

import { ILocation } from 'app_search/types'

import {
  ENGINE_QUERY_TESTER_PATH,
  ENGINE_SCHEMA_PATH,
  getEngineRoute,
  getReindexJobRoute
} from 'app_search/utils/routePaths'

import { IndexingStatusPopover } from 'shared/components/IndexingStatus'

import Telemetry from 'app_search/components/Telemetry'
import QueryTesterFlyout from 'app_search/QueryTester/QueryTesterPopover'
import EngineNavigation from './EngineNavigation'
import MainNavigation from './MainNavigation'
import SampleEngineBanner from './SampleEngineBanner'
import SampleEngineLoader from './SampleEngineLoader'
import TrialBanner from './TrialBanner'

import NewFeatuersFlyout from 'app_search/NewFeatures'
import StuiFlashMessages from 'stui/FlashMessages'

interface IAppChromeProps {
  children: Element
  location: ILocation
  isEngine?: boolean
}

const AppChrome: React.SFC<IAppChromeProps> = ({ children, location, isEngine }) => {
  const {
    account: {
      accountId,
      freeTrial,
      freeTrialNotApplicable,
      isDemo,
      isResettingDemo,
      onboardingComplete,
      seedingSampleEngine,
      telemetryOptedIn,
      telemetryOptinInStatusUnknown,
      telemetryLastSentAt,
      telemetrySendIntervalHours
    },
    newFeatures
  } = useValues(AppLogic)
  const {
    engine: {
      name: engineName,
      activeReindexJob,
      sample: isSampleEngine
    }
  } = useValues(EngineLogic)

  const { setIndexingStatus } = useActions(EngineLogic)

  const { canSendTelemetryData } = useAbilities()

  const [disableResetButton, setResetDisabled] = useState(isResettingDemo)
  const [flashMessages, setFlashMessages] = useState(null)

  const onUnmount = () => {
    setFlashMessages(null)
  }

  useEffect(() => {
    setResetDisabled(isResettingDemo)
    setFlashMessages(_get(location, 'state.flashMessages'))
    return onUnmount
  }, [location, onboardingComplete])

  const viewErrorsPath = activeReindexJob && getReindexJobRoute(engineName, activeReindexJob.activeReindexJobId)

  const containerCssClass = classNames(
    'stui-layout',
    { 'stui-layout--banner': isSampleEngine },
    `stui-layout--${isEngine ? 'engine' : 'account'}`
  )

  const demoLinkCssClass = classNames(
    'o-stui-link o-stui-link--primary',
    { 'visually-disabled': disableResetButton }
  )

  const handleResetClick = e => {
    e.preventDefault()
    resetDemo()
  }

  const resetDemo = () => {
    if (disableResetButton) { return }
    setResetDisabled(true)
    http(routes.resetDemoLocoMocoAccountPath(accountId))
  }

  const isQueryTesterRoute = location.pathname === getEngineRoute(ENGINE_QUERY_TESTER_PATH, engineName)
  const isSchemaRoute = location.pathname === getEngineRoute(ENGINE_SCHEMA_PATH, engineName)

  const utilityHeader = <div className="stui-layout-utility-header">
    <div className="o-stui-header-container">
      {isDemo && <span><a className={demoLinkCssClass} onClick={handleResetClick}>Reset Demo</a>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
      {freeTrial && !freeTrialNotApplicable && <TrialBanner />}
      {isEngine && engineName &&
        <div className="o-stui-nav o-stui-nav--utility">
          {activeReindexJob && !isSchemaRoute &&
            <IndexingStatusPopover
              itemId={engineName}
              viewErrorsPath={viewErrorsPath}
              getStatusPath={routes.statusLocoMocoEngineReindexJobPath}
              getItemDetailPath={routes.detailsLocoMocoEnginePath}
              setGlobalIndexingStatus={setIndexingStatus}
              {...activeReindexJob}
            />}
          {!isQueryTesterRoute && <QueryTesterFlyout />}
        </div>}
      {(!isEngine && newFeatures.length > 0) &&
        <div className="o-stui-nav o-stui-nav--utility">
          <NewFeatuersFlyout entries={newFeatures} />
        </div>}
    </div>
  </div>

  const shouldRenderTelemetryComponent = !!process.env.LOCO_TOGO && canSendTelemetryData && (telemetryOptinInStatusUnknown || telemetryOptedIn)
  const shouldShowTelemetryCallout = !!process.env.LOCO_TOGO && telemetryOptinInStatusUnknown && canSendTelemetryData

  return (
    <div>
      <div className={containerCssClass}>
        {isSampleEngine && <>
          <SampleEngineBanner />
          {seedingSampleEngine && <SampleEngineLoader />}
        </>}

        <div className="stui-layout-sidebar-container">
          <MainNavigation />
          {isEngine && <EngineNavigation engineName={engineName} />}
          {seedingSampleEngine && <div className="sample-engine-menu-overlay" />}
        </div>
        <div className="stui-layout-view-container">
          {shouldRenderTelemetryComponent &&
            <Telemetry
              lastSentAt={telemetryLastSentAt}
              optedIn={telemetryOptedIn}
              sendIntervalHours={telemetrySendIntervalHours}
              showCallout={shouldShowTelemetryCallout}
            />
          }
          {(freeTrial || isEngine || newFeatures.length > 0 || isDemo) && utilityHeader}
          <div className="stui-view">
            <StuiFlashMessages isWrapped={true} {...flashMessages} />
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(AppChrome)
