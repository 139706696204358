import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'

import { EngineLogic } from 'app_search/Engine'
import routes from 'app_search/routes'
import { IIndexingStatusProps, IObject } from 'app_search/types'
import handleAPIError from 'app_search/utils/handleAPIError'
import { TEXT } from 'shared/constants/fieldTypes'
import { ADD, UPDATE } from 'shared/constants/operations'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

interface ISchemaResponseProps {
  schema: IObject
  mostRecentIndexJob: IIndexingStatusProps
  unsearchedUnconfirmedFields: boolean
  unconfirmedFields: string[]
}

interface ISchemaMetaEngineProps {
  conflictingFields: IObject
  fields: IObject
}

interface ISchemaSetProps {
  showAddFieldModal: boolean
  flashMessages: IStuiFlashMessagesProps
}

interface IFieldCoercionError {
  external_id: string
  error: string
}

export interface IFieldCoercionErrors {
  [key: string]: IFieldCoercionError[]
}

interface ISchemaChangeErrorsProps {
  fieldCoercionErrors: IFieldCoercionErrors
}

export const SchemaLogic = storeLogic({
  connect: () => ({
    actions: [
      EngineLogic, ['setIndexingStatus']
    ],
    values: [
      EngineLogic, ['engineName']
    ]
  }),
  actions: () => ({
    onInitializeSchema: (schemaProps: ISchemaResponseProps) => schemaProps,
    onInitializeSchemaMetaEngine: (schemaMetaEngineProps: ISchemaMetaEngineProps) => schemaMetaEngineProps,
    onInitializeSchemaFieldErrors: (fieldCoercionErrorsProps: ISchemaChangeErrorsProps) => fieldCoercionErrorsProps,
    onSchemaSetSuccess: (schemaProps: ISchemaSetProps & ISchemaResponseProps) => schemaProps,
    onSchemaSetError: (errorProps: ISchemaSetProps) => errorProps,
    onSchemaSetFormErrors: (errors: string[]) => errors,
    updateNewFieldType: (newFieldType: string) => newFieldType,
    onFieldTypeUpdate: ({ schema, formUnchanged }: { schema: IObject, formUnchanged: boolean }) => ({ schema, formUnchanged }),
    onIndexingComplete: (numDocumentsWithErrors: number) => numDocumentsWithErrors,
    resetMostRecentIndexJob: (emptyReindexJob: IIndexingStatusProps) => emptyReindexJob,
    showFieldSuccess: (successMessage: string) => successMessage,
    setFieldName: (rawFieldName: string) => rawFieldName,
    openAddFieldModal: true,
    closeAddFieldModal: true,
    resetSchemaState: true,
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages })
  }),
  reducers: ({ actions }) => ({
    activeSchema: [{}, {
      [actions.onInitializeSchema]: (_, { schema }) => schema,
      [actions.onInitializeSchemaMetaEngine]: (_, { schema }) => schema,
      [actions.onSchemaSetSuccess]: (_, { schema }) => schema,
      [actions.onFieldTypeUpdate]: (_, { schema }) => schema
    }],
    serverSchema: [{}, {
      [actions.onInitializeSchema]: (_, { schema }) => schema,
      [actions.onSchemaSetSuccess]: (_, { schema }) => schema
    }],
    mostRecentIndexJob: [{}, {
      [actions.onInitializeSchema]: (_, { mostRecentIndexJob }) => mostRecentIndexJob,
      [actions.resetMostRecentIndexJob]: (_, emptyReindexJob) => emptyReindexJob,
      [actions.onSchemaSetSuccess]: (_, { mostRecentIndexJob }) => mostRecentIndexJob,
      [actions.onIndexingComplete]: (state, numDocumentsWithErrors) => ({
        ...state,
        numDocumentsWithErrors,
        percentageComplete: 100,
        hasErrors: numDocumentsWithErrors > 0,
        isActive: false
      })
    }],
    flashMessages: [{}, {
      [actions.resetMostRecentIndexJob]: () => ({}),
      [actions.resetSchemaState]: () => ({}),
      [actions.onSchemaSetSuccess]: (_, { flashMessages }) => flashMessages,
      [actions.onSchemaSetError]: (_, { flashMessages }) => flashMessages,
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages
    }],
    newFieldType: [TEXT, {
      [actions.updateNewFieldType]: (_, newFieldType) => newFieldType,
      [actions.onSchemaSetSuccess]: () => TEXT
    }],
    addFieldFormErrors: [null, {
      [actions.onSchemaSetSuccess]: () => null,
      [actions.closeAddFieldModal]: () => null,
      [actions.onSchemaSetFormErrors]: (_, addFieldFormErrors) => addFieldFormErrors
    }],
    unsearchedUnconfirmedFields: [false, {
      [actions.onSchemaSetSuccess]: (_, { unsearchedUnconfirmedFields }) => unsearchedUnconfirmedFields
    }],
    unconfirmedFields: [[], {
      [actions.onInitializeSchema]: (_, { unconfirmedFields }) => unconfirmedFields,
      [actions.onSchemaSetSuccess]: (_, { unconfirmedFields }) => unconfirmedFields
    }],
    formUnchanged: [true, {
      [actions.onSchemaSetSuccess]: () => true,
      [actions.onFieldTypeUpdate]: (_, { formUnchanged }) => formUnchanged
    }],
    showAddFieldModal: [false, {
      [actions.onSchemaSetSuccess]: () => false,
      [actions.onSchemaSetError]: () => false,
      [actions.openAddFieldModal]: () => true,
      [actions.closeAddFieldModal]: () => false
    }],
    dataLoading: [true, {
      [actions.onSchemaSetSuccess]: () => false,
      [actions.onInitializeSchema]: () => false,
      [actions.onInitializeSchemaMetaEngine]: () => false,
      [actions.resetSchemaState]: () => true
    }],
    rawFieldName: ['', {
      [actions.setFieldName]: (_, rawFieldName) => rawFieldName,
      [actions.onSchemaSetSuccess]: () => ''
    }],
    conflictingFields: [{}, {
      [actions.onInitializeSchemaMetaEngine]: (_, { conflictingFields }) => conflictingFields
    }],
    fields: [{}, {
      [actions.onInitializeSchemaMetaEngine]: (_, { fields }) => fields
    }],
    fieldCoercionErrors: [{}, {
      [actions.onInitializeSchemaFieldErrors]: (_, { fieldCoercionErrors }) => fieldCoercionErrors
    }]
  }),
  thunks: ({ actions, get }) => ({
    initializeSchema: () => {
      const route = routes.locoMocoEngineSchemaPath(get('engineName'))
      http(route)
        .then(({ data }) => actions.onInitializeSchema(data))
    },
    initializeSchemaMeta: () => {
      const route = routes.locoMocoEngineSchemaPath(get('engineName'))
      http(route)
        .then(({ data }) => actions.onInitializeSchemaMetaEngine(data))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    initializeSchemaFieldErrors: (activeReindexJobId) => {
      const route = routes.locoMocoEngineReindexJobPath(get('engineName'), activeReindexJobId)
      http(route)
        .then(({ data: { fieldCoercionErrors } }) => actions.onInitializeSchemaFieldErrors({ fieldCoercionErrors }))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    addNewField: (fieldName: string, newFieldType: string) => {
      const schema = _cloneDeep(get('activeSchema'))
      schema[fieldName] = newFieldType
      actions.setServerField(schema, ADD)
    },
    updateExistingFieldType: (fieldName: string, newFieldType: string) => {
      const schema = _cloneDeep(get('activeSchema'))
      schema[fieldName] = newFieldType
      actions.onFieldTypeUpdate({ schema, formUnchanged: _isEqual(get('serverSchema'), schema) })
    },
    updateFields: () => actions.setServerField(get('activeSchema'), UPDATE),
    setServerField: (updatedSchema, operation) => {
      const isAdding = operation === ADD
      const engineSlug = get('engineName')
      const successMessage = isAdding ? 'New field added.' : 'Schema updated.'
      const route = routes.locoMocoEngineSchemaPath(engineSlug)
      const emptyReindexJob = {
        engineSlug,
        percentageComplete: 100,
        numDocumentsWithErrors: 0,
        activeReindexJobId: 0,
        isActive: false
      }

      actions.resetMostRecentIndexJob(emptyReindexJob)

      http.post(route, updatedSchema)
        .then(({ data }) => {
          window.scrollTo(0, 0)

          if (!_isEmpty(data.mostRecentIndexJob)) {
            actions.setIndexingStatus(data.mostRecentIndexJob)
          }

          actions.onSchemaSetSuccess({
            ...data,
            showAddFieldModal: false,
            flashMessages: { success: [successMessage] }
          })
        })
        .catch(handleAPIError(messages => {
          window.scrollTo(0, 0)
          isAdding ?
            actions.onSchemaSetFormErrors(messages) :
            actions.onSchemaSetError({ flashMessages: { error: messages } })
        }))
    }
  })
})

export default SchemaLogic
