import React from 'react'

import AnalyticsSectionContent, { IAnalyticsSectionContentProps } from './AnalyticsSectionContent'

const QueryDetail: React.SFC<IAnalyticsSectionContentProps> = (props) => (
  <AnalyticsSectionContent
    title="Top Queries"
    isDetails={true}
    {...props}
  />
)

export default QueryDetail
