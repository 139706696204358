import Raven from 'raven-js'
import React from 'react'

export const ravenInstall = ({ dsn, ...options }: { dsn: string } & Raven.RavenOptions) => {
  Raven.config(dsn, options).install()
}

interface IRavenBoundaryState {
  error: Error | null
}

export class RavenBoundary extends React.Component<{}, IRavenBoundaryState> {
  readonly state: IRavenBoundaryState = {
    error: null
  }

  componentDidCatch(error: Error, extra: React.ErrorInfo) {
    this.setState({ error })
    Raven.captureException(error, { extra })
  }

  render() {
    if (this.state.error) {
      return null
    }
    return this.props.children
  }
}

export const captureException = (error, extra?) => Raven.captureException(error, { extra })
