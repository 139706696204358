import React from 'react'

import { Link } from 'react-router-dom'

import { useValues } from 'kea'

import { AppLogic } from 'app_search/App'
import { EngineLogic } from 'app_search/Engine'

import {
  CREDENTIALS_PATH
} from 'app_search/utils/routePaths'

import { apiTokenSort } from './CredentialsLogic'
import CredentialsToken from './CredentialsToken'

import StuiCopyToCipboard from 'stui/CopyToClipboard'

import {
  EuiBadge,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer
} from '@elastic/eui'

interface ICredentialsModalProps {
  onClose()
}

const CredentialsModal: React.SFC<ICredentialsModalProps> = ({ onClose }) => {
  const { account: { accountKey }, apiUrl } = useValues(AppLogic)
  const { engine: { apiTokens } } = useValues(EngineLogic)

  return (
    <EuiOverlayMask>
      <EuiModal onClose={onClose} data-test-subj="CredentialsModal">
        <EuiModalHeader>
          <EuiModalHeaderTitle>Credentials</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiSpacer />
          <div className="o-stui-credentials-list">
            {!process.env.LOCO_TOGO && <div className="o-stui-credential">
              <div className="o-stui-credential__name u-stui-nowrap">Host Identifier</div>
              <StuiCopyToCipboard content={accountKey} />
              <span data-test-subj="CredentialsHostIdentifier">{accountKey}</span>
            </div>}
            <div className="o-stui-credential">
              <div className="o-stui-credential__name u-stui-nowrap">API Endpoint</div>
              <StuiCopyToCipboard content={apiUrl} />
              <span data-test-subj="CredentialsAPIEndpoint">{apiUrl}</span>
            </div>
          </div>
          <EuiSpacer />
          <div className="c-stui-header">
            <div className="c-stui-header__content">
              <h3 className="euiTitle euiTitle--small">API Keys</h3>
            </div>
          </div>
          <EuiSpacer size="m" />
          <div className="o-stui-credentials-list">
            {apiTokens.slice().sort(apiTokenSort).map(({ name, key, type, read, write }) => (
              <div className="o-stui-credential" key={key}>
                <div className="o-stui-credential__name"><span>{name}</span></div>
                <CredentialsToken isPrivate={type === 'private'} token={key || ''} />
                <div className="o-stui-credential__permissions">
                  {type === 'search' && <EuiBadge color="warning">S</EuiBadge>}
                  {(type === 'private' && read) && <EuiBadge color="primary">R</EuiBadge>}
                  {(type === 'private' && write) && <EuiBadge color="secondary">W</EuiBadge>}
                </div>
              </div>
            ))}
          </div>
        </EuiModalBody>
        <EuiModalFooter>
          <Link data-test-subj="CredentialsModalManageButton" to={CREDENTIALS_PATH}>
            <EuiButtonEmpty>Manage</EuiButtonEmpty>
          </Link>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}

export default CredentialsModal
