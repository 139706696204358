import React from 'react'

import classNames from 'classnames'

interface IStuiTableHeadProps {
  children: React.ReactNode
  className?: string
}

const StuiTableHead: React.SFC<IStuiTableHeadProps> = ({ children, className }) => (
  <thead className={classNames('c-stui-table-header', className)}>
    {children}
  </thead>
)

export default StuiTableHead
