import React from 'react'
import { range, uniq } from 'underscore'

import classNames from 'classnames'

import { IMetaPage } from 'app_search/types'

import { EuiSpacer } from '@elastic/eui'

interface IStuiPaginationProps {
  metaPage: IMetaPage
  handlePageClick(page: number)
}

const MAX_PAGES = 100
const PAGE_SEPARATOR = ':separator:'

const relevantPages = (currentPage, totalPages, { leftSize = 2, rightSize = 2, innerSize = 1 } = {}) => {
  const leftWindow = range(1, leftSize + 1)
  const rightWindow = range(totalPages - rightSize + 1, totalPages + 1)
  const innerWindow = range(currentPage - innerSize, currentPage + innerSize + 1)

  let pages = [...leftWindow, ...innerWindow, ...rightWindow].filter(page => page > 0 && page <= totalPages)
  pages.sort((a, b) => a - b) // Sort numerically
  pages = uniq(pages, true)

  const result = Array<number|string>()
  for (let i = 0; i < pages.length; i++) {
    if (i > 0 && pages[i] !== pages[i - 1] + 1) {
      // Insert a page separator when encountering a non-consecutive page number
      result.push(PAGE_SEPARATOR)
    }
    result.push(pages[i])
  }

  return result
}

const StuiPagination: React.SFC<IStuiPaginationProps> = (props) => {
  const { handlePageClick, metaPage } = props
  const { current, total_pages: totalPages } = metaPage
  const pages = totalPages < MAX_PAGES ? totalPages : MAX_PAGES

  const pageCssClass = (page) => classNames(
    'c-stui-pagination__page',
    { 'c-stui-pagination__page--active': page === current }
  )

  const previousCssClass = classNames(
    'c-stui-pagination__previous',
    { 'u-stui-disabled': current <= 1 }
  )

  const nextCssClass = classNames(
    'c-stui-pagination__next',
    { 'u-stui-disabled': current >= pages }
  )

  const pageLink = (page) => (
    <a className={pageCssClass(page)} onClick={(event) => onClick(event, page)} href="#">{page}</a>
  )

  const onClick = (event, page) => {
    event.preventDefault()
    handlePageClick(page)
  }

  const separator = () => <span className="c-stui-pagination__separator">…</span>

  return (
    <div className="c-stui-pagination">
      <a className={previousCssClass} onClick={(event) => onClick(event, current - 1)} href="#">← Previous</a>
      {relevantPages(current, totalPages).map((page, index) => (
        <React.Fragment key={index}>
         {page === PAGE_SEPARATOR ? separator() : pageLink(page)}
        </React.Fragment>
      ))}
      <a className={nextCssClass} onClick={(event) => onClick(event, current + 1)} href="#">Next →</a>
      <EuiSpacer />
    </div>
  )
}

export default StuiPagination
