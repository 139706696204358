import React from 'react'

import classNames from 'classnames'

interface IStuiTableBodyCellProps {
  children?: React.ReactNode
  className?: string
  fixed?: boolean
  wrap?: boolean
  colspan?: number
}

const StuiTableBodyCell: React.SFC<IStuiTableBodyCellProps> = ({ children, className, fixed, wrap, colspan }) => (
  <td className={classNames('c-stui-table-cell', { 'c-stui-table-cell--fixed': fixed }, className)} colSpan={colspan}>
    <div className={classNames('c-stui-table-cell__content', { 'c-stui-table-cell__content--wrap': wrap })}>
      {children}
    </div>
  </td>
)

export default StuiTableBodyCell
