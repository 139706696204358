import { Role } from 'app_search/classes'
import routes from 'app_search/routes'
import {
  IAccountProps,
  ICurrentUser,
  IObject,
  IRole,
  IServerAppProps,
  ISupportedLanguages
} from 'app_search/types'
import http from 'shared/http'
import { storeLogic } from 'shared/store'

export const AppLogic = storeLogic({
  actions: () => ({
    initializeAppData: (componentProps: IServerAppProps) => componentProps,
    billingUpdate: (role: IRole, account: IAccountProps) => ({ role, account }),
    freeTrialUpdate: (account: IAccountProps) => ({ account }),
    telemetryUpdate: (account: IAccountProps) => ({ account }),
    telemetrySent: (lastSentAt: string) => ({ lastSentAt }),
    setServerRole: (role: IRole) => role,
    setSeedingSampleEngine: (seedingSampleEngine: boolean) => seedingSampleEngine,
    setOnboardingComplete: true,
    updateAccount: (account: IAccountProps) => ({ account })
  }),
  reducers: ({ actions }) => ({
    role: [{} as IRole, {
      [actions.billingUpdate]: (_, { role }) => role,
      [actions.setServerRole]: (_, role) => role
    }],
    currentUser: [{} as ICurrentUser, {
      [actions.initializeAppData]: (_, { currentUser }) => currentUser
    }],
    account: [{} as IAccountProps, {
      [actions.initializeAppData]: (_, { account }) => account,
      [actions.billingUpdate]: (_, { account }) => account,
      [actions.freeTrialUpdate]: (_, { account }) => account,
      [actions.telemetryUpdate]: (_, { account }) => account,
      [actions.telemetrySent]: (state, { lastSentAt }) => ({
        ...state,
        telemetryLastSentAt: lastSentAt
      }),
      [actions.setOnboardingComplete]: state => ({
        ...state,
        onboardingComplete: true
      }),
      [actions.setSeedingSampleEngine]: (state, seedingSampleEngine) => ({
        ...state,
        seedingSampleEngine
      }),
      [actions.updateAccount]: (_, { account }) => account
    }],
    supportedLanguages: [{} as ISupportedLanguages, {
      [actions.initializeAppData]: (_, { supportedLanguages }) => supportedLanguages
    }],
    apiUrl: ['', {
      [actions.initializeAppData]: (_, { apiUrl }) => apiUrl || ''
    }],
    appVersion: ['', {
      [actions.initializeAppData]: (_, { appVersion }) => appVersion
    }],
    newFeatures: [[] as IObject[], {
      [actions.initializeAppData]: (_, { newFeatures }) => newFeatures
    }],
    serverStateInitialized: [false, {
      [actions.initializeAppData]: () => true
    }]
  }),
  selectors: ({ selectors }) => ({
    myRole: [
      () => [selectors.role],
      role => new Role(role)
    ]
  }),
  thunks: ({ actions, values }) => ({
    fetchLatestAccountState: () => {
      const { account: { accountId } } = values
      const url = routes.globalAccountStateLocoMocoAccountPath(accountId)

      http(url)
        .then(({ data: { account } }) => {
          actions.updateAccount(account)
        })
    }
  })
})

export default AppLogic
