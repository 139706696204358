import React, { useEffect, useState, useRef } from 'react'

import axios, { Canceler, CancelToken } from 'axios'
import { Motion, spring } from 'react-motion'

import http from 'shared/http'

interface IIndexingStatusFetcherProps {
  activeReindexJobId: number
  itemId: string
  percentageComplete: number
  numDocumentsWithErrors: number
  onComplete?(numDocumentsWithErrors: number)
  getStatusPath(itemId: string, activeReindexJobId: number)
  children(percentageComplete: number, numDocumentsWithErrors: number): JSX.Element
}

interface IIndexingStatus {
  numDocumentsWithErrors: number
  percentageComplete: number
}

interface IMotionStatusProps {
  defaultStatus: IIndexingStatus,
  currentStatus: IIndexingStatus
  children(percentageComplete: number, numDocumentsWithErrors: number): JSX.Element
}

const MotionStatus: React.SFC<IMotionStatusProps> = ({ defaultStatus, currentStatus, children }) => (
  <Motion
    defaultStyle={{
      percentageComplete: defaultStatus.percentageComplete
    }}
    style={{ percentageComplete: spring(currentStatus.percentageComplete), numDocumentsWithErrors: currentStatus.numDocumentsWithErrors }}
  >
    {({ percentageComplete, numDocumentsWithErrors }) => children(percentageComplete, numDocumentsWithErrors)}
  </Motion >
)

const IndexingStatusFetcher: React.SFC<IIndexingStatusFetcherProps> = ({
  activeReindexJobId,
  children,
  getStatusPath,
  itemId,
  numDocumentsWithErrors,
  onComplete,
  percentageComplete = 0
}) => {
  const [indexingStatus, setIndexingStatus] = useState({ numDocumentsWithErrors, percentageComplete })
  const pollingCancelFunction = useRef<Canceler>()
  const pollingInterval = useRef<number>()
  const pollingCancelToken = useRef<CancelToken>(axios.CancelToken.source().token)

  useEffect(() => {
    pollingInterval.current = window.setInterval(
      () => {
        http.get(getStatusPath(itemId, activeReindexJobId), {
          cancelToken: pollingCancelToken.current
        })
          .then(({ data }: { data: IIndexingStatus }) => {
            if (data.percentageComplete >= 100) {
              clearInterval(pollingInterval.current)
            }
            setIndexingStatus({ percentageComplete: data.percentageComplete, numDocumentsWithErrors: data.numDocumentsWithErrors })
            if (data.percentageComplete >= 100 && onComplete) {
              onComplete(data.numDocumentsWithErrors)
            }

          }).catch(error => {
            if (!axios.isCancel(error)) {
              throw (error)
            }
          })
      },
      3000
    )

    return () => {
      if (pollingCancelFunction.current) {
        pollingCancelFunction.current()
      }
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current)
      }
    }
  }, [])

  return (
    <MotionStatus
      currentStatus={indexingStatus}
      defaultStatus={{ percentageComplete, numDocumentsWithErrors }}
      children={children}
    />
  )
}

export default IndexingStatusFetcher
