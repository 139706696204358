import React from 'react'

import {
  EuiButton,
  EuiForm,
  EuiIcon
} from '@elastic/eui'

import { IObject } from 'app_search/types'

import { HIGH_METERED_PLAN_NAME, LOW_METERED_PLAN_NAME, predefinedPlans } from 'app_search/Billing/plans'
import { CONTACT_SALES_URL } from 'shared/constants/urls'

interface IPlanSelectorProps {
  accountBillingMeta: IObject
  canManagePlan: boolean
  openUpdatePaymentMethodModalWithPlan(selectedPlan: IObject)
  onPlanSelect(selectedPlan: string)
}

const PlanSelector: React.SFC< IPlanSelectorProps > = ({ openUpdatePaymentMethodModalWithPlan, onPlanSelect, accountBillingMeta, canManagePlan }) => {
  function handlePlanSelect(selectedPlanName) {
    onPlanSelect(selectedPlanName)
  }

  return (
    <div
      className="c-stui-pricing-grid"
      data-test-subj="PlanSelector"
    >
      <div className="c-stui-pricing-card">
        <div className="c-stui-pricing-card__header">
          <div className="c-stui-pricing-card__name">Standard</div>
          <div className="c-stui-pricing-card__price">$49</div>
        </div>
        <div className="c-stui-pricing-card__limits">
          <div className="o-stui-label">Includes</div>
          <div className="o-stui-pricing-limit">
            <div className="o-stui-pricing-limit__title">50k documents</div>
            <div className="o-stui-pricing-limit__description">+$20 per additional 25k</div>
            <div className="o-stui-pricing-limit__note">*10KB max document size</div>
          </div>
          <div className="o-stui-pricing-limit">
            <div className="o-stui-pricing-limit__title">500k API calls</div>
            <div className="o-stui-pricing-limit__description">+$5 per additional 50k</div>
          </div>
          <div className="o-stui-pricing-limit">
            <div className="o-stui-pricing-limit__title">1 Engine</div>
            <div className="o-stui-pricing-limit__description">+$25 per additional Engine</div>
          </div>
        </div>
        <div className="c-stui-pricing-card__features">
          <ul className="o-stui-pricing-features">
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="secondary" type="check"/> Basic Analytics
            </li>
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="secondary" type="check"/> 7 days of analytics history
            </li>
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="secondary" type="check"/> Advanced API access
            </li>
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="secondary" type="check"/> Basic security controls
            </li>
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="secondary" type="check"/> API logs
            </li>
          </ul>
        </div>
        <div className="c-stui-pricing-card__actions">
          { accountBillingMeta.plan.description === 'Standard' ?
            (
              <a className="euiButton euiButton--disabled">
                <span className="euiButton__content">
                  <span className="euiButton__text">Current Plan</span>
                </span>
              </a>
            ) : canManagePlan &&
              (accountBillingMeta.hasCard  ?
                (
                  <EuiForm>
                    <form>
                      <div className="o-stui-input-container">
                        <EuiButton
                          fill={true}
                          color="secondary"
                          onClick={() => handlePlanSelect(LOW_METERED_PLAN_NAME)}
                          data-test-subj="StandardPlanSelectButton"
                        >
                            Select
                        </EuiButton>
                      </div>
                    </form>
                  </EuiForm>
                ) : (
                  <EuiButton
                    className="current-payment-method__update"
                    fill={true}
                    color="secondary"
                    data-test-subj="StandardPlanSelectButton"
                    onClick={() => openUpdatePaymentMethodModalWithPlan(predefinedPlans[LOW_METERED_PLAN_NAME])}
                  >
                    Select
                  </EuiButton>
                )
              )
          }
        </div>
      </div>
      <div className="c-stui-pricing-card c-stui-pricing-card--featured">
        <div className="c-stui-pricing-card__header">
          <div className="c-stui-pricing-card__name">Pro</div>
          <div className="c-stui-pricing-card__price">$199</div>
        </div>
        <div className="c-stui-pricing-card__limits">
          <div className="o-stui-label">Includes</div>
          <div className="o-stui-pricing-limit">
            <div className="o-stui-pricing-limit__title">100k documents</div>
            <div className="o-stui-pricing-limit__description">+$20 per additional 25k</div>
            <div className="o-stui-pricing-limit__note">*100KB max document size</div>
          </div>
          <div className="o-stui-pricing-limit">
            <div className="o-stui-pricing-limit__title">1M API calls</div>
            <div className="o-stui-pricing-limit__description">+$5 per additional 50k</div>
          </div>
          <div className="o-stui-pricing-limit">
            <div className="o-stui-pricing-limit__title">1 Engine</div>
            <div className="o-stui-pricing-limit__description">+$100 per additional Engine</div>
          </div>
        </div>
        <div className="c-stui-pricing-card__features">
          <div className="o-stui-label">All Standard features plus:</div>
          <ul className="o-stui-pricing-features">
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="ghost" type="check"/> Advanced analytics, including searches, clicks, and other insights
            </li>
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="ghost" type="check"/> 6 months of analytics history
            </li>
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="ghost" type="check"/> Advanced API access
            </li>
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="ghost" type="check"/> Granular security controls
            </li>
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="ghost" type="check"/> Extended API log retention
            </li>
          </ul>
        </div>
        <div className="c-stui-pricing-card__actions">
          { accountBillingMeta.plan.description === 'Pro' ?
            (
              <a className="euiButton euiButton--disabled">
                <span className="euiButton__content">
                  <span className="euiButton__text">Current Plan</span>
                </span>
              </a>
            ) : canManagePlan &&
              (accountBillingMeta.hasCard ?
                (
                  <EuiForm>
                    <form>
                      <div className="o-stui-input-container">
                        <EuiButton
                          fill={true}
                          color="secondary"
                          onClick={() => handlePlanSelect(HIGH_METERED_PLAN_NAME)}
                          data-test-subj="ProPlanSelectButton"
                        >
                          Select
                        </EuiButton>
                      </div>
                    </form>
                  </EuiForm>
                ) : (
                  <EuiButton
                    className="current-payment-method__update"
                    fill={true}
                    color="secondary"
                    data-test-subj="ProPlanSelectButton"
                    onClick={() => openUpdatePaymentMethodModalWithPlan(predefinedPlans[HIGH_METERED_PLAN_NAME])}
                  >
                    Select
                  </EuiButton>
                )
              )
          }
        </div>
      </div>
      <div className="c-stui-pricing-card">
        <div className="c-stui-pricing-card__header">
          <div className="c-stui-pricing-card__name">Premium</div>
          <div className="c-stui-pricing-card__price">$1,999+</div>
        </div>
        <div className="c-stui-pricing-card__limits">
          <div className="o-stui-volume-pricing-message">
          <strong>Volume Pricing</strong>
          <em>for documents, API calls, and Engines</em>
          </div>
        </div>
        <div className="c-stui-pricing-card__features">
          <div className="o-stui-label">All Pro features plus:</div>
          <ul className="o-stui-pricing-features">
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="secondary" type="check"/> Custom analytics history
            </li>
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="secondary" type="check"/> Custom security controls
            </li>
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="secondary" type="check"/> Custom API log retention
            </li>
            <li className="o-stui-pricing-features__feature">
              <EuiIcon color="secondary" type="check"/> Dedicated 24/7 Support
            </li>
          </ul>
        </div>
        <div className="c-stui-pricing-card__actions">
          <EuiButton
            fill={true}
            color="primary"
            href={CONTACT_SALES_URL}
          >
            Get in touch
          </EuiButton>
        </div>
      </div>
    </div>
  )
}

export default PlanSelector
