import routes from 'app_search/routes'
import http from 'shared/http'

import { AppLogic } from 'app_search/App'
import { IEngine } from 'app_search/types'
import { storeLogic } from 'shared/store'

export interface IFreeTrialExtend {
  isOnboarding: boolean
  hasEngine: boolean
  firstEngine?: IEngine
}

export const FreeTrialExtendLogic = storeLogic({
  connect: () => ({
    actions: [
      AppLogic, ['freeTrialUpdate']
    ]
  }),
  actions: () => ({
    setTrialExtendData: (trialProps: IFreeTrialExtend) => trialProps
  }),
  reducers: ({ actions }) => ({
    isOnboarding: [false, {
      [actions.setTrialExtendData]: (_, { isOnboarding }) => isOnboarding
    }],
    hasEngine: [true, {
      [actions.setTrialExtendData]: (_, { hasEngine }) => hasEngine
    }],
    firstEngine: [null, {
      [actions.setTrialExtendData]: (_, { firstEngine }) => firstEngine
    }],
    dataLoading: [true, {
      [actions.setTrialExtendData]: () => false
    }]
  }),
  thunks: ({ actions }) => ({
    initializeTrialExtend: () => {
      http(routes.locoMocoExtendTrialPath())
        .then(response => {
          const { newGlobalState: { account } , ...data } = response.data
          actions.setTrialExtendData(data)
          actions.freeTrialUpdate(account)
        })
    }
  })
})

export default FreeTrialExtendLogic
