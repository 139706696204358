import { AppLogic } from 'app_search/App'
import { Ability } from 'app_search/classes'
import routes from 'app_search/routes'
import { IObject, IRoleMapping, TRole } from 'app_search/types'
import handleAPIError from 'app_search/utils/handleAPIError'
import { NOT_FOUND, ROLE_MAPPINGS_PATH } from 'app_search/utils/routePaths'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

const ROLE_MAPPINGS_SERVER_PATH = !!process.env.LOCO_TOGO ? routes.locoTogoRoleMappingsPath() : null
const DELETE_MESSAGE = 'Are you sure you want to permanently delete this mapping? This action is not reversible and some users might lose access.'

interface IRoleMappingsServerDetails {
  flashMessages?: IStuiFlashMessagesProps
  roleMappings: IRoleMapping[]
}

interface IRoleMappingServerDetails {
  flashMessages?: IStuiFlashMessagesProps
  roleMappings: IRoleMapping[]
  history: IObject
}

export const RoleMappingsLogic = storeLogic({
  connect: () => ({
    values: [
      AppLogic, ['myRole']
    ]
  }),
  actions: () => ({
    setRoleMappingsData: (data: IRoleMappingsServerDetails) => data,
    setRoleMappingData: (data: IRoleMappingServerDetails) => data,
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages }),
    handleRoleChange: (roleType: TRole) => ({ roleType }),
    handleEngineSelectionChange: (engineName: string, selected: boolean) => ({ engineName, selected }),
    handleAttributeSelectorChange: (value: string, firstElasticsearchRole: string) => ({ value, firstElasticsearchRole }),
    handleAttributeValueChange: (value: string) => ({ value }),
    handleAccessAllEnginesChange: true,
    resetState: true
  }),
  reducers: ({ actions }) => ({
    dataLoading: [true, {
      [actions.setRoleMappingsData]: () => false,
      [actions.setRoleMappingData]: () => false,
      [actions.resetState]: () => true
    }],
    flashMessages: [{}, {
      [actions.setRoleMappingsData]: (_, { flashMessages }) => flashMessages || {},
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages,
      [actions.resetState]: () => ({})
    }],
    roleMappings: [[], {
      [actions.setRoleMappingsData]: (_, { roleMappings }) => roleMappings,
      [actions.resetState]: () => []
    }],
    hasAdvancedRoles: [false, {
      [actions.setRoleMappingData]: (_, { hasAdvancedRoles }) => hasAdvancedRoles
    }],
    availableEngines: [[], {
      [actions.setRoleMappingData]: (_, { availableEngines }) => availableEngines
    }],
    attributes: [[], {
      [actions.setRoleMappingData]: (_, { attributes }) => attributes
    }],
    elasticsearchRoles: [[], {
      [actions.setRoleMappingData]: (_, { elasticsearchRoles }) => elasticsearchRoles
    }],
    roleMapping: [{}, {
      [actions.setRoleMappingData]: (_, { roleMapping }) => roleMapping || null
    }],
    roleType: ['owner', {
      [actions.setRoleMappingData]: (_, { roleMapping }) => roleMapping ? roleMapping.roleType : 'owner',
      [actions.handleRoleChange]: (_, { roleType }) => roleType
    }],
    accessAllEngines: [true, {
      [actions.setRoleMappingData]: (_, { roleMapping }) => roleMapping ? roleMapping.accessAllEngines : true,
      [actions.handleRoleChange]: (_, { roleType }) => !Ability.canHaveScopedEngines(roleType),
      [actions.handleAccessAllEnginesChange]: (accessAllEngines) => !accessAllEngines
    }],
    attributeValue: ['', {
      [actions.setRoleMappingData]: (_, { roleMapping }) => roleMapping ? roleMapping.attributeValue : '',
      [actions.handleAttributeSelectorChange]: (_, { value, firstElasticsearchRole }) => value === 'role' ? firstElasticsearchRole : '',
      [actions.handleAttributeValueChange]: (_, { value }) => value
    }],
    attributeName: ['username', {
      [actions.setRoleMappingData]: (_, { roleMapping }) => roleMapping ? roleMapping.attributeName : 'username',
      [actions.handleAttributeSelectorChange]: (_, { value }) => value
    }],
    selectedEngines: [new Set(), {
      [actions.setRoleMappingData]: (_, { roleMapping }) => roleMapping ? new Set(roleMapping.engines.map(engine => (engine.name))) : new Set(),
      [actions.handleAccessAllEnginesChange]: () => new Set(),
      [actions.handleEngineSelectionChange]: (engines, { engineName, selected }) => {
        const newSelectedEngineNames = new Set(engines as Set<string>)
        if (selected) {
          newSelectedEngineNames.add(engineName)
        } else {
          newSelectedEngineNames.delete(engineName)
        }

        return newSelectedEngineNames
      }
    }],
    routerHistory: [{}, {
      [actions.setRoleMappingData]: (_, { history }) => history
    }]
  }),
  thunks: ({ actions, get }) => ({
    initializeRoleMappings: () => http(routes.collectionLocoTogoRoleMappingsPath())
      .then(({ data }) => actions.setRoleMappingsData(data)),
    initializeRoleMapping: (history: IObject, roleId?: string) => {
      const url = roleId ? routes.locoTogoRoleMappingPath(roleId) : routes.newLocoTogoRoleMappingPath()

      http(url)
        .then(({ data }) => actions.setRoleMappingData({ ...data, history }))
        .catch(error => {
          const { response } = error
          const errorHandler = handleAPIError(messages => {
            response && response.status === 404
              ? history.push(NOT_FOUND)
              : actions.setFlashMessages({ error: messages })
          })
          errorHandler(error)
        })
    },
    handleDeleteMapping: () => {
      const roleMapping = get('roleMapping')
      const routerHistory = get('routerHistory')
      if (!roleMapping) { return }
      if (window.confirm(DELETE_MESSAGE)) {
        http.delete(routes.locoTogoRoleMappingPath(roleMapping.id))
          .then(() => { routerHistory.push(ROLE_MAPPINGS_PATH) })
          .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
      }
    },
    handleResetMappings: async (callback) => {
      try {
        await http.post(routes.resetLocoTogoRoleMappingsPath())
        actions.initializeRoleMappings()
      } catch (error) {
        const errorHandler = handleAPIError(messages => actions.setFlashMessages({ error: messages }))
        errorHandler(error)
      } finally {
        callback()
      }
    },
    handleSaveMapping: () => {
      const attributeName = get('attributeName')
      const attributeValue = get('attributeValue')
      const roleType = get('roleType')
      const roleMapping = get('roleMapping')
      const accessAllEngines = get('accessAllEngines')
      const selectedEngines = get('selectedEngines')
      const routerHistory = get('routerHistory')

      const payload = {
        ...{ attributeName, attributeValue, roleType, accessAllEngines },
        engines: Array.from(selectedEngines),
        ...(!!roleMapping ? { id: roleMapping.id } : {})
      }
      const request = !roleMapping ? http.post(ROLE_MAPPINGS_SERVER_PATH, payload) : http.put(routes.locoTogoRoleMappingPath(roleMapping.id), payload)
      request
        .then(() => { routerHistory.push(ROLE_MAPPINGS_PATH) })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    }
  })
})
