import React from 'react'

import classNames from 'classnames'
import _pull from 'lodash/pull'

import StuiFormattedContent from 'stui/FormattedContent'
import StuiIcon from 'stui/Icon'
import { StuiResultFieldValue } from 'stui/Result'
import {
  StuiTable,
  StuiTableBody,
  StuiTableBodyCell,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'
import StuiTooltip from 'stui/Tooltip'

import { IObject } from 'app_search/types'
import { TEXT } from 'shared/constants/fieldTypes'

interface ICurationsTableProps {
  className?: string
  docType: string
  documents: IObject[]
  dragging?: boolean
  header: React.ReactNode
  over?: boolean
  updating?: boolean
  reference?: any
  schema: IObject
  handlePromoteClick?(id: string)
  handleHideClick?(id: string)
}

const CurationsTable: React.SFC<ICurationsTableProps> = ({
  className,
  docType,
  dragging,
  header,
  over,
  updating,
  documents,
  reference,
  schema,
  handlePromoteClick,
  handleHideClick
}) => {
  const hasDocuments = documents.length > 0
  const documentKeys = hasDocuments ? _pull(Object.keys(documents[0]), 'id', '_meta') : []
  const isPromoted = docType === 'promoted'
  const isOrganic = docType === 'organic'
  const isHidden = docType === 'hidden'
  const getHandleCss = (btnType, isActive = false) => classNames(
    'o-stui-pinning-control',
    `o-stui-pinning-control--${btnType}`,
    {
      'o-stui-pinning-control--active': !!isActive
    }
  )

  const containerCss = classNames(
    {
      'c-stui-documents-container--empty': !hasDocuments,
      'c-stui-documents-container--drag-active': dragging,
      'c-stui-documents-container--drag-over': over
    },
    className
  )

  const resultTableClass = (type) => (classNames(
    'c-stui-curated-results-container',
    `c-stui-curated-results-container--${type}`,
    {
      'c-stui-curated-results-container--empty': documents.length === 0
    }
  ))

  const getDocumentDisplay = (document) => {
    const value = isOrganic ? document.raw : document
    return Array.isArray(value) ? value.join(', ') : value
  }

  return (
    <div className={resultTableClass(docType)}>
      {header}
      <StuiTable fixedColumns={true} className="c-stui-curated-results-table">
          {hasDocuments &&
            <StuiTableHead>
              <StuiTableRow>
                <StuiTableHeadCell fixed={true} />
                <StuiTableHeadCell fixed={true}>ID</StuiTableHeadCell>
                {documentKeys.map(((heading, index) => <StuiTableHeadCell key={index}>{heading}</StuiTableHeadCell>))}
              </StuiTableRow>
            </StuiTableHead>}
          <StuiTableBody reference={reference} className={containerCss}>
            {hasDocuments && documents.map((document, index) => {
              const id = isOrganic ? document.id.raw : document.id
              return (
                <StuiTableRow key={index} className={classNames({ dragging })} dragDropReference={id}>
                  <StuiTableBodyCell fixed={true} className="c-stui-table-cell--curation-controls">
                    <div className="o-stui-pinning-controls">
                      {!isHidden &&
                      <div className={getHandleCss('move')}>
                        <span className="handle-bar" />
                        <span className="handle-bar" />
                        <span className="handle-bar" />
                        <span className="handle-bar" />
                      </div>}
                      {handlePromoteClick &&
                        <a className={getHandleCss('promote', isPromoted)} onClick={() => handlePromoteClick(id)} data-test-subj={isPromoted ? 'DemoteCurationLink' : 'PromoteCurationLink'}>
                          <StuiTooltip content={isPromoted ? 'Demote this result' : 'Promote this result'}>
                            <StuiIcon name={isPromoted ? 'star-fill' : 'star'} />
                          </StuiTooltip>
                        </a>}
                      {handleHideClick &&
                        <a className={getHandleCss('hide', isHidden)} onClick={() => handleHideClick(id)} data-test-subj={isHidden ? 'ShowCurationLink' : 'HideCurationLink'}>
                          <StuiTooltip content={isHidden ? 'Show result' : 'Hide result'}>
                            <StuiIcon name={isHidden ? 'hide-fill' : 'hide'} />
                          </StuiTooltip>
                        </a>}
                    </div>
                  </StuiTableBodyCell>
                  <StuiTableBodyCell fixed={true}>
                    <StuiResultFieldValue raw={id} type="id" />
                  </StuiTableBodyCell>
                  {documentKeys.map((key, i) => {
                    const getCellContent = (field) => {
                      const fieldHasValue = isOrganic ? document[field] && (!!document[field].snippet || !!document[field].raw) : !!document[field]
                      if (!fieldHasValue) {
                        return <div className="u-stui-data-type--empty">&mdash;</div>
                      }
                      return schema[field] === TEXT ?
                        <StuiFormattedContent content={getDocumentDisplay(document[field])} /> :
                        <StuiResultFieldValue raw={getDocumentDisplay(document[field])} type={schema[field]} />
                    }
                    return <StuiTableBodyCell key={i}>{getCellContent(key)}</StuiTableBodyCell>
                  })}
                </StuiTableRow>
              )
            })}
          </StuiTableBody>
      </StuiTable>
      {updating &&
        <div className="c-stui-curated-results-container__loader">
          <div className="o-stui-loader o-stui-loader--xxl" />
        </div>
      }
    </div>
  )
}

export default CurationsTable
