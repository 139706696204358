import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIconTip,
  EuiSpacer,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiTextColor
} from '@elastic/eui'

import { IObject, IRole, IRoleMapping } from 'app_search/types'

interface IAccessItem {
  name: string
}

interface ISharedRoleMapping extends IRoleMapping {
  accessItems: IAccessItem[]
}

interface IRoleMappingsTableProps {
  accessItemKey: 'groups' | 'engines'
  accessHeader: string
  roleMappings: IObject[]
  addMappingButton: React.ReactNode
  accessAllEngines?: boolean
  myRole?: IRole
  getRoleMappingPath(roleId: string)
}

const MAX_CELL_WIDTH = 24

const noItemsPlaceholder = <EuiTextColor color="subdued">&mdash;</EuiTextColor>

const RoleMappingsTable: React.SFC<IRoleMappingsTableProps> = ({
  accessItemKey,
  accessHeader,
  roleMappings,
  addMappingButton,
  getRoleMappingPath,
  myRole
}) => {
  const [filterValue, updateValue] = useState('')

  // This is needed because SMAS has `engines` and SMES has `groups`.
  const standardizeRoleMapping = roleMappings.map(rm => {
    const _rm = { ...rm } as IObject
    _rm.accessItems = rm[accessItemKey]
    return _rm
  }) as ISharedRoleMapping[]

  const filterResults = (result) => {
    const values = Object.values(result) as string[]
    const regexp = new RegExp(filterValue, 'i')
    return values.filter(x => regexp.test(x)).length > 0
  }

  const filteredResults = standardizeRoleMapping.filter(filterResults) as ISharedRoleMapping[]

  return (
    <>
      <EuiFlexGroup justifyContent="spaceBetween">
        <EuiFlexItem>
          <EuiFieldSearch value={filterValue} placeholder="Filter roles..." onChange={e => updateValue(e.target.value)} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {addMappingButton}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      {filteredResults.length > 0 ?
        <EuiTable>
          <EuiTableHeader>
            <EuiTableHeaderCell>External Attribute</EuiTableHeaderCell>
            <EuiTableHeaderCell>Attribute Value</EuiTableHeaderCell>
            <EuiTableHeaderCell>Role</EuiTableHeaderCell>
            <EuiTableHeaderCell>{accessHeader}</EuiTableHeaderCell>
            <EuiTableHeaderCell />
          </EuiTableHeader>
          <EuiTableBody>
            {filteredResults.map(({ id, attributeName, attributeValue, roleType, accessAllEngines, accessItems, toolTip }) => (
              <EuiTableRow key={id}>
                <EuiTableRowCell>{attributeName}</EuiTableRowCell>
                <EuiTableRowCell style={{ maxWidth: MAX_CELL_WIDTH }}>{attributeValue}</EuiTableRowCell>
                <EuiTableRowCell>{roleType}</EuiTableRowCell>
                <EuiTableRowCell style={{ maxWidth: MAX_CELL_WIDTH }}>
                  {accessAllEngines ? 'All' : <>
                    {accessItems.length === 0 ?
                      noItemsPlaceholder :
                      accessItems.map(({ name }) => (<Fragment key={name}>{name}<br /></Fragment>))}
                  </>}
                </EuiTableRowCell>
                <EuiTableRowCell>
                  {(!myRole || myRole.ability.invitableRoleTypes().includes(roleType)) && id && <Link to={getRoleMappingPath(id)}>Manage</Link>}
                  {(!myRole || myRole.ability.invitableRoleTypes().includes(roleType)) && toolTip && <EuiIconTip position="left" content={toolTip.content} />}
                </EuiTableRowCell>
              </EuiTableRow>
            ))}
          </EuiTableBody>
        </EuiTable>
        : <p>No results found for '{filterValue}'</p>}
    </>
  )
}

export default RoleMappingsTable
