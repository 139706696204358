import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import classNames from 'classnames'

import { CurationsLogic } from './CurationsLogic'

import { IEngineDetails, IMatch, IObject } from 'app_search/types'

import {
  EuiFieldSearch,
  EuiSpacer
} from '@elastic/eui'

import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiIcon from 'stui/Icon'
import StuiMain from 'stui/Main'
import { StuiResult } from 'stui/Result'
import StuiTooltip from 'stui/Tooltip'

import CurationsBackToCurationButton from './CurationsBackToCurationButton'
import getResultId from './getResultId'

interface ICurationsAddManuallyProps {
  actions: {
    initializeCurationDetail(id: string)
    resetCuration()
    setPromoted(id: string)
    removePromoted(id: string)
    updateSearchQuery(query: string)
  }
  engine: IEngineDetails
  engineName: string
  match: IMatch
  searchResults: IObject[]
  searchQuery: string
  dataLoading: boolean
  flashMessages: IStuiFlashMessagesProps
}

const CurationAddManually: React.SFC<ICurationsAddManuallyProps> = ({
  actions: {
    initializeCurationDetail,
    resetCuration,
    setPromoted,
    removePromoted,
    updateSearchQuery
  },
  match: {
    params: { curationId }
  },
  engine,
  engineName,
  searchResults,
  searchQuery,
  dataLoading,
  flashMessages
}) => {
  useEffect(() => {
    initializeCurationDetail(curationId)
    return resetCuration
  }, [curationId])

  const containerCssClass = classNames(
    'o-stui-replaceable-results',
    { dataLoading },
    { 'no-results': !searchResults || searchResults.length < 1 }
  )

  const togglePromote = (result) => {
    const resultId = getResultId(result)
    result.isPromoted ? removePromoted(resultId) : setPromoted(resultId)
  }

  return (
    <div>
      <CurationsBackToCurationButton curationId={curationId} engineName={engineName} key="breadcrumbs" />
      <StuiHeader key="header" contained={true} viewHeader={true}>
        <StuiHeading type="view">Add Result to Curation</StuiHeading>
      </StuiHeader>
      <StuiMain key="main">
        <StuiFlashMessages {...flashMessages} />
        <EuiFieldSearch
          onChange={e => updateSearchQuery(e.target.value)}
          autoFocus={true}
          data-test-subj="QueryTesterSearchInput"
          placeholder="Search engine documents..."
          fullWidth={true}
        />
        <EuiSpacer key="spacer" />
        <div key="results" className={containerCssClass}>
          {searchResults && searchResults.map((result, index) => {
            // `isPromoted` is separated from the rest of the fields so it doesn't appear as a field in results.
            const { isPromoted, ...resultObject } = result
            return (
              <StuiResult
                key={index}
                resultObject={resultObject}
                query={searchQuery}
                schema={engine.schema}
                condensed={true}
                extraAction={() => togglePromote(result)}
                extraActionIcon={
                  <StuiTooltip content={isPromoted ? 'Demote this result' : 'Promote this result'}>
                    <StuiIcon name={isPromoted ? 'star-fill' : 'star'} className="c-stui-result__extra-icon" />
                  </StuiTooltip>}
              />
            )
          })}
        </div>
      </StuiMain>
    </div>
  )
}

export default withRouter(CurationsLogic(CurationAddManually))
