import React from 'react'

import { Link } from 'react-router-dom'

import { EuiButton } from '@elastic/eui'

interface IAddRoleMappingButtonProps {
  path: string
}

const AddRoleMappingButton: React.SFC<IAddRoleMappingButtonProps> = ({ path }) => (
  <Link to={path}>
    <EuiButton
      fill={true}
      color="secondary"
      onClick={() => null}
    >
      Add Mapping
    </EuiButton>
  </Link>
)

export default AddRoleMappingButton
