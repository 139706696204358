import React from 'react'

import { EuiProgress } from '@elastic/eui'

interface IStuiPasswordStrengthProps {
  strengthScore?: number
}

const StuiPasswordStrength: React.SFC<IStuiPasswordStrengthProps> = ({ strengthScore }) => {
  if (!strengthScore) {
    return null
  }

  let meterEl

  switch (strengthScore) {
    case 1:
      meterEl = <EuiProgress value={25} max={100} color="danger" size="m" />
      break
    case 2:
      meterEl = <EuiProgress value={50} max={100} color="danger" size="m" />
      break
    case 3:
      meterEl = <EuiProgress value={75} max={100} color="secondary" size="m" />
      break
    case 4:
      meterEl = <EuiProgress value={100} max={100} color="secondary" size="m" />
      break
    default:
      meterEl = <EuiProgress value={0} max={100} color="danger" size="m" />
  }

  return meterEl
}

export default StuiPasswordStrength
