import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import _isEmpty from 'lodash/isEmpty'

import { EngineLogic } from 'app_search/Engine/EngineLogic'
import routes from 'app_search/routes'
import {
  IBoost,
  IObject,
  ISearchSettings,
  ISchemaConflicts
} from 'app_search/types'
import handleAPIError from 'app_search/utils/handleAPIError'
import { NUMBER } from 'shared/constants/fieldTypes'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

const UPDATE_SUCCESS_MESSAGE = 'Relevance successfully tuned. The changes will impact your results shortly.'
const DELETE_SUCCESS_MESSAGE = 'Relevance has been reset to default values. The change will impact your results shortly.'
const RESET_CONFIRMATION_MESSAGE = 'Are you sure you want to restore relevance defaults?'
const DELETE_CONFIRMATION_MESSAGE = 'Are you sure you want to delete this boost?'

// If the user hasn't entered a filter, then we can skip filtering the array entirely
const filterIfTerm = (array: string[], filterTerm: string): string[] => filterTerm === '' ? array : array.filter(item => item.includes(filterTerm))

interface ISearchSettingsProps {
  searchSettings: ISearchSettings
  schema: IObject
  schemaConflicts: ISchemaConflicts
}

export const SearchSettingsLogic = storeLogic({
  connect: () => ({
    values: [
      EngineLogic, ['engineName']
    ]
  }),
  actions: () => ({
    onInitializeSearchSettings: (props: ISearchSettingsProps) => props,
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages }),
    setSearchSettings: (searchSettings: ISearchSettings) => ({ searchSettings }),
    setSearchSettingsResponse: (searchSettings: ISearchSettings, flashMessages: IStuiFlashMessagesProps) => ({ searchSettings, flashMessages }),
    setFilterValue: (value: string) => value,
    setSearchQuery: (query: string) => query,
    setSearchResults: (searchResults: IObject[]) => searchResults,
    setResultsLoading: (resultsLoading: boolean) => resultsLoading,
    clearSearchResults: null,
    resetSearchSettingsState: true,
    dismissSchemaConflictCallout: true
  }),
  reducers: ({ actions }) => ({
    searchSettings: [{}, {
      [actions.onInitializeSearchSettings]: (_, { searchSettings }) => searchSettings,
      [actions.setSearchSettings]: (_, { searchSettings }) => searchSettings,
      [actions.setSearchSettingsResponse]: (_, { searchSettings }) => searchSettings
    }],
    schema: [{}, {
      [actions.onInitializeSearchSettings]: (_, { schema }) => schema
    }],
    flashMessages: [{}, {
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages,
      [actions.setSearchSettingsResponse]: (_, { flashMessages }) => flashMessages,
      [actions.resetSearchSettingsState]: () => ({})
    }],
    dataLoading: [true, {
      [actions.onInitializeSearchSettings]: () => false,
      [actions.resetSearchSettingsState]: () => true
    }],
    resultsLoading: [false, {
      [actions.setResultsLoading]: (_, resultsLoading) => resultsLoading,
      [actions.setSearchResults]: () => false,
      [actions.setFlashMessages]: () => false
    }],
    unsavedChanges: [false, {
      [actions.setSearchSettings]: () => true,
      [actions.setSearchSettingsResponse]: () => false
    }],
    filterInputValue: ['', {
      [actions.setFilterValue]: (_, filterInputValue) => filterInputValue
    }],
    query: ['', {
      [actions.setSearchQuery]: (_, query) => query
    }],
    searchResults: [null, {
      [actions.clearSearchResults]: () => null,
      [actions.setSearchResults]: (_, searchResults) => searchResults
    }],
    schemaConflicts: [{}, {
      [actions.onInitializeSearchSettings]: (_, { schemaConflicts }) => schemaConflicts || {}
    }],
    showSchemaConflictCallout: [true, {
      [actions.dismissSchemaConflictCallout]: () => false
    }]
  }),
  selectors: ({ selectors }) => ({
    engineHasSchemaFields: [
      () => [selectors.schema],
      (schema: IObject): boolean => Object.keys(schema).length >= 2
    ],
    schemaFields: [
      () => [selectors.schema],
      (schema: IObject) => Object.keys(schema)
    ],
    schemaFieldsWithConflicts: [
      () => [selectors.schemaConflicts],
      (schemaConflicts: IObject) => Object.keys(schemaConflicts)
    ],
    filteredSchemaFields: [
      () => [selectors.schemaFields, selectors.filterInputValue],
      (schemaFields: string[], filterInputValue: string): string[] => filterIfTerm(schemaFields, filterInputValue)
    ],
    filteredSchemaFieldsWithConflicts: [
      () => [selectors.schemaFieldsWithConflicts, selectors.filterInputValue],
      (schemaFieldsWithConflicts: string[], filterInputValue: string): string[] => filterIfTerm(schemaFieldsWithConflicts, filterInputValue)
    ]
  }),
  thunks: ({ actions, get }) => ({
    initializeSearchSettings: () => {
      const route = routes.detailsLocoMocoEngineSearchSettingsPath(get('engineName'))
      http(route)
        .then(({ data }) => actions.onInitializeSearchSettings(data))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    getSearchResults: _debounce(
      () => {
        const query = get('query')
        const { search_fields: searchFields, boosts } = removeBoostStateProps(get('searchSettings'))
        if (!query) {
          return actions.clearSearchResults()
        }
        actions.setResultsLoading(true)
        http.post(
          routes.searchSettingsSearchLocoMocoEnginePath({
            query,
            format: 'json',
            slug: get('engineName')
          }),
          {
            boosts: _isEmpty(boosts) ? undefined : boosts,
            search_fields: searchFields
          })
          .then(({ data }) => actions.setSearchResults(data.results))
          .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
      },
      250
    ),
    onSearchSettingsSuccess: (searchSettings, message) => {
      actions.setSearchSettingsResponse(searchSettings, { success: [message] })
      actions.getSearchResults()
      window.scrollTo(0, 0)
    },
    onSearchSettingsError: messages => {
      actions.setFlashMessages({ error: messages })
      window.scrollTo(0, 0)
    },
    updateSearchSettings: () => {
      http({
        data: removeBoostStateProps(get('searchSettings')),
        method: 'put',
        url: routes.locoMocoEngineSearchSettingsPath(get('engineName'))
      })
        .then(({ data }) => actions.onSearchSettingsSuccess(data, UPDATE_SUCCESS_MESSAGE))
        .catch(handleAPIError(messages => actions.onSearchSettingsError(messages)))
    },
    resetSearchSettings: () => {
      if (window.confirm(RESET_CONFIRMATION_MESSAGE)) {
        http({
          method: 'post',
          url: routes.resetLocoMocoEngineSearchSettingsPath(get('engineName'))
        })
          .then(({ data }) => actions.onSearchSettingsSuccess(data, DELETE_SUCCESS_MESSAGE))
          .catch(handleAPIError(messages => actions.onSearchSettingsError(messages)))
      }
    },
    toggleSearchField: (name, disableField) => {
      const searchSettings = get('searchSettings')
      const { search_fields: searchFields } = searchSettings

      actions.setSearchSettings({
        ...searchSettings,
        search_fields: {
          ...searchFields,
          [name]: disableField ? undefined : { weight: 1 }
        }
      })
      actions.getSearchResults()
    },
    updateFieldWeight: (name, weight) => {
      const searchSettings = get('searchSettings')
      const { search_fields: searchFields } = searchSettings

      actions.setSearchSettings({
        ...searchSettings,
        search_fields: {
          ...searchFields,
          [name]: {
            ...searchFields[name],
            weight: Math.round(weight * 10) / 10
          }
        }
      })
      actions.getSearchResults()
    },
    addBoost: (name, type) => {
      const searchSettings = get('searchSettings')
      const { boosts } = searchSettings
      const emptyBoost = { type, factor: 1, newBoost: true }
      let boostArray

      if (Array.isArray(boosts[name])) {
        boostArray = boosts[name].slice()
        boostArray.push(emptyBoost)
      } else {
        boostArray = [emptyBoost]
      }

      actions.setSearchSettings({
        ...searchSettings,
        boosts: {
          ...boosts,
          [name]: boostArray
        }
      })
    },
    deleteBoost: (name, index) => {
      if (window.confirm(DELETE_CONFIRMATION_MESSAGE)) {
        const searchSettings = get('searchSettings')
        const { boosts } = searchSettings
        const boostsRemoved = boosts[name].slice()
        boostsRemoved.splice(index, 1)
        const updatedBoosts = { ...boosts }

        if (boostsRemoved.length > 0) {
          updatedBoosts[name] = boostsRemoved
        } else {
          delete (updatedBoosts[name])
        }

        actions.setSearchSettings({
          ...searchSettings,
          boosts: updatedBoosts
        })
        actions.getSearchResults()
      }
    },
    updateBoostFactor: (name, index, factor) => {
      const searchSettings = get('searchSettings')
      const { boosts } = searchSettings
      const updatedBoosts = _cloneDeep(boosts[name])
      updatedBoosts[index].factor = Math.round(factor * 10) / 10

      actions.setSearchSettings({
        ...searchSettings,
        boosts: {
          ...boosts,
          [name]: updatedBoosts
        }
      })
      actions.getSearchResults()
    },
    updateBoostValue: (name, boostIndex, valueIndex, value) => {
      const searchSettings = get('searchSettings')
      const { boosts } = searchSettings
      const updatedBoosts: IBoost[] | any[] = _cloneDeep(boosts[name])
      if (updatedBoosts[boostIndex].value === undefined) {
        updatedBoosts[boostIndex].value = [value]
      } else {
        updatedBoosts[boostIndex].value[valueIndex] = value
      }

      actions.setSearchSettings({
        ...searchSettings,
        boosts: {
          ...boosts,
          [name]: updatedBoosts
        }
      })
      actions.getSearchResults()
    },
    updateBoostCenter: (name, boostIndex, value) => {
      const searchSettings = get('searchSettings')
      const { boosts } = searchSettings
      const updatedBoosts = _cloneDeep(boosts[name])
      const fieldType = get('schema')[name]
      updatedBoosts[boostIndex].center = parseBoostCenter(fieldType, value)

      actions.setSearchSettings({
        ...searchSettings,
        boosts: {
          ...boosts,
          [name]: updatedBoosts
        }
      })
      actions.getSearchResults()
    },
    addBoostValue: (name, boostIndex) => {
      const searchSettings = get('searchSettings')
      const { boosts } = searchSettings
      const updatedBoosts: IBoost[] | any[] = _cloneDeep(boosts[name])
      const updatedBoost = updatedBoosts[boostIndex]
      if (updatedBoost) {
        updatedBoost.value = Array.isArray(updatedBoost.value) ? updatedBoost.value : ['']
        updatedBoost.value.push('')
      }

      actions.setSearchSettings({
        ...searchSettings,
        boosts: {
          ...boosts,
          [name]: updatedBoosts
        }
      })
      actions.getSearchResults()
    },
    removeBoostValue: (name, boostIndex, valueIndex) => {
      const searchSettings = get('searchSettings')
      const { boosts } = searchSettings
      const updatedBoosts: IBoost[] | any[] = _cloneDeep(boosts[name])
      if (updatedBoosts[boostIndex].value !== undefined) {
        updatedBoosts[boostIndex].value.splice(valueIndex, 1)
      }

      actions.setSearchSettings({
        ...searchSettings,
        boosts: {
          ...boosts,
          [name]: updatedBoosts
        }
      })
      actions.getSearchResults()
    },
    updateBoostSelectOption: (name, boostIndex, optionType, value) => {
      const searchSettings = get('searchSettings')
      const { boosts } = searchSettings
      const updatedBoosts = _cloneDeep(boosts[name])
      updatedBoosts[boostIndex][optionType] = value

      actions.setSearchSettings({
        ...searchSettings,
        boosts: {
          ...boosts,
          [name]: updatedBoosts
        }
      })
      actions.getSearchResults()
    },
    updateSearchValue: query => {
      actions.setSearchQuery(query)
      actions.getSearchResults()
    }
  })
})

const removeBoostStateProps = searchSettings => {
  const updatedSettings = _cloneDeep(searchSettings)
  const { boosts } = updatedSettings
  const keys = Object.keys(boosts)
  keys.forEach(key => boosts[key].forEach(boost => delete boost.newBoost))

  return updatedSettings
}

const parseBoostCenter = (fieldType, value) => {
  // Leave non-numeric fields alone
  if (fieldType === NUMBER) {
    const floatValue = parseFloat(value)
    return isNaN(floatValue) ? value : floatValue
  }
  return value
}

export default SearchSettingsLogic
