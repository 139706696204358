import { Ability } from 'app_search/classes'
import routes from 'app_search/routes'
import { IUsersState, IUserState } from 'app_search/types'
import { splitSeparatedValue } from 'app_search/utils'
import { USERS_PATH } from 'app_search/utils/routePaths'
import http from 'shared/http'
import { storeLogic } from 'shared/store'

const defaultUsersState = {
  dataLoading: true,
  flashMessages: {},
  invitationsEmptyState: null,
  demo: false,
  showInvitationsModal: false,
  myRole: null,
  users: [],
  invitations: [],
  invitationRoleType: null,
  defaultInvitationRoleType: null,
  emails: '',
  username: ''
}

const defaultUserState = {
  dataLoading: true,
  flashMessages: {},
  demo: false,
  hasAdvancedRoles: false,
  myRole: null,
  user: null,

  availableEngines: [],
  engineAccessEnabled: false,

  selectedAccessAllEngines: true,
  selectedEngineNames: new Set(),
  selectedRoleType: null,

  initialEngineNames: new Set()
}

export const UsersLogic = storeLogic({
  actions: () => ({
    setUsersState: (state: IUsersState) => state,
    setUserState: (state: IUserState) => state,
    setLoadingOnExit: () => true
  }),
  reducers: ({ actions }) => ({
    // Users.tsx
    usersState: [defaultUsersState, {
      [actions.setUsersState]: (state, newState) => ({ ...state, ...newState }),
      [actions.setLoadingOnExit]: (state) => ({ ...state, dataLoading: true })
    }],

    // User.tsx
    userState: [defaultUserState, {
      [actions.setUserState]: (state, newState) => ({ ...state, ...newState })
    }]
  }),
  thunks: ({ actions, get }) => ({
    initializeUsersData: () => {
      actions.setUsersState(defaultUsersState)
      http(routes.locoMocoUsersPath()).then(({ data }) => {
        actions.setUsersState({
          ...data,
          dataLoading: false,
          invitationRoleType: data.defaultInvitationRoleType
        })
      })
    },
    initializeUserData: (roleId) => {
      actions.setUserState(defaultUserState)
      http(routes.locoMocoRolePath(roleId)).then(({ data }) => {
        const engineNames = new Set(data.user.engines.map((engine) => engine.name))
        actions.setUserState({
          ...data,
          engineAccessEnabled: Ability.canHaveScopedEngines(data.user.role.roleType),
          selectedAccessAllEngines: data.user.accessAllEngines,
          selectedEngineNames: engineNames,
          selectedRoleType: data.user.role.roleType,
          initialEngineNames: engineNames,
          dataLoading: false
        })
      })
    },
    handleSaveUser: (event) => {
      event.preventDefault()
      const userState = get('userState')
      if (userState.demo) { return }

      const user = userState.user
      const role = user.role
      const roleType = userState.selectedRoleType
      const accessAllEngines = userState.selectedAccessAllEngines
      const engineNames = Array.from(userState.selectedEngineNames)

      http({
        method: 'put',
        url: routes.locoMocoRolePath(role.id, {
          role_id: role.role_id,
          data: {
            role_type: roleType,
            access_all_engines: accessAllEngines,
            engine_names: engineNames
          }
        })
      }).then(({ data }) => {
        actions.setUserState(data)
      }).catch(({ response }) => {
        actions.setUserState(response.data)
      })
    },
    handleRemoveUser: (event, history) => {
      event.preventDefault()
      const userState = get('userState')
      if (userState.demo) { return }

      const user = userState.user
      const role = user.role

      http({
        method: 'delete',
        url: routes.locoMocoRolePath(role.id)
      }).then(({ data }) => {
        if (userState.myRole.id === role.id) {
          // In this case the user is removing themselves from the account
          window.location.href = routes.sessionsSelectProductPath()
          return
        }

        history.push(USERS_PATH)
        actions.setUsersState(data)
      }).catch(({ response }) => {
        actions.setUserState(response.data)
      })
    },
    handleResendInvitation: (event, code) => {
      event.preventDefault()
      if (get('usersState').demo) { return }
      http({
        method: 'post',
        url: routes.resendLocoMocoInvitationPath(code)
      }).then(({ data }) => {
        actions.setUsersState({
          invitations: data.invitations,
          flashMessages: {
            success: data.success,
            error: data.errors
          }
        })
      }).catch(({ response }) => {
        actions.setUsersState({
          flashMessages: { error: response.data.errors }
        })
      })
    },
    toggleInvitationsModal: () => {
      if (get('usersState').demo) { return }
      actions.setUsersState({
        showInvitationsModal: !get('usersState').showInvitationsModal
      })
    },
    handleRevokeInvitation: (event, code) => {
      event.preventDefault()
      if (get('usersState').demo) { return }
      http({
        method: 'delete',
        url: routes.locoMocoInvitationPath(code)
      }).then(({ data }) => {
        actions.setUsersState({
          invitations: data.invitations,
          flashMessages: {
            success: data.success,
            error: data.errors
          }
        })
      }).catch(({ response }) => {
        actions.setUsersState({
          flashMessages: { error: response.data.errors }
        })
      })
    },
    handleInviteButtonSubmit: (event) => {
      event.preventDefault()
      const usersState = get('usersState')
      if (usersState.demo) { return }
      http({
        data: {
          emails: Array.from(new Set(splitSeparatedValue(usersState.emails))),
          role_type: usersState.invitationRoleType
        },
        method: 'post',
        url: routes.locoMocoInvitationsPath()
      }).then(({ data }) => {
        actions.setUsersState({
          emails: '',
          users: data.users,
          invitations: data.invitations,
          myRole: data.myRole,
          flashMessages: {
            success: data.success,
            error: data.errors
          }
        })
      }).catch(({ response }) => {
        actions.setUsersState({
          flashMessages: { error: response.data.errors }
        })
      }).then(() => {
        actions.toggleInvitationsModal()
      })
    },
    handleLTGInviteButtonSubmit: (event) => {
      event.preventDefault()
      const usersState = get('usersState')
      if (usersState.demo) { return }
      http({
        data: {
          email: usersState.emails.trim(),
          username: usersState.username.trim(),
          role_type: usersState.invitationRoleType
        },
        method: 'post',
        url: routes.locoMocoInvitationsPath()
      }).then(({ data }) => {
        actions.setUsersState({
          emails: '',
          username: '',
          users: data.users,
          invitations: data.invitations,
          myRole: data.myRole,
          flashMessages: {
            success: data.success,
            error: data.errors
          }
        })
      }).catch(({ response }) => {
        actions.setUsersState({
          flashMessages: { error: response.data.errors }
        })
      }).then(() => {
        actions.toggleInvitationsModal()
      })
    }
  })
})
