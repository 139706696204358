import React, { useEffect } from 'react'

import { useActions, useValues } from 'kea'

import { AppLogic } from 'app_search/App'
import routes from 'app_search/routes'
import http from 'shared/http'
import StuiEmptyState from 'stui/EmptyState'
import StuiLoader from 'stui/Loader'

import { EuiSpacer } from '@elastic/eui'

const pollingIntervalTime = 5000 // milliseconds

const SampleEngineLoader: React.SFC<{}> = () => {
  const { account: { accountId } } = useValues(AppLogic)
  const { setSeedingSampleEngine } = useActions(AppLogic)

  const seedingSampleEngineRoute = routes.seedingSampleEngineLocoMocoAccountPath(accountId)

  const pollSeedingRoute = () => http(seedingSampleEngineRoute).then(({ data }) => setSeedingSampleEngine(data))

  useEffect(() => {
    const pollingInterval = window.setInterval(pollSeedingRoute, pollingIntervalTime)

    return () => {
      clearInterval(pollingInterval)
    }
  }, [])

  return (
    <div className="sample-engine-loader" data-test-subj="SampleEngineLoader">
      <StuiLoader size="xxxl" />
      <EuiSpacer size="xl" />
      <StuiEmptyState
        title="We're setting up your sample engine."
        description="It will be ready in a matter of seconds. Hold tight!"
      />
    </div>
  )
}

export default SampleEngineLoader
