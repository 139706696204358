import _get from 'lodash/get'

import { AppLogic } from 'app_search/App'
import { predefinedPlans } from 'app_search/Billing/plans'
import routes from 'app_search/routes'
import { IAccount, IObject } from 'app_search/types'
import http from 'shared/http'
import { storeLogic } from 'shared/store'

import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

const defaultUIState = {
  paymentVerificationRequiredModalOpen: false,
  planSelectorModalOpen: false,
  processingPaymentModalOpen: false,
  updatePaymentMethodModalOpen: false
}

const PAYMENT_INTENT_REQUIRES_ACTION = 'requires_action'

const FALLBACK_ERROR_CONTACT_INFORMATION = 'Please try again or contact support@swiftype.com if the problem persists.'
const BILLING_FALLBACK_ERROR = `There was a problem retrieving your billing information. ${FALLBACK_ERROR_CONTACT_INFORMATION}`
const SELECT_PLAN_FALLBACK_ERROR = `There was a problem selecting your plan. ${FALLBACK_ERROR_CONTACT_INFORMATION}`
const SAVE_PAYMENT_METHOD_FALLBACK_ERROR = `There was a problem updating your payment method. ${FALLBACK_ERROR_CONTACT_INFORMATION}`

export const BillingLogic = storeLogic({
  connect: () => ({
    actions: [
      AppLogic, ['billingUpdate']
    ]
  }),
  actions: () => ({
    openUpdatePaymentMethodModalWithPlan: (selectedPlan: IObject) => selectedPlan,
    setBillingData: (data: IAccount & IObject) => data,
    togglePlanSelectorModal: () => null,
    toggleUpdatePaymentMethodModal: () => null,
    openProcessingPaymentModal: () => null,
    openPaymentVerificationRequiredModal: (paymentVerificationState: IObject) => paymentVerificationState,
    setPaymentVerificationError: (errorMessage: string) => errorMessage,
    closeAllModals: () => null,
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => flashMessages,
    setCompany: (company: string) => company,
    setDomain: (domain: string) => domain,
    setSelectedBusiness: (selectedBusiness: string) => selectedBusiness,
    setSelectedEmployeesNumber: (selectedEmployeesNumber: string) => selectedEmployeesNumber,
    setAddress1: (address1: string) => address1,
    setAddress2: (address2: string) => address2,
    setCity: (city: string) => city,
    setState: (state: string) => state,
    setPostalCode: (postalCode: string) => postalCode,
    setCountry: (country: string) => country
  }),
  reducers: ({ actions }) => ({
    uiState: [defaultUIState, {
      [actions.openProcessingPaymentModal]: (state) => ({ ...state, ...defaultUIState, processingPaymentModalOpen: true }),
      [actions.openPaymentVerificationRequiredModal]: (state) => ({ ...state, ...defaultUIState, paymentVerificationRequiredModalOpen: true }),
      [actions.setPaymentVerificationError]: (state) => ({ ...state, ...defaultUIState }),
      [actions.closeAllModals]: (state) => ({ ...state, ...defaultUIState }),
      [actions.openUpdatePaymentMethodModalWithPlan]: (state) => ({ ...state, ...{ updatePaymentMethodModalOpen: true, planSelectorModalOpen: false } }),
      [actions.togglePlanSelectorModal]: (state) => ({ ...state, ...{ planSelectorModalOpen: !state.planSelectorModalOpen, updatePaymentMethodModalOpen: false } }),
      [actions.toggleUpdatePaymentMethodModal]: (state) => ({ ...state, ...{ updatePaymentMethodModalOpen: !state.updatePaymentMethodModalOpen, planSelectorModalOpen: false } })
    }],
    dataLoading: [true, {
      [actions.setBillingData]: () => false
    }],
    lmAccount: [{}, {
      [actions.setBillingData]: (_, { lmAccount }) => lmAccount
    }],
    accountBillingMeta: [{}, {
      [actions.setBillingData]: (_, { accountBillingMeta }) => accountBillingMeta
    }],
    accountBillingUsage: [{}, {
      [actions.setBillingData]: (_, { accountBillingUsage }) => accountBillingUsage
    }],
    invoices: [[], {
      [actions.setBillingData]: (_, { invoices }) => invoices
    }],
    canManagePlan: [{}, {
      [actions.setBillingData]: (_, { canManagePlan }) => canManagePlan
    }],
    stripeKey: [null, {
      [actions.setBillingData]: (_, { stripeKey }) => stripeKey
    }],
    selectedPlan: [null, {
      [actions.toggleUpdatePaymentMethodModal]: () => null,
      [actions.openPaymentVerificationRequiredModal]: (_, { selectedPlan }) => selectedPlan,
      [actions.openUpdatePaymentMethodModalWithPlan]: (_, selectedPlan) => selectedPlan
    }],
    paymentIntentClientSecret: [null, {
      [actions.openPaymentVerificationRequiredModal]: (_, { paymentIntentClientSecret }) => paymentIntentClientSecret
    }],
    flashMessages: [{}, {
      [actions.setPaymentVerificationError]: (_, errorMessage) => ({ error: [errorMessage] }),
      [actions.setFlashMessages]: (_, flashMessages) => flashMessages
    }],
    businessOptions: [[], {
      [actions.setBillingData]: (_, { businessOptions }) => businessOptions
    }],
    employeesNumberOptions: [[], {
      [actions.setBillingData]: (_, { employeesNumberOptions }) => employeesNumberOptions
    }],
    countryOptions: [[], {
      [actions.setBillingData]: (_, { countryOptions }) => countryOptions
    }],
    company: ['', {
      [actions.setBillingData]: (_, { company }) => company || '',
      [actions.setCompany]: (_, company) => company
    }],
    domain: ['', {
      [actions.setBillingData]: (_, { domain }) => domain || '',
      [actions.setDomain]: (_, domain) => domain
    }],
    selectedBusiness: ['', {
      [actions.setBillingData]: (_, { selectedBusiness }) => selectedBusiness,
      [actions.setSelectedBusiness]: (_, selectedBusiness) => selectedBusiness
    }],
    selectedEmployeesNumber: ['', {
      [actions.setBillingData]: (_, { selectedEmployeesNumber }) => selectedEmployeesNumber,
      [actions.setSelectedEmployeesNumber]: (_, selectedEmployeesNumber) => selectedEmployeesNumber
    }],
    address1: ['', {
      [actions.setBillingData]: (_, { address1 }) => address1 || '',
      [actions.setAddress1]: (_, address1) => address1
    }],
    address2: ['', {
      [actions.setBillingData]: (_, { address2 }) => address2 || '',
      [actions.setAddress2]: (_, address2) => address2
    }],
    city: ['', {
      [actions.setBillingData]: (_, { city }) => city || '',
      [actions.setCity]: (_, city) => city
    }],
    state: ['', {
      [actions.setBillingData]: (_, { state }) => state || '',
      [actions.setState]: (_, state) => state
    }],
    postal_code: ['', {
      [actions.setBillingData]: (_, { postal_code }) => postal_code || '',
      [actions.setPostalCode]: (_, postalCode) => postalCode
    }],
    country: ['', {
      [actions.setBillingData]: (_, { country }) => country || '',
      [actions.setCountry]: (_, country) => country
    }]
  }),
  selectors: ({ selectors }) => ({
    stripe: [
      () => [selectors.stripeKey, (_, props) => props.Stripe],
      (stripeKey, Stripe) => Stripe && stripeKey ? Stripe(stripeKey) : null
    ]
  }),
  thunks: ({ actions, get }) => ({
    initializeBillingData: () => {
      const route = routes.billingLocoMocoSettingsBillingPath()
      http(route)
        .then(({ data }) => actions.setBillingData(data))
        .catch(error =>  {
          const errorMessages = _get(error, 'response.data.errors', [BILLING_FALLBACK_ERROR])
          actions.setFlashMessages({
            error: errorMessages
          })
        })
    },
    billingUpdateSucceeded: (response, plan?: IObject, onSuccess?: () => any) => {
      if (response.data.paymentIntentStatus === PAYMENT_INTENT_REQUIRES_ACTION) {
        return actions.openPaymentVerificationRequiredModal({
          selectedPlan: plan,
          paymentIntentClientSecret: response.data.paymentIntentClientSecret
        })
      }

      if (response.data.success) {
        actions.setFlashMessages({ success: [response.data.success] })

        const { role, account } = response.data.newGlobalState
        actions.billingUpdate(role, account)
      }
      actions.initializeBillingData()
      if (onSuccess instanceof Function) {
        onSuccess()
      }
    },
    billingUpdateFailed: (error, fallbackErrorMessage: string, onError?: () => any) => {
      const errorMessages = _get(error, 'response.data.errors', [fallbackErrorMessage])
      actions.setFlashMessages({ error: errorMessages })
      if (onError instanceof Function) {
        onError()
      }
    },
    cancelPaymentVerification: () => {
      actions.setPaymentVerificationError('Payment verification was cancelled')
    },
    saveSelectedPlan: (planName: string, onSuccess?: () => any, onError?: () => any) => {
      const route = routes.locoMocoPlanPath()
      actions.openProcessingPaymentModal()
      http.put(route, { plan: { name: planName } })
        .then(response => actions.billingUpdateSucceeded(response, predefinedPlans[planName], onSuccess))
        .catch(error => actions.billingUpdateFailed(error, SELECT_PLAN_FALLBACK_ERROR, onError))
    },
    savePaymentMethod: (stripeToken: string, onSuccess?: () => any, onError?: () => any, selectedPlan?: string) => {
      const route = routes.locoMocoSettingsBillingPath()
      const data: any = {
        stripe_token: stripeToken,
        firmographics: {
          address1: get('address1'),
          address2: get('address2'),
          city: get('city'),
          state: get('state'),
          postal_code: get('postal_code'),
          country: get('country'),
          company: get('company'),
          domain: get('domain'),
          business: get('selectedBusiness'),
          employees_number: get('selectedEmployeesNumber')
        }
      }
      if (selectedPlan) {
        data.plan = { name: selectedPlan }
      }
      actions.openProcessingPaymentModal()
      http.put(route, data, { maxRedirects: 0 })
        .then(response => actions.billingUpdateSucceeded(response, selectedPlan && predefinedPlans[selectedPlan], onSuccess))
        .catch(error => actions.billingUpdateFailed(error, SAVE_PAYMENT_METHOD_FALLBACK_ERROR, onError))
    },
    processPaymentVerificationSuccess: (onSuccess?: () => any, onError?: () => any) => {
      const route = routes.locoMocoSettingsBillingPath()
      actions.openProcessingPaymentModal()
      http.put(route, { finalize: 'true' }, { maxRedirects: 0 })
        .then(response => actions.billingUpdateSucceeded(response, null, onSuccess))
        .catch(error => actions.billingUpdateFailed(error, SELECT_PLAN_FALLBACK_ERROR, onError))
    },
    verifyPayment: () => {
      const {
        openProcessingPaymentModal,
        setPaymentVerificationError,
        processPaymentVerificationSuccess,
        closeAllModals
      } = actions

      const stripe = get('stripe')
      const paymentIntentClientSecret = get('paymentIntentClientSecret')
      openProcessingPaymentModal()
      stripe.confirmCardPayment(paymentIntentClientSecret)
        .then(result => {
          if (result.error) {
            setPaymentVerificationError(result.error.message)
          } else {
            processPaymentVerificationSuccess(closeAllModals, closeAllModals)
          }
        })
    }
  })
})
