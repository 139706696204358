import React from 'react'

import { Link } from 'react-router-dom'

import { getDocumentDetailRoute } from 'app_search/utils'

import { EuiSpacer } from '@elastic/eui'

import {
  StuiTable,
  StuiTableBody,
  StuiTableBodyCell,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'

import { IQueryClick } from 'app_search/types'

import AnalyticsChart from './AnalyticsChart'
import AnalyticsMetricCard from './AnalyticsMetricCard'
import AnalyticsTableCard from './AnalyticsTableCard'

interface IQueryDetailContentProps {
  query: string
  startDate: string
  endDate: string
  totalQueriesForQuery: number
  queriesPerDayForQuery: number
  topClicksForQuery: IQueryClick[]
}

const QueryDetailContent: React.SFC<IQueryDetailContentProps> = ({
  query,
  startDate,
  endDate,
  totalQueriesForQuery,
  queriesPerDayForQuery,
  topClicksForQuery
}) => (
  <>
      <AnalyticsMetricCard title={`Queries for ${query}`} value={totalQueriesForQuery} />
      <EuiSpacer size="xxl" />
      <AnalyticsChart
        data={[queriesPerDayForQuery]}
        labels={[query]}
        startDate={new Date(startDate)}
        endDate={new Date(endDate)}
      />
      <EuiSpacer size="xxl" />
      <AnalyticsTableCard
        title="Top Clicks"
        description="The documents with the most clicks resulting from this query."
      >
        <StuiTable>
          <StuiTableHead>
            <StuiTableRow>
              <StuiTableHeadCell>Document</StuiTableHeadCell>
              <StuiTableHeadCell>Clicks</StuiTableHeadCell>
            </StuiTableRow>
          </StuiTableHead>
          <StuiTableBody>
            {topClicksForQuery.map(({ key, document, doc_count }, index) => (
              <StuiTableRow key={index}>
                {document ? (
                  <StuiTableBodyCell><Link to={getDocumentDetailRoute(document.engine, document.id)}>{key}</Link></StuiTableBodyCell>
                ) : (
                  <StuiTableBodyCell>{key}</StuiTableBodyCell>
                )}
                <StuiTableBodyCell>{doc_count}</StuiTableBodyCell>
              </StuiTableRow>
            ))}
          </StuiTableBody>
        </StuiTable>
      </AnalyticsTableCard>
  </>
)

export default QueryDetailContent
