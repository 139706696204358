import React from 'react'

import StuiLineChart from 'stui/LineChart'

interface IAnalyticsChartProps {
  data: any
  labels: string[]
  startDate: Date
  endDate: Date
}

const getDates = (start, end) => {
  const arr: Date[] = []
  const dt = new Date(start)

  while (dt <= end) {
    arr.push(new Date(dt))
    dt.setDate(dt.getDate() + 1)
  }

  return arr
}

const WHITE = '#ffffff'
const PRIMARY_BORDER_COLOR = '#437dec'
const SECONDARY_BORDER_COLORS = ['#6cc98e', '#65bef9', '#efa161', '#d3485c']

const getPrimaryGradient = () => {
  const context = document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D
  const primaryGradient = context.createLinearGradient(0, 0, 0, 300)
  primaryGradient.addColorStop(0.5, '#E6E9F5')
  primaryGradient.addColorStop(1, 'rgba(230,233,245, 0)')
  return primaryGradient
}

const AnalyticsChart: React.SFC<IAnalyticsChartProps> = ({ data, labels, startDate, endDate }) => {
  const datasets = data.slice(1).map((dataset, index) => ({
    borderColor: SECONDARY_BORDER_COLORS[index],
    borderDash: [5, 5],
    borderWidth: 2,
    data: dataset,
    fill: false,
    label: labels[index + 1],
    pointBackgroundColor: WHITE,
    pointHoverRadius: 5,
    pointRadius: 3
  }))
  datasets.push({
    backgroundColor: getPrimaryGradient(),
    borderColor: PRIMARY_BORDER_COLOR,
    data: data[0],
    label: labels[0],
    pointBackgroundColor: WHITE,
    pointBorderWidth: 3,
    pointHoverRadius: 6,
    pointRadius: 5
  })

  const chartData = {
    labels: getDates(startDate, endDate),
    datasets
  }

  return <StuiLineChart chartData={chartData} />
}

export default AnalyticsChart
