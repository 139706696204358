import React from 'react'

import classNames from 'classnames'

import { AccordionItemTitle } from 'react-accessible-accordion'

interface IStuiAccordionItemHeaderProps {
  children: any
  className?: string
  hideBodyClassName?: string
}

const StuiAccordionItemHeader: React.SFC<IStuiAccordionItemHeaderProps> = (props) => {
  const { ...other } = props
  const className = classNames(
    'o-stui-accordion-item__header',
    props.className
  )
  const hideBodyClassName = classNames(
    'o-stui-accordion-item__header--collapsed',
    props.className && `${props.className}--collapsed`,
    props.hideBodyClassName
  )

  return (
    <AccordionItemTitle
      {...other}
      className={className}
      hideBodyClassName={hideBodyClassName}
    />
  )
}

export default StuiAccordionItemHeader
