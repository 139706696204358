import React from 'react'

import { Route, Switch } from 'react-router-dom'

import {
  ROLE_MAPPING_NEW_PATH,
  ROLE_MAPPING_PATH,
  ROLE_MAPPINGS_PATH
} from 'app_search/utils/routePaths'

import RoleMapping from './RoleMapping'
import RoleMappings from './RoleMappings'

const RoleMappingsRouter: React.SFC = (props) => (
  <Switch>
    <Route exact={true} path={ROLE_MAPPINGS_PATH} render={() => <RoleMappings {...props} />} />
    <Route exact={true} path={ROLE_MAPPING_NEW_PATH} render={() => <RoleMapping isNew={true} {...props} />} />
    <Route path={ROLE_MAPPING_PATH} render={() => <RoleMapping {...props} />} />
  </Switch>
)

export default RoleMappingsRouter
