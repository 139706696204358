import React from 'react'

import { EuiSelect } from '@elastic/eui'

import { DATE, GEOLOCATION, NUMBER, TEXT } from 'shared/constants/fieldTypes'

interface ISchemaSelectProps {
  fieldName: string
  fieldType: string
  disabled?: boolean
  isAdd?: boolean
  handleFieldTypeChange(fieldType: string)
}

const selectData = [
  { value: TEXT, text: TEXT },
  { value: NUMBER, text: NUMBER },
  { value: DATE, text: DATE },
  { value: GEOLOCATION, text: GEOLOCATION }
]

const SchemaSelect: React.SFC<ISchemaSelectProps> = ({
  fieldName,
  fieldType,
  disabled,
  isAdd,
  handleFieldTypeChange
}) => (
  <EuiSelect
    name={`select-${isAdd ? 'add' : fieldName}`}
    required={true}
    value={fieldType}
    options={selectData}
    disabled={disabled}
    onChange={e => handleFieldTypeChange(e.target.value)}
    data-test-subj="SchemaSelect"
  />
)

export default SchemaSelect
