import React from 'react'

import classNames from 'classnames'
import filesize from 'filesize'
import humanizeList from 'humanize-list'
import Dropzone from 'react-dropzone'

import { IObject } from 'app_search/types'

import { EuiButton } from '@elastic/eui'

import StuiHeading from 'stui/Heading'
import StuiIcon from 'stui/Icon'

interface IStuiDropzoneProps {
  fileTypes: string[]
  className?: string
  title?: string
  description?: string
  onDrop(files: IObject[])
}

interface IStuiDropzoneState {
  acceptedFiles: IObject[]
  rejectedFiles: IObject[]
}

class StuiDropzone extends React.Component<IStuiDropzoneProps, IStuiDropzoneState> {
  readonly state: IStuiDropzoneState = {
    acceptedFiles: [],
    rejectedFiles: []
  }

  render() {
    const {
      className,
      title,
      description,
      fileTypes
    } = this.props
    const { acceptedFiles, rejectedFiles } = this.state
    const baseClassName = 'c-stui-dropzone'
    const dropzoneClasses = classNames(
      baseClassName,
      className,
      {
        'c-stui-dropzone--rejected': rejectedFiles.length > 0
      }
    )

    const acceptedFilesContent = (
      <div className="c-stui-dropzone-summary">
        <div className="c-stui-dropzone-summary__icon"/>
        <div className="c-stui-dropzone-summary__files">
          {acceptedFiles.map((f) => (
            <div className="c-stui-dropzone-file" key={f.name}>
              <div className="c-stui-dropzone-file__name">{f.name}</div>
              <div className="c-stui-dropzone-file__description">
                {filesize(f.size)}
              </div>
            </div>
          ))}
        </div>
        <EuiButton onClick={this.clearFiles}>
          Select a different file
        </EuiButton>
      </div>
    )

    const rejectedFilesContent = (
      <div className="c-stui-dropzone-summary">
        <div className="c-stui-dropzone-summary__icon"/>
        <div className="c-stui-dropzone-summary__files">
          {rejectedFiles.map((f) => (
            <div className="c-stui-dropzone-file" key={f.name}>
              <div className="c-stui-dropzone-file__name">{f.name}</div>
              <p className="c-stui-dropzone-file__description">
                <strong>{f.type}</strong> files cannot be uploaded. The file types allowed are {humanizeList(fileTypes)}.
              </p>
            </div>
          ))}
        </div>
        <EuiButton onClick={this.clearFiles}>
          Select a different file
        </EuiButton>
      </div>
    )

    const getDropzoneContent = () => {
      if (rejectedFiles.length > 0) {
        return rejectedFilesContent
      }

      return acceptedFiles.length > 0 ? acceptedFilesContent : (
        <>
          <StuiIcon className="c-stui-dropzone__icon" name="import" />
          <StuiHeading className="c-stui-dropzone__title" type="section">
            {title || 'Try dropping some files here, or click to select files to upload.'}
          </StuiHeading>
          {description && <p className="c-stui-dropzone__description">
              {description}
            </p>}
          <EuiButton>
            Choose File
          </EuiButton>
        </>
      )
    }

    return (
      <Dropzone
        accept={fileTypes}
        className={dropzoneClasses}
        activeClassName={`${baseClassName}--active`}
        acceptClassName={`${baseClassName}--accepted`}
        rejectClassName={`${baseClassName}--rejected`}
        disabledClassName={`${baseClassName}--disabled`}
        multiple={false}
        onDropAccepted={(files) => this.handleDrop(files)}
        onDropRejected={(files) => this.handleRejection(files)}
      >
        {getDropzoneContent()}
      </Dropzone>
    )
  }

  private handleDrop = (acceptedFiles) => {
    this.props.onDrop(acceptedFiles)
    this.setState({ acceptedFiles })
  }

  private handleRejection = (rejectedFiles) => {
    this.props.onDrop(rejectedFiles)
    this.setState({ rejectedFiles })
  }

  private clearFiles = (e) => {
    e.stopPropagation()
    this.setState({ acceptedFiles: [], rejectedFiles: [] })
  }
}

export default StuiDropzone
