import { IObject } from 'app_search/types'

import queryString from 'query-string'

export const setQueryParams = (params: IObject, history: IObject) => {
  history.push({
    pathname: history.location.pathname,
    search: queryString.stringify(params)
  })
}

export const parseQueryParams = (search: string) => queryString.parse(search)

export const paramValueToArray = value => value ? Array.isArray(value) ? value : [value] : []
