import React from 'react'

import classNames from 'classnames'

import { TRaw, TSnippet } from 'app_search/types'

interface IStuiResultFieldValueProps {
  raw?: TRaw
  snippet?: TSnippet
  type?: string
  className?: string
}

const getRawArrayDisplay = (rawArray) => {
  return `[${rawArray.map((raw) => isNaN(parseFloat(raw)) ? `"${raw}"` : raw).join(', ')}]`
}

const parseHighlights = (highlight: string) => {
  return highlight.replace(
    /<em>(.+?)<\/em>/gi,
    '<em class="o-stui-result-highlight" data-highlight="$1">$1</em>'
  )
}

const isFieldValueEmpty = (type, raw, snippet) => {
  const isNumber = type === 'number'
  if (isNumber) {
    return raw === null
  }

  return (!snippet && !raw)
}
const StuiResultFieldValue: React.SFC<IStuiResultFieldValueProps> = ({ snippet, raw, type, className }) => {
  const isEmpty = isFieldValueEmpty(type, raw, snippet)
  const fieldValueClassName = classNames(
    'o-stui-result-field-value',
    {
      'u-stui-data-type--empty': isEmpty,
      [`u-stui-data-type--${type}`]: !!type
    },
    className
  )
  const fieldValueNode = snippet ?
    <div className="o-stui-result-snippet" dangerouslySetInnerHTML={{ __html: parseHighlights(snippet) }} /> :
    <div className="o-stui-result-raw">{Array.isArray(raw) ? getRawArrayDisplay(raw) : raw}</div>

  return (
    <div className={fieldValueClassName}>
      {isEmpty ? '\u2014' : fieldValueNode}
    </div>
  )
}

export default StuiResultFieldValue
