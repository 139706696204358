import React, { useEffect } from 'react'
import { Prompt } from 'react-router'

import classNames from 'classnames'

import { Loading } from 'app_search/components/Loading'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiLink,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle
} from '@elastic/eui'

import {
  IObject,
  ISearchSettings
} from 'app_search/types'
import { StuiAccordion } from 'stui/Accordion'
import StuiEmptyState from 'stui/EmptyState'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'
import { StuiResult } from 'stui/Result'
import StuiSubHeading from 'stui/SubHeading'

import SearchSettingsField from './SearchSettingsField'
import { SearchSettingsLogic } from './SearchSettingsLogic'

interface ISearchSettingsProps {
  actions: {
    initializeSearchSettings()
    setFilterValue(value: string)
    updateSearchSettings()
    resetSearchSettings()
    updateSearchValue(query: string)
    addBoost(name: string, type: string)
    toggleSearchField(name: string, disableField: boolean)
    updateFieldWeight(name: string, weight: number)
    deleteBoost()
    updateBoostFactor()
    addBoostValue()
    removeBoostValue()
    updateBoostValue()
    updateBoostSelectOption()
    updateBoostCenter()
    resetSearchSettingsState()
    dismissSchemaConflictCallout()
  }
  dataLoading: boolean
  engineName: string
  filterInputValue: string
  flashMessages: IStuiFlashMessagesProps
  query: string
  resultsLoading: boolean
  schema: IObject
  schemaFields: string[]
  schemaFieldsWithConflicts: string[]
  searchResults: IObject[]
  searchSettings: ISearchSettings
  unsavedChanges: boolean
  showSchemaConflictCallout: boolean
  engineHasSchemaFields: boolean
  filteredSchemaFields: string[]
  filteredSchemaFieldsWithConflicts: string[]
}

const FIELD_FILTER_CUTOFF = 10
const UNSAVED_MESSAGE = 'Your relevence tuning has not been saved. Are you sure you want to leave?'

const SearchSettings: React.SFC<ISearchSettingsProps> = ({
  actions: {
    initializeSearchSettings,
    setFilterValue,
    updateSearchSettings,
    resetSearchSettings,
    updateSearchValue,
    addBoost,
    deleteBoost,
    toggleSearchField,
    updateFieldWeight,
    updateBoostFactor,
    addBoostValue,
    removeBoostValue,
    updateBoostValue,
    updateBoostSelectOption,
    updateBoostCenter,
    resetSearchSettingsState,
    dismissSchemaConflictCallout
  },
  dataLoading,
  engineName,
  filterInputValue,
  flashMessages,
  query,
  resultsLoading,
  schema,
  schemaFields,
  schemaFieldsWithConflicts,
  showSchemaConflictCallout,
  searchResults,
  searchSettings,
  unsavedChanges,
  engineHasSchemaFields,
  filteredSchemaFields,
  filteredSchemaFieldsWithConflicts
}) => {
  useEffect(() => {
    initializeSearchSettings()
    return resetSearchSettingsState
  }, [])

  useEffect(() => {
    window.onbeforeunload = unsavedChanges ? () => UNSAVED_MESSAGE : null
  }, [unsavedChanges])

  if (dataLoading) { return <Loading /> }

  const containerCssClass = classNames(
    'o-stui-replaceable-results',
    { loading: resultsLoading },
    { 'query-required': !searchResults },
    { 'no-results': searchResults && searchResults.length < 1 }
  )

  const handleFilterChange = e => setFilterValue(e.target.value)
  const handleSearchChange = e => updateSearchValue(e.target.value)
  const handleUpdateClick = e => {
    e.preventDefault()
    updateSearchSettings()
  }

  const formActions = (
    <>
      <EuiButtonEmpty onClick={resetSearchSettings} data-test-subj="DefaultRelevanceTuningButton">Restore Defaults</EuiButtonEmpty>
      <EuiButton color="secondary" fill={true} onClick={handleUpdateClick} data-test-subj="SaveRelevanceTuningButton">Save</EuiButton>
    </>
  )

  const SchemaConflictCallout = ({ count }) => {
    if (!count) {
      return <></>
    }
    return <>
      <EuiCallOut color="warning">
        <EuiFlexGroup>
          <EuiFlexItem >
            <EuiText color="warning" >
              {count} inactive field{count === 1 ? '' : 's'} due to field-type conflicts.{' '}
              <EuiLink
                href="https://swiftype.com/documentation/app-search/guides/meta-engines"
                target="_blank"
              >
                What's&nbsp;this?
              </EuiLink>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false} >
            <EuiLink onClick={dismissSchemaConflictCallout}>
              <EuiText>Dismiss</EuiText>
            </EuiLink>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiCallOut>
      <EuiSpacer />
    </>
  }

  return (
    <div data-test-subj="SearchSettings">
      <StuiHeader
        key="header"
        contained={true}
        viewHeader={true}
        isSticky={true}
        actions={engineHasSchemaFields && formActions}
      >
        <StuiHeading type="view">Relevance Tuning</StuiHeading>
        <StuiSubHeading>Set field weights and boosts.</StuiSubHeading>
      </StuiHeader>
      {engineHasSchemaFields
        ? (
          <>
            <StuiMain>
              <Prompt when={unsavedChanges} message={UNSAVED_MESSAGE} />
              <EuiSpacer />
              <StuiFlashMessages {...flashMessages} />
              <div className="stui-view-split-layout">
                <div className="stui-view-split-layout__pane">
                  <form onSubmit={handleUpdateClick}>
                    {showSchemaConflictCallout && <SchemaConflictCallout count={schemaFieldsWithConflicts.length} />}
                    <StuiHeader>
                      <StuiHeading type="section">Manage Fields</StuiHeading>
                    </StuiHeader>
                    {schemaFields.length > FIELD_FILTER_CUTOFF &&
                      <div className="fields-filter-wrap">
                        <EuiSpacer />
                        <EuiFieldSearch
                          value={filterInputValue}
                          onChange={handleFilterChange}
                          placeholder={`Filter ${schemaFields.length} fields...`}
                          fullWidth={true}
                        />
                      </div>}
                    <EuiSpacer />
                    {filteredSchemaFields.length > 0 &&
                      <StuiAccordion className="c-stui-field-manager">
                        {filteredSchemaFields.map(fieldName => (
                          <SearchSettingsField
                            key={fieldName}
                            name={fieldName}
                            type={schema[fieldName]}
                            field={searchSettings.search_fields[fieldName]}
                            boosts={searchSettings.boosts && searchSettings.boosts[fieldName]}
                            addBoost={addBoost}
                            deleteBoost={deleteBoost}
                            toggleSearchField={toggleSearchField}
                            updateFieldWeight={updateFieldWeight}
                            updateBoostFactor={updateBoostFactor}
                            addBoostValue={addBoostValue}
                            removeBoostValue={removeBoostValue}
                            updateBoostValue={updateBoostValue}
                            updateBoostSelectOption={updateBoostSelectOption}
                            updateBoostCenter={updateBoostCenter}
                          />
                        ))}
                      </StuiAccordion>
                    }
                    <EuiSpacer />
                    {filteredSchemaFieldsWithConflicts.length > 0 &&
                      <>
                        <StuiHeader>
                          <StuiHeading type="section">Disabled Fields</StuiHeading>
                        </StuiHeader>
                        <EuiSpacer />
                        <EuiFlexGroup
                          direction="column"
                        >

                          {filteredSchemaFieldsWithConflicts.map(fieldName => (
                            <EuiFlexItem
                              className="c-stui-field"
                              key={fieldName}
                              style={{ alignItems: 'flex-start' }}
                            >
                              <EuiTitle size="xs">
                                <h4>{fieldName}</h4>
                              </EuiTitle>
                              <EuiHealth color="warning">Inactive due to field-type conflict</EuiHealth>
                            </EuiFlexItem>
                          ))}
                        </EuiFlexGroup>
                        <EuiSpacer />
                      </>
                    }
                  </form>
                </div>
                <div className="stui-view-split-layout__pane">
                  <div className="js-stui-results-preview-sticky-wrapper">
                    <div className="c-stui-results-preview">
                      <EuiPanel hasShadow={true} paddingSize="l">
                        <EuiTitle size="m">
                          <h4>Preview</h4>
                        </EuiTitle>
                        <EuiSpacer />
                        <div className="c-stui-replaceable-table__container">
                          <EuiFieldSearch
                            onChange={handleSearchChange}
                            placeholder={`Search ${engineName}`}
                            fullWidth={true}
                            data-test-subj="SearchRelevanceTuningInput"
                          />
                          <EuiSpacer key="spacer" />
                          <div key="results" className={containerCssClass}>
                            {searchResults && searchResults.map((result, index) => {
                              return (
                                <StuiResult
                                  key={index}
                                  resultObject={result}
                                  showScore={true}
                                  schema={schema}
                                  condensed={true}
                                  query={query}
                                  boosts={searchSettings.boosts}
                                  searchFields={searchSettings.search_fields}
                                />
                              )
                            })}
                          </div>
                        </div>
                      </EuiPanel>
                    </div>
                  </div>
                </div>
              </div>
            </StuiMain>
            <footer key="footer" className="c-stui-view-footer">
              <div className="c-stui-view-footer__container">
                <div className="c-stui-view-footer__actions">
                  {formActions}
                </div>
              </div>
            </footer>
          </>
        ) : (
          <StuiMain>
            <StuiEmptyState
              title="Tuning requires schema fields"
              description="Index documents with at least two schema fields to tune relevance."
              icon="weights"
              action={<EuiButton fill={true} href="https://swiftype.com/documentation/app-search/guides/relevance-tuning">Read the Relevance Tuning Guide</EuiButton>}
            />
          </StuiMain>
        )
      }
    </div>
  )
}

export default SearchSettingsLogic(SearchSettings)
