import React, { useEffect, useState } from 'react'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer
} from '@elastic/eui'

import { TEXT } from 'shared/constants/fieldTypes'
import { formatFieldName } from 'shared/utils'

import SchemaSelect from './SchemaSelect'

interface ISchemaAddFieldModalProps {
  disableForm?: boolean
  addFieldFormErrors?: string[]
  addNewField(fieldName: string, newFieldType: string)
  closeAddFieldModal()
}

const SchemaAddFieldModal: React.SFC<ISchemaAddFieldModalProps> = ({
  addNewField,
  addFieldFormErrors,
  closeAddFieldModal,
  disableForm
}) => {
  const [loading, setLoading] = useState(false)
  const [newFieldType, updateNewFieldType] = useState(TEXT)
  const [formattedFieldName, setFormattedFieldName] = useState('')
  const [rawFieldName, setRawFieldName] = useState('')

  useEffect(() => {
    if (addFieldFormErrors) setLoading(false)
  }, [addFieldFormErrors])

  const handleChange = ({ currentTarget: { value } }) => {
    setRawFieldName(value)
    setFormattedFieldName(formatFieldName(value))
  }

  const submitForm = e => {
    e.preventDefault()
    addNewField(formattedFieldName, newFieldType)
    setLoading(true)
  }

  const fieldNameNote = rawFieldName !== formattedFieldName ?
    <>The field will be named <strong>{formattedFieldName}</strong></> :
    'Field names can only contain lowercase letters, numbers, and underscores'

  return (
    <EuiOverlayMask>
      <form onSubmit={submitForm}>
        <EuiModal onClose={closeAddFieldModal} maxWidth={500}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Add a New Field</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <p>Once added, a field cannot be removed from your schema.</p>
            <EuiForm>
              <EuiSpacer />
              <EuiFlexGroup gutterSize="m">
                <EuiFlexItem>
                  <EuiFormRow
                    label="Field name"
                    helpText={fieldNameNote}
                    fullWidth={true}
                    data-test-subj="SchemaAddFieldNameRow"
                    error={addFieldFormErrors}
                    isInvalid={!!addFieldFormErrors}
                  >
                    <EuiFieldText
                      placeholder="name"
                      type="text"
                      onChange={handleChange}
                      required={true}
                      value={rawFieldName}
                      fullWidth={true}
                      autoFocus={true}
                      isLoading={loading}
                      data-test-subj="SchemaAddFieldNameField"
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFormRow
                    label="Field type"
                    data-test-subj="SchemaAddFieldTypeRow"
                  >
                    <SchemaSelect
                      fieldName={formattedFieldName}
                      disabled={disableForm}
                      fieldType={newFieldType}
                      isAdd={true}
                      handleFieldTypeChange={fieldType => updateNewFieldType(fieldType)}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiForm>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty
              onClick={closeAddFieldModal}
            >
              Cancel
            </EuiButtonEmpty>
            <EuiButton
              color="secondary"
              fill={true}
              disabled={disableForm}
              type="submit"
              isLoading={loading}
              data-test-subj="SchemaAddFieldAddFieldButton"
            >
              Add Field
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </form>
    </EuiOverlayMask>
  )
}

export default SchemaAddFieldModal
