import React from 'react'

import classNames from 'classnames'

import StuiIcon from 'stui/Icon'

export interface IStuiEmptyStateProps {
  icon?: string
  title: string
  description?: React.ReactNode
  className?: string
  action?: React.ReactNode
  compact?: boolean
}

const StuiEmptyState: React.SFC<IStuiEmptyStateProps> = ({ title, icon, description, className, action, compact }) => (
  <div className={classNames('o-stui-empty-state', className, compact && 'o-stui-empty-state--compact')}>
    {icon && <div className="o-stui-empty-state__icon"><StuiIcon name={icon} /></div>}
    <div className="o-stui-empty-state__title">{title}</div>
    {description && <p className="o-stui-empty-state__description">{description}</p>}
    {action && <div className="o-stui-empty-state__action">{action}</div>}
  </div>
)

export default StuiEmptyState
