import React from 'react'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import { IObject } from 'app_search/types'

interface IPaymentVerificationRequiredModalProps {
  selectedPlan: IObject | null
  cancelPaymentVerification()
  verifyPayment()
}

const PaymentVerificationRequiredModal: React.SFC<IPaymentVerificationRequiredModalProps> = ({ selectedPlan, cancelPaymentVerification, verifyPayment }) => {
  return (
    <EuiOverlayMask>
      <EuiModal
        className="billing-payment-verification-modal"
        onClose={cancelPaymentVerification}
        data-test-subj="PaymentVerificationRequiredModal"
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>Payment verification required</EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiText>
            <p>By completing checkout, you consent to App Search initiating monthly payments on your behalf, calculated by your account tier and usage.</p>
          </EuiText>
          <div className="billing-payment-verification-modal--plan">
            <EuiText size="s">Billing Plan</EuiText>
            <EuiSpacer size="s" />
            {selectedPlan && <EuiText size="m"><strong>{selectedPlan.description} (${selectedPlan.price}/month)</strong></EuiText>}
          </div>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={cancelPaymentVerification}>Cancel</EuiButtonEmpty>
          <EuiButton
            fill={true}
            color="primary"
            onClick={verifyPayment}
            data-test-subj="PaymentVerificationRequiredModalVerifyPaymentButton"
          >
            Verify Payment
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}

export default PaymentVerificationRequiredModal
