import React from 'react'

import { EuiTitle } from '@elastic/eui'
import { IObject } from 'app_search/types'

enum StuiHeadingTypes {
  view,
  sub,
  section,
  title
}

interface IStuiHeadingProps {
  type: keyof typeof StuiHeadingTypes
  className?: string
}

const StuiHeading: React.SFC<IStuiHeadingProps> = ({ type, className, children }) => {
  const mapTypeToSize: IObject = {
    view: 'l',
    sub: 'm',
    section: 's',
    title: 'xs'
  }

  const headingElement = (size, content) => {
    let heading
    switch (size) {
      case 'view':
        heading = <h1>{content}</h1>
        break
      case 'sub':
        heading = <h2>{content}</h2>
        break
      case 'section':
        heading = <h3>{content}</h3>
        break
      default:
        heading = <h4>{content}</h4>
        break
    }
    return heading
  }

  return (
    <EuiTitle
      className={className}
      size={mapTypeToSize[type]}
    >
      {headingElement(type, children)}
    </EuiTitle>
  )
}

export default StuiHeading
