import { EngineLogic } from 'app_search/Engine/EngineLogic'
import routes from 'app_search/routes'
import { IMeta, ISynonymSet } from 'app_search/types'
import handleAPIError from 'app_search/utils/handleAPIError'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

interface ISynonymsFetchResponse {
  meta: IMeta
  results: ISynonymSet[]
}

const SYNONYM_SET_CREATED = 'Synonym set created. The set will impact your results shortly.'
const SYNONYM_SET_UPDATED = 'Synonym set saved. The set will impact your results shortly.'
const SYNONYM_SET_DELETED = 'Synonym set successfully deleted. The set will impact your results shortly.'
const emptySynonymSet = ['', '']

export const SynonymsLogic = storeLogic({
  connect: () => ({
    values: [
      EngineLogic, ['engineName']
    ]
  }),
  actions: () => ({
    createSynonymSet: (synonymSet: ISynonymSet) => ({ synonymSet }),
    addSynonymSets: (synonymSets: ISynonymSet[]) => ({ synonymSets }),
    resetSynonyms: true,
    updateSynonymSet: (synonymSet: ISynonymSet) => ({ synonymSet }),
    deleteSynonymSetSuccess: (synonymId: string) => ({ synonymId }),
    showSynonymsForm: true,
    hideSynonymsForm: false,
    setFormErrors: (formErrors: string[]) => ({ formErrors }),
    setFormSynonyms: (formSynonyms: string[]) => ({ formSynonyms }),
    setFormSynonymValue: (value: string, index: number) => ({ value, index }),
    setActiveSynonymSet: (activeSynonymSet: ISynonymSet) => ({ activeSynonymSet }),
    addSynonymInput: true,
    removeSynonymInput: (index: number) => ({ index }),
    handleActiveSynomymSet: (activeSynonymSet: ISynonymSet) => ({ activeSynonymSet }),
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages })
  }),
  reducers: ({ actions }) => ({
    synonymSets: [[], {
      [actions.createSynonymSet]: (state, { synonymSet }) => [...state, synonymSet],
      [actions.addSynonymSets]: (state, { synonymSets }) => [...state, ...synonymSets],
      [actions.resetSynonyms]: () => [],
      [actions.updateSynonymSet]: (state, { synonymSet }) => state.map((set) => (
        set.id === synonymSet.id ? synonymSet : set
      )),
      [actions.deleteSynonymSetSuccess]: (state, { synonymId }) => state.filter((set) => set.id !== synonymId)
    }],
    synonymsFormVisible: [false, {
      [actions.showSynonymsForm]: () => true,
      [actions.hideSynonymsForm]: () => false,
      [actions.createSynonymSet]: () => false,
      [actions.updateSynonymSet]: () => false,
      [actions.deleteSynonymSetSuccess]: () => false,
      [actions.handleActiveSynomymSet]: () => true
    }],
    activeSynonymSet: [null, {
      [actions.setActiveSynonymSet]: (_, { activeSynonymSet }) => activeSynonymSet,
      [actions.updateSynonymSet]: () => null,
      [actions.hideSynonymsForm]: () => null,
      [actions.addSynonymSets]: () => null,
      [actions.deleteSynonymSetSuccess]: () => null,
      [actions.handleActiveSynomymSet]: (_, { activeSynonymSet }) => activeSynonymSet
    }],
    formSynonyms: [emptySynonymSet, {
      [actions.setFormSynonyms]: (_, { formSynonyms }) => formSynonyms,
      [actions.setFormSynonymValue]: (formSynonyms, { value, index }) => {
        const synonyms = [...formSynonyms]
        synonyms[index] = value
        return synonyms
      },
      [actions.addSynonymInput]: formSynonyms => ([...formSynonyms, '']),
      [actions.hideSynonymsForm]: () => emptySynonymSet,
      [actions.createSynonymSet]: () => emptySynonymSet,
      [actions.updateSynonymSet]: () => emptySynonymSet,
      [actions.deleteSynonymSetSuccess]: () => emptySynonymSet,
      [actions.removeSynonymInput]: (formSynonyms, { index }) => {
        const synonyms = [...formSynonyms]
        synonyms.splice(index, 1)
        return synonyms
      }
    }],
    formErrors: [[], {
      [actions.setFormErrors]: (_, { formErrors }) => formErrors
    }],
    dataLoading: [true, {
      [actions.addSynonymSets]: () => false,
      [actions.resetSynonyms]: () => true
    }],
    flashMessages: [{}, {
      [actions.createSynonymSet]: () => ({ success: [SYNONYM_SET_CREATED] }),
      [actions.updateSynonymSet]: () => ({ success: [SYNONYM_SET_UPDATED] }),
      [actions.deleteSynonymSetSuccess]: () => ({ success: [SYNONYM_SET_DELETED] }),
      [actions.resetSynonyms]: () => ({}),
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages
    }]
  }),
  thunks: ({ actions, get }) => ({
    initializeSynonyms: () => {
      actions.fetchAllSynonymSets()
    },
    fetchAllSynonymSets: (page: number = 1) => {
      http({
        url: routes.locoMocoEngineSynonymsPath(get('engineName'), { page: { current: page } })
      })
        .then(({ data }: { data: ISynonymsFetchResponse }) => {
          actions.addSynonymSets(data.results)

          if (page < data.meta.page.total_pages) {
            actions.fetchAllSynonymSets(page + 1)
          }
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    deleteSynonymSet: (synonymId: string) => {
      http({
        method: 'delete',
        url: routes.locoMocoEngineSynonymPath(get('engineName'), synonymId)
      })
        .then(() => actions.deleteSynonymSetSuccess(synonymId))
        .catch(handleAPIError(messages => actions.setFormErrors(messages)))
    },
    submitSynonymSet: () => {
      actions.setFormErrors([])
      const synonyms = get('formSynonyms')
      const activeSynonymSet = get('activeSynonymSet')
      const engineName = get('engineName')
      const successCallback = activeSynonymSet ? actions.updateSynonymSet : actions.createSynonymSet
      const url = activeSynonymSet ?
        routes.locoMocoEngineSynonymPath(engineName, activeSynonymSet.id) :
        routes.locoMocoEngineSynonymsPath(engineName)

      return http({
        url,
        data: { synonyms },
        method: activeSynonymSet ? 'put' : 'post'
      })
        .then(({ data }) => successCallback(data))
        .catch(handleAPIError(messages => actions.setFormErrors(messages)))
    }
  })
})
