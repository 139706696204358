import React from 'react'

import classNames from 'classnames'
import moment from 'moment'
import numeral from 'numeral'
import { Line } from 'react-chartjs-2'

import { IObject } from 'app_search/types'

import StuiEmptyState from 'stui/EmptyState'

interface IStuiLineChartProps {
  chartData: IObject
}

interface IStuiLineChartState {
  tooltip: IObject
}

class StuiLineChart extends React.Component<IStuiLineChartProps, IStuiLineChartState> {
  readonly state: IStuiLineChartState = {
    tooltip: {}
  }

  chartOptions = {
    animation: {
      duration: 0
    },
    elements: {
      line: {
        lineTension: 0.40
      }
    },
    global: {
      defaultFontColor: '#647487',
      // tslint:disable-next-line:max-line-length
      defaultFontFamily: '-apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif'
    },
    grid: {
      offsetGridLines: true
    },
    hover: {
      animationDuration: 0,
      intersect: true,
      mode: 'nearest'
    },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 0,
    scales: {
      xAxes: [{
        gridLines: {
          borderDash: [6, 6]
        },
        ticks: {
          callback: (value) => moment(value).utc().format('M/D'),
          fontColor: '#647487',
          padding: 10
        }
      }],
      yAxes: [{
        gridLines: {
          color: '#E9EDF2',
          drawBorder: false
        },
        ticks: {
          beginAtZero: true,
          callback: (value) => numeral(value).format('0[.]0a'),
          fontColor: '#647487',
          maxTicksLimit: 5,
          padding: 10
        }
      }]
    },
    tooltips: {
      caretPadding: 0,
      caretSize: 0,
      custom: (tooltip) => this.setState({ tooltip }),
      enabled: false,
      intersect: false,
      mode: 'index',
      xPadding: 200,
      yPadding: 0
    }
  }

  render() {
    const {
      labels,
      datasets
    } = this.props.chartData

    const {
      opacity = 0,
      xAlign,
      x,
      width,
      caretX,
      dataPoints,
      labelColors
    } = this.state.tooltip

    const tooltipLeftOffset = xAlign === 'right' ? x + width : caretX

    const chartHasData = datasets[0].data.length > 0

    return chartHasData ? (
      <div className="c-stui-line-chart-container">
        <Line data={this.props.chartData} options={this.chartOptions} />
        {opacity !== 0 && (
          <>
            <div
              className="c-stui-chart-highlight"
              style={{
                left: `${tooltipLeftOffset}px`,
                opacity
              }}
            />
            <div
              className="c-stui-chart-tooltip-container"
              data-direction={xAlign}
              style={{
                transform: `translate(${tooltipLeftOffset}px, -50%)`,
                opacity
              }}
            >
              <div className="c-stui-chart-tooltip">
                <div className="c-stui-chart-tooltip__title">
                  {moment(labels[dataPoints[0].index]).utc().format('MMMM D, YYYY')}
                </div>
                <div className="c-stui-chart-tooltip__content">
                  <div className="c-stui-chart-tooltip-items">
                    {dataPoints.map(({ datasetIndex, yLabel }, index) => (
                      <div
                        key={datasetIndex}
                        className={classNames(
                          'c-stui-chart-tooltip-item',
                          `c-stui-chart-tooltip-item--${index < dataPoints.length - 1 ? 'secondary' : 'primary'}`
                        )}
                      >
                        <span
                          className="c-stui-chart-tooltip-item__key"
                          style={{
                            backgroundColor: labelColors[datasetIndex].borderColor
                          }}
                        />
                        <span className="c-stui-chart-tooltip-item__name">
                          <strong className="c-stui-chart-tooltip-item__count">{yLabel}</strong>
                          &nbsp;
                          {datasets[datasetIndex].label}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {dataPoints.map(({ index, datasetIndex, x: left, y: top }) => (
              <div
                key={datasetIndex}
                className={classNames(
                  'c-stui-chart-point c-stui-chart-point--visible',
                  { 'c-stui-chart-point--primary': !datasets[datasetIndex].label }
                )}
                data-index={index}
                data-dataset-index={datasetIndex}
                style={{
                  top: `${top}px`,
                  left: `${left}px`,
                  backgroundColor: labelColors[datasetIndex].borderColor
                }}
              />
            ))}
          </>
        )}
      </div>
    ) : (
      <StuiEmptyState
        icon="analytics"
        title="Nothing to report"
        description="No search acitivity found during this time period."
        compact={true}
        className="metric-empty-state"
      />
    )
  }
}

export default StuiLineChart
