import React, { useEffect } from 'react'

import classNames from 'classnames'
import { Prompt } from 'react-router'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'

import { Loading } from 'app_search/components/Loading'
import { ISchemaConflicts } from 'app_search/types'
import { IObject } from 'shared/types'
import StuiEmptyState from 'stui/EmptyState'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiMain from 'stui/Main'
import StuiSubHeading from 'stui/SubHeading'

import ConfirmModifyModal from './ConfirmModifyModal'
import ConfirmResetModal from './ConfirmResetModal'
import ConfirmSaveModal from './ConfirmSaveModal'
import ResultSettingsCallout from './ResultSettingsCallout'
import { ResultSettingsLogic } from './ResultSettingsLogic'
import ResultSettingsTable from './ResultSettingsTable'
import SampleResponse from './SampleResponse'
import {
  EOpenModal,
  IFieldResultSettingObject,
  IServerFieldResultSettingObject
} from './types'

const UNSAVED_MESSAGE = 'Result Settings have not been saved. Are you sure you want to leave?'

interface IResultSettingsProps {
  actions: {
    clearAllFields()
    clearRawSizeForField(fieldName: string)
    clearSnippetSizeForField(fieldName: string)
    closeModals()
    initializeResultSettingsData()
    saveResultSettings(resultFields: IServerFieldResultSettingObject)
    openConfirmModifyModal()
    openConfirmResetModal()
    openConfirmSaveModal()
    resetAllFields()
    toggleRawForField(fieldName: string)
    toggleSnippetFallbackForField(fieldName: string)
    toggleSnippetForField(fieldName: string)
    updateRawSizeForField(fieldName: string, size: number)
    updateSnippetSizeForField(fieldName: string, size: number)
  }
  schemaConflicts: ISchemaConflicts
  dataLoading: boolean
  flashMessages: IStuiFlashMessagesProps
  nonTextResultFields: IFieldResultSettingObject
  openModal: EOpenModal
  resultFieldsAtDefaultSettings: boolean
  resultFieldsEmpty: boolean
  saving: boolean
  schema: IObject
  reducedServerResultFields: IServerFieldResultSettingObject
  stagedUpdates: boolean
  textResultFields: IFieldResultSettingObject
}

const ResultSettings: React.SFC<IResultSettingsProps> = ({
  actions: {
    clearAllFields,
    closeModals,
    initializeResultSettingsData,
    openConfirmResetModal,
    openConfirmSaveModal,
    resetAllFields,
    saveResultSettings
  },
  schemaConflicts,
  dataLoading,
  flashMessages,
  nonTextResultFields,
  openModal,
  resultFieldsAtDefaultSettings,
  resultFieldsEmpty,
  saving,
  schema,
  reducedServerResultFields,
  stagedUpdates,
  textResultFields
}) => {
  const onClearAllValuesClick = (e) => {
    e.preventDefault()
    clearAllFields()
  }

  const onRestoreDefaultClick = (e) => {
    e.preventDefault()
    openConfirmResetModal()
  }

  const onSaveClick = (e) => {
    e.preventDefault()
    openConfirmSaveModal()
  }

  useEffect(() => {
    initializeResultSettingsData()
  }, [])

  useEffect(() => {
    window.onbeforeunload = stagedUpdates ? () => UNSAVED_MESSAGE : null
  }, [stagedUpdates])

  const resultSettingsActions = <>
    <EuiButtonEmpty
      onClick={onClearAllValuesClick}
    >
      Clear all values
    </EuiButtonEmpty>
    <EuiButton
      fill={true}
      color="primary"
      onClick={onRestoreDefaultClick}
      disabled={resultFieldsAtDefaultSettings}
    >
      Restore Defaults
    </EuiButton>
    <EuiButton
      onClick={onSaveClick}
      color="secondary"
      fill={true}
      disabled={resultFieldsEmpty || !stagedUpdates}
    >
      Save
    </EuiButton>
  </>

  if (dataLoading) {
    return <Loading />
  }

  const StuiMainClassNames = classNames(
    'result-settings__main',
    { faded: openModal === EOpenModal.ConfirmModifyModal }
  )

  return (
    <div
      className="result-settings"
      data-test-subj="ResultSettings"
    >
      <StuiHeader
        contained={true}
        viewHeader={true}
        isSticky={true}
        actions={(openModal !== EOpenModal.ConfirmModifyModal) && resultSettingsActions}
      >
        <EuiTitle size="l">
          <h1>Result Settings</h1>
        </EuiTitle>
        <StuiSubHeading>Enrich search results and select which fields will appear.</StuiSubHeading>
      </StuiHeader>
      <EuiSpacer />
      <StuiMain className={StuiMainClassNames}>
        <Prompt when={stagedUpdates} message={UNSAVED_MESSAGE} />
        <StuiFlashMessages {...flashMessages} />
        <ResultSettingsCallout />
        <EuiSpacer />
        <EuiFlexGroup>
          <EuiFlexItem className="result-settings__table-container">
            {Object.keys(schema).length > 0
              ? <ResultSettingsTable
                nonTextFields={nonTextResultFields}
                textFields={textResultFields}
                schemaConflicts={schemaConflicts}
              />
              : <StuiEmptyState icon="settings" title="Engine does not have a schema" description="You need one! A schema is created for you after you index some documents." />}

          </EuiFlexItem>
          <EuiFlexItem className="result-settings__sample-response-container">
            <SampleResponse
              resultSettings={reducedServerResultFields}
              schema={schema}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        {openModal === EOpenModal.ConfirmModifyModal &&
          <ConfirmModifyModal
            onConfirm={closeModals}
          />
        }
        {openModal === EOpenModal.ConfirmResetModal &&
          <ConfirmResetModal
            onConfirm={resetAllFields}
            onClose={closeModals}
          />
        }
        {openModal === EOpenModal.ConfirmSaveModal &&
          <ConfirmSaveModal
            saving={saving}
            onConfirm={() => saveResultSettings(reducedServerResultFields)}
            onClose={closeModals}
          />
        }
      </StuiMain>
    </div>
  )
}

export default ResultSettingsLogic(ResultSettings)
