import React from 'react'

import {
  EuiAvatar,
  EuiSpacer
} from '@elastic/eui'

import StuiTruncatedContent from 'stui/TruncatedContent'

const MAX_LENGTH = 28

export interface ICurrentUserProps {
  name: string | null
  email: string | null
  pictureUrl: string | null
}

const CurrentUser: React.SFC<ICurrentUserProps> = (props) => {
  const name = props.name || props.email || ''
  const avatar = props.pictureUrl ? <EuiAvatar imageUrl={props.pictureUrl} name={name} /> : <EuiAvatar name={name.toUpperCase()} />
  return (
    <div className="c-stui-current-account" tabIndex={0}>
      <div key="avatar" className="c-stui-current-account__avatar">{avatar}</div>
      <div key="content" className="c-stui-current-account__content u-visible-if-expanded">
        <div className="c-stui-current-account__name">
          <StuiTruncatedContent tooltipType="title" content={name} length={MAX_LENGTH} />
        </div>
        {!process.env.LOCO_TOGO && props.name && props.email &&
          <>
            <EuiSpacer size="xs" />
            <div className="c-stui-current-account__email">
              <StuiTruncatedContent tooltipType="title" content={props.email} length={MAX_LENGTH} />
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default CurrentUser
