import React, { useEffect, useState } from 'react'

import { EuiButton, EuiOverlayMask, EuiConfirmModal } from '@elastic/eui'

import { Loading } from 'app_search/components/Loading'

import { AddRoleMappingButton, RoleMappingsTable } from 'shared/components/RoleMapping'

import StuiEmptyState from 'stui/EmptyState'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import { IRole, IRoleMapping, IRouter } from 'app_search/types'
import { getRoleMappingRoute, ROLE_MAPPING_NEW_PATH } from 'app_search/utils/routePaths'

import { RoleMappingsLogic } from './RoleMappingsLogic'

interface IRoleMappingsProps extends IRouter {
  actions: {
    initializeRoleMappings()
    handleResetMappings(callback)
    resetState()
  }
  flashMessages: IStuiFlashMessagesProps
  myRole: IRole
  roleMappings: IRoleMapping[]
  dataLoading: boolean
}

const RoleMappings: React.SFC<IRoleMappingsProps> = ({
  actions: {
    initializeRoleMappings,
    handleResetMappings,
    resetState
  },
  flashMessages,
  myRole,
  roleMappings,
  dataLoading
}) => {
  useEffect(() => {
    initializeRoleMappings()
    return resetState
  }, [])
  const [isResetWarningVisible, setResetWarningVisibility] = useState(false)
  const showWarning = () => setResetWarningVisibility(true)
  const hideWarning = () => setResetWarningVisibility(false)

  if (dataLoading) { return <Loading /> }

  const addMappingButton = <AddRoleMappingButton path={ROLE_MAPPING_NEW_PATH} />

  const roleMappingEmptyState = (
    <StuiEmptyState
      icon="content-fill"
      title="No Role Mappings yet"
      description="All users who successfully authenticate will be assigned the Owner role and have access to all engines. Add a new role to override the default."
      action={addMappingButton}
    />
  )

  const roleMappingsTable = (
    <RoleMappingsTable
      roleMappings={roleMappings}
      accessItemKey="engines"
      accessHeader="Engine Access"
      addMappingButton={addMappingButton}
      getRoleMappingPath={getRoleMappingRoute}
      myRole={myRole}
    />
  )

  const resetMappings = (
    <EuiButton size="s" color="danger" onClick={showWarning}>
      Reset mappings
    </EuiButton>
  )
  const resetMappingsWarningModal = isResetWarningVisible ? (
    <EuiOverlayMask>
      <EuiConfirmModal
        onCancel={hideWarning}
        onConfirm={() => handleResetMappings(hideWarning)}
        title="Are you sure you want to reset role mappings?"
        cancelButtonText="Cancel"
        confirmButtonText="Reset role mappings"
        buttonColor="danger"
        maxWidth={640}
      >
        <p>
          <strong>All role mappings will be deleted</strong>, and all users who successfully
          authenticate will be assigned the Owner role and have access to all engines.
        </p>
      </EuiConfirmModal>
    </EuiOverlayMask>
  ) : null

  return (
    <div>
      <StuiHeader contained={true} viewHeader={true} actions={resetMappings}>
        <StuiHeading type="view">Users & Roles</StuiHeading>
        <p>Define role mappings for elasticsearch-native and elasticsearch-saml authentication.</p>
      </StuiHeader>
      <StuiMain>
        <StuiFlashMessages {...flashMessages} />
        {roleMappings.length === 0 ? roleMappingEmptyState : roleMappingsTable}
      </StuiMain>
      {resetMappingsWarningModal}
    </div>
  )
}

export default RoleMappingsLogic(RoleMappings)
