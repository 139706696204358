import React, { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'

import { useValues } from 'kea'

import { AppLogic } from 'app_search/App'
import useAbilities from 'app_search/utils/useAbilities'

import {
  BILLING_PATH,
  CREDENTIALS_PATH,
  ENGINES_PATH,
  META_ENGINES_PATH,
  ROLE_MAPPINGS_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
  USERS_PATH
} from 'app_search/utils/routePaths'

import StuiIcon from 'stui/Icon'

import { EuiSpacer } from '@elastic/eui'

import { ILocation } from 'app_search/types'

import CurrentUser from 'app_search/components/CurrentUser'

import Logo from './assets/Logo'

interface IMainNavigationProps {
  location: ILocation
}

const mainNavLinks = {
  engines: {
    label: 'Engines',
    href: ENGINES_PATH
  },
  metaEngines: {
    label: 'Meta Engines',
    testSubj: 'MetaEngines',
    href: META_ENGINES_PATH
  },
  credentials: {
    label: 'Credentials',
    href: CREDENTIALS_PATH
  },
  users: {
    label: 'Users',
    href: USERS_PATH
  },
  usersRoles: {
    label: 'Users & Roles',
    testSubj: 'UsersRoles',
    href: ROLE_MAPPINGS_PATH
  },
  settings: {
    label: 'Settings',
    href: SETTINGS_PATH
  },
  billing: {
    label: 'Billing',
    href: BILLING_PATH
  }
}

const IS_TOGO = process.env.LOCO_TOGO
const linkBaseClass = 'o-stui-nav-item o-stui-nav-item--default'

const NavLink = ({ section, pathname }) => {
  const { label, href, testSubj } = mainNavLinks[section]
  const isActive = href === pathname || pathname.startsWith(`${href}/`)
  const cssClass = classNames(
    linkBaseClass,
    { 'o-stui-nav-item--active': isActive }
  )

  return <Link
    to={href}
    className={cssClass}
    data-test-subj={`${testSubj ? testSubj : label}Link`}
    tabIndex={0}
  >
    <span className="o-stui-nav-item__label">{label}</span>
  </Link>
}

const MainNavigation: React.SFC<IMainNavigationProps> = ({ location: { pathname } }) => {
  const { appVersion, currentUser } = useValues(AppLogic)

  const {
    canDestroySession,
    canManageSettings,
    canManageTelemetryData,
    canUpdateUserPassword,
    canViewAccountCredentials,
    canViewEngines,
    canViewMetaEngines,
    canViewBilling,
    canViewRoleMappings,
    canViewUsers
  } = useAbilities()

  const [sidebarExpanded, setSidebarExpanded] = useState(false)

  const containerElementRef = useRef<HTMLDivElement>(null)

  useEffect(() => { setSidebarExpanded(false) }, [location.hash])

  const onDocumentClick = (e) => {
    if (
      containerElementRef.current
      && e.target !== containerElementRef.current
      && !containerElementRef.current.contains(e.target)
    ) {
      setSidebarExpanded(false)
    }
  }

  useEffect(() => {
    if (sidebarExpanded) {
      document.addEventListener('click', onDocumentClick)
      return () => {
        document.removeEventListener('click', onDocumentClick)
      }
    }
  }, [sidebarExpanded])

  const blurFocusedNavItems = () => {
    const activeElement = document.activeElement as HTMLElement
    if (activeElement && activeElement.classList.contains('o-stui-nav-item') && activeElement.blur) {
      activeElement.blur()
    }
  }

  useEffect(() => {
    if (!sidebarExpanded) {
      blurFocusedNavItems()
    }
  }, [sidebarExpanded])

  const openSideBar = () => {
    setSidebarExpanded(true)
  }

  const closeSideBar = () => {
    setSidebarExpanded(false)
  }

  const sidebarCssClass = classNames(
    'stui-layout-account-sidebar u-stui-inverted',
    { 'stui-layout-account-sidebar--expanded': sidebarExpanded }
  )

  const showSettingsNavItem = canManageSettings || canManageTelemetryData || canUpdateUserPassword || currentUser.pendingReconfirmation
  const showAccountNavHeader = canViewUsers || canViewBilling || showSettingsNavItem || canDestroySession
  const smasFeedbackHref = `mailto:app-search-feedback@elastic.co?subject=${encodeURIComponent('App Search self managed feedback')}`
  const supportEmailHref = `mailto:support@swiftype.com?subject=${encodeURIComponent('App Search support needed')}&amp;body=${encodeURIComponent(`

  -----
  App Search Email: ${currentUser.email}
  `)}`

  return (
    <div className={sidebarCssClass} ref={containerElementRef}>
      <div className="stui-layout-account-sidebar__inner-container">
        <div className="o-stui-header-container">
          <Link className="o-stui-brand" to={ROOT_PATH}>
            <div className="o-stui-brand__icon"><Logo /></div>
            <span className="o-stui-brand__name u-visible-if-expanded">
              <span>App Search</span>
              {IS_TOGO && <span title="Version Number" className="smas-version-number">v{appVersion}</span>}
            </span>
          </Link>
          <div className="stui-layout-account-sidebar__close o-stui-close u-visible-if-expanded" data-test-subj="SideBarClose" tabIndex={-1} onClick={closeSideBar}>
            <StuiIcon name="close" />
          </div>
        </div>
        <div className="c-stui-account-navigation-container">
          <div className="c-stui-account-sidebar-control u-hidden-if-expanded" data-test-subj="SideBarOpen" tabIndex={0} onClick={openSideBar}>
            <span /><span /><span />
          </div>
          <nav className="c-stui-account-navigation o-stui-nav o-stui-nav--vertical">
            {canViewEngines && <NavLink section="engines" pathname={pathname} />}
            {canViewMetaEngines && <NavLink section="metaEngines" pathname={pathname} />}
            {canViewAccountCredentials && <NavLink section="credentials" pathname={pathname} />}
            <EuiSpacer />
            {showAccountNavHeader && <div className="o-stui-sidebar-heading hide-on-mobile">Account</div>}
            {canViewRoleMappings && <NavLink section="usersRoles" pathname={pathname} />}
            {canViewUsers && <NavLink section="users" pathname={pathname} />}
            {showSettingsNavItem && <NavLink section="settings" pathname={pathname} />}
            {canViewBilling && <NavLink section="billing" pathname={pathname} />}
            {canDestroySession && <a href="/logout" className={linkBaseClass} data-test-subj="SignOutLink" tabIndex={0}>
              <span className="o-stui-nav-item__label">Sign Out</span>
            </a>}
          </nav>
        </div>
        {IS_TOGO ?
          <a className="c-stui-secondary-link" target="_blank" href={smasFeedbackHref} title="Send">
            <span className="c-stui-secondary-link__icon">
              <StuiIcon name="feedback" />
            </span>
            <span className="c-stui-secondary-link__label u-visible-if-expanded">Send Feedback</span>
          </a>
          : <>
            <a className="c-stui-secondary-link" href="/select" title="Swiftype Home">
              <span className="c-stui-secondary-link__icon">
                <StuiIcon name="home" />
              </span>
              <span className="c-stui-secondary-link__label u-visible-if-expanded">Swiftype Home</span>
            </a>
            <a className="c-stui-secondary-link" target="_blank" href={supportEmailHref} title="Contact Support">
              <span className="c-stui-secondary-link__icon">
                <StuiIcon name="feedback" />
              </span>
              <span className="c-stui-secondary-link__label u-visible-if-expanded">Contact Support</span>
            </a>
          </>}
        <a className="c-stui-secondary-link" href="https://swiftype.com/documentation/app-search/getting-started" target="_blank" title="Visit the documentation">
          <span className="c-stui-secondary-link__icon">
            <StuiIcon name="documentation" />
          </span>
          <span className="c-stui-secondary-link__label u-visible-if-expanded">Visit the Documentation</span>
        </a>
        {canDestroySession && currentUser.email && <CurrentUser {...currentUser} />}
      </div>
    </div>
  )
}

export default withRouter(MainNavigation)
