// The API errors we are handling can come from one of two ways:
//  - When Axios recieves a response containing an error code, such as a 404 or 500
//  - Our own JS while handling a successful response from Axios
//
// In the first case, if it is a purposeful error (like a 404) we are likely to receive
// an `error` or `errors` property in the response's data, which will contain messages
// we can display  to the user.
interface IPossibleHTTPError {
  response?: {
    data?: {
      errors?: string | string[]
      error?: string | string[]
    }
  }
}

// If the error we are handling does not contain a response, or the response does not contain
// `error` or `errors` properties then we will return a generic error message for the user.
export const getErrorMessages = (error: IPossibleHTTPError): string[] => {
  const defaultErrorMessage = 'An unexpected error occurred'
  const errorMessage: string | string[] = error?.response?.data?.errors || error?.response?.data?.error || [defaultErrorMessage]

  // The StuiFlashMessages component we use to display these messages expects an array of messages
  const errorMessages: string[] = Array.isArray(errorMessage) ? errorMessage : [errorMessage]

  // When the StuiFlashMessages component receives an array of messages, it makes the first item into a title
  // with subsequent messages displayed below it.  If we have multiple messages, we'll insert a generic title.
  return errorMessages.length > 1 ? ['Oops, something isn\'t quite right', ...errorMessages] : errorMessages
}

// Our error handler accepts a callback function to show error messages or do other post-request clean-up
export const handleAPIError = (onErrorCallback: (errorMessages: string[]) => void): (_: IPossibleHTTPError) => void => {
  return (error: IPossibleHTTPError) => {
    const errorMessages: string[] = getErrorMessages(error)

    onErrorCallback(errorMessages)

    // If this was a programming error or a failed request (such as a CORS) error, we need to rethrow the error
    // so that it can be logged to Sentry and in the developer console
    if (!error.response) {
      throw error
    }
  }
}

export default handleAPIError
