import React, { useState } from 'react'

import { useValues } from 'kea'

import { EngineLogic } from 'app_search/Engine'

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiTitle
} from '@elastic/eui'

import QueryTesterSearch from './QueryTesterSearch'

const QueryTesterFlyout: React.SFC<{}> = () => {
  const { engine: { name: engineName, schema, result_fields: resultFields } } = useValues(EngineLogic)
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false)

  const toggleFlyout = () => { setIsFlyoutOpen(!isFlyoutOpen) }

  const flyout = isFlyoutOpen && (
    <EuiFlyout
      onClose={toggleFlyout}
      hideCloseButton={true}
      ownFocus={true}
      data-test-subj="QueryTesterFlyout"
      aria-labelledby="queryTesterFlyoutTitle"
    >
      <EuiFlyoutHeader hasBorder={true}>
        <EuiTitle size="m">
          <h2 id="queryTesterFlyoutTitle">Query Tester</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody style={{ display: 'flex' }}>
        <QueryTesterSearch
          resultFields={resultFields}
          engineName={engineName}
          schema={schema}
          condensed={true}
        />
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              iconType="cross"
              onClick={toggleFlyout}
            >
              Close
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )

  return <>
    <EuiButtonEmpty iconType="inspect" data-test-subj="QueryTesterFlyoutButton" onClick={toggleFlyout}>Query Tester</EuiButtonEmpty>
    {flyout}
  </>
}

export default QueryTesterFlyout
