import React, { useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'

import { Loading } from 'app_search/components/Loading'
import { ROLE_MAPPINGS_PATH } from 'app_search/utils/routePaths'

import {
  AttributeSelector,
  DeleteMappingCallout,
  RoleSelector
} from 'shared/components/RoleMapping'

import { RoleMappingsLogic } from './RoleMappingsLogic'

import {
  EuiButton,
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiRadio,
  EuiSpacer
} from '@elastic/eui'

import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import { Ability } from 'app_search/classes'
import { IEngine, IObject, IRole, IRoleMapping, IRouter, TRole } from 'app_search/types'

interface IRoleMappingProps extends IRouter {
  actions: {
    initializeRoleMapping(history: IObject, role: IRole, roleId?: string)
    handleDeleteMapping()
    handleRoleChange(roleType: TRole)
    handleSaveMapping()
    handleAccessAllEnginesChange()
    handleEngineSelectionChange(engineName: string, selected: boolean)
    handleAttributeSelectorChange(value: string, firstElasticsearchRole: string)
    handleAttributeValueChange(value: string)
    resetState()
  }
  myRole: IRole
  isNew?: boolean
  dataLoading: boolean
  hasAdvancedRoles: boolean
  availableEngines: IEngine[]
  selectedEngines: Set<string>
  attributes: string[]
  elasticsearchRoles: string[]
  roleMapping?: IRoleMapping
  roleType: TRole
  accessAllEngines: boolean
  attributeValue: string
  attributeName: string
  flashMessages: IStuiFlashMessagesProps
}

const advancedRoleTypes = [
  {
    type: 'dev',
    description: 'Devs can manage all aspects of an Engine.'
  },
  {
    type: 'editor',
    description: 'Editors can manage Search Settings.'
  },
  {
    type: 'analyst',
    description: 'Analysts can only view Documents, Query Tester, and Analytics.'
  }
]

const standardRoleTypes = [
  {
    type: 'owner',
    description: 'Owners can do anything. There can be many owners on the account, but there must be at least one owner at any time.'
  },
  {
    type: 'admin',
    description: 'Admins can do anything, except manage telemetry data.'
  }
]

const RoleMapping: React.SFC<IRoleMappingProps> = ({
  actions: {
    initializeRoleMapping,
    handleDeleteMapping,
    handleRoleChange,
    handleSaveMapping,
    handleAccessAllEnginesChange,
    handleEngineSelectionChange,
    handleAttributeSelectorChange,
    handleAttributeValueChange,
    resetState
  },
  myRole,
  isNew,
  dataLoading,
  hasAdvancedRoles,
  availableEngines,
  attributes,
  elasticsearchRoles,
  roleMapping,
  roleType,
  accessAllEngines,
  selectedEngines,
  attributeValue,
  attributeName,
  flashMessages,
  match: { params: { roleId } },
  history
}) => {
  useEffect(() => {
    initializeRoleMapping(history, roleId)
    return resetState
  }, [])

  if (dataLoading) { return <Loading /> }

  const saveRoleMappingButton = (
    <EuiButton onClick={handleSaveMapping} fill={true}>
      {isNew ? 'Save' : 'Update'} Role Mapping
    </EuiButton>
  )

  const engineSelector = (engine) => {
    return <EuiCheckbox
      key={engine.name}
      name={engine.name}
      id={engine.name}
      checked={selectedEngines.has(engine.name)}
      onChange={(e) => { handleEngineSelectionChange(engine.name, e.target.checked) }}
      label={engine.name}
    />
  }

  const advancedRoleSelectors = <>
    <EuiSpacer />
    <StuiHeader>
      <StuiHeading type="title">Full or Limited Engine Access</StuiHeading>
    </StuiHeader>
    <EuiSpacer />
    {advancedRoleTypes.map(({ type, description }) => (
      <RoleSelector
        key={type}
        disabled={!myRole.ability.invitableRoleTypes().includes(type)}
        roleType={roleType}
        roleTypeOption={type}
        description={description}
        onChange={handleRoleChange}
      />
    ))}
  </>

  return (
    <>
      <div className="stui-view-topbar">
        <div className="o-stui-breadcrumbs">
          <Link className="o-stui-breadcrumb" to={ROLE_MAPPINGS_PATH}>Users & Roles</Link>
          <div className="o-stui-breadcrumb">{isNew ? 'Add' : 'Manage'} Role Mapping</div>
        </div>
      </div>
      <StuiHeader contained={true} viewHeader={true} actions={saveRoleMappingButton}>
        <StuiHeading type="view">{isNew ? 'Add' : 'Manage'} Role Mapping</StuiHeading>
      </StuiHeader>
      <StuiMain>
        <StuiFlashMessages {...flashMessages} />
        <AttributeSelector
          attributeName={attributeName}
          attributeValue={attributeValue}
          attributes={attributes}
          elasticsearchRoles={elasticsearchRoles}
          disabled={!!roleMapping}
          handleAttributeSelectorChange={handleAttributeSelectorChange}
          handleAttributeValueChange={handleAttributeValueChange}
        />
        <EuiSpacer />
        <EuiFlexGroup alignItems="stretch">
          <EuiFlexItem>
            <EuiPanel paddingSize="l">
              <StuiHeader>
                <StuiHeading type="section">Role</StuiHeading>
              </StuiHeader>
              <EuiSpacer />
              <StuiHeader>
                <StuiHeading type="title">Full Engine Access</StuiHeading>
              </StuiHeader>
              <EuiSpacer />
              {standardRoleTypes.map(({ type, description }) => (
                <RoleSelector
                  key={type}
                  roleType={roleType}
                  onChange={handleRoleChange}
                  roleTypeOption={type}
                  description={description}
                />
              ))}
              {hasAdvancedRoles && advancedRoleSelectors}
            </EuiPanel>
          </EuiFlexItem>
          {hasAdvancedRoles && <EuiFlexItem>
            <EuiPanel paddingSize="l">
              <StuiHeader>
                <StuiHeading type="section">Engine Access</StuiHeading>
              </StuiHeader>
              <EuiSpacer />
              <EuiFormRow>
                <EuiRadio
                  id="accessAllEngines"
                  disabled={!Ability.canHaveScopedEngines(roleType)}
                  checked={accessAllEngines}
                  onChange={handleAccessAllEnginesChange}
                  label={
                    <StuiHeader>
                      <StuiHeading type="title">Full Engine Access</StuiHeading>
                      <p>Access to all current and future Engines.</p>
                    </StuiHeader>
                  }
                />
              </EuiFormRow>
              <EuiFormRow>
                <>
                  <EuiRadio
                    id="selectEngines"
                    disabled={!Ability.canHaveScopedEngines(roleType)}
                    checked={!accessAllEngines}
                    onChange={handleAccessAllEnginesChange}
                    label={
                      <StuiHeader>
                        <StuiHeading type="title">Limited Engine Access</StuiHeading>
                        <p>Limit user access to specific engines:</p>
                      </StuiHeader>
                    }
                  />
                  {!accessAllEngines &&
                    <div className="engines-list">
                      {availableEngines.map(engine => engineSelector(engine))}
                    </div>
                  }
                </>
              </EuiFormRow>
            </EuiPanel>
          </EuiFlexItem>
          }
        </EuiFlexGroup>
        <EuiSpacer />
        {roleMapping && <DeleteMappingCallout handleDeleteMapping={handleDeleteMapping} />}
      </StuiMain>
    </>
  )
}

export default withRouter(RoleMappingsLogic(RoleMapping))
