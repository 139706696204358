import React from 'react'

import {
  EuiButton,
  EuiRange
} from '@elastic/eui'

import StuiHeading from 'stui/Heading'

interface IBoostFooterProps {
  name: string
  index: number
  score: number
  onChange(name: string, index: number, value: number)
  deleteBoost(name: string, index: number)
}

const BoostFooter: React.SFC<IBoostFooterProps> = ({ name, index, score, onChange, deleteBoost }) => {
  const handleRangeChange = e => onChange(name, index, parseFloat(e.target.value))

  return (
    <>
      <div className="o-stui-panel-section">
        <div className="o-stui-panel-section__header">
          <StuiHeading type="title" className="o-stui-panel-section__title">
            Impact
        </StuiHeading>
        </div>
        <div className="o-stui-panel-section__body boost-range">
          <EuiRange
            min={-10}
            max={10}
            step={.1}
            value={score}
            onChange={handleRangeChange}
            showInput={true}
            compressed={true}
            fullWidth={true}
          />
        </div>
      </div>
      <div className="o-stui-panel-section o-stui-panel-section--footer">
        <div className="o-stui-panel-section__body">
          <EuiButton
            color="danger"
            iconType="cross"
            size="s"
            onClick={() => deleteBoost(name, index)}
            data-test-subj="DeleteBoostRelevanceTuningButton"
          >
            Delete Boost
          </EuiButton>
        </div>
      </div>
    </>
  )

}
export default BoostFooter
