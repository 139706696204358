import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'

import { EngineLogic } from 'app_search/Engine/EngineLogic'
import routes from 'app_search/routes'
import { IObject, ISchemaConflicts } from 'app_search/types'
import handleAPIError from 'app_search/utils/handleAPIError'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

import {
  EOpenModal,
  IFieldResultSetting,
  IFieldResultSettingObject,
  IServerFieldResultSetting,
  IServerFieldResultSettingObject
} from './types'

interface IEngineResultSettingsDetailsData {
  data: {
    schema: IObject
    schemaConflicts?: ISchemaConflicts
    searchSettings: {
      result_fields: IServerFieldResultSettingObject
    }
  }
}

interface IEngineResultSettingsData {
  data: {
    result_fields: IServerFieldResultSettingObject
  }
}

const DEFAULT_SNIPPET_SIZE = 100
const DEFAULT_FIELD_SETTINGS: IFieldResultSetting = { raw: true, snippet: false, snippetFallback: false }
const DISABLED_FIELD_SETTINGS: IFieldResultSetting = { raw: false, snippet: false, snippetFallback: false }

const convertToFieldResultSetting = (serverFieldResultSetting: IServerFieldResultSetting) => {
  const fieldResultSetting: IFieldResultSetting = {
    raw: !!serverFieldResultSetting.raw,
    snippet: !!serverFieldResultSetting.snippet,
    snippetFallback: !!(serverFieldResultSetting.snippet && typeof serverFieldResultSetting.snippet === 'object' && serverFieldResultSetting.snippet.fallback)
  }

  if (serverFieldResultSetting.raw && typeof serverFieldResultSetting.raw === 'object' && serverFieldResultSetting.raw.size) {
    fieldResultSetting.rawSize = serverFieldResultSetting.raw.size
  }

  if (serverFieldResultSetting.snippet && typeof serverFieldResultSetting.snippet === 'object' && serverFieldResultSetting.snippet.size) {
    fieldResultSetting.snippetSize = serverFieldResultSetting.snippet.size
  }

  return fieldResultSetting
}

const convertToServerFieldResultSetting = (fieldResultSetting: IFieldResultSetting) => {
  const serverFieldResultSetting: IServerFieldResultSetting = {}
  if (fieldResultSetting.raw) {
    serverFieldResultSetting.raw = {}
    if (fieldResultSetting.rawSize) {
      serverFieldResultSetting.raw.size = fieldResultSetting.rawSize
    }
  }

  if (fieldResultSetting.snippet) {
    serverFieldResultSetting.snippet = {}
    if (fieldResultSetting.snippetFallback) {
      serverFieldResultSetting.snippet.fallback = fieldResultSetting.snippetFallback
    }
    if (fieldResultSetting.snippetSize) {
      serverFieldResultSetting.snippet.size = fieldResultSetting.snippetSize
    }
  }

  return serverFieldResultSetting
}

const convertServerResultFieldsToResultFields = (serverResultFields: IServerFieldResultSettingObject, schema: IObject) => {
  const resultFields: IFieldResultSettingObject = Object.keys(schema).reduce((acc: IFieldResultSettingObject, fieldName: string) => ({
    ...acc,
    [fieldName]: serverResultFields[fieldName] ? convertToFieldResultSetting(serverResultFields[fieldName]) : DISABLED_FIELD_SETTINGS
  }), {})
  return resultFields
}

const areFieldsAtDefaultSettings = (fields: IFieldResultSettingObject) => {
  for (const [, resultSettings] of Object.entries(fields)) {
    if (!_isEqual(resultSettings, DEFAULT_FIELD_SETTINGS)) {
      return false
    }
  }
  return true
}

const areFieldsEmpty = (fields: IFieldResultSettingObject) => {
  for (const [, resultSettings] of Object.entries(fields)) {
    if (!_isEmpty(resultSettings)) {
      return false
    }
  }
  return true
}

const splitResultFields = (resultFields: IFieldResultSettingObject, schema: IObject) => {
  const textResultFields = {}
  const nonTextResultFields = {}
  const keys = Object.keys(schema)
  keys.forEach(fieldName => {
    (schema[fieldName] === 'text' ? textResultFields : nonTextResultFields)[fieldName] = resultFields[fieldName]
  })

  return { textResultFields, nonTextResultFields }
}

const clearAllServerFields = (fields: IServerFieldResultSettingObject) => updateAllFields(fields, {})

const resetAllServerFields = (fields: IServerFieldResultSettingObject) => updateAllFields(fields, { raw: {} })

const clearAllFields = (fields: IFieldResultSettingObject) => updateAllFields(fields, {})

const resetAllFields = (fields: IFieldResultSettingObject) => updateAllFields(fields, DEFAULT_FIELD_SETTINGS)

const updateAllFields = (fields: IFieldResultSettingObject | IServerFieldResultSettingObject, newValue) => {
  return Object.keys(fields).reduce((acc, fieldName) => ({ ...acc, [fieldName]: { ...newValue } }), {})
}

export const ResultSettingsLogic = storeLogic({
  connect: () => ({
    values: [
      EngineLogic, ['engineName']
    ]
  }),
  actions: () => ({
    clearAllFields: () => true,
    closeModals: () => true,
    initializeResultFields: (
      serverResultFields: IServerFieldResultSettingObject,
      schema: IObject,
      schemaConflicts: ISchemaConflicts
    ) => {
      const resultFields = convertServerResultFieldsToResultFields(serverResultFields, schema)
      for (const fieldName of Object.keys(schema)) {
        if (!(fieldName in serverResultFields)) {
          serverResultFields[fieldName] = {}
        }
      }
      return ({
        serverResultFields,
        resultFields,
        schema,
        schemaConflicts,
        ...splitResultFields(resultFields, schema)
      })
    },
    openConfirmResetModal: () => true,
    openConfirmSaveModal: () => true,
    resetAllFields: () => true,
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages }),
    updateField: (fieldName: string, field: IFieldResultSetting) => ({ fieldName, field }),
    saving: () => true
  }),
  reducers: ({ actions }) => ({
    dataLoading: [true, {
      [actions.initializeResultFields]: () => false
    }],
    flashMessages: [{}, {
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages
    }],
    lastSavedResultFields: [{}, {
      [actions.initializeResultFields]: (_, { resultFields }: { resultFields: IFieldResultSettingObject }) => resultFields
    }],
    nonTextResultFields: [{}, {
      [actions.initializeResultFields]: (_, { nonTextResultFields }: { nonTextResultFields: IFieldResultSettingObject }) => nonTextResultFields,
      [actions.clearAllFields]: (nonTextResultFields: IFieldResultSettingObject) => clearAllFields(nonTextResultFields),
      [actions.resetAllFields]: (nonTextResultFields: IFieldResultSettingObject) => resetAllFields(nonTextResultFields),
      [actions.updateField]: (nonTextResultFields: IFieldResultSettingObject, { fieldName, field }: { fieldName: string, field: IFieldResultSetting }) => fieldName in nonTextResultFields ? { ...nonTextResultFields, [fieldName]: field } : nonTextResultFields
    }],
    openModal: [EOpenModal.None, {
      [actions.initializeResultFields]: (_, { resultFields }: { resultFields: IFieldResultSettingObject }) => areFieldsAtDefaultSettings(resultFields) ? EOpenModal.ConfirmModifyModal : EOpenModal.None,
      [actions.openConfirmResetModal]: () => EOpenModal.ConfirmResetModal,
      [actions.openConfirmSaveModal]: () => EOpenModal.ConfirmSaveModal,
      [actions.closeModals]: () => EOpenModal.None,
      [actions.saving]: () => EOpenModal.None,
      [actions.resetAllFields]: () => EOpenModal.None,
      [actions.setFlashMessages]: () => EOpenModal.None
    }],
    resultFields: [{}, {
      [actions.initializeResultFields]: (_, { resultFields }: { resultFields: IFieldResultSettingObject }) => resultFields,
      [actions.clearAllFields]: (resultFields: IFieldResultSettingObject) => clearAllFields(resultFields),
      [actions.resetAllFields]: (resultFields: IFieldResultSettingObject) => resetAllFields(resultFields),
      [actions.updateField]: (resultFields: IFieldResultSettingObject, { fieldName, field }: { fieldName: string, field: IFieldResultSetting }) => fieldName in resultFields ? { ...resultFields, [fieldName]: field } : resultFields
    }],
    saving: [false, {
      [actions.saving]: () => true,
      [actions.initializeResultFields]: () => false,
      [actions.setFlashMessages]: () => false
    }],
    schema: [{}, {
      [actions.initializeResultFields]: (_, { schema }) => schema
    }],
    schemaConflicts: [{}, {
      [actions.initializeResultFields]: (_, { schemaConflicts }) => schemaConflicts || {}
    }],
    serverResultFields: [{}, {
      [actions.initializeResultFields]: (_, { serverResultFields }: { serverResultFields: IServerFieldResultSettingObject }) => serverResultFields,
      [actions.clearAllFields]: (serverResultFields: IServerFieldResultSettingObject) => clearAllServerFields(serverResultFields),
      [actions.resetAllFields]: (serverResultFields: IServerFieldResultSettingObject) => resetAllServerFields(serverResultFields),
      [actions.updateField]: (serverResultFields: IServerFieldResultSettingObject, { fieldName, field }: { fieldName: string, field: IFieldResultSetting }) => {
        const newServerResultFields: IServerFieldResultSettingObject = { ...serverResultFields }
        const newServerField: IServerFieldResultSetting = convertToServerFieldResultSetting(field)
        newServerResultFields[fieldName] = newServerField
        return newServerResultFields
      }
    }],
    textResultFields: [{}, {
      [actions.initializeResultFields]: (_, { textResultFields }: { textResultFields: IFieldResultSettingObject }) => textResultFields,
      [actions.clearAllFields]: (textResultFields: IFieldResultSettingObject) => clearAllFields(textResultFields),
      [actions.resetAllFields]: (textResultFields: IFieldResultSettingObject) => resetAllFields(textResultFields),
      [actions.updateField]: (textResultFields: IFieldResultSettingObject, { fieldName, field }: { fieldName: string, field: IFieldResultSetting }) => fieldName in textResultFields ? { ...textResultFields, [fieldName]: field } : textResultFields
    }]
  }),
  selectors: ({ selectors }: { selectors: { lastSavedResultFields: IFieldResultSettingObject, resultFields: IFieldResultSettingObject, serverResultFields: IServerFieldResultSettingObject } }) => ({
    resultFieldsAtDefaultSettings: [
      () => [selectors.resultFields],
      (resultFields: IFieldResultSettingObject) => areFieldsAtDefaultSettings(resultFields)
    ],
    resultFieldsEmpty: [
      () => [selectors.resultFields],
      (resultFields: IFieldResultSettingObject) => areFieldsEmpty(resultFields)
    ],
    stagedUpdates: [
      () => [selectors.lastSavedResultFields, selectors.resultFields],
      (lastSavedResultFields: IFieldResultSettingObject, resultFields: IFieldResultSettingObject) => !_isEqual(lastSavedResultFields, resultFields)
    ],
    reducedServerResultFields: [
      () => [selectors.serverResultFields],
      (serverResultFields: IServerFieldResultSettingObject) =>
        Object.entries(serverResultFields)
          .reduce((acc, [fieldName, resultSetting]) => {
            if (resultSetting.raw || resultSetting.snippet) {
              acc[fieldName] = resultSetting
            }
            return acc
          }, {})
    ]
  }),
  thunks: ({ actions, get }) => ({
    clearRawSizeForField: (fieldName: string) => {
      const field: IFieldResultSetting = { ...get('resultFields')[fieldName] }
      delete field.rawSize
      actions.updateField(fieldName, field)
    },
    clearSnippetSizeForField: (fieldName: string) => {
      const field: IFieldResultSetting = { ...get('resultFields')[fieldName] }
      delete field.snippetSize
      actions.updateField(fieldName, field)
    },
    initializeResultSettingsData: () => {
      http.get(routes.detailsLocoMocoEngineResultSettingsPath(get('engineName')))
        .then(({
          data: {
            schema,
            schemaConflicts,
            searchSettings: {
              result_fields: serverFieldResultSettings
            }
          }
        }: IEngineResultSettingsDetailsData) => {
          actions.initializeResultFields(serverFieldResultSettings, schema, schemaConflicts)
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    saveResultSettings: (resultFields: IServerFieldResultSettingObject) => {
      actions.saving()
      http.put(routes.locoMocoEngineResultSettingsPath(get('engineName'), {
        format: 'json'
      }), {
        result_fields: resultFields
      })
        .then(({
          data: {
            result_fields: serverFieldResultSettings
          }
        }: IEngineResultSettingsData) => {
          actions.initializeResultFields(serverFieldResultSettings, get('schema'))
          actions.setFlashMessages({ success: ['Result Settings have been saved successfully.'] })
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    toggleRawForField: (fieldName: string) => {
      const field: IFieldResultSetting = { ...get('resultFields')[fieldName] }
      field.raw = !field.raw
      if (!field.raw) {
        delete field.rawSize
      }
      actions.updateField(fieldName, field)
    },
    toggleSnippetFallbackForField: (fieldName: string) => {
      const field: IFieldResultSetting = { ...get('resultFields')[fieldName] }
      field.snippetFallback = !field.snippetFallback
      actions.updateField(fieldName, field)
    },
    toggleSnippetForField: (fieldName: string) => {
      const field: IFieldResultSetting = { ...get('resultFields')[fieldName] }
      field.snippet = !field.snippet
      if (field.snippet) {
        field.snippetSize = DEFAULT_SNIPPET_SIZE
      } else {
        delete field.snippetSize
      }
      actions.updateField(fieldName, field)
    },
    updateRawSizeForField: (fieldName: string, size: number) => {
      const field: IFieldResultSetting = { ...get('resultFields')[fieldName] }
      field.rawSize = size
      actions.updateField(fieldName, field)
    },
    updateSnippetSizeForField: (fieldName: string, size: number) => {
      const field: IFieldResultSetting = { ...get('resultFields')[fieldName] }
      field.snippetSize = size
      actions.updateField(fieldName, field)
    }
  })
})
