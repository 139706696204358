import { captureException } from 'app_search/sentry'

import {
  IAbility,
  IObject,
  IRole,
  TRole
} from 'app_search/types'

export class Ability implements IAbility {
  static canHaveScopedEngines(roleType: TRole) {
    return Role.unscopedRoles.indexOf(roleType) > -1
  }

  ability: IObject
  roles: {
    id: {
      managableRoleTypes: string[]
      canRemoveRole: boolean
    }
  }

  constructor(ability) {
    this.ability = ability
    this.roles = ability.roles
  }

  getOtherRole(id: string | number) {
    if (this.roles[id] === undefined) {
      captureException(
        'RBAC: Could not find role based on id provided',
        {
          id,
          component: 'Ability',
          roles: this.roles
        }
      )
    }
    return this.roles[id]
  }

  can(action: string, subject: string) {
    // to mirror cancan's special handling of 'manage'
    // https://github.com/ryanb/cancan/wiki/defining-abilities#the-can-method
    return this.ability.manage.includes(subject) || (this.ability[action] !== undefined && this.ability[action].includes(subject))
  }

  canChangeRole(otherRole: IRole) {
    const scopedOtherRole = this.getOtherRole(otherRole.id)
    return scopedOtherRole && scopedOtherRole.managableRoleTypes.length > 0
  }

  canChangeRoleTo(otherRole: IRole, roleType: TRole) {
    const scopedOtherRole = this.getOtherRole(otherRole.id)
    return !!(scopedOtherRole && scopedOtherRole.managableRoleTypes.includes(roleType))
  }

  canRemoveRole(otherRole: IRole) {
    const scopedOtherRole = this.getOtherRole(otherRole.id)
    return !!(scopedOtherRole && scopedOtherRole.canRemoveRole)
  }

  accessAllEngines() {
    return this.ability.access_all_engines
  }

  credentialTypes() {
    return this.ability.credential_types
  }

  invitableRoleTypes() {
    return this.ability.invitable_role_types
  }
}

export class Role implements IRole {
  static unscopedRoles = ['dev', 'editor', 'analyst']
  id: string
  roleType: TRole
  ability: IAbility
  constructor(role) {
    this.id = role.id
    this.roleType = role.roleType
    this.ability = new Ability(role.ability)
  }
}
