import React, { useEffect } from 'react'

import classNames from 'classnames'
import { withRouter } from 'react-router-dom'

import { Loading } from 'app_search/components/Loading'
import { IEngineDetails, IMeta, IObject, IRole } from 'app_search/types'
import useAbilities from 'app_search/utils/useAbilities'

import { DocumentCreationPopover } from 'app_search/components/DocumentCreation'

import { DocumentsLogic } from './DocumentsLogic'

import {
  EuiButton,
  EuiCallOut,
  EuiFieldSearch,
  EuiSpacer
} from '@elastic/eui'

import StuiEmptyState from 'stui/EmptyState'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'
import StuiPagination from 'stui/Pagination'
import { StuiResult } from 'stui/Result'

export interface IDocumentsProps {
  actions: {
    openDocumentCreation()
    closeDocumentCreation()
    updateValue(query: string)
    getSearchResults(nextPage?: number)
  }
  history: any
  engine: IEngineDetails
  preLoadedSchema: IObject
  preLoadedEngine: IEngineDetails
  role: IRole
  searchMeta: IMeta
  searchResults?: IObject[]
  query: string
  loading: boolean
  flashMessages: IStuiFlashMessagesProps
  showDocumentCreation: boolean
  hasSearchResults: boolean
  dataLoading: boolean
  showEmptyState: boolean
}

const Documents: React.SFC<IDocumentsProps> = ({
  actions: {
    openDocumentCreation,
    closeDocumentCreation,
    updateValue,
    getSearchResults
  },
  history,
  engine,
  dataLoading,
  searchResults,
  searchMeta,
  loading,
  flashMessages,
  query,
  showDocumentCreation,
  hasSearchResults,
  showEmptyState
}) => {
  const { canManageEngineDocuments } = useAbilities()

  useEffect(() => {
    getSearchResults()
  }, [])

  if (dataLoading) { return <Loading /> }

  const containerCssClass = classNames(
    'o-stui-replaceable-results',
    { loading },
    { 'no-results': !hasSearchResults }
  )
  const documentCreationButton = <EuiButton fill={true} color="secondary" onClick={openDocumentCreation} data-test-subj="IndexDocumentsButton">Index Documents</EuiButton>

  const documentationLink = <EuiButton href="https://swiftype.com/documentation/app-search/guides/indexing-documents">Read the Indexing Guide</EuiButton>

  const isMetaEngine = engine.type === 'meta'

  const documentsContent = showEmptyState ?
    <StuiEmptyState
      icon="content-fill"
      title="No documents yet!"
      description="Indexed documents will show up here."
      action={!isMetaEngine && canManageEngineDocuments ? documentCreationButton : documentationLink}
    /> :
    <>
      {isMetaEngine &&
        <>
          <EuiCallOut
            iconType="iInCircle"
            title="You are within a Meta Engine"
          >
            <p>Meta Engines have many Source Engines. Visit your Source Engines to alter their documents.</p>
          </EuiCallOut>
          <EuiSpacer />
        </>
      }
      <div className="c-stui-table-controls">
        <div className="c-stui-table-controls-group">
          <EuiFieldSearch
            onChange={e => updateValue(e.target.value)}
            autoFocus={true}
            data-test-subj="DocumentsFilterInput"
            placeholder="Filter documents..."
            className="o-stui-input-container__input"
          />
        </div>
      </div>
      <div className={containerCssClass}>
        {searchResults && searchResults.map((result, index) => {
          return (
            <StuiResult
              key={index}
              resultObject={result}
              schema={engine.schema}
              showScore={false}
              engineName={engine.name}
              query={query}
              history={history}
            />
          )
        })}
      </div>
    </>

  return (
    <div data-test-subj="Documents">
      <StuiHeader
        key="header"
        contained={true}
        viewHeader={true}
        isSticky={true}
        actions={isMetaEngine || showEmptyState || !canManageEngineDocuments ? null : documentCreationButton}
      >
        <StuiHeading type="view">Documents</StuiHeading>
      </StuiHeader>
      <EuiSpacer key="spacer" />
      <StuiMain key="main">
        <StuiFlashMessages {...flashMessages} />
        {documentsContent}
      </StuiMain>
      {searchMeta && searchMeta.page.total_pages > 1 &&
        <StuiPagination
          key="pager"
          metaPage={searchMeta.page}
          handlePageClick={getSearchResults}
        />
      }
      {showDocumentCreation && <DocumentCreationPopover engine={engine} handleClose={closeDocumentCreation} />}
    </div>
  )
}

export default withRouter(DocumentsLogic(Documents))
