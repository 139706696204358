import React from 'react'

import {
  EuiModal,
  EuiOverlayMask
} from '@elastic/eui'

import PlanSelector from 'app_search/components/PlanSelector/PlanSelector'
import { IObject } from 'app_search/types'

interface IPlanSelectorModalProps {
  accountBillingMeta: IObject
  canManagePlan: boolean
  closePlanSelectorModal()
  openUpdatePaymentMethodModalWithPlan(selectedPlan: IObject)
  onPlanSelect(selectedPlan: string)
}

const PlanSelectorModal: React.SFC<IPlanSelectorModalProps> = ({
  closePlanSelectorModal,
  openUpdatePaymentMethodModalWithPlan,
  onPlanSelect,
  accountBillingMeta,
  canManagePlan
}) => (
  <EuiOverlayMask>
    <EuiModal className="pricing-grid-modal" onClose={closePlanSelectorModal}>
      <PlanSelector {...{ openUpdatePaymentMethodModalWithPlan, onPlanSelect, accountBillingMeta, canManagePlan }} />
    </EuiModal>
  </EuiOverlayMask>
)
export default PlanSelectorModal
