import { AppLogic } from 'app_search/App'
import routes from 'app_search/routes'
import { IEngineDetails, IMeta } from 'app_search/types'
import handleAPIError from 'app_search/utils/handleAPIError'
import { ENGINE_NEW_PATH } from 'app_search/utils/routePaths'
import { DEFAULT_META } from 'shared/constants/defaultMeta'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

const getDeleteMessage = name => `Are you sure you want to permanently delete "${name}" and all of its content?`

export const EnginesLogic = storeLogic({
  connect: () => ({
    values: [
      AppLogic, ['myRole', 'currentUser', 'account']
    ]
  }),
  actions: () => ({
    setEnginesData: (engines: IEngineDetails[], meta: IMeta) => ({ engines, meta }),
    resetEnginesData: true,
    setEnginesAfterDeletion: (name: string) => name,
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages }),
    setFilterInputValue: (filterInputValue: string) => filterInputValue,
    setDisplayedPage: (displayedPage: number) => displayedPage
  }),
  reducers: ({ actions }) => ({
    engines: [[], {
      [actions.resetEnginesData]: () => ([]),
      [actions.setEnginesData]: (_, { engines }) => engines,
      [actions.setEnginesAfterDeletion]: (state, name) => state.filter(engine => engine.name !== name)
    }],
    engineMeta: [DEFAULT_META, {
      [actions.resetEnginesData]: () => DEFAULT_META,
      [actions.setEnginesData]: (_, { meta }) => meta
    }],
    dataLoading: [true, {
      [actions.resetEnginesData]: () => true,
      [actions.setEnginesData]: () => false,
      [actions.setFlashMessages]: () => false
    }],
    filterInputValue: ['', {
      [actions.setFilterInputValue]: (_, filterInputValue) => filterInputValue
    }],
    displayedPage: [1, {
      [actions.setDisplayedPage]: (_, displayedPage) => displayedPage,
      [actions.setEnginesAfterDeletion]: () => 1
    }],
    flashMessages: [{}, {
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages,
      [actions.setEnginesAfterDeletion]: () => ({ success: ['Successfully deleted engine.'] }),
      [actions.resetEnginesData]: () => ({})
    }]
  }),
  thunks: ({ actions, get }) => ({
    initializeEnginesData: (history) => {
      actions.resetEnginesData()
      actions.fetchAllEngines(history)
    },
    fetchAllEngines: (history, page = 1) => {
      const engineMeta = get('engineMeta')
      const engines = get('engines')
      const url = routes.locoMocoEnginesPath({
        page: {
          current: page,
          size: engineMeta.page.size
        }
      })

      http(url)
        .then(({ data: { meta, results } }) => {
          if (page === 1 && results.length < 1) {
            const myRole = get('myRole')
            if (myRole.ability.can('manage', 'account_engines')) {
              history.push(ENGINE_NEW_PATH)
            }
          }

          actions.setEnginesData([...engines, ...results], meta)
          if (page < meta.page.total_pages) {
            actions.fetchAllEngines(history, page + 1)
          }
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    deleteEngine: name => {
      if (window.confirm(getDeleteMessage(name))) {
        http.delete(routes.locoMocoEnginePath(name))
          .then(() => actions.setEnginesAfterDeletion(name))
          .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
      }
    }
  })
})
