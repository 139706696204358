import React from 'react'

const getPasswordStrength = (score: number): string => {
  if (score >= 4) {
    return 'Strong'
  }
  if (score === 3) {
    return 'Average'
  }
  return 'Too weak'
}

/*
  The `getZxcvbnResult` function expects that you have included the
  file `app/assets/zxcvbn.js` in some way on the page, which sets
  `zxcvbn` and `zxcvbnUserInputsFromEmail` on the global `window` object

  If you're working in ruby, include these two lines in the `def content`
  function in your View class
  ```
    javascript_include_tag('zxcvbn.js')
    javascript_include_tag('zxcvbn_user_inputs.js')
  ```
*/
declare const window: {
  zxcvbn?: any
  zxcvbnUserInputsFromEmail?: any
} & Window

export const getZxcvbnResult = (password = '', email = '') => {
  if (window.zxcvbn) {
    if (password.length > 0) {
      const userInputs = email ? window.zxcvbnUserInputsFromEmail(email) : []
      return window.zxcvbn(password, userInputs)
    }
  }
  return null
}

export const newPasswordIsNotStrongEnough = (zxcvbnResult, minPasswordScore) => {
  if (!zxcvbnResult || !minPasswordScore) {
    return false
  }

  return zxcvbnResult.score < minPasswordScore
}

export const getPasswordStrengthMessage = (zxcvbnResult, minPasswordScore) => {
  if (zxcvbnResult === null) {
    return null
  }

  let warning
  let suggestion
  let strengthDescription

  if (newPasswordIsNotStrongEnough(zxcvbnResult, minPasswordScore)) {
    if (zxcvbnResult.feedback) {
      warning = zxcvbnResult.feedback.warning
    }

    if (zxcvbnResult.feedback && zxcvbnResult.feedback.suggestions) {
      suggestion = zxcvbnResult.feedback.suggestions.join(' ')
    }
  }

  strengthDescription = getPasswordStrength(zxcvbnResult.score)

  return (zxcvbnResult.score < 3) &&
    <>
      {strengthDescription && <strong>{strengthDescription}. </strong>}
      {warning && `${warning}. `}
      {suggestion}
    </>
}
