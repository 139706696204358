import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { CurationsLogic } from './CurationsLogic'

import {
  EuiButton,
  EuiPanel,
  EuiSpacer
} from '@elastic/eui'

import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import CurationQueries from './CurationQueries'
import CurationsViewAllButton from './CurationsViewAllButton'

import { IObject } from 'app_search/types'

interface ICurationsNewProps {
  history: IObject
  engineName: string
  canSubmitNewQueriesForm?: boolean
  actions: {
    initializeNewCuration()
    submitCurationSet(history: IObject)
    resetCuration()
  }
  flashMessages: IStuiFlashMessagesProps
}

const CurationsNew: React.SFC<ICurationsNewProps> = ({
  history,
  flashMessages,
  engineName,
  actions: {
    initializeNewCuration,
    submitCurationSet,
    resetCuration
  },
  canSubmitNewQueriesForm: canSubmit
}) => {
  useEffect(() => {
    initializeNewCuration()
    return resetCuration
  }, [])

  return (
    <div>
      <CurationsViewAllButton engineName={engineName} key="breadcrumbs" />
      <StuiHeader key="header" contained={true} viewHeader={true}>
        <StuiHeading type="view">Create a Curation</StuiHeading>
      </StuiHeader>
      <StuiMain key="main">
        <StuiFlashMessages {...flashMessages} />
        <EuiPanel hasShadow={true}>
          <StuiHeader>
            <StuiHeading type="section">Curation Queries</StuiHeading>
            <p>Add one query or toggle for multiple queries. You can add or remove queries later.</p>
          </StuiHeader>
          <EuiSpacer />
          <CurationQueries isNewCuration={true} className="curation-queries--new" />
          <EuiSpacer />
          <EuiButton
            fill={true}
            disabled={!canSubmit}
            onClick={() => submitCurationSet(history)}
            data-test-subj="ContinueCreateCurationButton"
          >
            Continue
          </EuiButton>
        </EuiPanel>
      </StuiMain>
    </div>
  )
}

export default withRouter(CurationsLogic(CurationsNew))
