import React from 'react'

interface IStuiLoaderProps {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl'
}

const StuiLoader: React.SFC<IStuiLoaderProps> = ({ size = 'l' }) => (
  <div className={`o-stui-loader o-stui-loader--${size}`} />
)

export default StuiLoader
