import React, { useEffect } from 'react'

import {
  IFlashMessagesAuth,
  ILMCurrentUser,
  ILMInvitation
} from 'app_search/types'

import AuthHeader from './AuthHeader'
import CurrentUserHeader from './CurrentUserHeader'
import { InvitationLogic } from './InvitationLogic'
import NoUserHeader from './NoUserHeader'

interface IInvitationProps {
  actions: {
    initializeData(invitationCode: string)
  }
  invitation: ILMInvitation | null
  invitationCode: string
  currentUser: ILMCurrentUser | null
  flashMessages: IFlashMessagesAuth
  minPasswordScore: number
}

const Invitation: React.SFC<IInvitationProps> = ({
  actions: {
    initializeData
  },
  invitationCode,
  currentUser,
  flashMessages,
  invitation,
  minPasswordScore
}) => {
  useEffect(() => { initializeData(invitationCode) }, [])

  if (!invitation) {
    return (<></>)
  }

  return (
    <div className="invitations" data-test-subj="Invitations">
      <AuthHeader />
      <div className="view-content">
        <div className="auth-container">
          { currentUser ?
              <CurrentUserHeader {...{ currentUser, flashMessages, invitation, minPasswordScore }} />
              : <NoUserHeader  {...{ flashMessages, invitation, minPasswordScore }}/>
          }
        </div>
      </div>
    </div>
  )
}

export default InvitationLogic(Invitation)
