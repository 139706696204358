import React from 'react'

import { Link } from 'react-router-dom'

import { getEngineRoute, getQueryDetailRoute } from 'app_search/utils/routePaths'

import StuiEmptyState from 'stui/EmptyState'
import {
  StuiTable,
  StuiTableBody,
  StuiTableBodyCell,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'

import { IRecentQuery } from 'app_search/types'

import QueryDetailMenu from './QueryDetailMenu'
import ViewAllButton from './ViewAllButton'

interface IRecentQueryTableProps {
  engineName: string
  queries: IRecentQuery[]
  viewAllPath?: string
}

const RecentQueryTable: React.SFC<IRecentQueryTableProps> = ({
  engineName,
  queries,
  viewAllPath
}) => queries.length > 0 ? (
  <>
    <StuiTable>
      <StuiTableHead>
        <StuiTableRow>
          <StuiTableHeadCell>Search Term</StuiTableHeadCell>
          <StuiTableHeadCell>Time</StuiTableHeadCell>
          <StuiTableHeadCell>Analytics Tags</StuiTableHeadCell>
          <StuiTableHeadCell>Result Count</StuiTableHeadCell>
          <StuiTableHeadCell />
        </StuiTableRow>
      </StuiTableHead>
      <StuiTableBody>
        {queries.map(({ query_string, document_ids, tags, timestamp }, index) => {
          const query = query_string ? query_string : '""'
          return (
            <StuiTableRow key={index}>
              <StuiTableBodyCell><Link to={getQueryDetailRoute(engineName, query)}>{query}</Link></StuiTableBodyCell>
              <StuiTableBodyCell>{timestamp}</StuiTableBodyCell>
              <StuiTableBodyCell>{tags.join(', ')}</StuiTableBodyCell>
              <StuiTableBodyCell>{document_ids.length}</StuiTableBodyCell>
              <StuiTableBodyCell><QueryDetailMenu query={query} engineName={engineName} /></StuiTableBodyCell>
            </StuiTableRow>
          )
        })}
      </StuiTableBody>
    </StuiTable>
    {viewAllPath && queries.length > 0 && <ViewAllButton path={getEngineRoute(viewAllPath, engineName)} />}
  </>
) : (
  <StuiEmptyState
    compact={true}
    title="No recent queries"
    description="Queries will appear here as they are received."
    icon="analytics"
    className="metric-empty-state"
  />
)

export default RecentQueryTable
