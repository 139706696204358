import React from 'react'

import { Link, withRouter } from 'react-router-dom'

import { Loading } from 'app_search/components/Loading'
import { USERS_PATH } from 'app_search/utils/routePaths'

import { UsersLogic } from './UsersLogic'

import {
  EuiButton,
  EuiCallOut,
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiRadio,
  EuiSpacer
} from '@elastic/eui'

import StuiFlashMessages from 'stui/FlashMessages'
import StuiHeader from 'stui/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import { Ability, Role } from 'app_search/classes'
import { IMatch, IObject, IUserState } from 'app_search/types'

interface IUserProps {
  match: IMatch
  history: IObject
  actions: {
    initializeUserData(roleId: string)
    handleSaveUser(event: IObject)
    handleRemoveUser(event: IObject, history: IObject)
    setUserState(updatedUserState: IObject)
  }
  userState: IUserState
}

class User extends React.Component<IUserProps> {
  componentDidMount() {
    this.props.actions.initializeUserData(this.props.match.params.roleId)
  }

  render() {
    const actions = this.props.actions
    const state = this.props.userState
    if (state.dataLoading) { return <Loading /> }

    const myRole = new Role(state.myRole)

    const viewActions = myRole.ability.canChangeRole(state.user.role) && (
      <EuiButton
        color="secondary"
        fill={true}
        onClick={(e) => actions.handleSaveUser(e)}
      >
        Save Changes
      </EuiButton>
    )

    const removeUser = myRole.ability.canRemoveRole(state.user.role) && (
      <EuiCallOut
        color="danger"
        title="Remove User"
      >
        <p>Warning! This action cannot be undone.</p>
        <EuiButton
          color="danger"
          fill={true}
          href="#"
          onClick={(e) => actions.handleRemoveUser(e, this.props.history)}
          data-confirm={`Are you sure you want to remove the following user? ${state.user.email}`}
        >
          Remove User
        </EuiButton>
      </EuiCallOut>
    )

    const roleSelector = (roleType, description) => {
      return (
        <EuiFormRow>
          <EuiRadio
            disabled={!myRole.ability.canChangeRoleTo(state.user.role, roleType)}
            id={roleType}
            checked={roleType === state.selectedRoleType}
            onChange={() => {
              actions.setUserState({
                selectedRoleType: roleType,
                engineAccessEnabled: Ability.canHaveScopedEngines(roleType),
                ...(!Ability.canHaveScopedEngines(roleType) && {
                  selectedAccessAllEngines: true,
                  selectedEngineNames: new Set()
                })
              })
            }}
            label={
              <StuiHeader>
                <StuiHeading className="users-layout__users--roletype" type="title">{roleType}</StuiHeading>
                <p>{description}</p>
              </StuiHeader>
            }
          />
        </EuiFormRow>
      )
    }

    const engineSelector = (engine) => {
      const engineName = engine.name
      const elId = `engine_${engineName}`
      return <EuiCheckbox
        key={engineName}
        name={engineName}
        id={elId}
        checked={state.selectedEngineNames.has(engineName)}
        onChange={(e) => {
          const newSelectedEngineNames = new Set(state.selectedEngineNames as Set<string>)
          if (e.target.checked) {
            newSelectedEngineNames.add(engineName)
          } else {
            newSelectedEngineNames.delete(engineName)
          }
          actions.setUserState({
            selectedEngineNames: newSelectedEngineNames
          })
        }}
        label={engineName}
      />
    }

    const engineAccessDisabled = !myRole.ability.canChangeRole(state.user.role) || !state.engineAccessEnabled
    const availableEngines = state.availableEngines.filter(e => e.type !== 'meta')
    const availableMetaEngines = state.availableEngines.filter(e => e.type === 'meta')

    const advancedRoleSelectors = <>
      <EuiSpacer />
      <StuiHeader>
        <StuiHeading type="title">Full or Limited Engine Access</StuiHeading>
      </StuiHeader>
      <EuiSpacer />
      {roleSelector('dev', 'Devs can manage all aspects of an Engine.')}
      {roleSelector('editor', 'Editors can manage Search Settings.')}
      {roleSelector('analyst', 'Analysts can only view Documents, Query Tester, and Analytics.')}
      {!process.env.LOCO_TOGO && <>
        <EuiSpacer />
        <StuiHeader>
          <StuiHeading type="title">No Engine Access</StuiHeading>
        </StuiHeader>
        <EuiSpacer />
        {roleSelector('finance', 'Finance users can only manage billing details.')}
      </>}
    </>

    return (
      <>
        <div className="stui-view-topbar">
          <div className="o-stui-breadcrumbs">
            <Link className="o-stui-breadcrumb" to={USERS_PATH}>Users</Link>
            <div className="o-stui-breadcrumb">Manage User</div>
          </div>
        </div>
        <StuiHeader contained={true} viewHeader={true} actions={viewActions}>
          <StuiHeading type="view">{state.user.name}</StuiHeading>
          <p>{state.user.email}</p>
        </StuiHeader>
        <StuiMain>
          <StuiFlashMessages {...state.flashMessages} />
          <EuiFlexGroup alignItems="stretch">
            <EuiFlexItem>
              <EuiPanel paddingSize="l">
                <StuiHeader>
                  <StuiHeading type="section">Role</StuiHeading>
                </StuiHeader>
                <EuiSpacer />

                <StuiHeader>
                  <StuiHeading type="title">Full Engine Access</StuiHeading>
                </StuiHeader>
                <EuiSpacer />
                {roleSelector('owner', 'Owners can do anything, which includes changing billing details and closing the account. There can be many owners on the account, but there must be at least one owner at any time.')}
                {roleSelector('admin', 'Admins can do anything, except modify billing information and close the account.')}

                {state.hasAdvancedRoles && advancedRoleSelectors}
              </EuiPanel>
            </EuiFlexItem>
            {state.hasAdvancedRoles && <EuiFlexItem>
              <EuiPanel paddingSize="l">
                <StuiHeader>
                  <StuiHeading type="section">Engine Access</StuiHeading>
                </StuiHeader>
                <EuiSpacer />
                <EuiFormRow>
                  <EuiRadio
                    disabled={engineAccessDisabled}
                    name="engine"
                    id="engine_1"
                    value="true"
                    checked={!!state.selectedAccessAllEngines}
                    onChange={() => actions.setUserState({
                      selectedAccessAllEngines: true,
                      selectedEngineNames: new Set()
                    })}
                    label={
                      <StuiHeader>
                        <StuiHeading type="title">Full Engine Access</StuiHeading>
                        <p>Access to all current and future Engines.</p>
                      </StuiHeader>
                    }
                  />
                </EuiFormRow>
                <EuiFormRow>
                  <>
                    <EuiRadio
                      disabled={engineAccessDisabled}
                      name="engine"
                      id="engine_2"
                      value="true"
                      checked={!state.selectedAccessAllEngines}
                      onChange={() => actions.setUserState({
                        selectedAccessAllEngines: false,
                        selectedEngineNames: state.initialEngineNames
                      })}
                      label={
                        <StuiHeader>
                          <StuiHeading type="title">Limited Engine Access</StuiHeading>
                          <p>Limit user access to specific engines:</p>
                        </StuiHeader>
                      }
                    />
                    {!state.selectedAccessAllEngines &&
                      <>
                        <div className="engines-list">
                          {availableEngines.map((engine) => engineSelector(engine))}
                          {availableMetaEngines.length > 0 &&
                            <>
                              <EuiSpacer />
                              <StuiHeader>
                                <StuiHeading type="title">Meta-Engine Access</StuiHeading>
                                <p className="meta-engines-sub-title">Users will also have read access to the documents in connected Engines through Meta Engine access.</p>
                              </StuiHeader>
                              <EuiSpacer />
                              {availableMetaEngines.map((engine) => engineSelector(engine))}
                            </>
                          }
                        </div>
                      </>
                    }
                  </>
                </EuiFormRow>
              </EuiPanel>
            </EuiFlexItem>
            }
          </EuiFlexGroup>
          <EuiSpacer size="xl" />
          {removeUser}
        </StuiMain>
      </>
    )
  }
}

export default withRouter(UsersLogic(User))
