// parses, then stringifies json content
// If the content isn't properly formatted JSON, the original content is returned
export function safeJsonParseAndStringify(content: string) {
  try {
    return JSON.stringify(JSON.parse(content), null, 2)
  } catch (e) {
    return content
  }
}

export function isJsonParseable(content: string) {
  try {
    JSON.parse(content)
    return true
  } catch (e) {
    return false
  }
}
