import React, { useEffect, useState } from 'react'

import routes from 'app_search/routes'
import classNames from 'classnames'
import _isEmpty from 'lodash/isEmpty'
import _uniq from 'lodash/uniq'
import http from 'shared/http'

import { TEXT } from 'shared/constants/fieldTypes'

import { IBoost, IObject, IResultFieldProps, TBoost } from 'app_search/types'

import ResultField from './ResultField'
import ResultMetaField from './ResultMetaField'

import StuiIcon from 'stui/Icon'

interface IStuiResultProps {
  engineName?: string
  resultObject: IObject
  showScore?: boolean
  condensed?: boolean
  schema: IObject
  query: string
  boosts?: IObject
  searchFields?: IObject
  history?: any
  extraActionIcon?: IObject
  extraAction?()
}

interface IMetaContainerProps {
  showScore: boolean,
  formattedMetaFields: IObject
}

const ID = 'id'
const META = '_meta'
const SNIPPET_RGX = /<em>(.+?)<\/em>/ig
const RESULT_CUTOFF = 7 // 5 are shown. Extra 2 are id and _meta.

const MetaContainer: React.SFC<IMetaContainerProps> = ({
  showScore,
  formattedMetaFields
}) => {
  const showEngineLabel: boolean = formattedMetaFields.id !== formattedMetaFields.scopedId

  const score = () => {
    return <ResultMetaField
      field="score"
      value={formattedMetaFields.score}
      type="score"
    />
  }

  const engine = () => {
    return <ResultMetaField
      field="engine"
      value={formattedMetaFields.engine}
      type="string"
    />
  }

  const id = () => (
    <ResultMetaField field={ID} value={formattedMetaFields.id} type={ID} />
  )

  return (
    <div className="c-stui-result__metadata-container">
      {showScore && (
        <div className="c-stui-result__metadata-container-column c-stui-result__metadata-container-column--left">
          {score()}
        </div>
      )}

      <div className="c-stui-result__metadata-container-column c-stui-result__metadata-container-column--right">
        {showEngineLabel && engine()}
        {id()}
      </div>
    </div>
  )
}

const StuiResult: React.SFC<IStuiResultProps> = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [largestBoostedField, setLargestBoostedField] = useState(0)

  const {
    resultObject,
    searchFields = {},
    showScore = false,
    condensed = false,
    boosts = {},
    extraAction,
    extraActionIcon
  } = props
  const resultKeys = Object.keys(resultObject)

  useEffect(() => {
    resultKeys.forEach(key => {
      const fieldBoosts = getFieldBoosts(key)
      const field = searchFields[key] || {}

      if (field.weight > 0 && field.weight !== 1) {
        fieldBoosts.push('weights')
      }

      const numOfBoosts = fieldBoosts.length

      if (numOfBoosts > largestBoostedField) {
        setLargestBoostedField(numOfBoosts)
      }
    })
  }, [resultObject, searchFields])

  const typeForField = (fieldName) => props.schema[fieldName]

  const getFieldBoosts = (key) => {
    const fieldBoosts: TBoost[] = []

    if (!_isEmpty(props.boosts) && boosts[key]) {
      boosts[key].forEach((boost: IBoost) => fieldBoosts.push(boost.type as TBoost))
    }

    return _uniq(fieldBoosts)
  }

  const onResultClick = (event) => {
    if (event.charCode === 13 || event.type === 'click') {
      const { engineName, query, history } = props
      if (!condensed && engineName) {
        http.post(routes.locoMocoEngineDocumentClickPath(engineName, formattedMetaFields.scopedId), { query })
          .then(() => {
            const documentPath = `/engines/${formattedMetaFields.engine}/documents/${formattedMetaFields.id}`
            if (history) {
              history.push(documentPath)
            } else {
              window.location.href = `${window.location.origin}/as#${documentPath}`
            }
          })
      }
    }
  }

  const snippetScore = (snippet) => {
    const snippetMatches = (snippet || '').match(SNIPPET_RGX)
    if (!snippetMatches) {
      return -1
    }
    return snippetMatches.join().replace(/<\/?em>/g, '').length
  }

  const boostedFields: IResultFieldProps[] = []
  const formattedTextFields: IResultFieldProps[] = []
  const formattedNonTextFields: IResultFieldProps[] = []
  const formattedMetaFields: { id: string, scopedId: string, score?: number, engine: string } = resultObject[META]

  resultKeys.filter((key) => key !== META).forEach((key) => {
    const { raw, snippet } = resultObject[key]
    const fieldBoosts = getFieldBoosts(key)
    const field = searchFields[key] || {}

    if (field.weight > 0 && field.weight !== 1) {
      fieldBoosts.push('weights')
    }

    if (key === ID) {
      formattedMetaFields.scopedId = raw
    } else {
      const data: IResultFieldProps = {
        raw,
        snippet,
        field: key,
        type: typeForField(key),
        boosts: fieldBoosts as TBoost[]
      }

      if (fieldBoosts.length) {
        boostedFields.push(data)
      } else if (typeForField(key) === TEXT) {
        formattedTextFields.push(data)
      } else {
        formattedNonTextFields.push(data)
      }
    }
  })

  boostedFields.sort((resultA, resultB) => {
    return snippetScore(resultB.snippet) - snippetScore(resultA.snippet)
  })

  formattedTextFields.sort((resultA, resultB) => {
    return snippetScore(resultB.snippet) - snippetScore(resultA.snippet)
  })

  const numResults = Object.keys(resultObject).length
  const resultContainerClassName = classNames(
    'c-stui-result-container',
    { 'c-stui-result-container--clickable': !condensed }
  )
  const resultContentInnerClassName = classNames(
    'c-stui-result__content-inner',
    { 'c-stui-result__content-inner boosted': !_isEmpty(boosts) }
  )
  const resultClassName = classNames(
    'c-stui-result',
    { 'c-stui-result--condensed': !!condensed },
    { 'c-stui-result--open': !!isOpen },
    { 'c-stui-result--small': numResults < RESULT_CUTOFF },
    { 'c-stui-result--boost-level-one': largestBoostedField === 1 },
    { 'c-stui-result--boost-level-two': largestBoostedField === 2 },
    { 'c-stui-result--boost-level-three': largestBoostedField === 3 }
  )

  return (
    <div key="results" className={resultContainerClassName} data-test-subj="StuiResult">
      <div className={resultClassName} title="View Document Details">
        <div className="c-stui-result__content-wrap" onClick={onResultClick} tabIndex={!condensed ? 0 : -1} onKeyPress={onResultClick}>
          <div className={resultContentInnerClassName}>
            <MetaContainer showScore={showScore} formattedMetaFields={formattedMetaFields} />
            <div className="c-stui-result__fieldset-container">
              <div className="c-stui-result-fieldset c-stui-result-fieldset--boosted">
                {boostedFields.map((result) => <ResultField key={result.field} {...result} />)}
              </div>
              <div className="c-stui-result-fieldset c-stui-result-fieldset--searched">
                {formattedTextFields.map((result) => <ResultField key={result.field} {...result} />)}
              </div>
              <div className="c-stui-result-fieldset c-stui-result-fieldset--unsearched">
                {formattedNonTextFields.map((result) => <ResultField key={result.field} {...result} />)}
              </div>
            </div>
          </div>
          {numResults > RESULT_CUTOFF &&
          <div className="c-stui-result__hidden-fields-indicator">
            {numResults - RESULT_CUTOFF} more fields
          </div>}
        </div>
        {numResults > RESULT_CUTOFF &&
        <button className="c-stui-result__toggle-expand-button" onClick={() => setIsOpen(!isOpen)}>
          <StuiIcon name="arrow-down" className="c-stui-result__toggle-icon" />
        </button>}
        {extraAction &&
        <button className="c-stui-result__extra-button" onClick={extraAction}>
          {extraActionIcon}
        </button>}
      </div>
    </div>
  )
}

export default StuiResult
