import React, { useEffect } from 'react'

import { Link, withRouter } from 'react-router-dom'

import { useValues } from 'kea'

import { Loading } from 'app_search/components/Loading'

import clientLogoJavaUrl from './images/clients/client-icon-java.svg'
import clientLogoJavascriptUrl from './images/clients/client-icon-javascript.svg'
import clientLogoNodeUrl from './images/clients/client-icon-node.svg'
import clientLogoPhpUrl from './images/clients/client-icon-php.svg'
import clientLogoPythonUrl from './images/clients/client-icon-python.svg'
import clientLogoRubyUrl from './images/clients/client-icon-ruby.svg'
import logoUrl from './images/docs--indexing-documents.jpg'

import { AppLogic } from 'app_search/App'

import { IObject } from 'app_search/types'
import {
  ENGINE_NEW_PATH,
  ENGINE_REFERENCE_APPLICATION_PATH,
  getEngineRoute,
  ONBOARDING_PATH,
  ROOT_PATH
} from 'app_search/utils/routePaths'

import FreeTrialExtendLogic, { IFreeTrialExtend } from './FreeTrialExtendLogic'

import {
  EuiButton,
  EuiSpacer
} from '@elastic/eui'

import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'
interface IFreeTrialExtendProps extends IFreeTrialExtend {
  actions: {
    initializeTrialExtend()
  }
  history: IObject
  dataLoading: boolean
}

const FreeTrialExtend: React.SFC<IFreeTrialExtendProps> = ({
  actions: {
    initializeTrialExtend
  },
  isOnboarding,
  hasEngine,
  firstEngine,
  dataLoading,
  history
}) => {
  const { account: { freeTrialExtendable } } = useValues(AppLogic)

  useEffect(() => {
    if (freeTrialExtendable) {
      initializeTrialExtend()
    } else {
      history.push(ROOT_PATH)
    }
  }, [])

  if (dataLoading) { return (<Loading />) }

  const languages = [
    {
      logoUrl: clientLogoRubyUrl,
      name: 'Ruby'
    },
    {
      logoUrl: clientLogoJavaUrl,
      name: 'Java'
    },
    {
      logoUrl: clientLogoJavascriptUrl,
      name: 'JavaScript'
    },
    {
      logoUrl: clientLogoPythonUrl,
      name: 'Python'
    },
    {
      logoUrl: clientLogoNodeUrl,
      name: 'Node'
    },
    {
      logoUrl: clientLogoPhpUrl,
      name: 'PHP'
    }
  ]

  const onboardingCallToActionContent = (
    <>
      <div className="free-trial-extended__title">Pick up where you left off, create an Engine</div>
      <EuiSpacer size="l" />
      <Link to={ONBOARDING_PATH}>
        <EuiButton fill={true}>Resume</EuiButton>
      </Link>
    </>
  )

  const createEngineCallToActionContent = (
    <>
      <div className="free-trial-extended__title">Create an Engine, ingest your data, build search</div>
      <EuiSpacer size="l" />
      <Link to={ENGINE_NEW_PATH}>
        <EuiButton fill={true}>Create Engine</EuiButton>
      </Link>
    </>
  )

  const referenceUICallToActionContent = (
    <>
      <div className="free-trial-extended__title">Find search inspiration within the Reference UI</div>
      <EuiSpacer size="l" />
      <Link to={getEngineRoute(ENGINE_REFERENCE_APPLICATION_PATH, firstEngine ? firstEngine.name : '')}>
        <EuiButton fill={true}>Start Tinkering</EuiButton>
      </Link>
    </>
  )

  return (
    <>
      <EuiSpacer size="xl" />
      <StuiMain key="main">
        <div className="free-trial-extended">
          <div className="free-trial-extended__engine">
            <EuiSpacer size="xl" />
            <StuiHeading type="view">14 more days to explore!</StuiHeading>
            <EuiSpacer size="s" />
            {isOnboarding && onboardingCallToActionContent}
            {!(isOnboarding || hasEngine) && createEngineCallToActionContent}
            {!isOnboarding && hasEngine && referenceUICallToActionContent}
            <EuiSpacer size="xl" />
          </div>
          <EuiSpacer size="xxl" />
          <div className="free-trial-extended__documentation">
            <div className="o-stui-documentation-call-out">
              <div className="o-stui-documentation-call-out__image">
                <img src={logoUrl} alt="Docs - indexing documents" />
              </div>
              <div className="o-stui-documentation-call-out__content">
                <small className="o-stui-documentation-call-out__type">Helpful Documentation</small>
                <div className="o-stui-documentation-call-out__title">Getting Started with App Search</div>
                <EuiButton
                  fill={true}
                  href="https://swiftype.com/documentation/app-search/"
                  target="_blank"
                  className="o-stui-documentation-call-out__action"
                >
                  Read the Guide
                </EuiButton>
              </div>
            </div>
          </div>
          <EuiSpacer size="l" />
          <div className="free-trial-extended__type">More Guided Tours</div>
          <EuiSpacer size="s" />
          <div className="free-trial-extended__tutorials">
            <div className="free-trial-extended__card tutorial-card__react">
              <EuiSpacer size="l" />
              <a href="https://swiftype.com/documentation/app-search/guides/relevance-tuning" target="_blank">Relevance Tuning</a>
              <EuiSpacer size="s" />
              <p>You can fine tune search relevance within the dashboard. Calibrate weights and boosts to get your search results looking <em>just right</em>.</p>
              <EuiSpacer size="l" />
            </div>
            <div className="free-trial-extended__card">
              <EuiSpacer size="l" />
              <a href="https://swiftype.com/documentation/app-search/guides/reference-ui" target="_blank">The Reference UI</a>
              <EuiSpacer size="s" />
              <p>Each Engine has access to a pre-built search experience, the Reference UI. Built in React, you can use it for experimentation or as a development foundation.</p>
              <EuiSpacer size="l" />
            </div>
          </div>
          <EuiSpacer size="l" />
          <div className="free-trial-extended__type">Explore App Search API Clients</div>
          <EuiSpacer size="s" />
          <div className="free-trial-extended__clients">
            <div className="free-trial-extended__card">
              <EuiSpacer size="xl" />
              <ul>
                {languages.map(lang => {
                  // TODO remove the Java if statement when https://github.com/elastic/app-search-java is public
                  if (lang.name === 'Java') {
                    return <li key={lang.name} className="free-trial-extended__client">
                    <a href={`https://github.com/swiftype/swiftype-app-search-${lang.name.toLowerCase()}`} target="_blank">
                      <img src={lang.logoUrl} alt={`${lang.name} Client`} />
                      <EuiSpacer size="s" />
                      {lang.name}
                    </a>
                  </li>
                  }

                  return <li key={lang.name} className="free-trial-extended__client">
                    <a href={`https://github.com/elastic/app-search-${lang.name.toLowerCase()}`} target="_blank">
                      <img src={lang.logoUrl} alt={`${lang.name} Client`} />
                      <EuiSpacer size="s" />
                      {lang.name}
                    </a>
                  </li>
                })}
              </ul>
              <EuiSpacer size="xl" />
            </div>
          </div>
        </div>
      </StuiMain>
    </>
  )
}

export default withRouter(FreeTrialExtendLogic(FreeTrialExtend))
