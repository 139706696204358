import React from 'react'

import {
  EuiTitle
} from '@elastic/eui'

import routes from 'app_search/routes'
import {
  IFlashMessagesAuth,
  ILMInvitation
} from 'app_search/types'

import AuthBox from './AuthBox'

interface INoUserHeaderProps {
  invitation: ILMInvitation
  flashMessages: IFlashMessagesAuth
  minPasswordScore?: number
}

const NoUserHeader: React.SFC<INoUserHeaderProps> = ({
  flashMessages,
  invitation,
  minPasswordScore
}) => {
  return (
    <div data-test-subj="NoUserHeader">
      <header className="euiTextAlign euiTextAlign--center">
        <EuiTitle size="m">
          <h2>
            { invitation.active ?
                'Create an account to accept your invitation.'
                : 'This invitation has expired. Please ask your Swiftype App Search Owner to re-invite you or sign in.'
            }
          </h2>
        </EuiTitle>
      </header>
      { invitation.active &&
        <AuthBox
          authorizationStrategies={invitation.account.authorizationStrategies}
          buttonLabel="Accept Invitation"
          capturedEmail={invitation.email}
          extraSubmitParams={{ lm_invitation_code: invitation.code }} // back-end API and Google Oauth expects snake-case for lm_invitation_code
          flashMessages={flashMessages}
          minPasswordScore={minPasswordScore}
          googleOauthAction={'accept_invitation'}
          redirectUrl={routes.sessionsLoginPath()}
          submitUrl={routes.authenticateAcceptInvitationPath()}
        />
      }
    </div>
  )
}

export default NoUserHeader
