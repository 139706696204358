import React from 'react'

import { capitalize } from 'app_search/utils'

import { EuiButtonEmpty } from '@elastic/eui'

import { StuiAccordionItemHeader } from 'stui/Accordion'
import StuiIcon from 'stui/Icon'

import { IBoostType } from 'app_search/types'

interface IBoostHeaderProps {
  type: IBoostType
  score: number
  summary?: string
}

const BoostHeader: React.SFC<IBoostHeaderProps> = ({ type, score, summary }) => (
  <StuiAccordionItemHeader className="c-stui-boost-container__header">
    <div className="c-stui-boost">
      <div className="c-stui-boost__content">
        <div className="c-stui-boost__type">
          <StuiIcon name={type} /> {capitalize(type)}
        </div>
        <div className="c-stui-boost__summary">
          <code>{summary}</code>
        </div>
      </div>
      <div className="c-stui-boost__score o-stui-boost-score" data-score={score}>{score}</div>
      <div className="c-stui-boost__actions">
        <EuiButtonEmpty size="s" data-test-subj="ManageBoostRelevanceTuningButton">Manage</EuiButtonEmpty>
      </div>
      <div className="c-stui-boost__close">
        <StuiIcon name="close" />
      </div>
    </div>
  </StuiAccordionItemHeader>
)

export default BoostHeader
