import React from 'react'

const Logo: React.SFC<{}> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32">
    <g fillRule="evenodd">
      <path d="M27.4346,7.7608 L28.4126,7.1968 C29.4176,8.4278 29.9996,9.9798 29.9996,11.6188 L29.9996,20.8568 C29.9996,23.3578 28.6656,25.6678 26.4996,26.9188 L18.9996,31.2488 L18.9996,17.9778 C18.9996,16.3348 18.4156,14.7818 17.4086,13.5498 L27.4346,7.7608 Z" className="tertiary" />
      <path d="M14.5,11.9156 C12.334,13.1666 11,15.4766 11,17.9776 L11,31.2496 L3.5,26.9186 C1.334,25.6676 0,23.3576 0,20.8566 L0,11.6196 C0,9.1186 1.334,6.8066 3.5,5.5566 L3.9926887,5.27213386 L4,5.2766 L15,11.6266 L14.5,11.9156 Z" className="secondary" />
      <path d="M18.5,0.9376 C16.334,-0.3124 13.666,-0.3124 11.5,0.9376 L4,5.2766 L15,11.6266 L26,5.2766 L18.5,0.9376 Z" className="primary" />
    </g>
  </svg>
)

export default Logo
