import React from 'react'

import StuiTooltip from 'stui/Tooltip'

interface ISchemaTruncatedEnginesProps {
  engines: string[],
  cutoff?: number
}

const SEPERATOR = ', '

const getTruncatedEngines = (engines, cutoff) => {
  const visbile = engines.slice(0, cutoff)
  const hidden = engines.slice(cutoff)
  return <>
    {visbile.join(SEPERATOR)},&nbsp;<StuiTooltip content={hidden.join(SEPERATOR)}>+{hidden.length}</StuiTooltip>
  </>
}

const SchemaTruncatedEngines: React.SFC<ISchemaTruncatedEnginesProps> = ({ engines, cutoff = 3 }) => {
  return engines.length > cutoff ?
    getTruncatedEngines(engines, cutoff) :
    <>{engines.join(SEPERATOR)}</>
}

export default SchemaTruncatedEngines
