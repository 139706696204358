import React from 'react'

import classNames from 'classnames'

interface IStuiTableProps {
  children: React.ReactNode
  className?: string
  fixedColumns?: boolean
}
class StuiTable extends React.Component<IStuiTableProps> {
  private tableRef: React.RefObject<HTMLDivElement> = React.createRef()

  componentDidMount() {
    if (this.props.fixedColumns) { this.handleFixedColumns() }
  }

  componentDidUpdate() {
    if (this.props.fixedColumns) { this.handleFixedColumns() }
  }

  render() {
    const { children, className, fixedColumns } = this.props
    const tableCssClass = classNames(
      'c-stui-table-container',
      { 'c-stui-table-container--overflow c-stui-table-container--fixed': fixedColumns },
      className
    )
    return (
      <div className={tableCssClass}>
        <div className="c-stui-table-container__inner" ref={this.tableRef}>
          <table className="c-stui-table">
            {children}
          </table>
        </div>
      </div>
    )
  }

  private handleFixedColumns = () => {
    const scrollContainer = this.tableRef.current
    if (scrollContainer) {
      const table = scrollContainer.querySelector<HTMLElement>('.c-stui-table')

      if (table && (table.offsetWidth > scrollContainer.offsetWidth)) {
        const tableRowArray = Array.from(table.querySelectorAll<HTMLElement>('.c-stui-table-row'))

        // Reset table fixed column widths
        tableRowArray.forEach((row) => {
          const columns = Array.from(row.querySelectorAll<HTMLElement>('.c-stui-table-cell--fixed'))
          columns.forEach(column => {
            column.style.cssText = ''
          })
        })

        scrollContainer.classList.add('c-stui-table-container__inner--scrolling')
        const fixedHeaderColumns = table.querySelectorAll<HTMLElement>('.c-stui-table-header .c-stui-table-row:first-child .c-stui-table-cell--fixed')
        const fixedBodyColumns = table.querySelectorAll<HTMLElement>('.c-stui-table-body .c-stui-table-row:first-child .c-stui-table-cell--fixed')
        const headerColumnWidths = Array.from(fixedHeaderColumns).map((column) => column.offsetWidth)
        const bodyColumnWidths = Array.from(fixedBodyColumns).map((column) => column.offsetWidth)
        const columnWidths = headerColumnWidths.map((width, i) => Math.max(width, bodyColumnWidths[i]))
        scrollContainer.style.marginLeft = columnWidths.reduce((a, b) => a + b, 0).toString()

        tableRowArray.forEach((row) => {
          const rowHeight = row.offsetHeight
          const columns = row.querySelectorAll<HTMLElement>('.c-stui-table-cell--fixed')
          for (let index = 0; index < fixedHeaderColumns.length; index++) {
            const column = columns[index]
            column.style.position = 'absolute'
            column.style.height = rowHeight.toString()
            column.style.width = columnWidths[index].toString()
            column.style.left = columnWidths.slice(0, index).reduce((a, b) => a + b, 0).toString()
          }
          row.style.height = rowHeight.toString()
        })
      } else {
        scrollContainer.classList.add('c-stui-table-container__inner--fixed')
      }
    }
  }
}

export default StuiTable
