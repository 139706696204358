import React from 'react'

import { EuiButton, EuiSpacer } from '@elastic/eui'

import StuiEmptyState from 'stui/EmptyState'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeading from 'stui/Heading'
import StuiIcon from 'stui/Icon'
import StuiPagination from 'stui/Pagination'
import {
  StuiTable,
  StuiTableBody,
  StuiTableBodyCell,
  StuiTableControls,
  StuiTableControlsGroup,
  StuiTableHead,
  StuiTableRow
} from 'stui/Table'
import StuiTooltip from 'stui/Tooltip'
import StuiTruncatedContent from 'stui/TruncatedContent'

import { IApiToken, IMeta } from 'app_search/types'

import {
  ADMIN,
  SEARCH,
  TOKEN_TYPE_DISPLAY_NAMES
} from 'shared/constants/credentials'

const ALL = 'all'
const READ_WRITE = 'read/write'
const READ_ONLY = 'read-only'
const WRITE_ONLY = 'write-only'

const NAME_MAX_LENGTH = 32

import { apiTokenSort, CredentialsLogic } from './CredentialsLogic'
import CredentialsToken from './CredentialsToken'

interface ICredentialsListProps {
  actions: {
    deleteApiKey(tokenName: string)
    fetchCredentials(page: number)
    toggleCredentialsForm(apiToken?: IApiToken)
  }
  flashMessages: IStuiFlashMessagesProps
  apiTokens: IApiToken[]
  meta: IMeta
}

const getModeDisplayText = apiToken => {
  const { read = false, write = false, type } = apiToken

  switch (type) {
    case ADMIN:
      return '--'
    case SEARCH:
      return 'search'
    default:
      if (read && write) {
        return READ_WRITE
      }
      return write ? WRITE_ONLY : READ_ONLY
  }
}

const getEnginesDisplayText = apiToken => {
  const { type, access_all_engines: accessAll, engines = [] } = apiToken
  const engineList = () => (
    <ul className="credentials-engine-list">
      {engines.map(engine => <li key={engine} className="credentials-engine-list__engine">{engine}</li>)}
    </ul>
  )

  if (type === ADMIN) {
    return '--'
  }
  return accessAll ? ALL : engineList()
}

const CredentialsList: React.SFC<ICredentialsListProps> = ({
  actions: {
    deleteApiKey,
    fetchCredentials,
    toggleCredentialsForm
  },
  flashMessages,
  apiTokens,
  meta
}) => {
  const createKeyButton = (
    <EuiButton
      color="secondary"
      data-test-subj="CreateAPIKeyButton"
      fill={true}
      onClick={() => toggleCredentialsForm()}
    >
      Create a Key
    </EuiButton>
  )

  return apiTokens.length > 0 ? (
    <div id="credentials-list">
      <StuiTableControls>
        <StuiTableControlsGroup>
          <StuiHeading type="sub">API Keys</StuiHeading>
        </StuiTableControlsGroup>
        <StuiTableControlsGroup>
          {createKeyButton}
        </StuiTableControlsGroup>
      </StuiTableControls>

      <StuiFlashMessages {...flashMessages} />

      <StuiTable>
        <StuiTableHead>
          <StuiTableRow>
            <StuiTableBodyCell>Name</StuiTableBodyCell>
            <StuiTableBodyCell>Type</StuiTableBodyCell>
            <StuiTableBodyCell>Key</StuiTableBodyCell>
            <StuiTableBodyCell>Modes</StuiTableBodyCell>
            <StuiTableBodyCell>Engines</StuiTableBodyCell>
            <StuiTableBodyCell />
            <StuiTableBodyCell />
          </StuiTableRow>
        </StuiTableHead>
        <StuiTableBody data-test-subj="APIKeysList" className="list">
          {apiTokens.slice().sort(apiTokenSort).map((apiToken) => (
            <StuiTableRow key={apiToken.id}>
              <StuiTableBodyCell>
                <strong>
                  <StuiTruncatedContent content={apiToken.name} length={NAME_MAX_LENGTH} />
                </strong>
              </StuiTableBodyCell>
              <StuiTableBodyCell>{TOKEN_TYPE_DISPLAY_NAMES[apiToken.type]}</StuiTableBodyCell>
              <StuiTableBodyCell>
                <CredentialsToken isPrivate={apiToken.type === 'private'} token={apiToken.key || ''} />
              </StuiTableBodyCell>
              <StuiTableBodyCell>{getModeDisplayText(apiToken)}</StuiTableBodyCell>
              <StuiTableBodyCell>{getEnginesDisplayText(apiToken)}</StuiTableBodyCell>
              <StuiTableBodyCell>
                <StuiTooltip placement="left" content="Edit key">
                  <a
                    className="o-stui-table-action"
                    onClick={e => {
                      e.preventDefault()
                      toggleCredentialsForm(apiToken)
                    }}
                    rel="nofollow"
                    data-test-subj="EditAPIKey"
                    href="#"
                    title="Edit key"
                  >
                    <StuiIcon name="edit" />
                  </a>
                </StuiTooltip>
              </StuiTableBodyCell>
              <StuiTableBodyCell>
                <StuiTooltip placement="left" content="Delete key" type="danger">
                  <a
                    className="o-stui-table-action o-stui-delete-action"
                    data-confirm={`Are you sure you want to permanently delete "${apiToken.name}"?`}
                    onClick={e => {
                      e.preventDefault()
                      return apiToken.name && deleteApiKey(apiToken.name)
                    }}
                    rel="nofollow"
                    data-test-subj="DeleteAPIKey"
                    href="#"
                    title="Delete key"
                  >
                    <StuiIcon name="delete" />
                  </a>
                </StuiTooltip>
              </StuiTableBodyCell>
            </StuiTableRow>
          ))}
        </StuiTableBody>
      </StuiTable>
      <EuiSpacer />
      {meta && meta.page.total_pages > 1 &&
        <StuiPagination
          key="pager"
          metaPage={meta.page}
          handlePageClick={fetchCredentials}
        />}
    </div>
  ) : (
      <StuiEmptyState
        icon="key-fill"
        title="No API Keys have been created yet"
        description="Click Create a Key to make your first one"
        action={createKeyButton}
      />
    )
}

export default CredentialsLogic(CredentialsList)
