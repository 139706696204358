import React from 'react'

import { Link } from 'react-router-dom'

import { getEngineRoute, getQueryDetailRoute } from 'app_search/utils/routePaths'

import StuiEmptyState from 'stui/EmptyState'
import {
  StuiTable,
  StuiTableBody,
  StuiTableBodyCell,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'

import { IQuery } from 'app_search/types'

import QueryDetailMenu from './QueryDetailMenu'
import ViewAllButton from './ViewAllButton'

interface ITopResultsTableProps {
  engineName: string
  queries: IQuery[]
  showClicks?: boolean
  viewAllPath?: string
}

const TopResultsTable: React.SFC<ITopResultsTableProps> = ({
  engineName,
  queries,
  showClicks = false,
  viewAllPath
}) => queries.length > 0 ? (
  <>
    <StuiTable>
      <StuiTableHead>
        <StuiTableRow>
          <StuiTableHeadCell>Term</StuiTableHeadCell>
          <StuiTableHeadCell>Queries</StuiTableHeadCell>
          {showClicks && <StuiTableHeadCell>Clicks</StuiTableHeadCell>}
          <StuiTableHeadCell />
        </StuiTableRow>
      </StuiTableHead>
      <StuiTableBody>
        {queries.map(({ key, searches, clicks }) => {
          const query = key ? key : '""'
          return (
            <StuiTableRow key={key}>
              <StuiTableBodyCell><Link to={getQueryDetailRoute(engineName, query)}>{query}</Link></StuiTableBodyCell>
              <StuiTableBodyCell>{searches && searches.doc_count}</StuiTableBodyCell>
              {showClicks && <StuiTableBodyCell>{clicks && clicks.doc_count}</StuiTableBodyCell>}
              <StuiTableBodyCell><QueryDetailMenu query={query} engineName={engineName} /></StuiTableBodyCell>
            </StuiTableRow>
          )
        })}
      </StuiTableBody>
    </StuiTable>
    {viewAllPath && queries.length > 0 && <ViewAllButton path={getEngineRoute(viewAllPath, engineName)} />}
  </>
) : (
  <StuiEmptyState
    compact={true}
    title="No queries"
    description="No  queries were performed during this time period."
    icon="analytics"
    className="metric-empty-state"
  />
)

export default TopResultsTable
