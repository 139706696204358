import routes from 'app_search/routes'
import http from 'shared/http'
import { storeLogic } from 'shared/store'

import _debounce from 'lodash/debounce'

import { EngineLogic } from 'app_search/Engine'

import { IMeta, IObject } from 'app_search/types'
import handleAPIError from 'app_search/utils/handleAPIError'
import { getDocumentsRoute } from 'app_search/utils/routePaths'
import { DEFAULT_META } from 'shared/constants/defaultMeta'
import { POLLING_DURATION } from 'shared/constants/polling'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

const emptyQuery = ''
const DELETE_MESSAGE = 'Are you sure you want to delete this document?'

export const DocumentsLogic = storeLogic({
  connect: () => ({
    actions: [
      EngineLogic, ['setIndexingStatus']
    ],
    values: [
      EngineLogic, ['engine', 'engineName']
    ]
  }),
  actions: () => ({
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages }),
    setQuery: (query: string) => ({ query }),
    setSearchResults: (searchMeta: IMeta, searchResults: IObject[]) => ({ searchMeta, searchResults }),
    setPollingTimeoutId: (pollingTimeoutId: number) => pollingTimeoutId,
    setLoading: (loading: boolean) => ({ loading }),
    setFields: (fields: IObject[]) => ({ fields }),
    resetDocumentsState: true,
    openDocumentCreation: true,
    closeDocumentCreation: false
  }),
  reducers: ({ actions }) => ({
    dataLoading: [true, {
      [actions.setSearchResults]: () => false,
      [actions.resetDocumentsState]: () => true,
      [actions.setFields]: () => false,
      [actions.setFlashMessages]: () => false
    }],
    flashMessages: [{}, {
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages,
      [actions.setQuery]: () => ({}),
      [actions.resetDocumentsState]: () => ({})
    }],
    loading: [true, {
      [actions.setLoading]: (_, { loading }) => loading,
      [actions.setSearchResults]: () => false,
      [actions.setFlashMessages]: () => false
    }],
    pollingTimeoutId: [null, {
      [actions.setPollingTimeoutId]: (_, pollingTimeoutId) => pollingTimeoutId
    }],
    showDocumentCreation: [false, {
      [actions.openDocumentCreation]: () => true,
      [actions.closeDocumentCreation]: () => false
    }],
    searchMeta: [DEFAULT_META, {
      [actions.setSearchResults]: (_, { searchMeta }) => searchMeta
    }],
    searchResults: [[], {
      [actions.setSearchResults]: (_, { searchResults }) => searchResults
    }],
    query: [emptyQuery, {
      [actions.setQuery]: (_, { query }) => query,
      [actions.resetDocumentsState]: () => emptyQuery
    }],
    fields: [[], {
      [actions.setFields]: (_, { fields }) => fields
    }]
  }),
  selectors: ({ selectors }) => ({
    isPolling: [
      () => [selectors.pollingTimeoutId],
      pollingTimeoutId => !!pollingTimeoutId
    ],
    hasSearchResults: [
      () => [selectors.searchMeta],
      searchMeta => searchMeta.page.total_results > 0
    ],
    shouldStartPolling: [
      () => [selectors.query, selectors.isPolling, selectors.hasSearchResults],
      (query, isPolling, hasSearchResults) => query === emptyQuery && !isPolling && !hasSearchResults
    ],
    showEmptyState: [
      () => [selectors.isPolling, selectors.hasSearchResults],
      (isPolling, hasSearchResults) => isPolling && !hasSearchResults
    ]
  }),
  thunks: ({ actions, get }) => ({
    getDocuments: (successCallback, nextPage = 1) => {
      const query = get('query')
      const route = routes.locoMocoEngineDocumentsPath({
        query,
        engine_slug: get('engineName'),
        format: 'json'
      })

      http.post(route, { page: { current: nextPage } })
        .then(({ data }) => successCallback(data))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    getDocumentDetails: (documentId) => {
      const route = routes.locoMocoEngineDocumentPath(get('engineName'), documentId)

      http.get(route)
        .then(({ data }) => actions.setFields(data.fields))
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    pollForDocuments: () => {
      const pollingTimeoutId = window.setTimeout(() => actions.getDocuments(actions.onPollingSuccess), POLLING_DURATION)
      actions.setPollingTimeoutId(pollingTimeoutId)
    },
    onSearchResultsSuccess: ({ meta, results }) => {
      actions.setSearchResults(meta, results)
      if (get('shouldStartPolling')) {
        actions.pollForDocuments()
      }
    },
    getSearchResults: _debounce(
      (nextPage = 1) => {
        actions.setLoading(true)
        actions.getDocuments(actions.onSearchResultsSuccess, nextPage)
      },
      50
    ),
    onPollingSuccess: ({ meta, results }) => {
      if (meta.page.total_results > 0) {
        actions.setSearchResults(meta, results)
      } else {
        actions.pollForDocuments()
      }
    },
    updateValue: query => {
      actions.setQuery(query)
      actions.getSearchResults()
    },
    deleteDocument: (e, documentId, history) => {
      e.preventDefault()
      const engineName = get('engineName')
      if (window.confirm(DELETE_MESSAGE)) {
        const url = routes.locoMocoEngineDocumentPath(engineName, documentId)

        http.delete(url)
          .then(({ data }) => {
            history.push(getDocumentsRoute(engineName))
            actions.setFlashMessages(data.flashMessages)
          })
          .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
      }
    }
  }),
  events: ({ actions, values }) => ({
    beforeUnmount() {
      clearTimeout(values.pollingTimeoutId)
      actions.resetDocumentsState()
    }
  })
})
