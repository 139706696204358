import React from 'react'

import { Link } from 'react-router-dom'

import { getCurationRoute } from 'app_search/utils/routePaths'

interface ICurationsBackToCurationButtonProps {
  engineName: string
  curationId: string
}

const CurationsBackToCurationButton: React.SFC<ICurationsBackToCurationButtonProps> = ({ engineName, curationId }) => (
  <div className="stui-view-topbar" key="breadcrumbs">
    <div className="o-stui-breadcrumbs">
      <Link className="o-stui-breadcrumb" to={getCurationRoute(engineName, curationId)}>&larr; Back to curation</Link>
    </div>
  </div>
)

export default CurationsBackToCurationButton
